import React from "react";
import ClientTabs from "../../common/ClientTabs";
import AddFiles from "../Client/Files/AddFiles";
import Grids from "../Client/Files/Grids";
import UploadFiles from "../Client/Files/UploadFiles";
import FileListing from "../Client/Files/FileListing";
const Files = () => {
    return (
        <div className="right_section font-poppins">
            <div className="clients-detail-area py-3">
                <ClientTabs />
                <div className="row files_area mt-2">
                    <div className="col-md-7 left_files p-0">
                        <div className="row">
                            <Grids />
                            <AddFiles />
                            <div className="col-lg-12"><hr className="mt-4 mb-0" /></div>
                        </div>
                        <FileListing />
                    </div>
                    <UploadFiles />
                </div>
            </div>
        </div>
    )
}
export default Files;