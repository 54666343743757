import React, { useState, useEffect, useContext } from "react";
import { http } from "../../http/http";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { GlobalContext } from "../../../App";
const Edit_Invoice = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { showLoader, setShowLoader } = useContext(GlobalContext);
    const [clientList, setClientList] = useState([]);
    const [copClientList, setCopClientList] = useState([]);
    const [isEditMode, setIsEditMode] = useState(false);
    const [taxRate, setTaxRate] = useState("");
    const [getTaxList, setGetTaxList] = useState([]);
    const [editInvoiceDetail, setEditInvoiceDetail] = useState({
        invoice_title: '',
        invoice_date: '',
        due_date: '',
        currency: '',
        notes: '',
        client_id: '',
        client_type: null,
        total_price: 0,
        item_discount: '',
        tax_id: '',
        tax_price: '',
        items: []
    });

    const [subTotalAmount, setSubTotalAmount] = useState(editInvoiceDetail?.total_price);
    const [totalGst, setTotalGst] = useState([]);
    const [invoiceId, setInvoiceId] = useState(0);
    const [finalAmountArray, setFinalAmountArray] = useState([]);
    const [finalAmountSubTotal, setFinalAmountSubTotal] = useState(0);
    const [finalGstAmountSubTotal, setFinalGstAmountSubTotal] = useState(0);
    const [gstItemValue, setGstItemValue] = useState(0);
    const [gstItemValueId, setGstItemValueId] = useState(0);
    const [discountItem, setDiscountItem] = useState(0);
    const [selectedDiscountType, setSelectedDiscountType] = useState("1");
    const [deleteInvoice, setDeleteInvoice] = useState(false);
    const [editInvoiceData, setEditInvoiceData] = useState([])
    const [totalDiscountPrice, setTotalDiscountprise] = useState(0);
    const today = new Date().toISOString().split('T')[0];
    const [errors, setErrors] = useState({});





    const handleRemoveFields = (id, index) => {

        var arr = editInvoiceDetail;
        arr.splice(index, 1)
        setEditInvoiceDetail(arr)
        setDeleteInvoice(!deleteInvoice)


    };
    // Function to handle general input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditInvoiceData({
            ...editInvoiceData,
            [name]: value
        });
    };

    // // Function to handle client selection
    // const handleClientSelection = (event) => {
    //     const clientId = event.target.value;
    //     const clientType = editInvoiceData.client_type;

    //     if (clientType == 1) {
    //         const selectedClient = clientList.find(client => client.id == clientId);
    //         setEditInvoiceData({
    //             ...editInvoiceData,
    //             client_id: clientId, // Ensure client_id is updated
    //             customer: { ...editInvoiceData.customer, id: clientId, first_name: selectedClient.first_name, email: selectedClient.email }
    //         });
    //     } else if (clientType == 2) {
    //         const selectedClient = copClientList.find(client => client.id == clientId);
    //         setEditInvoiceData({
    //             ...editInvoiceData,
    //             client_id: clientId, // Ensure client_id is updated
    //             customer: { ...editInvoiceData.customer, id: clientId, name: selectedClient.name, email: selectedClient.email }
    //         });
    //     }
    //     setIsEditMode(false);
    // };

    // Function to enter edit mode
    const handleEditClick = () => {
        setIsEditMode(true);
    };

    // Function to fetch invoice details
    const editInvoiceHandle = () => {
        http(
            {
                method: "GET",
                url: `invoice/${id}/edit`,
                isSecure: true,
            },
            (res) => {
                setInvoiceId(id);
                setEditInvoiceDetail(res?.data?.invoice?.invoice_items);
                setEditInvoiceData(res?.data?.invoice);

            },
            (err) => {
                // Handle errors according to the response
                if (err.status === 401) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else if (err.status === 400) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else {
                    toast.error(err.single_message);
                }
            }
        );
    };





    // const amountArrayConvertion = (amountArray) => {


    //     if (amountArray?.length > 0) {
    //         const finalAmounts = amountArray.map(item => {
    //             const discount = item.item_discount ? parseFloat(item.item_discount) : 0;
    //             const amount = item.item_discount_price_type === "1"
    //                 ? (item.price * item.quantity) - discount
    //                 : (item.price * item.quantity) - ((item.price * item.quantity) * discount / 100);


    //             const gstAmount = amount * (item?.tax?.rate || 0) / 100;
    //             const itemGstAmount = amount + gstAmount;
    //             item.item_total = amount;

    //             return {
    //                 ...item,
    //                 discount_amount: item.item_discount_price_type === "1"
    //                     ? discount
    //                     : ((item.price * item.quantity) * discount / 100),
    //                 itemAmount: amount,
    //                 gstAmount: gstAmount,
    //                 itemGstAmount: itemGstAmount
    //             };
    //         });

    //         let totalgst = {};
    //         let totalDiscount = 0;
    //         finalAmounts.forEach((item) => {

    //             if (item.tax_id) {
    //                 const key = `${item.tax?.name}_${item.tax?.rate}%`;

    //                 if (!totalgst[key]) {
    //                     totalgst[key] = 0;
    //                 }

    //                 totalgst[key] += item.gstAmount;
    //             }
    //             totalDiscount += parseFloat(item.discount_amount) || 0;
    //         });

    //         let gstTotalNote = [];

    //         for (let key in totalgst) {
    //             gstTotalNote.push({ type: key, value: totalgst[key] });
    //         }

    //         setTotalGst(gstTotalNote);
    //         setFinalAmountArray(finalAmounts);
    //         setTotalDiscountprise(totalDiscount.toFixed(2));

    //         const totalAmount = finalAmounts.reduce((total, item) => total + item.itemAmount, 0);
    //         setFinalGstAmountSubTotal(totalAmount);

    //         const totalFinalAmount = finalAmounts.reduce((total, item) => total + item.itemGstAmount, 0);
    //         setFinalAmountSubTotal(totalFinalAmount);


    //     }
    // };
    const amountArrayConvertion = (amountArray) => {
        if (amountArray?.length > 0) {
            const finalAmounts = amountArray.map(item => {
                const discount = item.item_discount ? parseFloat(item.item_discount) : 0;
                const amount = item.item_discount_price_type === "1"
                    ? (item.price * item.quantity) - discount
                    : (item.price * item.quantity) - ((item.price * item.quantity) * discount / 100);

                const taxRate = parseFloat(item?.tax?.rate || 0); // Ensure rate is parsed correctly
                const gstAmount = parseFloat(((amount * taxRate) / 100).toFixed(2)); // Precise GST calculation
                const itemGstAmount = parseFloat((amount + gstAmount).toFixed(2)); // Total with GST
                item.item_total = parseFloat(amount.toFixed(2)); // Ensure total is consistent

                return {
                    ...item,
                    discount_amount: item.item_discount_price_type === "1"
                        ? discount
                        : ((item.price * item.quantity) * discount / 100),
                    itemAmount: amount,
                    gstAmount: gstAmount,
                    itemGstAmount: itemGstAmount,
                    tax: {
                        ...item.tax,
                        rate: taxRate.toFixed(2), // Ensure tax rate is always shown as 7.00
                    }
                };
            });

            let totalgst = {};
            let totalDiscount = 0;
            finalAmounts.forEach((item) => {
                if (item.tax_id) {
                    const key = `${item.tax?.name}_${item.tax?.rate}%`;

                    if (!totalgst[key]) {
                        totalgst[key] = 0;
                    }

                    totalgst[key] += item.gstAmount;
                }
                totalDiscount += parseFloat(item.discount_amount) || 0;
            });

            let gstTotalNote = [];
            for (let key in totalgst) {
                gstTotalNote.push({ type: key, value: parseFloat(totalgst[key].toFixed(2)) });
            }

            setTotalGst(gstTotalNote);
            setFinalAmountArray(finalAmounts);
            setTotalDiscountprise(totalDiscount.toFixed(2));

            const totalAmount = finalAmounts.reduce((total, item) => total + item.itemAmount, 0);
            setFinalGstAmountSubTotal(parseFloat(totalAmount.toFixed(2)));

            const totalFinalAmount = finalAmounts.reduce((total, item) => total + item.itemGstAmount, 0);
            setFinalAmountSubTotal(parseFloat(totalFinalAmount.toFixed(2)));
        }
    };


    const handleTaxChange = (event, index_val) => {
        const { value } = event.target;


        if (!value) {
            console.error("Empty or invalid value selected.");
            return; // Exit early if value is invalid
        }

        const splitArray = value.split("_");
        if (splitArray.length < 3) {
            console.error("Invalid value format:", value);
            return; // Exit if value format is incorrect
        }

        const rate = parseFloat(splitArray[2]);
        if (isNaN(rate)) {
            console.error("Invalid tax rate:", splitArray[2]);
            return; // Exit if tax rate is not a number
        }

        // const setArrayVal = editInvoiceDetail.map((element, index) => {
        //     if (index === index_val) {
        //         // Ensure precise tax_price calculation
        //         const tax_price = parseFloat(
        //             ((element.price * element.quantity * rate) / 100).toFixed(2)
        //         );

        //         return {
        //             ...element,
        //             tax_id: splitArray[0],
        //             tax: {
        //                 ...element.tax,
        //                 name: splitArray[1],
        //                 rate: rate,
        //                 id: splitArray[0],
        //             },
        //             tax_price, // Use precise tax_price
        //         };
        //     }
        //     return element;
        // });

        const setArrayVal = editInvoiceDetail && editInvoiceDetail.map((element, index) => {
            if (index === index_val) {
                const rate = parseFloat(splitArray[2]).toFixed(2); // Format rate as 7.00
                const tax_price = parseFloat(
                    ((element.price * element.quantity * rate) / 100).toFixed(2)
                );
                return {
                    ...element,
                    tax_id: splitArray[0],
                    tax: {
                        ...element.tax,
                        name: splitArray[1],
                        rate: parseFloat(rate).toFixed(2), // Format rate as 7.00
                        id: splitArray[0],
                    },
                    tax_price, // Use precise tax_price
                };
            }
            return element;
        });

        setEditInvoiceDetail(setArrayVal);

        setGstItemValueId(setArrayVal[index_val].id);
    };


    const handleItemDiscountTypeChange = (id, type, index_val) => {


        // Create a copy of the state array
        const updatedInvoiceDetail = [...editInvoiceDetail];


        // Update the specific item in the array immutably
        updatedInvoiceDetail[index_val] = {
            ...updatedInvoiceDetail[index_val],
            item_discount_price_type: type
        };

        // Set the new array to the state
        setEditInvoiceDetail(updatedInvoiceDetail);

        // Recalculate amounts
        amountArrayConvertion(updatedInvoiceDetail);
    };


    const handleDiscountChange = (id, value, index_val) => {
        // Create a copy of the state array
        const updatedInvoiceDetail = [...editInvoiceDetail];

        // Update the specific item in the array immutably
        updatedInvoiceDetail[index_val] = {
            ...updatedInvoiceDetail[index_val],
            item_discount: (value)
        };

        // Set the new array to the state
        setEditInvoiceDetail(updatedInvoiceDetail);

        // Recalculate amounts
        amountArrayConvertion(updatedInvoiceDetail);
    };


    // const handlePriceChange = (id, value, index_val) => {

    //     var setArrayVal = [...editInvoiceDetail];
    //     setArrayVal.map((element, index) => {
    //         if (index == index_val) {

    //             setArrayVal[index].price = (value);
    //         }
    //     });

    //     setEditInvoiceDetail(setArrayVal);

    // };

    const handlePriceChange = (id, value, index_val) => {
        const updatedInvoiceDetail = [...editInvoiceDetail];
        updatedInvoiceDetail[index_val] = {
            ...updatedInvoiceDetail[index_val],
            price: value
        };
        setEditInvoiceDetail(updatedInvoiceDetail);

        // Clear the error if the price is valid
        if (!isNaN(value) && parseFloat(value) > 0) {
            setErrors(prevErrors => {
                const { [`price${index_val}`]: _, ...remainingErrors } = prevErrors;
                return remainingErrors;
            });
        }

        // Recalculate amounts if necessary
        amountArrayConvertion(updatedInvoiceDetail);
    };


    // const handleQuantityChange = (id, value, index_val) => {
    //     // Create a copy of the state array
    //     const updatedInvoiceDetail = [...editInvoiceDetail];

    //     // Update the specific item in the array immutably
    //     updatedInvoiceDetail[index_val] = {
    //         ...updatedInvoiceDetail[index_val],
    //         quantity: (value)
    //     };

    //     // Set the new array to the state
    //     setEditInvoiceDetail(updatedInvoiceDetail);

    //     // Recalculate amounts
    //     amountArrayConvertion(updatedInvoiceDetail);
    // };
    const handleQuantityChange = (id, value, index_val) => {
        const updatedInvoiceDetail = [...editInvoiceDetail];
        updatedInvoiceDetail[index_val] = {
            ...updatedInvoiceDetail[index_val],
            quantity: value
        };
        setEditInvoiceDetail(updatedInvoiceDetail);

        // Clear the error if quantity is valid
        if (!isNaN(value) && parseInt(value) > 0) {
            setErrors(prevErrors => {
                const { [`quantity${index_val}`]: _, ...remainingErrors } = prevErrors;
                return remainingErrors;
            });
        }

        // Recalculate amounts if necessary
        amountArrayConvertion(updatedInvoiceDetail);
    };
    // const handleInputChange = (index, event) => {
    //     const { name, value } = event.target;
    //     const newAmountArray = [...editInvoiceDetail];
    //     newAmountArray[index][name] = value;
    //     setEditInvoiceDetail(newAmountArray);
    // };

    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        const updatedInvoiceDetail = [...editInvoiceDetail];
        updatedInvoiceDetail[index] = {
            ...updatedInvoiceDetail[index],
            [name]: value
        };
        setEditInvoiceDetail(updatedInvoiceDetail);

        // Clear error for 'name' or 'description' if the value is valid
        if ((name === 'name' || name === 'description') && value.trim()) {
            setErrors(prevErrors => {
                const { [`${name}${index}`]: _, ...remainingErrors } = prevErrors;
                return remainingErrors;
            });
        }
    };





    useEffect(() => {

        amountArrayConvertion(editInvoiceDetail);
    }, [discountItem, gstItemValue, selectedDiscountType, editInvoiceDetail, gstItemValueId, deleteInvoice]);

    useEffect(() => {
        editInvoiceHandle();
    }, []);
    useEffect(() => {
        amountArrayConvertion(editInvoiceDetail);

    }, [editInvoiceDetail]);

    // Function to handle invoice update
    // const updateInvoiceHandle = (e) => {
    //     e.preventDefault();
    //     // Run validation before submitting
    //     if (!validateForm()) {
    //         return; // Stop execution if the form is invalid
    //     }

    //     const payload = {
    //         invoice_title: editInvoiceData.invoice_title,
    //         invoice_date: editInvoiceData.invoice_date,
    //         due_date: editInvoiceData.due_date,
    //         currency: editInvoiceData.currency,
    //         notes: editInvoiceData.notes,
    //         client_id: editInvoiceData.client_id,
    //         client_type: editInvoiceData.client_type,
    //         company_profile_id: 1,
    //         discount: editInvoiceData?.discount,
    //         status: editInvoiceData?.status || "1",
    //         items: editInvoiceDetail?.map(item => ({
    //             name: item.name,
    //             description: item.description,
    //             quantity: item.quantity,
    //             price: item.price,
    //             item_discount: item.item_discount,
    //             item_discount_price_type: item.item_discount_price_type,
    //             tax_id: item.tax_id,
    //             tax_price: item.tax_price,// Include tax_price in payload
    //             tax_rate: parseFloat(item.tax_price),
    //         }))
    //     };

    //     http(
    //         {
    //             method: "PUT",
    //             url: `invoice/update/${invoiceId}`,
    //             isSecure: true,
    //             body: payload
    //         },
    //         (res) => {
    //             // Handle success
    //             navigate("/admin/invoice/invoice_detail");
    //         },
    //         (err) => {
    //             // Handle error
    //         }
    //     );
    // };


    const updateInvoiceHandle = (e) => {
        e.preventDefault();

        // Run validation before submitting
        if (!validateForm()) {
            return; // Stop execution if the form is invalid
        }
        setShowLoader(true);

        const payload = {
            invoice_title: editInvoiceData.invoice_title,
            invoice_date: editInvoiceData.invoice_date,
            due_date: editInvoiceData.due_date,
            currency: editInvoiceData.currency,
            notes: editInvoiceData.notes,
            client_id: editInvoiceData.client_id,
            client_type: editInvoiceData.client_type,
            company_profile_id: 1,
            discount: editInvoiceData?.discount,
            status: editInvoiceData?.status || "1",
            items: editInvoiceDetail?.map((item) => {

                // Calculate tax_rate dynamically if rate is missing
                const tax_rate = item.rate && !isNaN(item.rate)
                    ? parseFloat(item.rate) // Use rate if available
                    : item.tax_price && item.price && item.quantity
                        ? (parseFloat(item.tax_price) / (parseFloat(item.price) * parseInt(item.quantity, 10))) * 100
                        : 0; // Calculate or fallback to 0

                // Ensure tax_rate has two decimal precision
                const tax_rate_fixed = tax_rate.toFixed(2);

                // Calculate tax_price with precise formatting
                const tax_price = parseFloat(item.tax_price || 0).toFixed(2);


                return {
                    name: item.name,
                    description: item.description,
                    quantity: parseInt(item.quantity, 10), // Ensure quantity is an integer
                    price: parseFloat(item.price).toFixed(2), // Ensure price is formatted
                    item_discount: parseFloat(item.item_discount).toFixed(2), // Ensure discount is formatted
                    item_discount_price_type: item.item_discount_price_type,
                    tax_id: item.tax_id,
                    tax_price, // Use the precisely formatted tax_price
                    tax_rate: tax_rate_fixed, // Use the precisely formatted tax_rate
                };
            }),
        };

        http(
            {
                method: "PUT",
                url: `invoice/update/${invoiceId}`,
                isSecure: true,
                body: payload,
            },
            (res) => {
                toast.success(res.single_message)
                setShowLoader(false);
                navigate("/admin/invoice/invoice_detail");
            },
            (err) => {
                // Handle errors according to the response
                if (err.status === 401) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else if (err.status === 400) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else {
                    toast.error(err.single_message);
                    setShowLoader(false);
                }
            }
        );
    };



    const getClientList = () => {

        http(
            {
                method: "GET",
                url: "clients",
                isSecure: true,

            },
            (res) => {
                setClientList(res.data?.data);
            },
            (err) => {

            }
        )
    }
    useEffect(() => {
        getClientList();
    }, []);

    const getCopClientList = () => {

        http(
            {
                method: "GET",
                url: "corporate/clients",
                isSecure: true,

            },
            (res) => {
                setCopClientList(res.data?.data);
            },
            (err) => {

            }
        )
    }
    useEffect(() => {
        getCopClientList();
    }, []);
    const getTaxRate = () => {
        http(
            {
                method: "GET",
                url: "invoice/listingtax",
                isSecure: true,

            },
            (res) => {
                setGetTaxList(res?.data?.data)
            },
            (err) => {

            }
        )

    }
    useEffect(() => {
        getTaxRate();
    }, []);

    const handleAddItem = () => {
        var arr = editInvoiceDetail;
        var len = arr.length;

        //alert(arr[len].id);
        const newItem = {
            id: (arr[len - 1].id + 1), // or use any other unique identifier
            name: '',
            invoice_id: parseInt(invoiceId),
            description: '',
            quantity: '',
            price: '',
            item_discount: 0,
            item_discount_price_type: '1', // assuming '0' is percentage and '1' is dollar
            tax_id: '',
            tax_price: '',
            tax: { name: '', rate: 0 },
            item_total: ''
        };




        setEditInvoiceDetail(prevItems => [...prevItems, newItem]);
    };

    // Validation function
    const validateForm = () => {
        let formIsValid = true;
        let newErrors = {};

        // Validate main fields
        if (!editInvoiceData.invoice_title.trim()) {
            newErrors.invoice_title = 'Invoice title is required';
            formIsValid = false;
        }
        if (!editInvoiceData.invoice_date.trim()) {
            newErrors.invoice_date = 'Invoice date is required';
            formIsValid = false;
        }
        if (!editInvoiceData.currency.trim()) {
            newErrors.currency = 'Currency is required';
            formIsValid = false;
        }
        // Validate items array
        if (Array.isArray(editInvoiceDetail) && editInvoiceDetail.length > 0) {
            editInvoiceDetail.forEach((item, index) => {
                if (!item.name || item.name.trim() === '') {
                    newErrors[`name${index}`] = 'Item name is required';
                    formIsValid = false;
                }
                if (!item.description || item.description.trim() === '') {
                    newErrors[`description${index}`] = 'Description is required';
                    formIsValid = false;
                }
                if (!item.quantity || isNaN(item.quantity) || parseInt(item.quantity) <= 0) {
                    newErrors[`quantity${index}`] = 'Quantity must be greater than 0';
                    formIsValid = false;
                }
                if (!item.price || isNaN(item.price) || parseFloat(item.price) <= 0) {
                    newErrors[`price${index}`] = 'Price must be greater than 0';
                    formIsValid = false;
                }
            });

        }

        setErrors(newErrors);
        return formIsValid;
    };
    return (
        <div className="right_section font-poppins invoicing">
            <div className="row">
                <div className="col-md-8 offset-md-2 add-invoice-area mt-4 bg-white rounded-4 shadow-main p-4">
                    <h2 className="inv-title">Edit Invoice</h2>
                    <form className="mt-3" onSubmit={updateInvoiceHandle}>
                        <div className="row">
                            <div className="col-md-4 p-0">
                                <div className="row">
                                    <div className="col-md-12 mt-4">
                                        <label className="w-100">Title <span className="text-danger">*</span></label>
                                        <input type="text"
                                            className="form-input mt-3"
                                            name="invoice_title"
                                            placeholder="Invoice"
                                            value={editInvoiceData?.invoice_title}
                                            onChange={handleChange}
                                        />
                                        {errors.invoice_title && <div className="error">{errors.invoice_title}</div>}
                                    </div>
                                    <div className="col-md-12 mt-4">
                                        <label className="w-100">Invoice No.</label>
                                        <input
                                            type="text"
                                            className="form-input mt-3"
                                            placeholder="INV-02152"
                                            value={editInvoiceData?.invoice_number}
                                            readOnly
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="row">
                                    <div className="col-md-12 mt-4">
                                        <label className="w-100">Billing To <span className="text-danger">*</span></label>
                                        <div className="d-flex align-items-center gap-3">
                                            <div className="mt-3 radio-inv d-flex align-items-center gap-1">
                                                <input id="indi" type="radio" checked={editInvoiceData?.client_type == "1"} />
                                                <label >Individual Client</label>
                                            </div>
                                            <div className="mt-3 radio-inv d-flex align-items-center gap-1">
                                                <input id="corp" type="radio" checked={editInvoiceData?.client_type == "2"} />
                                                <label >Corporate Client</label>
                                            </div>
                                        </div>
                                        <input type="text" className="form-input mt-3 d-none" placeholder="Select Individual Client" />
                                    </div>
                                    {!isEditMode ? (
                                        <div className="col-md-12">
                                            <div className="client-info mt-2">
                                                <div className="info-head d-flex align-items-start justify-content-between">
                                                    <h3 className="fs-18">
                                                        {editInvoiceData?.client_type == 1 ? editInvoiceData?.customer?.first_name : editInvoiceData?.client_type == 2 ? editInvoiceData.customer.name : "N/A"}
                                                    </h3>

                                                    <a onClick={handleEditClick} style={{ cursor: 'pointer' }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                                            <g id="Group_856" data-name="Group 856" transform="translate(-971 -344)">
                                                                <rect id="Rectangle_276" data-name="Rectangle 276" width="30" height="30" rx="8" transform="translate(971 344)" fill="#ededee" />
                                                                <g id="write" transform="translate(971.004 344)">
                                                                    <path id="Path_3528" data-name="Path 3528" d="M8.3,26.037,8,28.468a.731.731,0,0,0,.728.827.718.718,0,0,0,.093-.006L11.257,29a2.317,2.317,0,0,0,1.35-.663l6.3-6.3a.292.292,0,0,0,0-.412l-3.232-3.235a.292.292,0,0,0-.412,0l-6.3,6.3a2.317,2.317,0,0,0-.663,1.35Z" transform="translate(0 -7.297)" fill="#202529" />
                                                                    <path id="Path_3529" data-name="Path 3529" d="M41.979,9.951,40.471,8.443a1.512,1.512,0,0,0-2.138,0L36.918,9.858a.292.292,0,0,0,0,.412L40.151,13.5a.292.292,0,0,0,.412,0l1.416-1.416A1.512,1.512,0,0,0,41.979,9.951Z" transform="translate(-20.425 0)" fill="#202529" />
                                                                    <path id="Path_3530" data-name="Path 3530" d="M36.874,52.583A.583.583,0,0,0,36.291,52H29.583a.583.583,0,0,0,0,1.167h6.707A.583.583,0,0,0,36.874,52.583Z" transform="translate(-14.877 -31.168)" fill="#202529" />
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </a>
                                                </div>

                                                <div className="info-contact text-end mt-2">
                                                    <span className="d-flex align-items-center flex-wrap justify-content-end">
                                                        <p className="fs-14 text--muted">{editInvoiceData?.customer?.primary_contact_person} |</p>
                                                        <p className="fs-14 text--muted ms-1">{editInvoiceData?.customer?.email}</p>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        // <div className="col-md-8 mt-4 dropdown-custom">
                                        //     <div className="drop-new position-relative">
                                        //         <select
                                        //             className="form-select mt-3"
                                        //             onChange={handleClientSelection}
                                        //             value={editInvoiceData?.client_id} // Ensure client_id is set correctly
                                        //         >
                                        //             {editInvoiceData?.client_type == 1 ? (
                                        //                 <React.Fragment>
                                        //                     <option value="" disabled>Select Individual Client</option>
                                        //                     {clientList.map((data) => (
                                        //                         <option key={data.id} value={data.id}>
                                        //                             {data.first_name}
                                        //                         </option>
                                        //                     ))}
                                        //                 </React.Fragment>
                                        //             ) : (
                                        //                 <React.Fragment>
                                        //                     <option value="" disabled>Select Corporate Client</option>
                                        //                     {copClientList.map((data) => (
                                        //                         <option key={data.id} value={data.id}>
                                        //                             {data.name}
                                        //                         </option>
                                        //                     ))}
                                        //                 </React.Fragment>
                                        //             )}
                                        //         </select>
                                        //     </div>
                                        // </div>
                                        <div className="col-md-8 mt-4 dropdown-custom">
                                            <div className="dropdown">
                                                <button
                                                    className="form-input dropdown-toggle w-100 text-start mt-2"
                                                    type="button"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    {editInvoiceData?.client_id
                                                        ? editInvoiceData?.client_type == 1
                                                            ? clientList.find((client) => client.id == editInvoiceData?.client_id)?.first_name || "Select Individual Client"
                                                            : copClientList.find((client) => client.id == editInvoiceData?.client_id)?.name || "Select Corporate Client"
                                                        : editInvoiceData?.client_type == 1
                                                            ? "Select Individual Client"
                                                            : "Select Corporate Client"}
                                                </button>
                                                <div className="dropdown-menu w-100 p-0">
                                                    <ul className="p-0">
                                                        <li>
                                                            <input
                                                                type="search"
                                                                className="form-input"
                                                                placeholder={editInvoiceData?.client_type === 1 ? "Search individual client..." : "Search corporate client..."}
                                                                value={editInvoiceData?.searchValue || ""}
                                                                onChange={(e) =>
                                                                    setEditInvoiceData({
                                                                        ...editInvoiceData,
                                                                        searchValue: e.target.value,
                                                                    })
                                                                }
                                                            />
                                                        </li>
                                                    </ul>
                                                    <ul className="p-0 listing_show">
                                                        {editInvoiceData?.client_type == 1
                                                            ? clientList
                                                                .filter((client) =>
                                                                    client.first_name.toLowerCase().includes(editInvoiceData?.searchValue?.toLowerCase() || "")
                                                                )
                                                                .map((client) => (
                                                                    <li key={client.id}>
                                                                        <button
                                                                            className="dropdown-item"
                                                                            type="button"
                                                                            onClick={() => {
                                                                                setEditInvoiceData({
                                                                                    ...editInvoiceData,
                                                                                    client_id: client.id,
                                                                                    customer: {
                                                                                        ...editInvoiceData.customer,
                                                                                        id: client.id,
                                                                                        first_name: client.first_name,
                                                                                        email: client.email,
                                                                                    },
                                                                                    searchValue: "", // Clear search field
                                                                                });
                                                                            }}
                                                                        >
                                                                            {client.first_name}
                                                                        </button>
                                                                    </li>
                                                                ))
                                                            : copClientList
                                                                .filter((client) =>
                                                                    client.name.toLowerCase().includes(editInvoiceData?.searchValue?.toLowerCase() || "")
                                                                )
                                                                .map((client) => (
                                                                    <li key={client.id}>
                                                                        <button
                                                                            className="dropdown-item"
                                                                            type="button"
                                                                            onClick={() => {
                                                                                setEditInvoiceData({
                                                                                    ...editInvoiceData,
                                                                                    client_id: client.id,
                                                                                    customer: {
                                                                                        ...editInvoiceData.customer,
                                                                                        id: client.id,
                                                                                        name: client.name,
                                                                                        email: client.email,
                                                                                    },
                                                                                    searchValue: "", // Clear search field
                                                                                });
                                                                            }}
                                                                        >
                                                                            {client.name}
                                                                        </button>
                                                                    </li>
                                                                ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 mt-4">
                                <label className="w-100">Order No.</label>
                                <input
                                    type="text"
                                    className="form-input mt-3"
                                    placeholder="Enter order no."
                                    value={editInvoiceData?.order_number}
                                    readOnly

                                />
                            </div>
                            <div className="col-md-4 mt-4">
                                <label className="w-100">Invoice Date <span className="text-danger">*</span></label>
                                <input
                                    type="date"
                                    className="form-input mt-3"
                                    name="invoice_date"
                                    placeholder="INV-02152"
                                    value={editInvoiceData?.invoice_date}
                                    onChange={handleChange}
                                    min={today} // Disable dates before toda

                                />
                                {errors.invoice_date && <div className="error">{errors.invoice_date}</div>}
                            </div>
                            <div className="col-md-4 mt-4">
                                <label className="w-100">Due Date</label>
                                <input
                                    type="date"
                                    className="form-input mt-3"
                                    name="due_date"
                                    placeholder="INV-02152"
                                    value={editInvoiceData?.due_date}
                                    onChange={handleChange}
                                    min={today} // Disable dates before toda

                                />
                            </div>
                            <div className="col-md-4 mt-4">
                                <label className="w-100">Currency <span className="text-danger">*</span></label>
                                <select
                                    className="form-input mt-3"
                                    name="currency"
                                    value={editInvoiceData?.currency}
                                    onChange={handleChange}>
                                    <option value="" disabled="disabled" selected>Select</option>
                                    <option value="Canadian">Canadian Dollar</option>
                                    <option value="American">American Dollar</option>

                                </select>
                                {errors.currency && <div className="error">{errors.currency}</div>}
                            </div>
                        </div>

                        <hr className="mt-4" />

                        <h4 className="fs-18 font-semibold">Add Items</h4>


                        {editInvoiceDetail?.length > 0 && editInvoiceDetail?.map((item, index) => (
                            <>
                                <div key={item.id} className="add-items-input d-flex gap-3 mt-4">

                                    <div className="d-flex gap-3">
                                        <div className="item-inp">
                                            <label className="w-100">Item <span className="text-danger">*</span></label>
                                            <input
                                                type="text"
                                                className="form-input mt-3"
                                                placeholder="Item Name"
                                                name="name"
                                                value={item?.name}
                                                onChange={(e) => handleInputChange(index, e)}
                                            />
                                            {errors[`name${index}`] && <div className="error">{errors[`name${index}`]}</div>}

                                        </div>
                                        <div className="desc-inp">
                                            <label className="w-100">Description <span className="text-danger">*</span></label>
                                            <input
                                                type="text"
                                                className="form-input mt-3"
                                                placeholder="Enter Description"
                                                name="description"
                                                value={item.description}
                                                onChange={(e) => handleInputChange(index, e)}
                                            />
                                            {errors[`description${index}`] && <div className="error">{errors[`description${index}`]}</div>}
                                        </div>
                                        <div className="qty-inp">
                                            <label className="w-100">Qty <span className="text-danger">*</span></label>
                                            <input
                                                type="number"
                                                className="form-input mt-3"
                                                placeholder="0"
                                                name="quantity"
                                                value={item.quantity}
                                                onChange={e => handleQuantityChange(item.id, e.target.value, index)}
                                            />
                                            {errors[`quantity${index}`] && <div className="error">{errors[`quantity${index}`]}</div>}
                                        </div>
                                        <div className="price-inp">
                                            <label className="w-100">Price <span className="text-danger">*</span></label>
                                            <input
                                                type="number"
                                                className="form-input mt-3"
                                                name="price"
                                                placeholder="0"
                                                value={item.price}
                                                onChange={(e) => handlePriceChange(item.id, e.target.value, index)}
                                            />
                                            {errors[`price${index}`] && <div className="error">{errors[`price${index}`]}</div>}
                                        </div>
                                        <div className="disc-inp position-relative discount">
                                            <label className="w-100">Discount</label>
                                            <div className="per-inp d-flex align-items-center">
                                                <input
                                                    type="number"
                                                    className="form-input mt-3"
                                                    name="item_discount"
                                                    placeholder="0"
                                                    value={item.item_discount}
                                                    onChange={e => handleDiscountChange(item.id, e.target.value, index)}
                                                />
                                                <div className="per d-flex align-items-center px-2 gap-2 mt-3">
                                                    <p
                                                        className={`px-1 ${item.item_discount_price_type == 1 ? 'active' : ''}`}
                                                        onClick={() => handleItemDiscountTypeChange(item.id, '1', index)}
                                                    >
                                                        $
                                                    </p>
                                                    <p
                                                        className={`px-1 ${item.item_discount_price_type == 0 ? 'active' : ''}`}
                                                        onClick={() => handleItemDiscountTypeChange(item.id, '0', index)}
                                                    >
                                                        %
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tax" key={item.id}>
                                            <label>Tax</label>


                                            <select
                                                className="form-input mt-3"
                                                id={`id${item?.tax_id}`}
                                                name="tax_id"
                                                value={`${item?.tax_id}_${item?.tax?.name}_${parseFloat(item?.tax?.rate).toFixed(2)}`}
                                                onChange={(e) => handleTaxChange(e, index)}
                                            >
                                                <option value="">Select</option>
                                                {getTaxList?.map((data) => (
                                                    <option key={data.id} value={`${data.id}_${data?.name}_${parseFloat(data.rate).toFixed(2)}`}>
                                                        {`${data?.name} (${parseFloat(data.rate).toFixed(2)}%)`}
                                                    </option>
                                                ))}



                                            </select>
                                        </div>
                                        <div className="amount-inp">
                                            <label className="w-100">Amount</label>
                                            <input
                                                type="text"
                                                className="form-input mt-3"
                                                name="item_total"
                                                placeholder="0"
                                                value={item.item_total}
                                                readOnly
                                            />
                                        </div>
                                        <div className="text-end">
                                            {index !== 0 && (
                                                <a
                                                    type="button"
                                                    onClick={() => handleRemoveFields(item.id, index)}
                                                    className="text-danger mt-4 align-content-center mt-4 h-100"
                                                >
                                                    <i className="fa fa-trash"></i>
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </>
                        ))
                        }
                        <a className="text-blue d-inline-block mt-2 fw-medium" onClick={handleAddItem}>+ Add Items</a>
                        <div className="light-blue-bg p-4 my-4">
                            <div className="row total">
                                <div className="col-md-12 text-end d-flex align-items-center justify-content-end">
                                    <h6 className="text-muted">Sub Total:</h6>
                                    <h6 className="font-weight-bold text-muted ms-5"><b>${finalGstAmountSubTotal}</b></h6>
                                </div>



                                {/* {editInvoiceDetail?.length > 0 && editInvoiceDetail?.map((item, index) => (
                                    <div key={index} className="col-md-12 text-end d-flex align-items-center justify-content-end mt-4">
                                        <h6 className="text-muted">{item?.tax?.name}</h6>
                                        <h6 className="font-weight-bold text-muted ms-5"><b>${item?.tax?.rate}</b></h6>
                                    </div>
                                ))} */}

                                {
                                    totalGst.length > 0 && totalGst?.map((item, index) => (
                                        <div key={index} className="col-md-12 text-end d-flex align-items-center justify-content-end mt-4">
                                            <h6 className="text-muted">{item.type}</h6>
                                            <h6 className="font-weight-bold text-muted ms-5"><b>${item.value.toFixed(2)}</b></h6>
                                        </div>
                                    ))
                                }
                                <div className="col-md-12 text-end d-flex align-items-center justify-content-end mt-4">
                                    <h6 className="text-muted">Total Discount:</h6>
                                    <h6 className="font-weight-bold text-muted ms-5"><b>${totalDiscountPrice}</b></h6></div>
                            </div>

                            <hr className="mt-4" />
                            {/* <div className="row total">
                                <div className="col-md-12 text-end d-flex align-items-center justify-content-end">
                                    <h6 className="text-blue">Total:</h6>
                                    <h6 className="font-weight-bold text-blue ms-5"><b>${editInvoiceDetail?.total_price}</b></h6>
                                </div>
                            </div> */}
                            <div className="row total">
                                <div className="col-md-12 text-end d-flex align-items-center justify-content-end">
                                    <h6 className="text-blue">Total:</h6>
                                    <h6 className="font-weight-bold text-blue ms-5"><b>${finalAmountSubTotal.toFixed(2)}</b></h6>
                                </div>
                            </div>
                        </div>
                        <hr className="mt-4" />

                        <div className="notes-inp">
                            <label className="w-100">Notes</label>
                            <textarea
                                className="form-input mt-3"
                                id=""
                                name="notes"
                                cols="30"
                                rows="5"
                                value={editInvoiceData?.notes}
                                onChange={handleChange}
                            >Type here....</textarea>
                        </div>

                        <div className="form-btns text-end d-flex items-center flex-wrap gap-2 mt-5 justify-content-end">

                            <button className="add-new">Update Invoice</button>
                        </div>



                    </form >
                </div >
            </div >
        </div >
    )
}
export default Edit_Invoice;