import React, { useEffect, useState } from "react";
// import AdminHeader from "../header/AdminHeader";
// import AdminSideBar from "../sideBar/AdminSideBar";
// import Footer from "../../common/Footer";
import { http } from "../../http/http";
import GeneralHelper from "../../http/GeneralHelper";
import { useNavigate } from "react-router-dom";

const AdminDashBoard = () => {
    const navigate = useNavigate();
    const [dashboardData, setDashBoardData] = useState([]);
    const [clientTasks, setClientTasks] = useState([]);
    const formatDate = (dateString) => {
        if (!dateString) return "N/A";
        const date = new Date(dateString);
        return new Intl.DateTimeFormat("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
        }).format(date);
    };

    const dashBoardData = () => {
        try {
            http(
                {
                    method: "GET",
                    url: `dashboard`,
                    isSecure: true,

                },
                (res) => {
                    setDashBoardData(res?.data)
                    setClientTasks(res?.data?.client_tasks || []);
                },
            )
        }
        catch (error) {
            console.error('Error fetching Comment data:', error);
        }
    }

    const handleEditClick = (taskId) => {
        navigate(`/admin/task/taskdetail/${taskId}`);
    };
    useEffect(() => {
        dashBoardData();
    }, []);

    return (
        <div className="right_section font-poppins">
            <div className="dashboard-indicators row">
                <div className="col-md-6 col-lg-3 indic mt-4">
                    <div className="inside grad-one d-flex align-items-center justify-content-between">
                        <div className="left-inside">
                            <h4 className="fw-bold text-black">{dashboardData?.total_clients}</h4>
                            <p className="text-blue mt-2">Total Clients</p>
                        </div>
                        <div className="right-inside p-2">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="80"
                                height="80"
                                viewBox="0 0 80 80"
                            >
                                <g
                                    id="Group_930"
                                    data-name="Group 930"
                                    transform="translate(-168 -134)"
                                >
                                    <rect
                                        id="Rectangle_158"
                                        data-name="Rectangle 158"
                                        width="80"
                                        height="80"
                                        rx="22"
                                        transform="translate(168 134)"
                                        fill="#065ac4"
                                    />
                                    <path
                                        id="Clients"
                                        d="M1166.687,1287.844a8.822,8.822,0,0,1-7.128,8.591,8.9,8.9,0,0,1-5.852-.9,8.822,8.822,0,0,1-4.222-10.326,8.878,8.878,0,0,1,3.553-4.745,8.762,8.762,0,0,1,11.078,1.129,8.534,8.534,0,0,1,1.923,2.863A9.024,9.024,0,0,1,1166.687,1287.844Zm-8.8,24.6c-7.169,0-13.294-1.129-13.294-5.685s6.083-5.727,13.294-5.727c7.17,0,13.294,1.129,13.294,5.685S1165.1,1312.446,1157.888,1312.446Zm12.562-24.434a12.074,12.074,0,0,1-2.069,6.772.386.386,0,0,0-.042.146.406.406,0,0,0,.021.167.215.215,0,0,0,.1.126.189.189,0,0,0,.146.063,5.47,5.47,0,0,0,1,.1,7.324,7.324,0,0,0,7.378-5.476,7.484,7.484,0,0,0-1.254-6.479,7.384,7.384,0,0,0-5.915-2.947,6.938,6.938,0,0,0-1.463.146.221.221,0,0,0-.188.1.227.227,0,0,0-.021.1l.063.125A12.111,12.111,0,0,1,1170.45,1288.011Zm5.686,10.869a5.815,5.815,0,0,1,2.55.815,5.91,5.91,0,0,1,1.9,1.9,3.844,3.844,0,0,1,.4,1.735,3.931,3.931,0,0,1-.4,1.756c-.962,2.09-4.055,2.759-5.267,2.927a.407.407,0,0,1-.167-.021.38.38,0,0,1-.146-.1.338.338,0,0,1-.084-.146.409.409,0,0,1-.021-.167,9.259,9.259,0,0,0-5.56-9.155l-.042-.042v-.062a.021.021,0,0,1,.021-.021c0-.021.021-.021.042-.021a30.725,30.725,0,0,1,6.772.627Zm-29.765-3.491a8.228,8.228,0,0,0,1-.1.312.312,0,0,0,.146-.063.544.544,0,0,0,.1-.126.235.235,0,0,0,.021-.167.3.3,0,0,0-.042-.146,12.194,12.194,0,0,1,.167-13.837.449.449,0,0,0,.063-.1.263.263,0,0,0-.021-.125.464.464,0,0,0-.167-.1,9.166,9.166,0,0,0-1.484-.146,7.484,7.484,0,0,0-7.295,6.062,7.723,7.723,0,0,0,.125,3.386,7.324,7.324,0,0,0,7.378,5.476Zm.334,2.947a.078.078,0,0,1-.021.063c0,.021-.021.021-.042.042a9.337,9.337,0,0,0-5.56,9.155.449.449,0,0,1,0,.167.551.551,0,0,1-.251.251.45.45,0,0,1-.167,0c-1.191-.167-4.306-.836-5.267-2.927a4.13,4.13,0,0,1-.4-1.735,4.224,4.224,0,0,1,.4-1.756,6.053,6.053,0,0,1,1.9-1.881,5.846,5.846,0,0,1,2.55-.836,33.64,33.64,0,0,1,6.793-.585.066.066,0,0,1,.042.021l.021.021Z"
                                        transform="translate(-949.992 -1121.722)"
                                        fill="#fff"
                                        fillRule="evenodd"
                                    />
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3 indic mt-4">
                    <div className="inside grad-two d-flex align-items-center justify-content-between">
                        <div className="left-inside">
                            <h4 className="fw-bold text-black">{dashboardData?.total_tasks}</h4>
                            <p className="text-blue mt-2">Total Tasks</p>
                        </div>
                        <div className="right-inside p-2">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="80"
                                height="80"
                                viewBox="0 0 80 80"
                            >
                                <g
                                    id="Group_392"
                                    data-name="Group 392"
                                    transform="translate(-647 -130)"
                                >
                                    <rect
                                        id="Rectangle_159"
                                        data-name="Rectangle 159"
                                        width="80"
                                        height="80"
                                        rx="22"
                                        transform="translate(647 130)"
                                        fill="#f5bb48"
                                    />
                                    <path
                                        id="Path_3513"
                                        data-name="Path 3513"
                                        d="M864.471,1193.944h9.9a1.361,1.361,0,0,0,.965-.4,1.379,1.379,0,0,0,.4-.974,1.347,1.347,0,0,0-.4-.956,1.361,1.361,0,0,0-.963-.4h-9.9a1.359,1.359,0,0,0-.963.4,1.342,1.342,0,0,0-.4.956,1.39,1.39,0,0,0,.4.974A1.362,1.362,0,0,0,864.471,1193.944Zm6.149-11.926h-6.149a1.362,1.362,0,0,0-.965.4,1.39,1.39,0,0,0-.4.974,1.342,1.342,0,0,0,.4.956,1.358,1.358,0,0,0,.963.4h6.149a1.357,1.357,0,0,0,.963-.4,1.34,1.34,0,0,0,.4-.956,1.382,1.382,0,0,0-.4-.974A1.359,1.359,0,0,0,870.62,1182.017Zm12.985-1.6c.428,0,.893-.018,1.316-.018a.924.924,0,0,1,.316.055,1.262,1.262,0,0,1,.266.184.731.731,0,0,1,.177.276.756.756,0,0,1,.061.312V1196a8.148,8.148,0,0,1-.61,3.142,8.382,8.382,0,0,1-1.766,2.683,8.478,8.478,0,0,1-2.655,1.782,8.018,8.018,0,0,1-3.139.643H863.087a8.639,8.639,0,0,1-8.587-8.655v-19.81a8.257,8.257,0,0,1,8.205-8.288h9.715a.658.658,0,0,1,.322.074.622.622,0,0,1,.274.184.848.848,0,0,1,.18.257.917.917,0,0,1,.063.331v5.917a6.145,6.145,0,0,0,6.077,6.137c.777,0,1.47,0,2.064.018H883.6Zm.5-2.683c-1.5,0-3.258,0-4.526-.018a3.718,3.718,0,0,1-3.675-3.712v-4.833a.874.874,0,0,1,.143-.5.937.937,0,0,1,.4-.331.966.966,0,0,1,.511-.037.846.846,0,0,1,.447.239c.983,1.048,2.338,2.463,3.675,3.878l3.64,3.822a.9.9,0,0,1,.228.441.856.856,0,0,1-.05.5.807.807,0,0,1-.314.386A1.072,1.072,0,0,1,884.106,1177.736Z"
                                        transform="translate(-183.5 -1016.167)"
                                        fill="#fff"
                                        fillRule="evenodd"
                                    />
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3 indic mt-4">
                    <div className="inside grad-three d-flex align-items-center justify-content-between">
                        <div className="left-inside">
                            <h4 className="fw-bold text-black">{dashboardData?.total_notes}</h4>
                            <p className="text-blue mt-2">Total Notes</p>
                        </div>
                        <div className="right-inside p-2">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="80"
                                height="80"
                                viewBox="0 0 80 80"
                            >
                                <g
                                    id="Group_393"
                                    data-name="Group 393"
                                    transform="translate(-1081 -130)"
                                >
                                    <rect
                                        id="Rectangle_160"
                                        data-name="Rectangle 160"
                                        width="80"
                                        height="80"
                                        rx="22"
                                        transform="translate(1081 130)"
                                        fill="#f93333"
                                    />
                                    <path
                                        id="Path_506"
                                        data-name="Path 506"
                                        d="M408.521,1279h16.59c6.114,0,9.519,3.523,9.519,9.561v20.448c0,6.136-3.4,9.58-9.519,9.58h-16.59c-6.017,0-9.521-3.444-9.521-9.58v-20.448C399,1282.523,402.5,1279,408.521,1279Zm.534,9.224v-.02h5.917a1.531,1.531,0,0,1,1.1.455,1.553,1.553,0,0,1,0,2.2,1.531,1.531,0,0,1-1.1.455h-5.917a1.544,1.544,0,0,1,0-3.088Zm0,12.035h15.519a1.545,1.545,0,0,0,0-3.088H409.055a1.545,1.545,0,0,0,0,3.088Zm0,9.046h15.519a1.5,1.5,0,0,0,.99-.515,1.556,1.556,0,0,0,0-2.078,1.5,1.5,0,0,0-.99-.515H409.055a1.587,1.587,0,0,0-1.484.732,1.584,1.584,0,0,0,0,1.662,1.528,1.528,0,0,0,1.484.713Z"
                                        transform="translate(704.667 -1129)"
                                        fill="#fff"
                                        fillRule="evenodd"
                                    />
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3 indic mt-4">
                    <div className="inside grad-four d-flex align-items-center justify-content-between">
                        <div className="left-inside">
                            <h4 className="fw-bold text-black">${dashboardData?.overall_billing}</h4>
                            <p className="text-blue mt-2">Overall Billing</p>
                        </div>
                        <div className="right-inside p-2">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="80"
                                height="80"
                                viewBox="0 0 80 80"
                            >
                                <g
                                    id="Group_394"
                                    data-name="Group 394"
                                    transform="translate(-1516 -130)"
                                >
                                    <rect
                                        id="Rectangle_161"
                                        data-name="Rectangle 161"
                                        width="80"
                                        height="80"
                                        rx="22"
                                        transform="translate(1516 130)"
                                        fill="#1c8b15"
                                    />
                                    <g
                                        id="Billing"
                                        transform="translate(1541.333 150)"
                                    >
                                        <path
                                            id="Path_3462"
                                            data-name="Path 3462"
                                            d="M4.25,10A8.746,8.746,0,0,1,13,1.25H24.044A8.746,8.746,0,0,1,32.791,10V39.457a1.381,1.381,0,0,1-2.471.848L28.93,38.519a2.3,2.3,0,0,0-3.634,0l-1.39,1.787a1.381,1.381,0,0,1-2.18,0l-1.389-1.787a2.3,2.3,0,0,0-3.634,0l-1.389,1.787a1.381,1.381,0,0,1-2.18,0l-1.39-1.787a2.3,2.3,0,0,0-3.634,0l-1.39,1.787a1.381,1.381,0,0,1-2.471-.848Zm7.365,1.841A1.381,1.381,0,0,1,13,10.457H24.044a1.381,1.381,0,0,1,0,2.762H13A1.381,1.381,0,0,1,11.615,11.838Zm0,7.365A1.381,1.381,0,0,1,13,17.822H24.044a1.381,1.381,0,0,1,0,2.762H13A1.381,1.381,0,0,1,11.615,19.2Z"
                                            transform="translate(-4.25 -1.25)"
                                            fill="#fff"
                                            fillRule="evenodd"
                                        />
                                    </g>
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tasks-area mt-4 align-items-center">
                <div className="row task-head">
                    <div className="col-md-8 left_head">
                        <h4 className="fs-20 mt-2 fw-semibold">Tasks</h4>
                    </div>
                    {/* <div className="col-md-4 right_head text-end">
                        <a href="#">
                            <img
                                src={require("../../../assets/images/plus-icon.png")}
                                alt=""
                            />
                        </a>
                    </div> */}
                </div>
                <div className="task-boxes mt-2">
                    <div className="row">
                        {clientTasks && clientTasks.map((task) => (
                            <div className="col-lg-4" key={task.id}>
                                <div className="task-white mt-3 shadow-lg">
                                    <div className="name-file d-flex justify-content-between align-items-center flex-wrap">
                                        <div className="left-name mt-1">
                                            <img
                                                src={require("../../../assets/images/user-pic.png")}
                                                alt=""
                                            />
                                            <p className="text-white">
                                                {task?.creator?.name}
                                            </p>
                                        </div>
                                        <div className="file-edit d-flex align-items-center gap-2 flex-wrap mt-1">
                                            <button className="d-flex align-items-center cursor-unset gap-1">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="8.908" height="14.999" viewBox="0 0 8.908 13.999">
                                                    <path id="XMLID_197_" d="M65.726,0h0a3.182,3.182,0,0,0-3.182,3.182V8.909a1.909,1.909,0,1,0,3.818,0V5.6a.636.636,0,0,0-1.273,0v3.3a.636.636,0,1,1-1.273,0V3.182a1.909,1.909,0,0,1,3.818,0V9.545a3.182,3.182,0,1,1-6.363,0V3.182a.636.636,0,0,0-1.273,0V9.545a4.454,4.454,0,1,0,8.908,0V3.182A3.185,3.185,0,0,0,65.726,0Z" transform="translate(-59.999)" fill="#202529" />
                                                </svg>
                                                {task?.documents?.length} Files
                                            </button>
                                            <a onClick={() => handleEditClick(task.id)} style={{ cursor: "pointer" }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                                    <g id="Group_856" data-name="Group 856" transform="translate(-971 -344)">
                                                        <rect id="Rectangle_276" data-name="Rectangle 276" width="30" height="30" rx="8" transform="translate(971 344)" fill="#ededee" />
                                                        <g id="write" transform="translate(971.004 344)">
                                                            <path id="Path_3528" data-name="Path 3528" d="M8.3,26.037,8,28.468a.731.731,0,0,0,.728.827.718.718,0,0,0,.093-.006L11.257,29a2.317,2.317,0,0,0,1.35-.663l6.3-6.3a.292.292,0,0,0,0-.412l-3.232-3.235a.292.292,0,0,0-.412,0l-6.3,6.3a2.317,2.317,0,0,0-.663,1.35Z" transform="translate(0 -7.297)" fill="#202529" />
                                                            <path id="Path_3529" data-name="Path 3529" d="M41.979,9.951,40.471,8.443a1.512,1.512,0,0,0-2.138,0L36.918,9.858a.292.292,0,0,0,0,.412L40.151,13.5a.292.292,0,0,0,.412,0l1.416-1.416A1.512,1.512,0,0,0,41.979,9.951Z" transform="translate(-20.425 0)" fill="#202529" />
                                                            <path id="Path_3530" data-name="Path 3530" d="M36.874,52.583A.583.583,0,0,0,36.291,52H29.583a.583.583,0,0,0,0,1.167h6.707A.583.583,0,0,0,36.874,52.583Z" transform="translate(-14.877 -31.168)" fill="#202529" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="box-content mt-3">
                                        <h3 className="fs-18">
                                            {task?.name}
                                        </h3>
                                        <h6 className="fs-14 text-muted">
                                            {formatDate(task.created_at)}
                                        </h6>
                                        <p className="fs-14 text-muted mt-2">
                                            {task.description
                                                ? <span dangerouslySetInnerHTML={{ __html: task.description }} />
                                                : "--"}
                                        </p>
                                    </div>
                                    <div className="task-box-footer d-flex align-items-center justify-content-between flex-wrap mt-2">
                                        <div className="left-task-footer mt-1">
                                            <div className="avatar-group">
                                                {task.assignees && task.assignees.length > 0 ? (
                                                    <>
                                                        {task.assignees.slice(0, 3).map((assignee) => (
                                                            <div className="avatar" key={assignee.id}>
                                                                {assignee.image_path ? (
                                                                    <img
                                                                        src={assignee.image_path}
                                                                        alt={assignee.name}
                                                                        title={assignee.name}
                                                                    />
                                                                ) : (
                                                                    <span
                                                                        className="avatar-text"
                                                                        title={assignee.name}
                                                                    >
                                                                        {assignee.name
                                                                            .split(" ")
                                                                            .map((part) => part[0])
                                                                            .join("")
                                                                            .slice(0, 2)
                                                                            .toUpperCase()}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        ))}
                                                        {task.assignees.length > 5 && (
                                                            <div
                                                                className="hidden-avatars"
                                                                title={task.assignees
                                                                    .slice(3)
                                                                    .map((assignee) => assignee.name)
                                                                    .join(", ")}
                                                            >
                                                                +{task.assignees.length - 3}
                                                            </div>
                                                        )}
                                                    </>
                                                ) : (
                                                    <p className="p-0 text-danger">No assignees</p>
                                                )}
                                            </div>
                                        </div>
                                        <span className={`${GeneralHelper.getTaskStatusColorName(task?.status_task_id)}_status `}>{GeneralHelper.getTaskStatusName(task?.status_task_id)}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default AdminDashBoard;
