import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { http } from "../../../http/http";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
const Add_Business_Interaction = (props) => {
    const { id } = useParams();
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
        setError, clearErrors,
        watch,
    } = useForm({ mode: "all" });
    const formValues = watch();
    const { showInderaction, setShowInderaction, handleInteractionData } = props;

    const addIntraction = () => {
        http(
            {
                method: "POST",
                url: `interaction_type/add`,
                isSecure: true,
                body: {
                    name: formValues.name,
                },
            },
            (res) => {
                toast.success(res.single_message)
                setShowInderaction(false);
                handleInteractionData();
                reset();
            },
            (err) => {
                // Handle errors according to the response
                if (err.status === 401) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else if (err.status === 400) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else {
                    toast.error(err.single_message);
                }
            }
        );
    }
    return (
        <Modal
            show={showInderaction}
            onHide={setShowInderaction}
            centered
            contentClassName="mx-auto"
            className="modal pin-modal font-poppins"
            backdrop="static"
            keyboard={false}
        >
            <div className="modal-header border-0 justify-content-between">
                <h4 className="fs-20">Add Interaction Type</h4>
                <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
            </div>
            <div className="modal-body">
                <div class="row services-form">
                    <div className="col-lg-12">
                        <form onSubmit={handleSubmit(addIntraction)}>
                            <div className="col-lg-12">
                                <label className="mt-2">Interaction Type</label>
                                <div className="text-start">
                                    <input
                                        name="name"
                                        id="name"
                                        className="form-input mt-2 mx-0"
                                        type="text"
                                        {...register("name", {
                                            required: "This field is required",
                                        })}
                                    />
                                    {errors?.name && <span className="error">{errors?.name.message}</span>}
                                </div>
                            </div>

                            <div className="col-lg-12 mt-4 mb-2">
                                <button type="submit" class="btn-blue ms-auto">Add</button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </Modal>
    )
}
export default Add_Business_Interaction;