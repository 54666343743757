// AdminHeader.js

import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Auth from "../../../auth/Auth";
import { http } from "../../http/http";
import Breadcrumbs from "./Breadcrumbs";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { GlobalContext } from "../../../App";
import { handlePermissionData, handlePermissionIdsData } from '../../http/help';



const AdminHeader = ({ toggleSidebar }) => {
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
        setError, clearErrors,
        watch,
    } = useForm({ mode: "all" });



    const formValues = watch();
    const { setShowLoader } = useContext(GlobalContext);
    const [userData, setUserData] = useState(null);
    const navigate = useNavigate();
    const [isSearchToggled, setIsSearchToggled] = useState(false);
    const [passwordModal, setPasswordModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [editProfileData, setEditProfileData] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassowrd, setShowConfirmPassword] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [profileImage, setProfileImage] = useState(""); // To store the selected image
    const [previewImage, setPreviewImage] = useState(""); // To display image preview
    const [roleData, setRoleData] = useState(null);



    const handleAdminLogout = () => {
        setShowLoader(true);
        http(
            {
                method: "POST",
                url: "logout",
                isSecure: true,

                body: {},
            },
            (res) => {
                if (res) {
                    localStorage.removeItem("createPassword");
                    localStorage.removeItem("formValues");
                    localStorage.removeItem("permission");
                    localStorage.removeItem("permissionIds")
                    Auth.logout();
                    navigate("/admin/login");
                    toast.success(res.single_message);
                    setShowLoader(false);
                }
            },
            (err) => {
                if (err.status_code == 402) {
                    navigate("/admin/login");
                    setShowLoader(false);
                }
            }
        );
    };

    const handleChangePassword = () => {
        setShowLoader(true);
        if (formValues.new_password !== formValues.new_password_confirmation) {
            toast.error("Passwords do not match");
            return;
        }
        http(
            {
                method: "POST",
                url: "change-password",
                isSecure: true,
                body: {
                    current_password: formValues.current_password,
                    new_password: formValues.new_password,
                    new_password_confirmation: formValues.new_password_confirmation,
                },
            },
            (res) => {
                toast.success(res.single_message);
                Auth.logout();
                navigate("/admin/login");
                setShowLoader(false);

            },
            (err) => {
                // Handle errors according to the response
                if (err.status === 401) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else if (err.status === 400) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else {
                    toast.error(err.single_message);
                    setShowLoader(false);
                }
            }
        );
    };

    // Define the breadcrumb based on the current location
    const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };


    const toggleSearch = () => {
        setIsSearchToggled(!isSearchToggled);
    };

    // useEffect(() => {
    //     const userDataFromStorage = JSON.parse(localStorage.getItem("__assist_erp"));
    //     const userRoleDataStorage = JSON.parse(localStorage.getItem("permission"));
    //     setUserData(userDataFromStorage?.user);
    //     setRoleData(userRoleDataStorage);
    // }, []);

    // EDIT PROFILE FUNCTION
    const getEditProfile = () => {
        http(
            {
                method: "GET",
                url: "edit-profile",
                isSecure: true,

            },
            (res) => {
                setEditProfileData(res?.data)
            },
            (err) => {
            }
        );
    }
    useEffect(() => {
        getEditProfile()
    }, [])

    useEffect(() => {
        if (editProfileData) {
            setFirstName(editProfileData.first_name || "");
            setLastName(editProfileData.last_name || "");
            setProfileImage(editProfileData?.image_path || "");
            setPreviewImage(editProfileData.image_path || "");
        }
    }, [editProfileData]);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setProfileImage(file);
            setPreviewImage(URL.createObjectURL(file)); // Create a temporary preview URL
        }
    };


    const handleSubmitPorfile = (e) => {
        e.preventDefault();
        setShowLoader(true);
        const formData = new FormData();

        // Append form values with checks
        if (firstName) formData.append("first_name", firstName);
        if (lastName) formData.append("last_name", lastName);

        if (profileImage instanceof File) {
            formData.append("image", profileImage); // Append file only if it's a valid File object
        }

        http(
            {
                method: "POST",
                url: "update-profile",
                isSecure: true,
                contentType: "multipart/form-data",
                body: formData,
            },
            (res) => {
                toast.success(res.single_message);
                handleUserDetails();
                setEditModal(false); // Close the modal on success
                setShowLoader(false);
            },
            (err) => {

                // Handle errors based on status
                if (err.status === 401) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else if (err.status === 400) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else {
                    toast.error(err.single_message);
                    setShowLoader(false);
                }
            }
        );
    };

    const handleCloseModal = () => {
        setEditProfileData(false)
    }
    useEffect(() => {

        handlePermissionIdsData();
        handlePermissionData();

    }, []);

    const handleUserDetails = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `user/roles-permissions`,
                    isSecure: true,
                },
                (res) => {
                    setRoleData(res?.data);
                },
            )
        }
        catch (error) {
            console.error('Error fetching staff data:', error);
        }
    };

    useEffect(() => {
        handleUserDetails();
    }, []);


    return (
        <>
            {/* <!-- HEADER START --> */}
            <nav className="navbar navbar-expand-lg navbar-light bg-transparent py-2 px-4 header-main font-poppins bg-white">
                <div className="mobile-menu">
                    <i
                        className="fas fa-align-left primary-text fs-4 me-3"
                        id="menu-toggle"
                        onClick={toggleSidebar}
                    ></i>
                </div>
                <div className="left-header d-flex align-items-center">
                    <h2 className="m-0 fs-24"><Breadcrumbs /> </h2>
                </div>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div
                    className="collapse navbar-collapse"
                    id="navbarSupportedContent"
                >
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0 right-header d-flex align-items-center ">
                        {/* <li>
                            <div
                                className="searchicon position-relative"
                                onClick={toggleSearch}
                            >
                                <img
                                    src={require("../../../assets/images/search.png")}
                                    alt=""
                                />
                                <span className="d-block rounded-circle position-absolute top-0 end-0"></span>
                            </div>
                        </li> */}
                        {/* <hr /> */}
                        <li>
                            <div className="messageicon position-relative">
                                <img
                                    src={require("../../../assets/images/message.png")}
                                    alt=""
                                />
                                <span className="d-block rounded-circle position-absolute top-0 end-0"></span>
                            </div>
                        </li>
                        <hr />
                        <li>
                            <div
                                className="bellicon position-relative"
                            // data-bs-target="#notification-profile"
                            // data-bs-toggle="modal"
                            >
                                <img
                                    src={require("../../../assets/images/bell.png")}
                                    alt=""
                                />
                                <span className="d-block rounded-circle position-absolute top-0 end-0"></span>
                            </div>
                        </li>
                        <hr />
                        <li className="nav-item dropdown dropdown-profile">
                            <a
                                className="nav-link dropdown-toggle second-text fw-bold d-flex align-items-center profile"
                                href="#"
                                id="navbarDropdown"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <div className="user-name me-2">
                                    <h4 className="text-black">{roleData?.name}</h4>
                                    <p>{roleData?.roles?.[0]}</p> {/* Display only the first role */}
                                </div>
                                {roleData?.image_path ? (
                                    // If the image exists, display the image
                                    <img
                                        src={roleData?.image_path}
                                        alt=""
                                        className="user_profile"

                                    />
                                ) : (
                                    // If no image, display initials
                                    <div
                                        className="user-initials d-flex align-items-center justify-content-center text-white"
                                        style={{
                                            width: "40px",
                                            height: "40px",
                                            borderRadius: "50%",
                                            backgroundColor: "#004fc5", // Use any fallback color
                                            fontSize: "16px",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {`${roleData?.name.charAt(0)}`}
                                    </div>
                                )}
                            </a>

                            <ul
                                className="dropdown-menu position-absolute ps-2 pe-3 drop-profile"
                                aria-labelledby="navbarDropdown"
                            >
                                <li className="profile d-flex align-items-center justify-content-between">
                                    <div className="left-dropprofile d-flex align-items-center">
                                        {roleData?.image_path ? (
                                            // If the image exists, display the image
                                            <img
                                                src={roleData?.image_path}
                                                alt=""
                                                className="user_profile"
                                                style={{
                                                    width: "40px",
                                                    height: "40px",
                                                    borderRadius: "50%",
                                                    objectFit: "cover",
                                                }}
                                            />
                                        ) : (
                                            // If no image, display initials
                                            <div
                                                className="user-initials d-flex align-items-center justify-content-center text-white"
                                                style={{
                                                    width: "40px",
                                                    height: "40px",
                                                    borderRadius: "50%",
                                                    backgroundColor: "#004fc5", // Use any fallback color
                                                    fontSize: "16px",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {`${roleData?.name.charAt(0)}`}
                                            </div>
                                        )}
                                        <div className="user-name ms-2">
                                            <h4 className="text-black">
                                                {roleData?.name}
                                            </h4>
                                            <p>{roleData?.roles?.[0]}</p>
                                        </div>
                                    </div>
                                    <img
                                        src={require("../../../assets/images/drop_up.png")}
                                        className="hide-profile"
                                        alt=""
                                    />
                                </li>
                                <li className="ps-0">
                                    <span className="d-flex align-items-center ">
                                        <img
                                            src={require("../../../assets/images/email.png")}
                                            className="me-2 my-2"
                                            alt=""
                                        />
                                        {roleData?.email}
                                    </span>
                                </li>
                                <li
                                    onClick={() => {
                                        setEditModal(true); // Show the modal
                                        getEditProfile(); // Call the API to fetch edit profile data
                                    }}
                                    className="bg-gray-li mt-1 py-3 d-flex align-items-center justify-content-between pe-3 edit"
                                >
                                    <div className="dropdown-item p-0 fs-14">
                                        Edit Profile
                                    </div>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16.001"
                                        height="16"
                                        viewBox="0 0 16.001 16"
                                    >
                                        <g id="write" transform="translate(-7.998 -8)">
                                            <path
                                                id="Path_3528"
                                                data-name="Path 3528"
                                                d="M8.341,27.144l-.336,2.779a.835.835,0,0,0,.832.945.821.821,0,0,0,.106-.007l2.779-.336a2.648,2.648,0,0,0,1.543-.758l7.2-7.2a.333.333,0,0,0,0-.471l-3.694-3.7a.333.333,0,0,0-.471,0L9.1,25.6a2.648,2.648,0,0,0-.758,1.543Z"
                                                transform="translate(0 -6.868)"
                                            />
                                            <path
                                                id="Path_3529"
                                                data-name="Path 3529"
                                                d="M42.715,10.23,40.992,8.506a1.728,1.728,0,0,0-2.444,0L36.93,10.124a.333.333,0,0,0,0,.471l3.7,3.7a.333.333,0,0,0,.471,0l1.618-1.618A1.728,1.728,0,0,0,42.715,10.23Z"
                                                transform="translate(-19.223 0)"
                                            />
                                            <path
                                                id="Path_3530"
                                                data-name="Path 3530"
                                                d="M38,52.667A.667.667,0,0,0,37.333,52H29.667a.667.667,0,0,0,0,1.333h7.667A.667.667,0,0,0,38,52.667Z"
                                                transform="translate(-14.001 -29.333)"
                                            />
                                        </g>
                                    </svg>
                                </li>

                                <li className="bg-gray-li mt-1 py-3 d-flex align-items-center justify-content-between pe-3 change" onClick={() => setPasswordModal(true)}>
                                    <div className="dropdown-item p-0 fs-14">
                                        Change Password
                                    </div>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="13.515"
                                        height="16"
                                        viewBox="0 0 13.515 16"
                                    >
                                        <path
                                            id="Path_3567"
                                            data-name="Path 3567"
                                            d="M4009.646,845.272v1.219a3.269,3.269,0,0,1,1.7,1.183,3.31,3.31,0,0,1,.668,1.964v3.925a3.379,3.379,0,0,1-.271,1.282,3.334,3.334,0,0,1-.74,1.081,3.353,3.353,0,0,1-2.385.956H4001.9a3.351,3.351,0,0,1-2.385-.956,3.342,3.342,0,0,1-.74-1.081,3.388,3.388,0,0,1-.271-1.282v-3.925a3.3,3.3,0,0,1,.668-1.963,3.347,3.347,0,0,1,1.7-1.185v-1.219a4.4,4.4,0,0,1,2.711-4.055,4.382,4.382,0,0,1,4.778.952,4.373,4.373,0,0,1,1.288,3.1Zm-4.38-2.907a2.942,2.942,0,0,1,2.965,2.907v1.048h-5.947v-1.064a2.939,2.939,0,0,1,2.982-2.895Zm.7,10.109a.7.7,0,0,1-.207.5.707.707,0,0,1-1,0,.7.7,0,0,1-.207-.5v-1.754a.7.7,0,0,1,.207-.5.708.708,0,0,1,1,0,.7.7,0,0,1,.207.5Z"
                                            transform="translate(-3998.5 -840.883)"
                                            fillRule="evenodd"
                                        />
                                    </svg>
                                </li>
                                <li className="bg-gray-li mt-1 py-3 d-flex align-items-center justify-content-between pe-3 logout">
                                    <div className="dropdown-item p-0 fs-14" onClick={handleAdminLogout}>Logout</div>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16.624"
                                        height="16.001"
                                        viewBox="0 0 16.624 16.001"
                                    >
                                        <path
                                            id="Path_3568"
                                            data-name="Path 3568"
                                            d="M1239.32,1285.384a.616.616,0,0,0,0,1.232h4.88v3.824a3.718,3.718,0,0,1-.288,1.376,3.547,3.547,0,0,1-.784,1.152,3.48,3.48,0,0,1-1.176.768,3.537,3.537,0,0,1-1.376.264h-3.96a3.538,3.538,0,0,1-1.376-.264,3.634,3.634,0,0,1-1.168-.76,3.638,3.638,0,0,1-1.072-2.528v-8.888a3.72,3.72,0,0,1,.288-1.376,3.551,3.551,0,0,1,.784-1.152,3.484,3.484,0,0,1,1.176-.768,3.542,3.542,0,0,1,1.376-.264h3.968a3.592,3.592,0,0,1,3.608,3.552v3.832Zm7.784-2.152,2.336,2.328a.684.684,0,0,1,.136.2.643.643,0,0,1,.048.24.575.575,0,0,1-.048.232.592.592,0,0,1-.136.2l-2.336,2.328a.624.624,0,0,1-.432.168.6.6,0,0,1-.608-.608.624.624,0,0,1,.168-.432l1.28-1.272H1244.2v-1.232h3.312l-1.28-1.272a.608.608,0,0,1,0-.872.59.59,0,0,1,.2-.136.574.574,0,0,1,.232-.048.629.629,0,0,1,.24.04A.681.681,0,0,1,1247.1,1283.232Z"
                                            transform="translate(-1233 -1277.999)"
                                        />
                                    </svg>
                                </li>
                            </ul>
                        </li>
                    </ul>

                    {isSearchToggled && (
                        <div className="search_field">
                            <form>
                                <div className="search_bar">
                                    <input
                                        type="search"
                                        placeholder="Search"
                                        className="form-control"
                                    />
                                    <input
                                        type="button"
                                        value="Search"
                                        className="btn btn-lg"
                                    />
                                    <div className="close_search">
                                        <img
                                            src="assets/images/cross.png"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    )}
                </div>
            </nav>

            {/* CHANGE PASSWORD MODAL START */}
            <Modal
                show={passwordModal}
                onHide={setPasswordModal}
                centered
                className="font-poppins p-0"
                backdrop="static"
                keyboard={false}
            >
                <div className="modal-header d-block border-0 justify-content-between px-4 pt-4">
                    <h4 className="fs-20">Change Password</h4>
                    <p className="mt-1 text-grays">You have the option to modify your password below.</p>
                </div>


                <div className="row">
                    <div className="col-lg-12 col-md-12 px-4 pb-4">
                        <form
                            onSubmit={handleSubmit(handleChangePassword)}

                        >
                            <div className="form-group input-group position-relative mt-3">
                                <label className="text-muted">
                                    Current Password
                                </label>
                                <div className="icon-input w-100 rounded mt-2 position-relative">
                                    <img
                                        src={require("../../../assets/images/lock.png")}
                                        alt="emailIcon"
                                        className="position-absolute"
                                    />
                                    <input
                                        type={showPassword ? "text" : "current_password"}
                                        name="current_password"
                                        id="current_password"
                                        className="form-control border-0 py-3 w-100 bg-red"
                                        placeholder="**********"
                                        {...register("current_password", {
                                            required: true,
                                        })}
                                    />
                                    {errors.current_password?.type === "required" && (
                                        <p
                                            role="alert"
                                            className="text-danger mb-0 pt-1 -fs-7 ms-1"
                                        >
                                            Required
                                        </p>
                                    )}
                                    {/* EYES ICON */}
                                    <div className="eye-icons position-absolute">
                                        <>
                                            {showPassword ? (
                                                <img
                                                    src={require("../../../assets/images/eye.png")}
                                                    className="me-2"
                                                    alt="emailIcon"
                                                    onClick={() => setShowPassword(!showPassword)}
                                                />
                                            ) : (
                                                <img
                                                    src={require("../../../assets/images/eye-hide.png")}
                                                    className="me-2"
                                                    alt="emailIcon"
                                                    onClick={() => setShowPassword(!showPassword)}
                                                />
                                            )}
                                        </>
                                    </div>
                                    {/* EYES ICON */}
                                </div>
                            </div>
                            <div className="form-group input-group position-relative mt-3">
                                <label className="text-muted">
                                    New Password
                                </label>
                                <div className="icon-input w-100 rounded mt-2 position-relative">
                                    <img
                                        src={require("../../../assets/images/lock.png")}
                                        alt="emailIcon"
                                        className="position-absolute"
                                    />
                                    <input
                                        type={showNewPassword ? "text" : "new_password"}
                                        name="new_password"
                                        id="new_password"
                                        className="form-control border-0 py-3 w-100 bg-red"
                                        placeholder="**********"
                                        {...register("new_password", {
                                            required: "Required",
                                            minLength: {
                                                value: 8,
                                                message: "Password must be at least 8 characters long"
                                            }
                                        })}
                                    />
                                    {errors.new_password && (
                                        <p role="alert" className="text-danger mb-0 pt-1 -fs-7 ms-1">
                                            {errors.new_password.message}
                                        </p>
                                    )}
                                    {/* EYES ICON */}
                                    <div className="eye-icons position-absolute">
                                        <>
                                            {showNewPassword ? (
                                                <img
                                                    src={require("../../../assets/images/eye.png")}
                                                    className="me-2"
                                                    alt="emailIcon"
                                                    onClick={() => setShowNewPassword(!showNewPassword)}
                                                />
                                            ) : (
                                                <img
                                                    src={require("../../../assets/images/eye-hide.png")}
                                                    className="me-2"
                                                    alt="emailIcon"
                                                    onClick={() => setShowNewPassword(!showNewPassword)}
                                                />
                                            )}
                                        </>
                                    </div>
                                    {/* EYES ICON */}
                                </div>
                            </div>
                            <div className="form-group input-group position-relative mt-3">
                                <label className="text-muted">
                                    Confirm Password
                                </label>
                                <div className="icon-input w-100 rounded mt-2 position-relative">
                                    <img
                                        src={require("../../../assets/images/lock.png")}
                                        alt="emailIcon"
                                        className="position-absolute"
                                    />
                                    <input
                                        type={showConfirmPassowrd ? "text" : "new_password_confirmation"}
                                        name="new_password_confirmation"
                                        id="new_password_confirmation"
                                        className="form-control border-0 py-3 w-100 bg-red"
                                        placeholder="**********"
                                        {...register("new_password_confirmation", {
                                            required: "Required",
                                            minLength: {
                                                value: 8,
                                                message: "Password must be at least 8 characters long"
                                            },
                                            validate: value =>
                                                value === formValues.new_password || "Passwords do not match",
                                        })}
                                    />
                                    {errors.new_password_confirmation && (
                                        <p role="alert" className="text-danger mb-0 pt-1 -fs-7 ms-1">
                                            {errors.new_password_confirmation.message}
                                        </p>
                                    )}
                                    {/* EYES ICON */}
                                    <div className="eye-icons position-absolute">
                                        <>
                                            {showConfirmPassowrd ? (
                                                <img
                                                    src={require("../../../assets/images/eye.png")}
                                                    className="me-2"
                                                    alt="emailIcon"
                                                    onClick={() => setShowConfirmPassword(!showConfirmPassowrd)}
                                                />
                                            ) : (
                                                <img
                                                    src={require("../../../assets/images/eye-hide.png")}
                                                    className="me-2"
                                                    alt="emailIcon"
                                                    onClick={() => setShowConfirmPassword(!showConfirmPassowrd)}
                                                />
                                            )}
                                        </>
                                    </div>
                                    {/* EYES ICON */}
                                </div>
                            </div>
                            <div className="form-btns text-end d-flex items-center flex-wrap gap-2 mt-4 justify-content-center p-0">
                                <button className="btn-transparent p-4" type="button" onClick={() => setPasswordModal(false)}>Cancel</button>
                                <button className="add-new p-4" type="submit">Save Changes</button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
            {/* CHANGE PASSWORD MODAL END */}

            {/* EDIT PROFILE MODAL START */}
            <Modal
                show={editModal}
                onHide={() => setEditModal(false)}
                centered
                className="font-poppins"
                backdrop="static"
                keyboard={false}
            >
                <div className="mainmodalarea py-4 px-3">
                    <div className="modal-header d-block border-0 justify-content-between">
                        <h4 className="fs-20">Edit Profile</h4>
                        <p className="mt-1 text-grays">
                            Please update your profile information in the section provided below.
                        </p>
                    </div>
                    <form onSubmit={handleSubmitPorfile}>
                        <div className="row">
                            {/* Profile Picture */}
                            <div className="col-lg-12 mt-3">
                                <div className="avatar-upload">
                                    <div className="avatar-edit">
                                        <input
                                            type="file"
                                            id="imageUpload"
                                            name="image"
                                            accept=".png, .jpg, .jpeg"
                                            onChange={handleImageChange}
                                        />
                                        <label htmlFor="imageUpload"></label>
                                    </div>
                                    <div className="avatar-preview">
                                        <div className="imagePreview d-flex align-items-center justify-content-center">
                                            <img
                                                src={previewImage || require("../../../assets/images/user-pic.png")}
                                                alt="User Profile"
                                                className="rounded-circle"
                                                style={{ width: "100px", height: "100px", objectFit: "cover" }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* First Name */}
                            <div className="col-lg-6 mt-3">
                                <div className="form-group input-group position-relative mt-3">
                                    <label className="text-muted">First Name</label>
                                    <div className="icon-input w-100 rounded mt-2 position-relative">
                                        <img
                                            src={require("../../../assets/images/user.png")}
                                            alt="User Icon"
                                            className="position-absolute"
                                            style={{ top: "50%", transform: "translateY(-50%)", left: "10px" }}
                                        />
                                        <input
                                            type="text"
                                            name="first_name"
                                            className="form-control border-0 py-3 w-100 ps-5"
                                            value={firstName}
                                            onChange={(e) => setFirstName(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* Last Name */}
                            <div className="col-lg-6 mt-3">
                                <div className="form-group input-group position-relative mt-3">
                                    <label className="text-muted">Last Name</label>
                                    <div className="icon-input w-100 rounded mt-2 position-relative">
                                        <img
                                            src={require("../../../assets/images/user.png")}
                                            alt="User Icon"
                                            className="position-absolute"
                                            style={{ top: "50%", transform: "translateY(-50%)", left: "10px" }}
                                        />
                                        <input
                                            type="text"
                                            name="last_name"
                                            className="form-control border-0 py-3 w-100 ps-5"
                                            value={lastName}
                                            onChange={(e) => setLastName(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* Modal Buttons */}
                            <div className="col-lg-12">
                                <div className="form-btns text-end d-flex items-center flex-wrap gap-2 mt-4 justify-content-center p-0">
                                    <button className="btn-transparent p-4" type="button" onClick={() => setEditModal(false)}>
                                        Cancel
                                    </button>
                                    <button className="add-new p-4" type="submit">
                                        Save Changes
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>

            {/* EDIT PROFILE MODAL END */}

            {/* <!-- HEADER END --> */}
        </>
    );
};

export default AdminHeader;
