import React, { useEffect, useState, useContext, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { http } from "../../http/http";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { clientContatDetail } from "../../../reduxTool/features/userSlice";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { GlobalContext } from "../../../App";
import { debounce } from "lodash";
import Contact_Detail from "../../common/Contact_Detail";

const Add_Contact = (props) => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const { showLoader, setShowLoader } = useContext(GlobalContext);
    const { fetchConData, showcCntactModal, setShowContactModal } = props;
    const [usersContact, setUsersContact] = useState([]);
    const [address, setAddress] = useState('');
    const [province, setProvince] = useState('');
    const [city, setCity] = useState('');
    const [searchResults, setSearchResults] = useState([]); // List of search results
    const [selectedContact, setSelectedContact] = useState(null); // Selected contact data
    const [searchTerm, setSearchTerm] = useState(""); // Input value for search
    const [hasSearched, setHasSearched] = useState(false);
    const [contactOpenModel, setOpenContactModel] = useState(false);
    const [openContactModalKey, setOpenContactModalKey] = useState(null);
    const [searchError, setSearchError] = useState(""); // To store validation message
    const [sourceModal, setSourceModal] = useState(null);
    const [formData, setFormData] = useState({
        phone_number: '',
        client_since: '',
        client_type: '',
        whatsapp_contact: '',
        postal_code: '',
        address: '',
        city: '',
        province: '',
    });
    const [formErrors, setFormErrors] = useState({});
    const [isContactSelected, setIsContactSelected] = useState(false);
    const [isAddingNew, setIsAddingNew] = useState(false);
    const resetForm = () => {
        setFormData({
            phone_number: '',
            client_since: '',
            client_type: '',
            whatsapp_contact: '',
            postal_code: '',
            address: '',
            city: '',
            province: '',
        });
        setAddress('');
        setCity('');
        setProvince('');
        setSearchTerm('');
        setSelectedContact(null);
        setSearchResults([]);
        setIsContactSelected(false);
        setIsAddingNew(false);
    };
    const onCancel = () => {
        resetForm(); // Clear all form data and states
        setShowContactModal(false); // Close the modal
        setIsContactSelected(false); // Ensure contact-related fields are hidden
        setIsAddingNew(false); // Disable "Add New" mode
    };
    // Fetch data from API based on search input
    const fetchSearchResults = (query) => {
        let url;

        if (query === "ContactKey") {
            url = `v2/contact_detail`;
            setSearchTerm(""); // Clear the search term if the query is 'fromContact'
        } else {
            const queryParams = new URLSearchParams({ search: query });
            url = `v2/contact_detail?${queryParams.toString()}`;
        }

        // Make the HTTP request
        http(
            {
                method: "GET",
                url,
                isSecure: true,
            },
            (res) => {
                if (res?.data?.data) {
                    if (query === "ContactKey") {
                        const filteredResults = res.data.data[0]; // Assuming 'data' is an array
                        setSelectedContact(filteredResults); // Set the first contact as selected
                    } else {
                        const filteredResults = res.data.data.filter(
                            (contact) =>
                                contact.first_name?.toLowerCase().includes(query.toLowerCase())

                            // ||
                            //     contact.company_name?.toLowerCase().includes(query.toLowerCase())
                        );
                        setSearchResults(filteredResults); // Update the search results state
                    }
                } else {
                    console.warn("No data found in the response."); // Log a warning if no data is found
                    setSearchResults([]); // Clear the search results
                }
            },
            (err) => {
                console.error("Error Response:", err); // Log the error response for debugging
                toast.error(err.single_message || "Failed to fetch results.");
                setShowLoader(false);
            }
        );
    };

    // Debounced search handler
    const debouncedSearch = useCallback(
        debounce((query) => {
            if (query.trim()) {
                fetchSearchResults(query);
            } else {
                setSearchResults([]); // Clear results if no query
            }
        }, 300),
        []
    );

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        const query = e.target.value;
        setSearchTerm(query);
        setIsContactSelected(false);
        setIsAddingNew(false);


        // Reset fields when search term changes
        setFormData({
            ...formData,
            address: '',
            city: '',
            province: '',
            postal_code: '',
            phone_number: '',
            whatsapp_contact: ''
        });
        setAddress("");
        setProvince("");
        setCity("");

        // Reset 'hasSearched' and search results when the input is cleared
        if (!query.trim()) {
            setHasSearched(false);
            setSearchResults([]); // Clear search results
        } else {
            setHasSearched(true);
            debouncedSearch(query); // Call debounced search
        }
    };
    const handleSelectContact = (contact) => {
        setSelectedContact(contact); // Save selected contact in state
        setSearchTerm(''); // Clear the search term to hide the dropdown
        setSearchResults([]);
        setHasSearched(false);
        setIsContactSelected(true);
        // Prefill form fields
        setAddress(contact.street_address || "");
        setProvince(contact.province || "");
        setCity(contact.city || "");
        setFormData({
            ...formData,
            address: contact.street_address || "",
            city: contact.city || "",
            province: contact.province || "",
            postal_code: contact.postal_code || "",
            phone_number: contact.phone_number || "",
            whatsapp_contact: contact.whatsapp_contact || "",
        });


        // Clear validation errors for the pre-filled fields
        setFormErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };

            // Remove errors for fields being pre-filled
            if (contact.phone_number) delete updatedErrors.phone_number;
            if (contact.whatsapp_contact) delete updatedErrors.whatsapp_contact;
            delete updatedErrors.searchTerm;
            return updatedErrors;
        });
    };


    const handleNumericInput = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);

        // Allow only numeric characters (0-9) and certain control keys
        if (!/^[0-9]+$/.test(keyValue) && !["Backspace", "Delete", "Tab", "ArrowLeft", "ArrowRight", "Home", "End"].includes(event.key)) {
            event.preventDefault();
        }
    };

    const onSubmit = (e) => {
        e.preventDefault();

        // Call the validation function
        const errors = validateForm();

        // Update the formErrors state with validation errors
        setFormErrors(errors);

        // Check if there are any errors
        if (Object.keys(errors).length > 0) {
            console.error("Validation Errors:", errors);
            return; // Stop the submission process
        }
        setShowLoader(true);
        // Clear previous errors
        setFormErrors({});
        const newUser = {
            ...formData,
        };
        setUsersContact([...usersContact, newUser]);
        http(
            {
                method: "POST",
                url: `client/contact/${id}/add`,
                body: {
                    phone_number: formData.phone_number,
                    unit_no: formData.unit_no,
                    address: formData.address,
                    city: formData.city,
                    province: formData.province,
                    postal_code: formData.postal_code,
                    whatsapp_contact: formData.whatsapp_contact,
                    client_since: formData.client_since,
                    client_type: formData.client_type,
                    contact_detail_id: selectedContact?.id

                },
                isSecure: true,
            },
            (res) => {
                dispatch(clientContatDetail(res));
                toast.success(res.single_message);
                setShowContactModal(false);
                setShowLoader(false);
                localStorage.setItem("userContacts", JSON.stringify(res?.data));
                // setFormData({
                //     phone_number: '',
                //     client_since: '',
                //     client_type: '',
                //     whatsapp_contact: '',
                //     postal_code: '',
                //     address: '',
                //     city: '',
                //     province: '',
                // });
                // setAddress('');
                // setCity('');
                // setProvince('');
                // setSearchTerm('');
                fetchConData();
                resetForm();

            },
            (err) => {
                // Handle errors according to the response
                if (err.status === 401) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else if (err.status === 400) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else {
                    toast.error(err.single_message);
                    setShowLoader(false);
                }
            }
        );
    }
    const onHideHandler = () => {
        resetForm(); // Clear all form data and states
        setShowContactModal(false); // Close the modal
        setIsContactSelected(false); // Ensure contact-related fields are hidden
        setIsAddingNew(false);
    };

    const handleSelect = async (value) => {
        setAddress(value);

        try {
            const results = await geocodeByAddress(value);
            if (results && results[0]) {
                const addressComponents = results[0].address_components;
                const formattedAddress = results[0].formatted_address;
                let selectedProvince = '';
                let postalCode = '';
                let selectedCity = '';

                // Extract the province (administrative_area_level_1) and postal code
                addressComponents.forEach((component) => {
                    if (component.types.includes('administrative_area_level_1')) {
                        selectedProvince = component.short_name; // Use `long_name` for full province name
                    }
                    if (component.types.includes('postal_code')) {
                        postalCode = component.short_name; // or long_name if you want the full postal code
                    }
                    if (component.types.includes('locality')) {
                        selectedCity = component.long_name; // City name
                    }
                });
                // Set the selected address, province, and postal code in the form
                setFormData({
                    ...formData,
                    address: formattedAddress,
                    province: selectedProvince,
                    postal_code: postalCode,
                    city: selectedCity,
                });
                setCity(selectedCity);
                setProvince(selectedProvince); // Update the province state
            } else {
                console.warn('No results found for the selected address.');
            }
        } catch (error) {
            // Improved error logging
            console.error('Error selecting place:', error?.message || 'No message', error?.stack || 'No stack trace');
        }
    };

    const handleChange = (value) => {
        setAddress(value);
    };

    useEffect(() => {
        if (openContactModalKey) {
            fetchSearchResults(openContactModalKey);
            setOpenContactModalKey(null);
        }
    }, [openContactModalKey]);

    // useEffect(() => {
    //     if (selectedContact) {
    //         // Update the search field with the selected contact's name or company name
    //         const selectedName = selectedContact.first_name || selectedContact.company_name || "";
    //         setSearchTerm(selectedName); // Show the selected name in the search field
    //         setAddress(selectedContact.street_address || "");
    //         setProvince(selectedContact.province || "");
    //         setCity(selectedContact.city || "");
    //         setFormData({
    //             ...formData,
    //             address: selectedContact.street_address || "",
    //             city: selectedContact.city || "",
    //             province: selectedContact.province || "",
    //             postal_code: selectedContact.postal_code || "",
    //             first_name: selectedContact.first_name || "",
    //             middle_name: selectedContact.middle_name || "",
    //             last_name: selectedContact.last_name || "",
    //             company_name: selectedContact.company_name || "",
    //             email: selectedContact.email || "",
    //             phone_number: selectedContact.phone_number || "",
    //         });
    //     }
    // }, [selectedContact]);

    useEffect(() => {
        if (selectedContact) {
            const selectedName = selectedContact.first_name || selectedContact.company_name || "";
            setSearchTerm(selectedName); // Update the search field
            setAddress(selectedContact.street_address || "");
            setProvince(selectedContact.province || "");
            setCity(selectedContact.city || "");
            setFormData({
                ...formData,
                address: selectedContact.street_address || "",
                city: selectedContact.city || "",
                province: selectedContact.province || "",
                postal_code: selectedContact.postal_code || "",
                first_name: selectedContact.first_name || "",
                middle_name: selectedContact.middle_name || "",
                last_name: selectedContact.last_name || "",
                company_name: selectedContact.company_name || "",
                email: selectedContact.email || "",
                phone_number: selectedContact.phone_number || "",
            });
        } else if (isAddingNew) {
            // Show the full form for "Add New"
            setFormData({
                phone_number: "",
                client_since: "",
                client_type: "",
                whatsapp_contact: "",
                postal_code: "",
                address: "",
                city: "",
                province: "",
            });
        } else {
            // Reset to show only the search field
            resetForm();
        }
    }, [selectedContact, isAddingNew]);

    const validateForm = () => {
        let errors = {};
        if (!searchTerm.trim()) {
            errors.searchTerm = "Required.";
        }
        if (!formData.phone_number) {
            errors.phone_number = "Required";
        } else if (formData.phone_number.length < 7) {
            errors.phone_number = "Phone number must be at least 7 digits.";
        }
        if (!formData.client_since) {
            errors.client_since = "Required";
        }
        if (!formData.client_type) {
            errors.client_type = "Required";
        }
        if (!formData.whatsapp_contact) {
            errors.whatsapp_contact = "Required";
        } else if (formData.whatsapp_contact.length < 7) {
            errors.whatsapp_contact = "WhatsApp contact must be at least 7 digits.";
        }

        return errors;
    };

    const handleInputChange = (field, value) => {
        // Update the form data
        setFormData((prev) => ({
            ...prev,
            [field]: value,
        }));

        // Clear the error for the specific field if it exists
        setFormErrors((prevErrors) => {
            const { [field]: removedError, ...rest } = prevErrors;
            return rest;
        });
    };



    return (
        <>
            <Modal
                show={showcCntactModal}
                onHide={onCancel}
                centered
                size="xl"
                contentClassName="mx-auto"
                className="modal operating-modal font-poppins"
                backdrop="static"
                keyboard={false}
            >
                <div className="modal-header border-0 justify-content-between">
                    <h4 className="fs-20 fw-semibold">Add Contact</h4>
                    <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
                </div>
                <div className="modal-body">
                    <form onSubmit={onSubmit}>
                        <div className="advance-form row">
                            {/* Search Contact */}
                            <div className="col-lg-4 col-md-12 mt-3 dctor-search">
                                <label className="fs-18">Selected Contact <span className="text-danger">*</span></label>
                                <div className="position-relative mt-2">
                                    <input
                                        type="text"
                                        className="form-input dtr-srch"
                                        placeholder="Search for a name or company"
                                        value={searchTerm}
                                        onChange={handleSearchChange}
                                    />

                                    {searchTerm.trim() && hasSearched && (
                                        <ul className={`p-0 ${!searchTerm ? "bdr-none" : ""}`}>
                                            {hasSearched && searchResults.length === 0 ? (
                                                <li>
                                                    <p
                                                        className="t_blue fw-semibold fs-14 w-100 dropdown-item cursor-pointer pb-2"
                                                        onClick={() => {
                                                            // setShowContactModal(false);
                                                            // setOpenContactModel(true);
                                                            // setIsAddingNew(true);
                                                            // setSourceModal("contact");
                                                            setShowContactModal(false);
                                                            setOpenContactModel(true);
                                                            setIsAddingNew(true); // Enable "Add New" mode
                                                            setSearchResults([]); // Clear search results
                                                            setSelectedContact(null); // Clear selected contact
                                                            setSearchTerm(""); // Clear search term
                                                            setSourceModal("contact");
                                                        }}
                                                    >
                                                        + Add New
                                                    </p>
                                                </li>
                                            ) : (
                                                searchResults.map(
                                                    (contact) =>
                                                        (contact.first_name) && (
                                                            <li
                                                                key={contact.id}
                                                                className="list-group-item list-group-item-action d-flex justify-content-start align-items-center w-auto search_dropbox"
                                                                onClick={() => handleSelectContact(contact)}
                                                                style={{ cursor: "pointer" }}
                                                            >
                                                                <p className="fs-16 text-black">{contact.first_name}</p>
                                                                {/* <p className="fs-16 text-black">{contact.company_name}</p> */}
                                                            </li>
                                                        )
                                                )
                                            )}
                                        </ul>
                                    )}
                                </div>
                                {formErrors.searchTerm && <div className="text-danger mt-2">{formErrors.searchTerm}</div>}
                            </div>
                            {isContactSelected || isAddingNew ? (
                                <>
                                    <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                        <label className="fs-18">Phone No. <span className="text-danger">*</span></label>
                                        <input
                                            type="tel"
                                            name="phone_number"
                                            id="phone_number"
                                            maxLength={15}
                                            className="form-input mt-2"
                                            placeholder="Phone No."
                                            value={formData.phone_number || ''}
                                            onChange={(e) => handleInputChange('phone_number', e.target.value)}
                                        // onKeyDown={handleNumericInput}
                                        />
                                        {formErrors.phone_number && <span className="text-danger">{formErrors.phone_number}</span>}
                                    </div>

                                    {/* Client Since */}
                                    <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                        <label className="fs-18">Client Since <span className="text-danger">*</span></label>
                                        <input
                                            type="date"
                                            name="client_since"
                                            id="client_since"
                                            className="form-input mt-2"
                                            max={new Date().toISOString().split("T")[0]}
                                            value={formData.client_since || ''}
                                            onChange={(e) => handleInputChange('client_since', e.target.value)}
                                        />
                                        {formErrors.client_since && <span className="text-danger">{formErrors.client_since}</span>}
                                    </div>

                                    {/* Client Type */}
                                    <div className="col-lg-4 col-md-12 mt-3">
                                        <label className="fs-18">Client Type <span className="text-danger">*</span></label>
                                        <select
                                            name="client_type"
                                            id="client_type"
                                            className="form-input mt-2"
                                            value={formData.client_type || ''}
                                            onChange={(e) => handleInputChange('client_type', e.target.value)}
                                        >
                                            <option value="" disabled>Select</option>
                                            <option value="Client">Client</option>
                                            <option value="Director Only">Director Only</option>
                                        </select>
                                        {formErrors.client_type && <span className="text-danger">{formErrors.client_type}</span>}
                                    </div>

                                    {/* Whatsapp Contact */}
                                    <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                        <label className="fs-18">Whatsapp Contact No. <span className="text-danger">*</span></label>
                                        <input
                                            type="tel"
                                            name="whatsapp_contact"
                                            id="whatsapp_contact"
                                            className="form-input mt-2"
                                            placeholder="Type here..."
                                            value={formData.whatsapp_contact || ''}
                                            onChange={(e) => handleInputChange('whatsapp_contact', e.target.value)}
                                        // onKeyDown={handleNumericInput}
                                        />
                                        {formErrors.whatsapp_contact && <span className="text-danger">{formErrors.whatsapp_contact}</span>}
                                    </div>

                                    {/* Street Address */}
                                    <div className="col-lg-8 col-md-12 col-md-12 mt-3">
                                        <label className="fs-18">Street Address</label>
                                        <PlacesAutocomplete
                                            value={address}
                                            onChange={handleChange}
                                            onSelect={handleSelect}
                                            searchOptions={{
                                                componentRestrictions: {
                                                    country: ["us", "ca"],
                                                },
                                            }}
                                        >
                                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                <div>
                                                    <input
                                                        {...getInputProps({
                                                            placeholder: 'Type address here...',
                                                            className: 'form-input mt-2 google-address',
                                                        })}
                                                    />
                                                    <div className="autocomplete-dropdown-container">
                                                        {loading && <div>Loading...</div>}
                                                        {suggestions.map((suggestion) => {
                                                            const className = suggestion.active
                                                                ? 'suggestion-item--active'
                                                                : 'suggestion-item';
                                                            return (
                                                                <div
                                                                    {...getSuggestionItemProps(suggestion, { className })}
                                                                    key={suggestion.placeId}
                                                                >
                                                                    <span>{suggestion.description}</span>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            )}
                                        </PlacesAutocomplete>
                                    </div>

                                    {/* Province */}
                                    <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                        <label className="fs-18">Province</label>
                                        <input
                                            className="form-input mt-2"
                                            name="province"
                                            id="province"
                                            value={province}
                                            onChange={(e) => setProvince(e.target.value)}
                                        />
                                    </div>

                                    {/* City */}
                                    <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                        <label className="fs-18">City</label>
                                        <input
                                            type="text"
                                            name="city"
                                            id="city"
                                            value={city}
                                            className="form-input mt-2"
                                            placeholder="Type here..."
                                            onChange={(e) => setCity(e.target.value)}
                                        />
                                    </div>

                                    {/* Postal Code */}
                                    <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                        <label className="fs-18">Postal Code</label>
                                        <input
                                            type="text"
                                            name="postal_code"
                                            id="postal_code"
                                            className="form-input mt-2"
                                            placeholder="Type here..."
                                            value={formData.postal_code || ''}
                                            onChange={(e) => handleInputChange('postal_code', e.target.value)}
                                        />
                                    </div>

                                    {/* Submit Button */}
                                    <div className="col-lg-12 mt-4 mb-2">
                                        <button type="submit" className="btn-blue ms-auto">Add</button>
                                    </div>
                                </>
                            ) : null}
                        </div>
                    </form>
                </div>
            </Modal>

            {/* Contact Details Component */}
            <Contact_Detail
                contactOpenModel={contactOpenModel}
                setOpenContactModel={setOpenContactModel}
                setShowContactModal={setShowContactModal}
                setOpenContactModalKey={setOpenContactModalKey}
                openContactModalKey={openContactModalKey}
                sourceModal={sourceModal}
                fetchSearchResults={fetchSearchResults}
                setIsAddingNew={setIsAddingNew}
            />
        </>
    );

}
export default Add_Contact;

