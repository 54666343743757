import React, { useState } from 'react'

const Business_Linked_Director_Detail = ({ businessDirectorData, onSave }) => {
    const [showSIN, setShowSIN] = useState(false);
    const [formData, setFormData] = useState({
        type: businessDirectorData?.type || "",
        designation_id: businessDirectorData?.designation_id || "",
        first_name: businessDirectorData?.first_name || "",
        middle_name: businessDirectorData?.middle_name || "",
        last_name: businessDirectorData?.last_name || "",
        company_name: businessDirectorData?.company_name || "",
        sin: businessDirectorData?.sin || "",
        date_of_birth: businessDirectorData?.date_of_birth || "",
        email: businessDirectorData?.email || "",
        phone_number: businessDirectorData?.phone_number || "",
        address: businessDirectorData?.address || "",
        city: businessDirectorData?.city || "",
        province: businessDirectorData?.province || "",
        postal_code: businessDirectorData?.postal_code || "",
        country: businessDirectorData?.country || "",
        shareholder_part: businessDirectorData?.shareholder_part || "0",
    });
    const toggleSINVisibility = () => {
        setShowSIN(!showSIN); // Toggle between showing and hiding SIN
    };
    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        if (onSave) {
            onSave(formData); // Pass the updated data to the parent component or API
        }
    };
    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="row light_input">
                    {formData?.type === 2 ? (
                        // Render company_name input when type is 2
                        <div className="col-md-6 mt-3">
                            <label htmlFor="company_name">Company Name</label>
                            <input
                                type="text"
                                id="company_name"
                                name="company_name"
                                className="form-input mt-2"
                                value={formData.company_name}
                                onChange={handleChange}
                                readOnly
                            />
                        </div>
                    ) : (
                        // Render first_name, middle_name, and last_name when type is not 2
                        <>
                            <div className="col-md-6 mt-3">
                                <label htmlFor="first_name">First Name</label>
                                <input
                                    type="text"
                                    id="first_name"
                                    name="first_name"
                                    className="form-input mt-2"
                                    value={formData.first_name}
                                    onChange={handleChange}
                                    readOnly
                                />
                            </div>
                            <div className="col-md-6 mt-3">
                                <label htmlFor="middle_name">Middle Name</label>
                                <input
                                    type="text"
                                    id="middle_name"
                                    name="middle_name"
                                    className="form-input mt-2"
                                    value={formData.middle_name}
                                    onChange={handleChange}
                                    readOnly
                                />
                            </div>
                            <div className="col-md-6 mt-3">
                                <label htmlFor="last_name">Last Name</label>
                                <input
                                    type="text"
                                    id="last_name"
                                    name="last_name"
                                    className="form-input mt-2"
                                    value={formData.last_name}
                                    onChange={handleChange}
                                    readOnly
                                />
                            </div>
                        </>
                    )}
                    <div className="col-md-6 mt-3">
                        <label htmlFor="email">SIN</label>
                        <div className="sin_area">
                            <input
                                type={showSIN ? "text" : "password"}
                                id="sin"
                                name="sin"
                                className="form-input mt-2"
                                value={formData.sin}
                                onChange={handleChange}
                                readOnly
                            />
                            <div className="sin_icons">
                                {showSIN ? (
                                    <i
                                        className="fa fa-eye-slash"
                                        onClick={toggleSINVisibility} // Hide SIN when clicked
                                    ></i>
                                ) : (
                                    <i
                                        className="fa fa-eye"
                                        onClick={toggleSINVisibility} // Show SIN when clicked
                                    ></i>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            className="form-input mt-2"
                            value={formData.email}
                            onChange={handleChange}
                            readOnly
                        />
                    </div>
                    <div className="col-md-6 mt-3">
                        <label htmlFor="email">Date Of Birth</label>
                        <input
                            type="date"
                            id="date_of_birth"
                            name="date_of_birth"
                            className="form-input mt-2"
                            value={formData.date_of_birth}
                            onChange={handleChange}
                            readOnly
                        />
                    </div>
                    {/* <div className="col-md-6 mt-3">
                        <label htmlFor="designation_id">Designation </label>
                        <input
                            type="text"
                            id="designation_id"
                            name="designation_id"
                            className="form-input mt-2"
                            value={formData.designation_id}
                            onChange={handleChange}
                            readOnly
                        />
                    </div> */}
                    <div className="col-md-6 mt-3">
                        <label htmlFor="shareholder_part">Shareholder Part%</label>
                        <input
                            type="text"
                            id="shareholder_part"
                            name="shareholder_part"
                            className="form-input mt-2"
                            value={formData.shareholder_part}
                            onChange={handleChange}
                            readOnly
                        />
                    </div>
                    <div className="col-md-6 mt-3">
                        <label htmlFor="phone_number">Phone Number</label>
                        <input
                            type="text"
                            id="phone_number"
                            name="phone_number"
                            className="form-input mt-2"
                            value={formData.phone_number}
                            onChange={handleChange}
                            readOnly
                        />
                    </div>
                    <div className="col-md-12 mt-3">
                        <label htmlFor="address">Address</label>
                        <textarea
                            id="address"
                            name="address"
                            className="form-input mt-2"
                            value={formData.address}
                            onChange={handleChange}
                            readOnly
                        ></textarea>
                    </div>
                    <div className="col-md-6 mt-3">
                        <label htmlFor="city">City</label>
                        <input
                            type="text"
                            id="city"
                            name="city"
                            className="form-input mt-2"
                            value={formData.city}
                            onChange={handleChange}
                            readOnly
                        />
                    </div>
                    <div className="col-md-6 mt-3">
                        <label htmlFor="province">Province</label>
                        <input
                            type="text"
                            id="province"
                            name="province"
                            className="form-input mt-2"
                            value={formData.province}
                            onChange={handleChange}
                            readOnly
                        />
                    </div>
                    <div className="col-md-12 mt-3">
                        <label htmlFor="postal_code">Postal Code</label>
                        <input
                            type="text"
                            id="postal_code"
                            name="postal_code"
                            className="form-input mt-2"
                            value={formData.postal_code}
                            onChange={handleChange}
                            readOnly
                        />
                    </div>
                    {/* <div className="col-md-12 mt-4">
                        <button type="submit" className="btn-blue px-4">
                            Save Changes
                        </button>
                    </div> */}
                </div>
            </form>
        </>
    )
}

export default Business_Linked_Director_Detail
