import React, { useState } from "react";

const ClientLinkedDetail = ({ clientData, onSave }) => {
    const [showSIN, setShowSIN] = useState(false);
    const [formData, setFormData] = useState({
        client_unique_id: clientData?.client_unique_id || "",
        user_id: clientData?.user_id || "",
        source: clientData?.source || "",
        first_name: clientData?.first_name || "",
        middle_name: clientData?.middle_name || "",
        last_name: clientData?.last_name || "",
        sin: clientData?.sin || "",
        email: clientData?.email || "",
        date_of_birth: clientData?.date_of_birth || "",
        date_of_landing: clientData?.date_of_landing || "",
        marital_status: clientData?.marital_status || "",
        marital_status_change_date: clientData?.marital_status_change_date || "",
        office_location: clientData?.office_location || "",
        address: clientData?.address || "",
        city: clientData?.city || "",
        province: clientData?.province || "",
        postal_code: clientData?.postal_code || "",
        share_holder: clientData?.share_holder || "",
        primary_contact: clientData?.primary_contact || "",
        id_proof: clientData?.id_proof || "",
        profile_picture: clientData?.profile_picture || "",
        primary_contact_person: clientData?.primary_contact_person || "",
        family_head: clientData?.family_head || "",
        gender: clientData?.gender || "",
        residency_status: clientData?.residency_status || "",
        refer_by_client: clientData?.refer_by_client || "",
        refer_by_staff: clientData?.refer_by_staff || "",
        account_manager: clientData?.account_manager || "",
        client_category: clientData?.client_category || "",
        occupation_category: clientData?.occupation_category || "",
        self_employed_hst_no: clientData?.self_employed_hst_no || "",
        status: clientData?.status || "",
    });
    const toggleSINVisibility = () => {
        setShowSIN(!showSIN); // Toggle between showing and hiding SIN
    };

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        if (onSave) {
            onSave(formData); // Pass the updated data to the parent or API
        }
    };

    return (
        <>

            <form onSubmit={handleSubmit}>
                <div className="row light_input">

                    <div className="col-md-6 mt-3">
                        <label htmlFor="first_name">First Name</label>
                        <input
                            type="text"
                            id="first_name"
                            name="first_name"
                            className="form-input mt-2"
                            value={formData.first_name}
                            onChange={handleChange}
                            placeholder="Enter first name"
                            readOnly
                        />
                    </div>
                    <div className="col-md-6 mt-3">
                        <label htmlFor="middle_name">Middle Name</label>
                        <input
                            type="text"
                            id="middle_name"
                            name="middle_name"
                            className="form-input mt-2"
                            value={formData.middle_name}
                            onChange={handleChange}
                            placeholder="Enter middle name"
                            readOnly
                        />
                    </div>
                    <div className="col-md-6 mt-3">
                        <label htmlFor="last_name">Last Name</label>
                        <input
                            type="text"
                            id="last_name"
                            name="last_name"
                            className="form-input mt-2"
                            value={formData.last_name}
                            onChange={handleChange}
                            placeholder="Enter last name"
                            readOnly
                        />
                    </div>
                    <div className="col-md-6 mt-3">
                        <label htmlFor="last_name">SIN</label>
                        <div className="sin_area">
                            <input
                                t type={showSIN ? "text" : "password"}
                                id="sin"
                                name="sin"
                                className="form-input mt-2"
                                value={formData.sin}
                                onChange={handleChange}
                                placeholder="Enter last name"
                                readOnly
                            />
                            <div className="sin_icons">
                                {showSIN ? (
                                    <i
                                        className="fa fa-eye-slash"
                                        onClick={toggleSINVisibility} // Hide SIN when clicked
                                    ></i>
                                ) : (
                                    <i
                                        className="fa fa-eye"
                                        onClick={toggleSINVisibility} // Show SIN when clicked
                                    ></i>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 mt-3">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            className="form-input mt-2"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="Enter email"
                            readOnly
                        />
                    </div>
                    <div className="col-md-6 mt-3">
                        <label htmlFor="date_of_birth">Date of Birth</label>
                        <input
                            type="date"
                            id="date_of_birth"
                            name="date_of_birth"
                            className="form-input mt-2"
                            value={formData.date_of_birth}
                            onChange={handleChange}
                            readOnly
                        />
                    </div>
                    <div className="col-md-6 mt-3">
                        <label htmlFor="date_of_birth">Date of Landing</label>
                        <input
                            type="date"
                            id="date_of_landing"
                            name="date_of_landing"
                            className="form-input mt-2"
                            value={formData.date_of_landing}
                            onChange={handleChange}
                            readOnly
                        />
                    </div>
                    <div className="col-md-6 mt-3">
                        <label htmlFor="date_of_birth">Marital Status</label>
                        <input
                            type="text"
                            id="marital_status"
                            name="marital_status"
                            className="form-input mt-2"
                            value={formData.marital_status}
                            onChange={handleChange}
                            readOnly
                        />
                    </div>
                    <div className="col-md-6 mt-3">
                        <label htmlFor="date_of_birth">Marital Change Date</label>
                        <input
                            type="text"
                            id="marital_status_change_date"
                            name="marital_status_change_date"
                            className="form-input mt-2"
                            value={formData.marital_status_change_date}
                            onChange={handleChange}
                            readOnly
                        />
                    </div>
                    <div className="col-md-6 mt-3">
                        <label htmlFor="date_of_birth">Primary Contact</label>
                        <input
                            type="text"
                            id="primary_contact"
                            name="primary_contact"
                            className="form-input mt-2"
                            value={formData.primary_contact}
                            onChange={handleChange}
                            readOnly
                        />
                    </div>
                    {/* <div className="col-md-6 mt-3">
                        <label htmlFor="date_of_birth">Gender</label>
                        <input
                            type="text"
                            id="gender"
                            name="gender"
                            className="form-input mt-2"
                            value={formData.gender}
                            onChange={handleChange}
                            readOnly
                        />
                    </div> */}
                    <div className="col-md-6 mt-3">
                        <label htmlFor="date_of_birth">Family Head</label>
                        <input
                            type="text"
                            id="family_head"
                            name="family_head"
                            className="form-input mt-2"
                            value={formData.family_head}
                            onChange={handleChange}
                            readOnly
                        />
                    </div>
                    {/* <div className="col-md-6 mt-3">
                        <label htmlFor="date_of_birth">Residency Status</label>
                        <input
                            type="text"
                            id="residency_status"
                            name="residency_status"
                            className="form-input mt-2"
                            value={formData.residency_status}
                            onChange={handleChange}
                            readOnly
                        />
                    </div> */}
                    {/* <div className="col-md-6 mt-3">
                        <label htmlFor="date_of_birth">Refer By Staff</label>
                        <input
                            type="text"
                            id="refer_by_staff"
                            name="refer_by_staff"
                            className="form-input mt-2"
                            value={formData.refer_by_staff}
                            onChange={handleChange}
                            readOnly
                        />
                    </div>
                    <div className="col-md-6 mt-3">
                        <label htmlFor="date_of_birth">Refer By Client</label>
                        <input
                            type="text"
                            id="refer_by_client"
                            name="refer_by_client"
                            className="form-input mt-2"
                            value={formData.refer_by_client}
                            onChange={handleChange}
                            readOnly
                        />
                    </div>
                    <div className="col-md-6 mt-3">
                        <label htmlFor="date_of_birth">Occupation Category</label>
                        <input
                            type="text"
                            id="occupation_category"
                            name="occupation_category"
                            className="form-input mt-2"
                            value={formData.occupation_category}
                            onChange={handleChange}
                            readOnly
                        />
                    </div>
                    <div className="col-md-6 mt-3">
                        <label htmlFor="date_of_birth">Account Manager</label>
                        <input
                            type="text"
                            id="account_manager"
                            name="account_manager"
                            className="form-input mt-2"
                            value={formData.account_manager}
                            onChange={handleChange}
                            readOnly
                        />
                    </div>

                    <div className="col-md-6 mt-3">
                        <label htmlFor="client_category">Client Category</label>
                        <input
                            type="text"
                            id="client_category"
                            name="client_category"
                            className="form-input mt-2"
                            value={formData.client_category}
                            onChange={handleChange}
                            placeholder="Enter client category"
                            readOnly
                        />
                    </div> */}
                    <div className="col-md-6 mt-3">
                        <label htmlFor="source">Self Employed Hst No.</label>
                        <input
                            type="text"
                            id="self_employed_hst_no"
                            name="self_employed_hst_no"
                            className="form-input mt-2"
                            value={formData.self_employed_hst_no}
                            onChange={handleChange}
                            placeholder="Enter source"
                            readOnly
                        />
                    </div>

                    {/* <div className="col-md-6 mt-3">
                        <label htmlFor="source">Source</label>
                        <input
                            type="text"
                            id="source"
                            name="source"
                            className="form-input mt-2"
                            value={formData.source}
                            onChange={handleChange}
                            placeholder="Enter source"
                            readOnly
                        />
                    </div> */}
                    <div className="col-md-6 mt-3">
                        <label htmlFor="source">Office Location</label>
                        <input
                            type="text"
                            id="office_location"
                            name="office_location"
                            className="form-input mt-2"
                            value={formData.office_location}
                            onChange={handleChange}
                            placeholder="Enter source"
                            readOnly
                        />
                    </div>


                    <div className="col-md-6 mt-3">
                        <label htmlFor="city">City</label>
                        <input
                            type="text"
                            id="city"
                            name="city"
                            className="form-input mt-2"
                            value={formData.city}
                            onChange={handleChange}
                            placeholder="Enter city"
                            readOnly
                        />
                    </div>
                    <div className="col-md-6 mt-3">
                        <label htmlFor="province">Province</label>
                        <input
                            type="text"
                            id="province"
                            name="province"
                            className="form-input mt-2"
                            value={formData.province}
                            onChange={handleChange}
                            placeholder="Enter province"
                            readOnly
                        />
                    </div>
                    <div className="col-md-6 mt-3">
                        <label htmlFor="postal_code">Postal Code</label>
                        <input
                            type="text"
                            id="postal_code"
                            name="postal_code"
                            className="form-input mt-2"
                            value={formData.postal_code}
                            onChange={handleChange}
                            placeholder="Enter postal code"
                            readOnly
                        />
                    </div>

                    {/* <div className="col-md-12 mt-4">
                        <button type="submit" className="btn-blue px-4">
                            Save Changes
                        </button>
                    </div> */}
                </div>
            </form>
        </>
    );
};

export default ClientLinkedDetail;
