import React, { useState } from 'react'

const Business_Linked_Detail = ({ businessData, onSave }) => {
    // Initialize state with provided corporate contact data
    const [formData, setFormData] = useState({
        name: businessData?.name || "",
        email: businessData?.email || "",
        folder_number: businessData?.folder_number || "",
        office_address: businessData?.office_address || "",
        city: businessData?.city || "",
        province: businessData?.province || "",
        postal_code: businessData?.postal_code || "",
        country: businessData?.country || "",
    });

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        if (onSave) {
            onSave(formData); // Pass the updated data to the parent component or API
        }
    };
    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="row light_input">
                    <div className="col-md-6 mt-3">
                        <label htmlFor="first_name">Name</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            className="form-input mt-2"
                            value={formData.name}
                            onChange={handleChange}
                            readOnly
                        />
                    </div>
                    <div className="col-md-6 mt-3">
                        <label htmlFor="first_name">Folder Name</label>
                        <input
                            type="text"
                            id="folder_number"
                            name="folder_number"
                            className="form-input mt-2"
                            value={formData.folder_number}
                            onChange={handleChange}
                            readOnly
                        />
                    </div>
                    <div className="col-md-6 mt-3">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            className="form-input mt-2"
                            value={formData.email}
                            onChange={handleChange}
                            readOnly
                        />
                    </div>
                    <div className="col-md-12 mt-3">
                        <label htmlFor="address">Address</label>
                        <textarea
                            id="office_address"
                            name="office_address"
                            className="form-input mt-2"
                            value={formData.office_address}
                            onChange={handleChange}
                            readOnly
                        ></textarea>
                    </div>
                    {/* <div className="col-md-6 mt-3">
                        <label htmlFor="city">City</label>
                        <input
                            type="text"
                            id="city"
                            name="city"
                            className="form-input mt-2"
                            value={formData.city}
                            onChange={handleChange}
                            readOnly
                        />
                    </div>
                    <div className="col-md-6 mt-3">
                        <label htmlFor="province">Province</label>
                        <input
                            type="text"
                            id="province"
                            name="province"
                            className="form-input mt-2"
                            value={formData.province}
                            onChange={handleChange}
                            readOnly
                        />
                    </div>
                    <div className="col-md-12 mt-3">
                        <label htmlFor="postal_code">Postal Code</label>
                        <input
                            type="text"
                            id="postal_code"
                            name="postal_code"
                            className="form-input mt-2"
                            value={formData.postal_code}
                            onChange={handleChange}
                            readOnly
                        />
                    </div> */}
                    {/* <div className="col-md-12 mt-4">
                        <button type="submit" className="btn-blue px-4">
                            Save Changes
                        </button>
                    </div> */}
                </div>
            </form>
        </>
    )
}

export default Business_Linked_Detail
