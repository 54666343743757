import React, { useState, useContext } from "react";
import OtpInput from "react-otp-input";
import { Link, useNavigate } from "react-router-dom";
import { GlobalContext } from "../../../App";
import { http } from "../../http/http";
import { toast } from "react-toastify";

const GetOtp = () => {
  const [otpValue, setOtpValue] = useState("");
  const { setShowLoader } = useContext(GlobalContext);
  const navigate = useNavigate();

  const changePassword = (e) => {
    e.preventDefault(); // Prevent form submission
    if (!otpValue || otpValue.length !== 6) {
      toast.error("Please fill the OTP"); // Show error if OTP is not filled
      return;
    }

    setShowLoader(true);
    http(
      {
        method: "POST",
        url: "verify-otp",
        isSecure: true,
        body: {
          otp: otpValue,
        },
      },
      (res) => {
        if (res) {
          setShowLoader(false);
          navigate("/admin/changepassword");
          toast.success(res.single_message);
        }
      },
      (err) => {
        if (err.status === 401) {
          toast.error(err.single_message);
          setShowLoader(false);
        } else if (err.status === 400) {
          toast.error(err.single_message);
          setShowLoader(false);
        } else {
          toast.error(err.single_message);
        }
      }
    );
  };

  return (
    <div className="form-page otpverify">
      <div className="row m-auto">
        <div className="col-lg-6 bg-white formRight position-relative d-flex align-items-center justify-content-center py-5">
          <img
            src={require("../../../assets/images/assistlogo.png")}
            alt="assistlogo"
            className="assistlogo position-absolute pb-5"
          />
          <img
            src={require("../../../assets/images/form-top.png")}
            alt="formtop"
            className="formtop position-absolute"
          />
          <img
            src={require("../../../assets/images/form-bottom.png")}
            alt="formbottom"
            className="formbottom position-absolute bottom-0"
          />
          <div className="main-form font-poppins">
            <form action="#" method="post" onSubmit={changePassword}>
              <div className="form-head">
                <img
                  src={require("../../../assets/images/otpicon.png")}
                  className="mb-5 otpicon"
                  alt=""
                />
                <h2 className="fw-bold">Enter OTP</h2>
                <h6 className="text-muted">
                  Please enter the One-Time Password (OTP) that we have just
                  sent to your email address.
                </h6>
              </div>

              <div className="form-area mt-4">
                <div className="mt-4 otp_box">
                  <div className="d-flex align-items-center justify-content-center gap-2">
                    <OtpInput
                      value={otpValue}
                      onChange={setOtpValue}
                      numInputs={6}
                      renderSeparator={<span>&nbsp;</span>}
                      renderInput={(props) => (
                        <input {...props} className="otp_control" />
                      )}
                    />
                  </div>
                  <div className="getotp text-end my-3">
                    <h4>
                      Didn't get the OTP?
                      <button className="bg-transparent text-primary border-0 ms-1 p-0">
                        Resend
                      </button>
                    </h4>
                  </div>
                  <div className="form-group mt-3 text-center">
                    <button
                      type="submit"
                      className="w-100 btn btn-primary rounded-4 border-none border py-3 text-white"
                      disabled={otpValue?.length !== 6}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="col-lg-6 formLeft vh-100">
          <div className="left-text position-relative vh-100">
            <div className="text-bottom pe-5 lg:pe-0 mt-0 lg:mt-5 position-absolute">
              <h2 className="text-white font-impact mb-0 lg:mb-5">Assisterp</h2>
              <p className="fw-light mt-4 text-white font-poppins">
                It is a long-established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum is that it has a more-or-less
                normal distribution of letters, as opposed to using 'Content
                here, content here', making it look like readable English.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetOtp;
