import React, { useState, useContext, useCallback, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { http } from "../../http/http";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { GlobalContext } from "../../../App";
import { debounce } from "lodash";
import Contact_Detail from "../../common/Contact_Detail";
import { Depended_list } from "../../../helpers/staticList";

const Dependent_Detail = (props) => {
    const { id } = useParams();
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
        watch,
        setError,
        unregister
    } = useForm({ mode: "all", defaultValues: { dependents: [] } });
    const formValues = watch();
    const { setShowLoader } = useContext(GlobalContext);
    const { fetchData, showDependentModal, setShowDependentModal } = props;
    const [searchResults, setSearchResults] = useState([]); // List of search results
    const [selectedContact, setSelectedContact] = useState(null); // Selected contact data
    const [searchTerm, setSearchTerm] = useState(""); // Input value for search
    const [hasSearched, setHasSearched] = useState(false);
    const [contactOpenModel, setOpenContactModel] = useState(false);
    const [openContactModalKey, setOpenContactModalKey] = useState(null);
    const [searchError, setSearchError] = useState(""); // To store validation message
    const [sourceModal, setSourceModal] = useState(null);
    const formatDate = (isoDate) => {
        if (!isoDate) return ""; // Return empty if no date
        const date = new Date(isoDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Add leading zero
        const day = String(date.getDate()).padStart(2, "0"); // Add leading zero
        return `${year}-${month}-${day}`;
    };

    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        middle_name: '',
        sin: '',
        date_of_birth: '',
        relation: '',
    });
    const [formErrors, setFormErrors] = useState({});
    const [isContactSelected, setIsContactSelected] = useState(false);
    const [isAddingNew, setIsAddingNew] = useState(false);
    const [showSIN, setShowSIN] = useState(false);


    const toggleSINVisibility = () => {
        setShowSIN(!showSIN); // Toggle between showing and hiding SIN
    };

    const resetForm = () => {
        setFormData({
            first_name: '',
            last_name: '',
            middle_name: '',
            sin: '',
            date_of_birth: '',
            relation: '',
        });
        setSearchTerm('');
        setSelectedContact(null);
        setSearchResults([]);
        setIsContactSelected(false);
        setIsAddingNew(false);
    };
    const onCancel = () => {
        resetForm();
        setShowDependentModal(false); // Close the modal
    };
    const addDependent = (e) => {
        e.preventDefault();

        // Call the validation function
        const errors = validateForm();

        // Update the formErrors state with validation errors
        setFormErrors(errors);

        // Check if there are any errors
        if (Object.keys(errors).length > 0) {
            console.error("Validation Errors:", errors);
            return; // Stop the submission process
        }
        setShowLoader(true);

        http(
            {
                method: "POST",
                url: `client/dependent/${id}/add`,
                body: {
                    first_name: formData.first_name || "",
                    last_name: formData.last_name || "",
                    middle_name: formData.middle_name || "",
                    sin: formData.sin || "",
                    date_of_birth: formData.date_of_birth || "",
                    relation: formData.relation || "",
                    contact_detail_id: selectedContact?.id
                },
                isSecure: true,
            },
            (res) => {
                toast.success(res.single_message);
                resetForm();
                fetchData();
                onHideHandler();
                setShowLoader(false);
            },
            (err) => {
                // Handle errors according to the response
                if (err.status === 401) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else if (err.status === 400) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else {
                    toast.error(err.single_message);
                    setShowLoader(false);
                }
            }
        );
    };

    const onHideHandler = () => {
        setShowDependentModal(false);
    };


    // Fetch data from API based on search input
    const fetchSearchResults = (query) => {
        let url;

        if (query === "DependedKey") {
            url = `v2/contact_detail`;
            setSearchTerm(""); // Clear the search term if the query is 'SpouseKey'
        } else {
            const queryParams = new URLSearchParams({ search: query });
            url = `v2/contact_detail?${queryParams.toString()}`;
        }

        // Make the HTTP request
        http(
            {
                method: "GET",
                url,
                isSecure: true,
            },
            (res) => {
                if (res?.data?.data) {
                    if (query === "DependedKey") {
                        const filteredResults = res.data.data[0]; // Assuming 'data' is an array
                        setSelectedContact(filteredResults); // Set the first contact as selected
                    } else {
                        const filteredResults = res.data.data.filter(
                            (contact) =>
                                contact.first_name?.toLowerCase().includes(query.toLowerCase())

                            // ||
                            //     contact.company_name?.toLowerCase().includes(query.toLowerCase())
                        );
                        setSearchResults(filteredResults); // Update the search results state
                    }
                } else {
                    console.warn("No data found in the response."); // Log a warning if no data is found
                    setSearchResults([]); // Clear the search results
                }
            },
            (err) => {
                console.error("Error Response:", err); // Log the error response for debugging
                toast.error(err.single_message || "Failed to fetch results.");
                setShowLoader(false);
            }
        );
    };

    // Debounced search handler
    const debouncedSearch = useCallback(
        debounce((query) => {
            if (query.trim()) {
                fetchSearchResults(query);
            } else {
                setSearchResults([]); // Clear results if no query
            }
        }, 300),
        []
    );



    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearchTerm(query);
        setIsContactSelected(false);
        setIsAddingNew(false);
        setFormData({
            ...formData,
            first_name: '',
            last_name: '',
            middle_name: '',
            sin: '',
            date_of_birth: '',
            relation: '',

        });

        // Reset 'hasSearched' and search results when the input is cleared
        if (!query.trim()) {
            setHasSearched(false);
            setSearchResults([]); // Clear search results
        } else {
            setHasSearched(true);
            debouncedSearch(query); // Call debounced search
        }
    };


    const handleSelectContact = (contact) => {
        setSelectedContact(contact); // Save selected contact in state
        setSearchTerm(''); // Clear the search term to hide the dropdown
        setSearchResults([]);
        setHasSearched(false);
        setIsContactSelected(true);
        setFormData({
            ...formData,

            first_name: contact.first_name || "",
            middle_name: contact.middle_name || "",
            last_name: contact.last_name || "",
            sin: contact.sin || "",
            relation: contact.relation || "",
            date_of_birth: formatDate(contact.dob) || "", // Ensure correct parentheses placement

        });

        // Clear validation errors for the pre-filled fields
        setFormErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };

            // Remove errors for fields being pre-filled
            if (contact.first_name) delete updatedErrors.first_name;
            if (contact.last_name) delete updatedErrors.last_name;
            delete updatedErrors.searchTerm;
            return updatedErrors;
        });
    };


    useEffect(() => {
        if (openContactModalKey) {
            fetchSearchResults(openContactModalKey);
            setOpenContactModalKey(null);
        }
    }, [openContactModalKey]);

    useEffect(() => {
        if (selectedContact) {
            // Update the search field with the selected contact's name or company name
            const selectedName = selectedContact.first_name || "";

            // || selectedContact.company_name || "";
            setSearchTerm(selectedName);
            setFormData({
                ...formData,
                first_name: selectedContact.first_name || "",
                middle_name: selectedContact.middle_name || "",
                last_name: selectedContact.last_name || "",
                relation: selectedContact.relation || "",
                sin: selectedContact.sin || "",
                date_of_birth: formatDate(selectedContact.dob) || "", // Ensure correct parentheses placement

            });

        } else if (isAddingNew) {
            // Reset form data when adding a new contact
            setFormData({
                first_name: '',
                last_name: '',
                middle_name: '',
                sin: '',
                date_of_birth: '',
                relation: '',
            });
        }
    }, [selectedContact, isAddingNew]);

    const validateForm = () => {
        let errors = {};
        if (!searchTerm.trim()) {
            errors.searchTerm = "Required.";
        }
        if (!formData.first_name) {
            errors.first_name = "Required";
        }
        if (!formData.last_name) {
            errors.last_name = "Required";
        }


        return errors;
    };

    const handleInputChange = (field, value) => {
        // Update the form data
        setFormData((prev) => ({
            ...prev,
            [field]: value,
        }));

        // Clear the error for the specific field if it exists
        setFormErrors((prevErrors) => {
            const { [field]: removedError, ...rest } = prevErrors;
            return rest;
        });
    };


    return (
        <>
            <Modal
                show={showDependentModal}
                onHide={onHideHandler}
                centered
                size="xl"
                contentClassName="mx-auto"
                className="modal operating-modal font-poppins"
                backdrop="static"
                keyboard={false}
            >
                <div className="modal-header border-0 justify-content-between">
                    <h4 className="fs-20 fw-semibold">Add Dependent Details</h4>
                    <Modal.Header closeButton onClick={onCancel} className="close-icon border-0"></Modal.Header>
                </div>
                <div className="modal-body">
                    <form onSubmit={addDependent}>
                        <div>
                            <div className="row mb-3">
                                <div className="col-lg-4 col-md-12 mt-3 dctor-search">
                                    <label className="fs-18">Select Dependent <span className="text-danger">*</span></label>
                                    <div className="position-relative mt-2">
                                        <input
                                            type="text"
                                            className="form-input dtr-srch"
                                            placeholder="Search for a name or company"
                                            value={searchTerm}
                                            onChange={handleSearchChange}
                                        />
                                        {searchError && <div className="text-danger mt-2">{searchError}</div>}
                                        {searchTerm.trim() && hasSearched && (
                                            <ul className={`p-0 ${!searchTerm ? "bdr-none" : ""}`}>
                                                {hasSearched && searchResults.length === 0 ? (
                                                    <li>
                                                        <p
                                                            className="t_blue fw-semibold fs-14 w-100 dropdown-item cursor-pointer pb-2"
                                                            onClick={() => {
                                                                // setShowDependentModal(false); // Close the current modal
                                                                // setOpenContactModel(true); // Open the new modal
                                                                // setSourceModal("depended"); // Set the source as "spouse"
                                                                setShowDependentModal(false);
                                                                setOpenContactModel(true);
                                                                setIsAddingNew(true); // Enable "Add New" mode
                                                                setSearchResults([]); // Clear search results
                                                                setSelectedContact(null); // Clear selected contact
                                                                setSearchTerm(""); // Clear search term
                                                                setSourceModal("depended");
                                                            }}
                                                        >
                                                            + Add New
                                                        </p>
                                                    </li>
                                                ) : (
                                                    searchResults.map(
                                                        (contact) =>
                                                            (contact.first_name) && (
                                                                <li
                                                                    key={contact.id}
                                                                    className="list-group-item list-group-item-action d-flex justify-content-start align-items-center w-auto search_dropbox"
                                                                    onClick={() => handleSelectContact(contact)}
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    <p className="fs-16 text-black">{contact.first_name}</p>
                                                                    {/* <p className="fs-16 text-black">{contact.company_name}</p> */}
                                                                </li>
                                                            )
                                                    )
                                                )}
                                            </ul>
                                        )}
                                    </div>
                                    {formErrors.searchTerm && <div className="text-danger mt-2">{formErrors.searchTerm}</div>}
                                </div>
                                {isContactSelected || isAddingNew ? (
                                    <>
                                        <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                            <label htmlFor="" className="fs-18">First Name <span className="text-danger">*</span></label>
                                            <input
                                                type="text"
                                                name="first_name"
                                                className="form-input mt-2"
                                                placeholder="Type here..."
                                                value={formData.first_name || ''}
                                                onChange={(e) => handleInputChange('first_name', e.target.value)}
                                                disabled
                                            />
                                            {formErrors.first_name && <span className="text-danger">{formErrors.first_name}</span>}
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                            <label htmlFor="" className="fs-18">Middle Name</label>
                                            <input
                                                type="text"
                                                name="middle_name"
                                                className="form-input mt-2"
                                                placeholder="Type here..."
                                                value={formData.middle_name || ''}
                                                onChange={(e) => handleInputChange('middle_name', e.target.value)}
                                                disabled
                                            />
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                            <label htmlFor="" className="fs-18">Last Name <span className="text-danger">*</span></label>
                                            <input
                                                type="text"
                                                name="last_name"
                                                className="form-input mt-2"
                                                placeholder="Type here..."
                                                // {...register("last_name", { required: "Required", })}
                                                value={formData.last_name || ''}
                                                onChange={(e) => handleInputChange('last_name', e.target.value)}
                                                disabled
                                            />
                                            {formErrors.last_name && <span className="text-danger">{formErrors.last_name}</span>}
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                            <label htmlFor="" className="fs-18">SIN</label>
                                            <div className="sin_area">
                                                <input
                                                    type={showSIN ? "text" : "password"}
                                                    name="sin"
                                                    className="form-input mt-2"
                                                    placeholder="Type here..."
                                                    maxLength={9}
                                                    // {...register("sin", {
                                                    //     pattern: {
                                                    //         value: /^\d+$/, // Allow only numbers

                                                    //     },
                                                    // })}
                                                    // onInput={(e) => {
                                                    //     e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Replace non-numeric characters
                                                    // }}
                                                    value={formData.sin || ''}
                                                    onChange={(e) => handleInputChange('sin', e.target.value)}
                                                />
                                                <div className="sin_icons">
                                                    {showSIN ? (
                                                        <i
                                                            className="fa fa-eye-slash"
                                                            onClick={toggleSINVisibility} // Hide SIN when clicked
                                                        ></i>
                                                    ) : (
                                                        <i
                                                            className="fa fa-eye"
                                                            onClick={toggleSINVisibility} // Show SIN when clicked
                                                        ></i>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                            <label htmlFor="" className="fs-18">Date of Birth</label>
                                            <input
                                                type="date"
                                                name="date_of_birth"
                                                className="form-input mt-2"
                                                placeholder="Type here..."
                                                value={formData.date_of_birth || ''}
                                                max={new Date().toISOString().split("T")[0]}
                                                onChange={(e) => handleInputChange('date_of_birth', e.target.value)}
                                            />
                                        </div>
                                        {/* <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                            <label htmlFor="" className="fs-18">Relation</label>
                                            <input
                                                type="text"
                                                name="relation"
                                                className="form-input mt-2"
                                                placeholder="Type here..."
                                                value={formData.relation || ''}
                                                onChange={(e) => handleInputChange('relation', e.target.value)}
                                            />
                                        </div> */}

                                        <div className="col-lg-4 col-md-6 mt-4">
                                            <label htmlFor="relation">
                                                Relation
                                            </label>
                                            <select
                                                name="relation"
                                                id="relation"
                                                className="form-input mt-2"
                                                value={formData.relation || ''}
                                                onChange={(e) => handleInputChange('relation', e.target.value)}
                                            >
                                                <option value=""
                                                    selected
                                                    disabled="disabled">Select</option>
                                                {Depended_list && Depended_list.getList().map((data) => (
                                                    <option key={data.key} value={data.key}>
                                                        {data.value}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="modal-footer border-0 px-2 pb-2 mt-4">
                                            <button type="submit" className="btn-blue">Add</button>
                                        </div>
                                    </>
                                ) : null}
                            </div>

                        </div>


                    </form>

                </div>
            </Modal>
            <Contact_Detail
                contactOpenModel={contactOpenModel}
                setOpenContactModel={setOpenContactModel}
                setShowDependentModal={setShowDependentModal}
                setOpenContactModalKey={setOpenContactModalKey}
                openContactModalKey={openContactModalKey}
                sourceModal={sourceModal}
                fetchSearchResults={fetchSearchResults}
                setIsAddingNew={setIsAddingNew}
            />
        </>

    );
};
export default Dependent_Detail;
