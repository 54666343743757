import React, { useEffect, useState, useContext, useCallback } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { http } from "../../http/http";
import { toast } from "react-toastify";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { GlobalContext } from "../../../App";
import moment from "moment";
import { debounce } from "lodash";
import Contact_Detail from "../../common/Contact_Detail";

const Spouse = (props) => {
    // const { id } = useParams();
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
        watch,
    } = useForm({ mode: "all" });
    const formValues = watch();
    const navigate = useNavigate();
    const { setShowLoader } = useContext(GlobalContext);
    const { fetchSpouData, spouseModal, setSpouseModal, id, } = props;
    const [usersSpouse, setUsersSpouse] = useState([]);
    const [address, setAddress] = useState('');
    const [province, setProvince] = useState('');
    const [city, setCity] = useState('');
    const [names, setNames] = useState([]);
    const [clientProfile, setClientProfile] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [isClientSinVisible, setIsClientSinSinVisible] = useState(false);
    const [searchResults, setSearchResults] = useState([]); // List of search results
    const [selectedContact, setSelectedContact] = useState(null); // Selected contact data
    const [searchTerm, setSearchTerm] = useState(""); // Input value for search
    const [hasSearched, setHasSearched] = useState(false);
    const [contactOpenModel, setOpenContactModel] = useState(false);
    const [openContactModalKey, setOpenContactModalKey] = useState(null);
    const [searchError, setSearchError] = useState(""); // To store validation message
    const [sourceModal, setSourceModal] = useState(null);
    const formatDate = (isoDate) => {
        if (!isoDate) return ""; // Return empty if no date
        const date = new Date(isoDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Add leading zero
        const day = String(date.getDate()).padStart(2, "0"); // Add leading zero
        return `${year}-${month}-${day}`;
    };


    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        middle_name: '',
        contact_number: '',
        sin: '',
        date_of_birth: '',
        date_of_landing: '',
        country_id: '',
        postal_code: '',
        address: '',
        city: '',
        province: '',
        mis_notes: '',
    });
    const [formErrors, setFormErrors] = useState({});
    const [isContactSelected, setIsContactSelected] = useState(false);
    const [isAddingNew, setIsAddingNew] = useState(false);
    const resetForm = () => {
        setFormData({
            first_name: '',
            middle_name: '',
            last_name: '',
            sin: '',
            contact_number: '',
            email: '',
            province: '',
            postal_code: '',
            date_of_birth: '',
            country_id: '',
            city: '',
            date_of_landing: '',
            mis_notes: '',
        });
        setAddress('');
        setCity('');
        setProvince('');
        setSearchTerm('');
        setSelectedContact(null);
        setSearchResults([]);
        setIsContactSelected(false);
        setIsAddingNew(false);
    };
    const [showSIN, setShowSIN] = useState(false);


    const toggleSINVisibility = () => {
        setShowSIN(!showSIN); // Toggle between showing and hiding SIN
    };

    const onCancel = () => {
        resetForm();
        setSpouseModal(false); // Close the modal
    };
    const fetchSearchResults = (query) => {
        let url;

        if (query === "SpouseKey") {
            url = `v2/contact_detail`;
            setSearchTerm(""); // Clear the search term if the query is 'SpouseKey'
        } else {
            const queryParams = new URLSearchParams({ search: query });
            url = `v2/contact_detail?${queryParams.toString()}`;
        }

        // Make the HTTP request
        http(
            {
                method: "GET",
                url,
                isSecure: true,
            },
            (res) => {
                if (res?.data?.data) {
                    if (query === "SpouseKey") {
                        const filteredResults = res.data.data[0]; // Assuming 'data' is an array
                        setSelectedContact(filteredResults); // Set the first contact as selected
                    } else {
                        const filteredResults = res.data.data.filter(
                            (contact) =>
                                contact.first_name?.toLowerCase().includes(query.toLowerCase())
                            //  ||
                            //     contact.company_name?.toLowerCase().includes(query.toLowerCase())
                        );
                        setSearchResults(filteredResults); // Update the search results state
                    }
                } else {
                    console.warn("No data found in the response."); // Log a warning if no data is found
                    setSearchResults([]); // Clear the search results
                }
            },
            (err) => {
                console.error("Error Response:", err); // Log the error response for debugging
                toast.error(err.single_message || "Failed to fetch results.");
                setShowLoader(false);
            }
        );
    };

    // Debounced search handler
    const debouncedSearch = useCallback(
        debounce((query) => {
            if (query.trim()) {
                fetchSearchResults(query);
            } else {
                setSearchResults([]); // Clear results if no query
            }
        }, 300),
        []
    );



    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearchTerm(query);
        setIsContactSelected(false);
        setIsAddingNew(false);
        setFormData({
            ...formData,
            first_name: '',
            last_name: '',
            middle_name: '',
            contact_number: '',
            sin: '',
            date_of_birth: '',
            date_of_landing: '',
            country_id: '',
            postal_code: '',
            address: '',
            city: '',
            province: '',
            mis_notes: '',
        });
        setAddress("");
        setProvince("");
        setCity("");
        // Reset 'hasSearched' and search results when the input is cleared
        if (!query.trim()) {
            setHasSearched(false);
            setSearchResults([]); // Clear search results
        } else {
            setHasSearched(true);
            debouncedSearch(query); // Call debounced search
        }
    };


    const handleSelectContact = (contact) => {
        setSelectedContact(contact); // Save selected contact in state
        setSearchTerm(''); // Clear the search term to hide the dropdown
        setSearchResults([]);
        setHasSearched(false);
        setIsContactSelected(true);
        // Prefill form fields
        setAddress(contact.street_address || "");
        setProvince(contact.province || "");
        setCity(contact.city || "");

        setFormData({
            ...formData,
            address: contact.street_address || "",
            city: contact.city || "",
            province: contact.province || "",
            postal_code: contact.postal_code || "",
            first_name: contact.first_name || "",
            middle_name: contact.middle_name || "",
            last_name: contact.last_name || "",
            sin: contact.sin || "",
            email: contact.email || "",
            country_id: contact.country_id || "",
            mis_notes: contact.mis_notes || "",
            date_of_birth: formatDate(contact.dob) || "", // Ensure correct parentheses placement
            date_of_landing: formatDate(contact.date_of_landing) || "",
        });

        // Clear validation errors for the pre-filled fields
        setFormErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };

            // Remove errors for fields being pre-filled
            if (contact.first_name) delete updatedErrors.first_name;
            if (contact.last_name) delete updatedErrors.last_name;
            if (contact.email) delete updatedErrors.email;
            delete updatedErrors.searchTerm;
            return updatedErrors;
        });
    };


    const onSubmit = (e) => {
        e.preventDefault();

        // Call the validation function
        const errors = validateForm();

        // Update the formErrors state with validation errors
        setFormErrors(errors);

        // Check if there are any errors
        if (Object.keys(errors).length > 0) {
            console.error("Validation Errors:", errors);
            return; // Stop the submission process
        }
        setShowLoader(true);
        const newUser = {
            ...formData,
        };
        setUsersSpouse([...usersSpouse, newUser]);
        http(
            {
                method: "POST",
                url: `client/spouse/${id}/add`,
                body: {
                    first_name: formData.first_name,
                    middle_name: formData.middle_name,
                    last_name: formData.last_name,
                    sin: formData.sin,
                    contact_number: formData.contact_number,
                    email: formData.email,
                    province: formData.province, // Province from formData
                    postal_code: formData.postal_code, // Postal code from formData
                    date_of_birth: formData.date_of_birth,
                    country_id: formData.country_id,
                    city: formData.city, // City from formData
                    date_of_landing: formData.date_of_landing,
                    mis_notes: formData.mis_notes,
                    address: formData.address, // Address from formData
                    contact_detail_id: selectedContact?.id || null,
                },
                isSecure: true,
            },
            (res) => {
                setSpouseModal(false);
                toast.success(res.single_message);
                resetForm();
                fetchSpouData();
                setShowModal(false);
            },
            (err) => {
                // Handle errors according to the response
                if (err.status === 401) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else if (err.status === 400) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else {
                    toast.error(err.single_message);
                    setShowLoader(false);
                }
            }
        );

    }

    const onHideHandler = () => {
        setSpouseModal(false);

    };


    const handleSelect = async (value) => {
        setAddress(value); // Temporarily set the entered value

        try {
            const results = await geocodeByAddress(value); // Get full address details

            if (results && results[0]) {
                const addressComponents = results[0].address_components;
                const formattedAddress = results[0].formatted_address; // Full address
                let selectedProvince = '';
                let postalCode = '';
                let selectedCity = '';

                // Extract specific details from the address components
                addressComponents.forEach((component) => {
                    if (component.types.includes('administrative_area_level_1')) {
                        selectedProvince = component.short_name; // Province
                    }
                    if (component.types.includes('postal_code')) {
                        postalCode = component.short_name; // Postal Code
                    }
                    if (component.types.includes('locality')) {
                        selectedCity = component.long_name; // City
                    }
                });

                // Set the values in the formData
                setFormData((prev) => ({
                    ...prev,
                    address: formattedAddress, // Set full address
                    province: selectedProvince || prev.province, // Preserve cleared values
                    postal_code: postalCode || prev.postal_code, // Preserve cleared values
                    city: selectedCity || prev.city, // Preserve cleared values
                }));

                // Update state for UI display
                setProvince(selectedProvince || province);
                setCity(selectedCity || city);
            } else {
                console.warn('No results found for the selected address.');
            }
        } catch (error) {
            console.error('Error selecting place:', error);
        }
    };


    const toggleClientSinVisibility = () => {
        setIsClientSinSinVisible(!isClientSinVisible); // Toggle the state
    };



    useEffect(() => {
        if (openContactModalKey) {

            fetchSearchResults(openContactModalKey);
            setOpenContactModalKey(null);
        }
    }, [openContactModalKey]);

    useEffect(() => {
        if (selectedContact) {
            const selectedName = selectedContact.first_name || selectedContact.company_name || "";
            setSearchTerm(selectedName); // Show the selected name in the search field
            setAddress(selectedContact.street_address || "");
            setProvince(selectedContact.province || "");
            setCity(selectedContact.city || "");
            setFormData({
                ...formData,
                address: selectedContact.street_address || "",
                city: selectedContact.city || "",
                province: selectedContact.province || "",
                postal_code: selectedContact.postal_code || "",
                first_name: selectedContact.first_name || "",
                middle_name: selectedContact.middle_name || "",
                last_name: selectedContact.last_name || "",
                company_name: selectedContact.company_name || "",
                email: selectedContact.email || "",
                sin: selectedContact.sin || "",
                mis_notes: selectedContact.mis_notes || "",
                contact_number: selectedContact.contact_number || "",
                date_of_birth: formatDate(selectedContact.dob) || "", // Ensure correct parentheses placement
                date_of_landing: formatDate(selectedContact.date_of_landing) || "",
            });
        }
        else if (isAddingNew) {
            setFormData({
                first_name: '',
                middle_name: '',
                last_name: '',
                sin: '',
                contact_number: '',
                email: '',
                province: '',
                postal_code: '',
                date_of_birth: '',
                country_id: '',
                city: '',
                date_of_landing: '',
                mis_notes: '',
            });
        }
    }, [selectedContact, isAddingNew]);

    const validateForm = () => {
        let errors = {};
        if (!searchTerm.trim()) {
            errors.searchTerm = "Required.";
        }
        if (!formData.contact_number) {
            errors.contact_number = "Required";
        } else if (formData.contact_number.length < 7) {
            errors.contact_number = "Phone number must be at least 7 digits.";
        }
        if (!formData.first_name) {
            errors.first_name = "Required";
        }
        if (!formData.last_name) {
            errors.last_name = "Required";
        }
        if (!formData.email) {
            errors.email = "Required";
        }

        return errors;
    };
    const handleInputChange = (field, value) => {
        // Update the form data
        setFormData((prev) => ({
            ...prev,
            [field]: value, // Dynamically update the specific field
        }));

        // Clear the error for the specific field if it exists
        setFormErrors((prevErrors) => {
            const { [field]: removedError, ...rest } = prevErrors;
            return rest;
        });
    };

    return (
        <>
            <Modal
                show={spouseModal}
                onHide={onHideHandler}
                centered
                size="xl"
                contentClassName="mx-auto"
                className="modal operating-modal font-poppins"
                backdrop="static"
                keyboard={false}
            >
                <div className="modal-header border-0 justify-content-between">
                    <h4 className="fs-20 fw-semibold">Spouse</h4>
                    <Modal.Header closeButton onClick={onCancel} className="close-icon border-0"></Modal.Header>
                </div>
                <div className="modal-body">
                    <form onSubmit={onSubmit}>
                        <div className="advance-form row">
                            <div className="col-lg-4 col-md-12 my-3 dctor-search">
                                <label className="fs-18">Select Spouse <span className="text-danger">*</span></label>
                                <div className="position-relative mt-2">
                                    <input
                                        type="text"
                                        className="form-input dtr-srch"
                                        placeholder="Search for a name or company"
                                        value={searchTerm}
                                        onChange={handleSearchChange}
                                    />

                                    {searchTerm.trim() && hasSearched && (
                                        <ul className={`p-0 ${!searchTerm ? "bdr-none" : ""}`}>
                                            {hasSearched && searchResults.length === 0 ? (
                                                <li>
                                                    <p
                                                        className="t_blue fw-semibold fs-14 w-100 dropdown-item cursor-pointer pb-2"
                                                        onClick={() => {
                                                            // setSpouseModal(false); // Close the current modal
                                                            // setOpenContactModel(true); // Open the new modal
                                                            // setSourceModal("spouse"); // Set the source as "spouse"


                                                            setSpouseModal(false);
                                                            setOpenContactModel(true);
                                                            setIsAddingNew(true); // Enable "Add New" mode
                                                            setSearchResults([]); // Clear search results
                                                            setSelectedContact(null); // Clear selected contact
                                                            setSearchTerm(""); // Clear search term
                                                            setSourceModal("spouse");
                                                        }}
                                                    >
                                                        + Add New
                                                    </p>
                                                </li>
                                            ) : (
                                                searchResults.map(
                                                    (contact) =>
                                                        (contact.first_name) && (
                                                            <li
                                                                key={contact.id}
                                                                className="list-group-item list-group-item-action d-flex justify-content-start align-items-center w-auto search_dropbox"
                                                                onClick={() => handleSelectContact(contact)}
                                                                style={{ cursor: "pointer" }}
                                                            >
                                                                <p className="fs-16 text-black">{contact.first_name}</p>
                                                                {/* <p className="fs-16 text-black">{contact.company_name}</p> */}
                                                            </li>
                                                        )
                                                )
                                            )}
                                        </ul>
                                    )}
                                </div>
                                {formErrors.searchTerm && <div className="text-danger mt-2">{formErrors.searchTerm}</div>}
                            </div>
                            {isContactSelected || isAddingNew ? (
                                <>
                                    <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                        <label className="fs-18">First Name <span className="text-danger">*</span></label>
                                        <input
                                            type="text"
                                            name="first_name"
                                            id="first_name"
                                            className="form-input mt-2"
                                            // {...register("first_name", {
                                            //     required: "Required ",
                                            // })}
                                            value={formData.first_name || ''}
                                            onChange={(e) => handleInputChange('first_name', e.target.value)}
                                            disabled
                                        />
                                        {formErrors.first_name && <span className="text-danger">{formErrors.first_name}</span>}
                                        {/* {errors?.first_name && <span className="error">{errors?.first_name.message}</span>} */}
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                        <label className="fs-18">Middle Name</label>
                                        <input
                                            type="text"
                                            name="middle_name"
                                            id="middle_name"
                                            className="form-input mt-2"
                                            value={formData.middle_name || ''}
                                            onChange={(e) => handleInputChange('middle_name', e.target.value)}
                                            disabled
                                        />
                                        {/* {formErrors.first_name && <span className="text-danger">{formErrors.first_name}</span>} */}
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                        <label className="fs-18">Last Name <span className="text-danger">*</span></label>
                                        <input
                                            type="text"
                                            name="last_name"
                                            id="last_name"
                                            className="form-input mt-2"
                                            // {...register("last_name", {
                                            //     required: "Required ",
                                            // })}
                                            value={formData.last_name || ''}
                                            onChange={(e) => handleInputChange('last_name', e.target.value)}
                                            disabled
                                        />
                                        {formErrors.last_name && <span className="text-danger">{formErrors.last_name}</span>}
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                        <label className="fs-18">SIN</label>

                                        <div className="sin_area">
                                            <input
                                                type={showSIN ? "text" : "password"}
                                                name="sin"
                                                id="sin"
                                                placeholder="Type here..."
                                                className="form-input mt-2"
                                                maxLength={9} // Limit to 9 digits
                                                inputMode="numeric" // Display numeric keyboard on mobile devices
                                                value={formData.sin || ''}
                                                onChange={(e) => handleInputChange('sin', e.target.value)}
                                            />
                                            <div className="sin_icons">
                                                {showSIN ? (
                                                    <i
                                                        className="fa fa-eye-slash"
                                                        onClick={toggleSINVisibility} // Hide SIN when clicked
                                                    ></i>
                                                ) : (
                                                    <i
                                                        className="fa fa-eye"
                                                        onClick={toggleSINVisibility} // Show SIN when clicked
                                                    ></i>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                        <label className="fs-18">Email Address <span className="text-danger">*</span></label>
                                        <input
                                            type="email"
                                            name="email"
                                            id="email"
                                            className="form-input mt-2"
                                            // {...register("email", {
                                            //     required: "Required ",
                                            // })}
                                            value={formData.email || ''}
                                            onChange={(e) => handleInputChange('email', e.target.value)}
                                        />
                                        {formErrors.email && <span className="text-danger">{formErrors.email}</span>}
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                        <label className="fs-18">Phone No. <span className="text-danger">*</span></label>
                                        <input
                                            type="number"
                                            name="contact_number"
                                            id="contact_number"
                                            className="form-input mt-2"
                                            // {...register("contact_number", {

                                            // })}
                                            // onKeyDown={(e) => {
                                            //     if (
                                            //         e.key === "e" || // Prevent 'e'
                                            //         e.key === "E" || // Prevent 'E'
                                            //         e.key === "+" || // Prevent '+'
                                            //         e.key === "-" || // Prevent '-'
                                            //         e.key === "." || // Prevent '.'
                                            //         (isNaN(Number(e.key)) &&
                                            //             e.key !== "Backspace" &&
                                            //             e.key !== "ArrowLeft" &&
                                            //             e.key !== "ArrowRight")
                                            //     ) {
                                            //         e.preventDefault();
                                            //     }
                                            // }}
                                            value={formData.contact_number || ''}
                                            onChange={(e) => handleInputChange('contact_number', e.target.value)}
                                        />
                                        {formErrors.contact_number && <span className="text-danger">{formErrors.contact_number}</span>}
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                        <label className="fs-18">Date of Birth</label>
                                        <input
                                            type="date"
                                            name="date_of_birth"
                                            id="date_of_birth"
                                            className="form-input mt-2"
                                            max={new Date().toISOString().split("T")[0]}
                                            // {...register("date_of_birth", {

                                            // })}
                                            value={formData.date_of_birth || ''}
                                            onChange={(e) => handleInputChange('date_of_birth', e.target.value)}
                                        />
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                        <label className="fs-18">Date of Landing</label>
                                        <input
                                            type="date"
                                            name="date_of_landing"
                                            id="date_of_landing"
                                            className="form-input mt-2"
                                            max={new Date().toISOString().split("T")[0]}
                                            // {...register("date_of_landing", {

                                            // })}
                                            value={formData.date_of_landing || ''}
                                            onChange={(e) => handleInputChange('date_of_landing', e.target.value)}
                                        />
                                    </div>
                                    <div className="col-lg-4 col-md-12 mt-3">
                                        <label className="fs-18">Country of Residence</label>
                                        <select
                                            className="form-input mt-2"
                                            name="country_id"
                                            id="country_id"
                                            value={formData.country_id || ''}
                                            onChange={(e) => handleInputChange('country_id', e.target.value)}

                                        >
                                            <option value="" disabled selected>Select</option>
                                            <option value="1" >Canada</option>
                                            <option value="2">USA</option>
                                        </select>
                                    </div>



                                    <div className="col-lg-8 col-md-12 col-md-12 mt-3">
                                        <label className="fs-18">Street Address</label>
                                        <PlacesAutocomplete
                                            value={formData.address} // Use `formData.address` here
                                            onChange={(value) => handleInputChange('address', value)} // Update `address` in `formData`
                                            onSelect={handleSelect} // Handle address selection here
                                            searchOptions={{
                                                componentRestrictions: {
                                                    country: ["us", "ca"],
                                                },
                                            }}
                                        >
                                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                <div>
                                                    <input
                                                        {...getInputProps({
                                                            placeholder: 'Type address here...',
                                                            className: 'form-input mt-2 google-address',
                                                        })}
                                                    />
                                                    <div className="autocomplete-dropdown-container">
                                                        {loading && <div>Loading...</div>}
                                                        {suggestions.map((suggestion) => {
                                                            const className = suggestion.active
                                                                ? 'suggestion-item--active'
                                                                : 'suggestion-item';
                                                            return (
                                                                <div
                                                                    {...getSuggestionItemProps(suggestion, {
                                                                        className,
                                                                    })}
                                                                    key={suggestion.placeId}
                                                                >
                                                                    <span>{suggestion.description}</span>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            )}
                                        </PlacesAutocomplete>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                        <label className="fs-18">Province</label>
                                        <input
                                            className="form-input mt-2"
                                            name="province"
                                            id="province"
                                            value={province}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                setProvince(value); // Update local province state
                                                setFormData((prev) => ({ ...prev, province: value })); // Update formData state
                                            }}
                                        />
                                    </div>

                                    <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                        <label className="fs-18">City</label>
                                        <input
                                            type="text"
                                            name="city"
                                            id="city"
                                            value={city}
                                            className="form-input mt-2"
                                            placeholder="Type here..."
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                setCity(value); // Update local city state
                                                setFormData((prev) => ({ ...prev, city: value })); // Update formData state
                                            }}
                                        />
                                    </div>

                                    <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                        <label className="fs-18">Postal Code</label>
                                        <input
                                            type="text"
                                            name="postal_code"
                                            id="postal_code"
                                            className="form-input mt-2"
                                            placeholder="Type here..."
                                            value={formData.postal_code || ''}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                setFormData((prev) => ({ ...prev, postal_code: value })); // Update formData state
                                            }}
                                        />
                                    </div>

                                    <div className="col-lg-8 col-md-12 col-md-12 mt-3">
                                        <label className="fs-18">Misc. Note</label>
                                        <input
                                            type="text"
                                            name="mis_notes"
                                            id="mis_notes"
                                            className="form-input mt-2"
                                            // {...register("mis_notes", {
                                            // })}

                                            value={formData.mis_notes || ''}
                                            onChange={(e) => handleInputChange('mis_notes', e.target.value)}
                                        />
                                    </div>
                                    <div className="col-lg-12 mt-4 mb-2">
                                        <button type="submit" className="btn-blue ms-auto">Add</button>
                                    </div>
                                </>
                            ) : null}
                        </div>
                    </form>
                </div>
            </Modal>


            <Contact_Detail
                contactOpenModel={contactOpenModel}
                setOpenContactModel={setOpenContactModel}
                setSpouseModal={setSpouseModal}
                setOpenContactModalKey={setOpenContactModalKey}
                openContactModalKey={openContactModalKey}
                sourceModal={sourceModal}
                fetchSearchResults={fetchSearchResults}
                setIsAddingNew={setIsAddingNew}
            />
        </>
    )
}
export default Spouse;