import React, { useState } from "react";
import { Modal } from "react-bootstrap";

const Upload_Files = ({
    open,
    onClose,
    type,
    buttonLabel = "Upload", // Default button label
    fileTypes = ["JPEG", "JPG", "PDF", "XLS", "DOC"], // Supported file types
    maxSize = 10, // Max file size in MB
    onFileUpload,
    onFileDelete,
}) => {
    const [fileLabel, setFileLabel] = useState("");
    const [fileList, setFileList] = useState([]); // Track uploaded files

    const handleFileUpload = (event) => {
        const files = event.target.files;
        const newFiles = Array.from(files).map((file) => ({
            name: file.name,
            size: (file.size / (1024 * 1024)).toFixed(2), // Convert size to MB
            type: file.type.split("/").pop().toUpperCase(),
            progress: 0,
            status: "Uploading",
        }));

        // Simulate upload progress
        newFiles.forEach((file, index) => {
            let progress = 0;
            const interval = setInterval(() => {
                progress += 10;
                setFileList((prevList) => {
                    const updatedList = [...prevList];
                    updatedList[prevList.length - newFiles.length + index] = {
                        ...file,
                        progress,
                        status: progress === 100 ? "Completed" : "Uploading",
                    };
                    return updatedList;
                });
                if (progress === 100) clearInterval(interval);
            }, 200);
        });

        setFileList((prevList) => [...prevList, ...newFiles]);
        if (onFileUpload) onFileUpload(files, fileLabel);
    };

    return (
        <Modal show={open} onHide={onClose} centered size="md" contentClassName="mx-auto h-auto" className="modal pin-modal font-poppins" backdrop="static" keyboard={false}>
            <div className="modal-header border-0 justify-content-between">
                <h4 className="fs-20 fw-semibold">File Upload</h4>
                <Modal.Header closeButton className="close-icon border-0" onClick={onClose}></Modal.Header>
            </div>
            <div className="modal-body">
                <div className="assigned-form row mt-1">
                    <div className="col-lg-12 mt-1">
                        <div className="col-lg-12">
                            <label className="fs-18">File Label Name</label>
                            <input type="text" name="file_label" className="form-input mt-2" placeholder="Type here..." value={fileLabel} onChange={(e) => setFileLabel(e.target.value)} />
                        </div>
                        <div className="upload-input position-relative mt-4 text-center">
                            <div className="main-wrapper">
                                <div className="upload-main-wrapper">
                                    <div className="upload-wrapper mx-auto cursor-pointer">
                                        <input type="file" id="upload-file" multiple onChange={handleFileUpload} />
                                        <i className="fa fa-upload me-2"></i>
                                        <span className="file-upload-text cursor-pointer">Browse</span>
                                    </div>
                                    <h4 className="fs-14 mt-2">Drag & drop here</h4>
                                    <p className="fs-14 text-muted">
                                        Supports: {fileTypes.join(", ")} Max Size: {maxSize} MB
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {fileList.length > 0 && (
                        <div className="col-lg-12 mt-3">
                            <p className="text-muted">{fileList.length} Files Uploading...</p>
                        </div>
                    )}

                    {fileList.map((fileObj, index) => (
                        <div className="uploading mt-2 position-relative" key={index}>
                            <div className="l_upload d-flex align-items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                                    <circle cx="20" cy="20" r="20" fill="#bad5f3" />
                                    <text x="20" y="22" textAnchor="middle" fontSize="12" fill="#0263d1">
                                        {fileObj.type}
                                    </text>
                                </svg>
                                <div className="doc-name w-100 ms-2">
                                    <h5 className="fs-14 text-black">{fileObj.status}</h5>
                                    <div className="doc-info d-flex align-items-center justify-content-between">
                                        <span className="d-flex align-items-center">
                                            <p className="fs-12">{fileObj.name}</p>
                                            <hr />
                                            <p className="fs-12">Size: {fileObj.size} MB</p>
                                        </span>
                                        <p className="fs-12 text-black">{fileObj.progress}%</p>
                                    </div>
                                    <div className="progress">
                                        <div
                                            className="progress-bar"
                                            role="progressbar"
                                            aria-valuenow={fileObj.progress}
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                            style={{
                                                width: `${fileObj.progress}%`,
                                                backgroundColor: fileObj.progress === 100 ? "green" : "#007bff", // Green when 100%
                                            }}
                                        ></div>
                                    </div>
                                </div>
                            </div>
                            <i
                                className="fa fa-times"
                                onClick={() => {
                                    setFileList((prevList) => prevList.filter((_, i) => i !== index));
                                    if (onFileDelete) onFileDelete(index);
                                }}
                            ></i>
                        </div>
                    ))}

                    <div className="col-md-12 mt-4 mb-2">
                        <button className="btn-blue ms-auto">{buttonLabel}</button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default Upload_Files;
