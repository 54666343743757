const AdminEmailVerified = () => {
    return (
        <div className="vh-100 d-flex align-items-center justify-content-center w-100 email_verified font-poppins">
            <div className="p-2 p-lg-3 mh-100">
                <div className="bg-overlay-white d-flex align-items-center border position-relative mx-auto p-3 p-sm-4 p-md-5 rounded-4 bg-white">
                    <div className="container">
                        <img src={require("../../../assets/images/e-verified.jpg")} alt="e-verified" className="d-block m-auto mb-2" width={200} height={145} />
                        <h2 className="text-center mb-3">Email Confirmation</h2>
                        <p className="w-75 text-center m-auto">We have sent email to <span className="fw-bold text-primary">test@gmail.com</span> to confirm and verify your email address.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminEmailVerified;
