import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import HttpClient from '../../../helpers/HttpClient';

// Asynchronous thunks for clients operations

export const fetchClientListData = createAsyncThunk('client/fetch', async (_, { getState, rejectWithValue }) => {
    try {
        const { search, filters, page, limit } = getState().clientSlice;
        const response = await HttpClient.get("/clients", { search, filters, page, limit }, {}, { showToast: false });
        return response;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const fetchClientData = createAsyncThunk('client/add', async (formData, { dispatch, rejectWithValue }) => {
    try {
        const response = await HttpClient.post("/add-individual", formData);
        // dispatch(fetchAttributeData());
        return response;
    } catch (error) {
        return rejectWithValue(error);
    }
});


//Asynchronous thunks for Business clients operations

export const addCorporateData = createAsyncThunk('corporate/add', async (formData, { dispatch, rejectWithValue }) => {
    try {
        const response = await HttpClient.post("/corporate/add", formData);
        // dispatch(fetchAttributeData());
        return response;
    } catch (error) {
        return rejectWithValue(error);
    }
});




// Slice definition for handling employee data
const clientSlice = createSlice({
    name: 'client',
    initialState: {
        clientData: [],
        data: [],
        currentClient: null,
        page: 1,
        limit: 100,
        search: '',
        filters: [],
        error: null,
        activeTab: "individual"
    },
    reducers: {
        setPage: (state, action) => { state.page = action.payload; },
        setLimit: (state, action) => { state.limit = action.payload; },
        setSearch: (state, action) => { state.search = action.payload; },
        setFilters: (state, action) => { state.filters = action.payload; },
        setActiveTab: (state, action) => { state.activeTab = action.payload },
        setPageAndSearch: (state, action) => {
            state.page = action.payload.page;
            state.search = action.payload.search;
        },
        setCurrentEmployee: (state, action) => { state.currentEmployee = action.payload; }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchClientData.fulfilled, (state, action) => {
                state.clientData = action.payload;
                state.error = null;
            })
            .addCase(fetchClientListData.fulfilled, (state, action) => {
                state.data = action.payload;
                state.page = action.payload.current_page;
                state.limit = action.payload.per_page;
                state.error = null;
            })
            // .addCase(fetchEmployeeById.fulfilled, (state, action) => {
            //     state.currentEmployee = action.payload;
            //     state.error = null;
            // })
            .addMatcher(
                (action) => action.type.endsWith('rejected'),
                (state, action) => {
                    state.error = action.error.message || 'An error occurred';
                }
            )
            // Handle fulfilled cases
            .addMatcher(
                (action) => action.type.endsWith('fulfilled'),
                (state) => {
                    state.error = null;
                }
            );
    }
});

// Enhanced dispatches for automatically fetching employee data after setting parameters
export const { setPage, setLimit, setSearch, setFilters, setPageAndSearch, setActiveTab } = clientSlice.actions;
const enhancedSetAction = (actionCreator) => (payload) => (dispatch) => {
    dispatch(actionCreator(payload));
    dispatch(fetchClientListData());
};

export const setPageEnhanced = enhancedSetAction(setPage);
export const setLimitEnhanced = enhancedSetAction(setLimit);
export const setSearchEnhanced = enhancedSetAction(setSearch);
export const setFiltersEnhanced = enhancedSetAction(setFilters);
export const setPageAndSearchEnhanced = enhancedSetAction(setPageAndSearch);

export default clientSlice.reducer;