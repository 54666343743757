import React, { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";

export default function Search({ handleSearch, previousSearch }) {
    const [searchValue, setSearchValue] = useState("");

    const debouncedSearch = useCallback(
        debounce((query) => handleSearch(query.trim()), 500),
        [handleSearch]
    );

    const handleInputChange = (event) => {
        const trimmedValue = event.target.value.trim();
        setSearchValue(event.target.value); // Display the current value
        debouncedSearch(trimmedValue); // Trigger debounced search
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            const trimmedValue = event.target.value.trim();
            debouncedSearch.cancel(); // Cancel pending debounce
            handleSearch(trimmedValue); // Trigger immediate search
        }
    };

    useEffect(() => {
        if (previousSearch) {
            setSearchValue(previousSearch);
        }
    }, [previousSearch]);

    return (
        <div className="search-part relative">
            <input
                type="search"
                value={searchValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                className="form-input"
                placeholder="Search..."
            />
        </div>
    );
}
