import React, { useEffect, useState } from "react";
import { http } from "../../http/http";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
// import { toast } from "react-toastify";
import moment from "moment";

const Notes_Detail = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const storedId = JSON.parse(localStorage.getItem("clientId"));
    const [noteViewData, setNoteViewData] = useState();
    const [archiveModal, setArchiveModal] = useState(false);
    const [openPinModal, setOpenPinModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [currentNote, setCurrentNote] = useState({
        id: null,
        title: "",
        description: "",
        tags: "",
    });
    const [tags, setTags] = useState([]); // Array of tags
    const [inputValue, setInputValue] = useState(""); // Current input value for new tags

    const handleEditClick = (note) => {
        console.log("Editing Note:", note);

        setCurrentNote({
            id: note.id || null,
            title: note.title || "",
            description: note.description || "",
        });

        const initialTags = note.tags ? note.tags.map((tag) => tag.name) : [];
        console.log("Tags for edit:", initialTags);
        setTags(initialTags);
        setOpenEditModal(true);
    };

    // Add a tag when Enter key is pressed
    const handleKeyDown = (e) => {
        if (e.key === "Enter" && inputValue.trim()) {
            e.preventDefault();
            if (!tags.includes(inputValue.trim())) {
                setTags([...tags, inputValue.trim()]);
            }
            setInputValue(""); // Clear the input
        }
    };

    // Remove a tag by its index
    const handleRemoveTag = (index) => {
        setTags(tags.filter((_, i) => i !== index));
    };

    // Handle input value changes
    const handleTagsInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const updateNoteHandle = (data) => {
        try {
            const existingTags = new Set(tags.map(tag => tag.trim()));
            const payload = {
                title: data.title,
                description: data.description,
                tag: Array.from(existingTags), // Convert tags back to array
            };
            console.log("Payload for update:", payload);
            http(
                {
                    method: "PUT",
                    url: `client/note/update/${storedId}/${noteViewData?.id}`,
                    isSecure: true,
                    body: payload,
                },
                (res) => {
                    toast.success(res.single_message);
                    setTags([]);
                    setOpenEditModal(false);
                    handleNotesView();
                },
                (err) => {
                    console.error("Update failed:", err);
                    toast.error(err.single_message || "An error occurred.");
                }
            );
        } catch (err) {
            console.error("Error during update:", err);
        }
    };
    const handleNotesView = () => {
        try {
            http(
                {
                    method: "GET",
                    url: `client/note/${storedId}/${id}/view`,
                    isSecure: true,
                },
                (res) => {
                    setNoteViewData(res?.data)

                },
                (err) => {
                    console.error(err);
                },
            );
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        handleNotesView();
    }, []);

    const handlePinSubmit = (pinValue) => {
        try {
            http({
                method: "PATCH",
                url: `client/note/${noteViewData?.id}/pin`,
                isSecure: true,
                body: { pin: pinValue },
            }, (res) => {
                toast.success(res.single_message)
                setOpenPinModal(false);
                navigate("/admin/client/note");
            }, (err) => {
                // Handle errors according to the response
                if (err.status === 401) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else if (err.status === 400) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else {
                    toast.error(err.single_message);
                }
            }
            );
        } catch (err) {
            console.error(err);
        }
    };

    const handleArchiveSubmit = (archiveValue) => {
        try {
            http({
                method: "PATCH",
                url: `client/note/${noteViewData?.id}/archive`,
                isSecure: true,
                body: { archive: archiveValue },

            }, (res) => {
                toast.success(res.single_message)
                setArchiveModal(false);
                navigate("/admin/client/note");
            }, (err) => {
                // Handle errors according to the response
                if (err.status === 401) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else if (err.status === 400) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else {
                    toast.error(err.single_message);
                }
            }
            );
        } catch (err) {
            console.error(err);
        }
    }

    const handlePinClick = () => {
        setOpenPinModal(true);
    };
    const handleArchiveClick = () => {
        setArchiveModal(true);
    }

    return (
        <div className="right_section font-poppins"> <div className="clients-detail-area py-3">
            <div className="row notes-detail align-items-center">
                <div className="col-md-12">
                    <h4 className="fs-20 fw-semibold">Note Details</h4>
                </div>

                <div className="col-md-12 mt-3">
                    <div className="bg-white rounded-4 shadow-lg p-4">
                        <div className="n_detail-head d-flex align-items-center justify-content-between flex-wrap">
                            <h4 className="fs-20 fw-semibold mt-2">{noteViewData?.title}</h4>
                            <div className="note-client d-flex align-items-center gap-2 flex-wrap mt-2">
                                <div className="l_client d-flex align-items-center">
                                    <img src={require("../../../assets/images/user-pic.png")} alt="" />
                                    <p className="fs-14 text-black ms-2">John Smith</p>
                                </div>
                                <div className="r_client d-flex align-items-center gap-1 ">
                                    {/* <span className={`d-flex align-items-center justify-content-center cursor-pointer ${noteViewData?.pin === "1" ? 'active-pin' : 'inactive-pin'}`} onClick={() => handlePinClick()}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14.01" height="13.999" viewBox="0 0 14.01 13.999">
                                            <path id="thumbtack" d="M13.583,3.941,10.113.472a1.506,1.506,0,0,0-1.922-.2A1.459,1.459,0,0,0,8.005,2.49l.345.345a.473.473,0,0,1,0,.666L7.125,4.727a.879.879,0,0,1-.835.229l-.479-.123a4.741,4.741,0,0,0-1.692-.128A1.966,1.966,0,0,0,2.463,6.022,2.048,2.048,0,0,0,2.94,8.171L3.959,9.226.171,13.016A.584.584,0,1,0,1,13.841L4.77,10.067l.729.755a2.728,2.728,0,0,0,1.9.856,2.048,2.048,0,0,0,.684-.117A1.834,1.834,0,0,0,9.31,9.985a4.723,4.723,0,0,0-.125-1.677l-.124-.6a.88.88,0,0,1,.233-.825l1.219-1.217a.543.543,0,0,1,.346-.142.425.425,0,0,1,.3.127l.3.3a1.5,1.5,0,0,0,1.938.218,1.459,1.459,0,0,0,.186-2.221Z" transform="translate(0 -0.013)" />
                                        </svg>
                                    </span>
                                    <span className="d-flex align-items-center justify-content-center cursor-pointer" onClick={() => handeArchiveClick()}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14.002" height="13.999" viewBox="0 0 14.002 13.999">
                                            <g id="box" transform="translate(0 -0.052)">
                                                <path id="Path_3569" data-name="Path 3569" d="M11.082.052H2.916A2.916,2.916,0,0,0,0,2.968H0a1.75,1.75,0,0,0,1.75,1.75h10.5A1.732,1.732,0,0,0,14,3.1,2.919,2.919,0,0,0,11.209.055Z" transform="translate(0)" />
                                                <path id="Path_3570" data-name="Path 3570" d="M33.583,213.385H21.917a.583.583,0,0,0-.583.583v4.666a2.92,2.92,0,0,0,2.916,2.916h7a2.92,2.92,0,0,0,2.916-2.916v-4.666A.583.583,0,0,0,33.583,213.385ZM29.5,216.3H26a.583.583,0,1,1,0-1.167h3.5a.583.583,0,1,1,0,1.167Z" transform="translate(-20.751 -207.5)" />
                                            </g>
                                        </svg>
                                    </span> */}
                                    <span className="d-flex align-items-center justify-content-center cursor-pointer" onClick={() => handleEditClick(noteViewData)} >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="13.999" height="13.998" viewBox="0 0 13.999 13.998">
                                            <g id="write" transform="translate(-7.998 -8)">
                                                <path id="Path_3528" data-name="Path 3528" d="M8.3,26.037,8,28.468a.731.731,0,0,0,.728.827.718.718,0,0,0,.093-.006L11.257,29a2.317,2.317,0,0,0,1.35-.663l6.3-6.3a.292.292,0,0,0,0-.412l-3.232-3.235a.292.292,0,0,0-.412,0l-6.3,6.3a2.317,2.317,0,0,0-.663,1.35Z" transform="translate(0 -7.297)" />
                                                <path id="Path_3529" data-name="Path 3529" d="M41.979,9.951,40.471,8.443a1.512,1.512,0,0,0-2.138,0L36.918,9.858a.292.292,0,0,0,0,.412L40.151,13.5a.292.292,0,0,0,.412,0l1.416-1.416A1.512,1.512,0,0,0,41.979,9.951Z" transform="translate(-20.425 0)" />
                                                <path id="Path_3530" data-name="Path 3530" d="M36.874,52.583A.583.583,0,0,0,36.291,52H29.583a.583.583,0,0,0,0,1.167h6.707A.583.583,0,0,0,36.874,52.583Z" transform="translate(-14.877 -31.168)" />
                                            </g>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <p className="fs-14 text-muted">{moment(noteViewData?.created_at).format('MMM D, YYYY, h:mm a')}</p>
                        <p className="fs-16 mt-2">{noteViewData?.description}</p>
                        <div className="tag_area">
                            {
                                noteViewData && noteViewData?.tags.map((tagsList) => (
                                    <div className="timeline__item-header mt-2" key={tagsList?.id}>
                                        <p className="tag_box">{tagsList?.name}</p>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <Modal
                show={openPinModal}
                onHide={setOpenPinModal}
                size="md"
                centered
                contentClassName="mx-auto p-3 h-auto"
                className="modal pin-modal font-poppins"
                backdrop="static"
                keyboard={false}
            >
                <div className="modal-header border-0 justify-content-between p-0">
                    <h4 className="fs-20">Pin</h4>
                    <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
                </div>
                <div className="modal-body ">
                    <h4 className="txt text-center mt-2">Are you sure you want to Pin?</h4>

                </div>
                <div className=" text-center my-4">
                    <form>
                        <div className="modal-footer border-0 justify-content-center p-0">
                            {noteViewData?.pin === "1" ? (
                                <button type="button" className="btn-blue" onClick={() => { handlePinSubmit("0"); setOpenPinModal(false); }}>Unpin</button>
                            ) : (
                                <button type="button" className="btn-blue" onClick={() => { handlePinSubmit("1"); setOpenPinModal(false); }}>Pin</button>
                            )}
                        </div>
                    </form>
                </div>
            </Modal>
            <Modal
                show={archiveModal}
                onHide={setArchiveModal}
                size="md"
                centered
                contentClassName="mx-auto p-3 h-auto"
                className="modal pin-modal font-poppins"
                backdrop="static"
                keyboard={false}
            >
                <div className="modal-header border-0 justify-content-between p-0">
                    <h4 className="fs-20">Archive</h4>
                    <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
                </div>
                <div className="modal-body ">
                    <h4 className="txt text-center mt-2">Are you sure you want to Archive?</h4>

                </div>
                <div className=" text-center my-4">
                    <form>
                        <div className="modal-footer border-0 justify-content-center p-0">
                            {noteViewData?.archive === "1" ? (
                                <button type="button" className="btn-blue" onClick={() => { handleArchiveSubmit("0"); setArchiveModal(false); }}>Unarchive</button>
                            ) : (
                                <button type="button" className="btn-blue" onClick={() => { handleArchiveSubmit("1"); setArchiveModal(false); }}>Archive</button>
                            )
                            }

                        </div>
                    </form>
                </div>
            </Modal>
            {/* EDIT NOTE MODAL */}
            <Modal
                show={openEditModal}
                onHide={() => setOpenEditModal(false)}
                size="lg"
                centered
                contentClassName="mx-auto h-auto"
                className="modal pin-modal font-poppins"
                backdrop="static"
                keyboard={false}
            >
                <div className="modal-header border-0 justify-content-between">
                    <div>
                        <h4 className="fs-24 fw-semibold">Edit Note Details</h4>
                        <p className="text-secondary">Please edit your note below.</p>
                    </div>
                </div>
                <div className="modal-body px-4">
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            console.log("Form submitted with:", currentNote); // Debug form data
                            updateNoteHandle(currentNote); // Trigger the update function
                        }}
                    >
                        {/* Title Input */}
                        <div className="input-group">
                            <label className="w-100">Title</label>
                            <input
                                type="text"
                                className="form-input mt-1"
                                name="title"
                                value={currentNote.title}
                                onChange={(e) => setCurrentNote({ ...currentNote, title: e.target.value })}
                            />
                        </div>

                        {/* Description Input */}
                        <div className="input-group mt-4">
                            <label className="w-100">Description</label>
                            <textarea
                                className="mt-1"
                                name="description"
                                cols="30"
                                rows="8"
                                value={currentNote.description}
                                onChange={(e) => setCurrentNote({ ...currentNote, description: e.target.value })}
                            ></textarea>
                        </div>

                        {/* Tags Input */}
                        <div className="form-group mt-4 moreInfoShow">
                            <label className="d-flex gap-2 align-items-end">
                                Tags <p className="fs-12 fst-italic">(Press Enter)</p>
                            </label>

                            <div className="bootstrap-tagsinput form-input">
                                {tags.map((tag, index) => (
                                    <span key={index} className="tag-item">
                                        <span className="tag-text">{tag}</span>
                                        <span className="tag-close" onClick={() => handleRemoveTag(index)}>x</span>
                                    </span>
                                ))}
                                <input
                                    type="text"
                                    name="input"
                                    value={inputValue}
                                    onChange={handleTagsInputChange}
                                    onKeyDown={handleKeyDown}
                                    placeholder="Type here..."
                                />
                            </div>
                        </div>

                        <div className="modal-footer border-0 justify-content-end">
                            <button
                                type="button"
                                className="btn-transparent"
                                onClick={() => setOpenEditModal(false)}
                            >
                                Cancel
                            </button>
                            <button type="submit" className="btn-blue">
                                Save
                            </button>
                        </div>
                    </form>
                </div>
            </Modal>
        </div>

    )
}
export default Notes_Detail;