import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { http } from "../../http/http";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { GlobalContext } from "../../../App";
import { toast } from "react-toastify";
const Business_Contact_Detail = () => {
    const navigate = useNavigate();
    const { setShowLoader } = useContext(GlobalContext);
    const [manualEntryData, setManualEntryData] = useState({
        type: 2,
        companyName: "",
        email: "",
        phoneNumber: "",
        streetAddress: "",
        city: "",
        province: "",
        country: "",
        postalCode: "",
        status: 1,
        notes: "",
    });
    const [address, setAddress] = useState("");
    const [errors, setErrors] = useState({ companyName: "", email: "" });
    useEffect(() => {
        // Retrieve data from sessionStorage
        const storedData = JSON.parse(sessionStorage.getItem("manualBusinessEntry"));
        if (storedData) {
            setManualEntryData((prevState) => ({ ...prevState, ...storedData }));
        }
    }, []);
    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setManualEntryData((prevData) => ({
    //         ...prevData,
    //         [name]: value,
    //     }));
    // };
    // const handleChange = (e) => {
    //     // Clear the error dynamically as the user types
    //     if (name === "companyName" && errors.companyName) {
    //         setErrors((prevErrors) => ({
    //             ...prevErrors,
    //             companyName: "",
    //         }));
    //     }
    //     if (name === "email" && errors.email) {
    //         setErrors((prevErrors) => ({
    //             ...prevErrors,
    //             email: "",
    //         }));
    //     }
    // };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setManualEntryData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        if (name === "companyName" && errors.companyName) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                companyName: "",
            }));
        }
        // Inline validation
        if (name === "email" && value) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? "" : "Invalid email format.",
            }));
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: value.trim() ? "" : "Required.",
            }));
        }
    };
    const handleSelect = async (value) => {
        setAddress(value);
        try {
            const results = await geocodeByAddress(value);
            if (results && results[0]) {
                const addressComponents = results[0].address_components;
                let city = "";
                let province = "";
                let postalCode = "";
                let country = "";
                addressComponents.forEach((component) => {
                    if (component.types.includes("locality")) {
                        city = component.long_name;
                    }
                    if (component.types.includes("administrative_area_level_1")) {
                        province = component.long_name;
                    }
                    if (component.types.includes("postal_code")) {
                        postalCode = component.long_name;
                    }
                    if (component.types.includes("country")) {
                        country = component.long_name;
                    }
                });
                setManualEntryData((prevData) => ({
                    ...prevData,
                    streetAddress: value,
                    city,
                    province,
                    postalCode,
                    country,
                }));
            } else {
                console.warn("No results found for the selected address.");
            }
        } catch (error) {
            console.error("Error selecting place:", error?.message || "No message", error?.stack || "No stack trace");
        }
    };
    const handleAddressChange = (value) => {
        setAddress(value);
    };
    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     if (!validateForm()) return;
    //     setShowLoader(true);
    //     const payload = {
    //         type: 2,
    //         company_name: manualEntryData.companyName,
    //         email: manualEntryData.email,
    //         phone_number: manualEntryData.phoneNumber,
    //         street_address: manualEntryData.streetAddress,
    //         city: manualEntryData.city,
    //         province: manualEntryData.province,
    //         country: manualEntryData.country,
    //         postal_code: manualEntryData.postalCode,
    //         status: manualEntryData.status,
    //         notes: manualEntryData.notes,
    //     };
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;
        setShowLoader(true);
        const payload = {
            type: 2,
            company_name: manualEntryData.companyName,
            email: manualEntryData.email,
            phone_number: manualEntryData.phoneNumber,
            street_address: manualEntryData.streetAddress,
            city: manualEntryData.city,
            province: manualEntryData.province,
            country: manualEntryData.country,
            postal_code: manualEntryData.postalCode,
            status: manualEntryData.status,
            notes: manualEntryData.notes,
        };
        http(
            {
                method: "POST",
                url: `v2/contact_detail/add`,
                body: payload,
                isSecure: true,
            },
            (res) => {
                toast.success(res.single_message);
                navigate("/admin/client/add_business");
                sessionStorage.removeItem("manualBusinessEntry")
                sessionStorage.setItem("contact_business_data", JSON.stringify(res.data));
                setShowLoader(false);
                setManualEntryData({
                    type: 2,
                    companyName: "",
                    email: "",
                    phoneNumber: "",
                    streetAddress: "",
                    city: "",
                    province: "",
                    country: "",
                    postalCode: "",
                    status: 1,
                    notes: "",
                });
            },
            (err) => {
                // Handle errors according to the response
                if (err.status === 401) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else if (err.status === 400) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else {
                    toast.error(err.single_message);
                    setShowLoader(false);
                }
            }
        );
    };
    const validateForm = () => {
        const newErrors = { companyName: "", email: "" };
        // Validate firstName
        if (!manualEntryData.companyName.trim()) {
            newErrors.companyName = "Required.";
        }
        // Validate email
        if (!manualEntryData.email.trim()) {
            newErrors.email = "Required.";
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(manualEntryData.email)) {
            newErrors.email = "Invalid email format.";
        }
        setErrors(newErrors);
        return Object.values(newErrors).every((error) => !error); // Return true if no errors
    };


    return (
        <div className="right_section font-poppins">
            <div className="business-form-area py-3">
                <div className="row business-head align-items-center">
                    <div className="col-md-10 offset-md-1 mx-auto left_head">
                        <h4 className="fs-20 mt-2 fw-semibold">Add Business Client</h4>
                    </div>
                    <div className="col-md-10 offset-md-1 mx-auto">
                        <div className="main-form-area p-4 mt-3 bg-white shadow-lg">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <h6 className="fs-20 fw-semibold">Contact Details</h6>
                                    </div>
                                </div>
                                <div className="row form-group">
                                    <div className="col-md-4 mt-4">
                                        <label htmlFor="" className="w-100">Company Name <span className="text-danger ms-1">*</span></label>
                                        <input
                                            type="text"
                                            name="companyName"
                                            className="form-input mt-2"
                                            placeholder="Type here..."
                                            value={manualEntryData.companyName}
                                            onChange={handleChange}
                                        />
                                        {errors.companyName && (
                                            <p className="text-danger mt-1">{errors.companyName}</p>
                                        )}
                                    </div>
                                    <div className="col-md-4 mt-4">
                                        <label htmlFor="" className="w-100">Email Address <span className="text-danger ms-1">*</span></label>
                                        <input
                                            type="email"
                                            name="email"
                                            className="form-input mt-2"
                                            placeholder="Type here..."
                                            value={manualEntryData.email}
                                            onChange={handleChange}
                                        />
                                        {errors.email && (
                                            <p className="text-danger mt-1">{errors.email}</p>
                                        )}
                                    </div>
                                    <div className="col-md-4 mt-4">
                                        <label htmlFor="" className="w-100">Phone Number </label>
                                        <input
                                            type="number"
                                            name="phoneNumber"
                                            className="form-input mt-2"
                                            placeholder="Phone Number"
                                            max={new Date().toISOString().split("T")[0]}
                                            value={manualEntryData.phoneNumber}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col-md-4 mt-4">
                                        <label className="w-100">Street Address</label>
                                        <PlacesAutocomplete
                                            value={address}
                                            onChange={handleAddressChange}
                                            onSelect={handleSelect}
                                            searchOptions={{
                                                componentRestrictions: {
                                                    country: ["us", "ca"],
                                                },
                                            }}
                                        >
                                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                <div>
                                                    <input
                                                        {...getInputProps({
                                                            placeholder: "Type address here...",
                                                            className: "form-input mt-2",
                                                        })}
                                                    />
                                                    <div className="autocomplete-dropdown-container">
                                                        {loading && <div>Loading...</div>}
                                                        {suggestions.map((suggestion) => {
                                                            const className = suggestion.active
                                                                ? "suggestion-item--active"
                                                                : "suggestion-item";
                                                            return (
                                                                <div
                                                                    {...getSuggestionItemProps(suggestion, { className })}
                                                                    key={suggestion.placeId}
                                                                >
                                                                    <span>{suggestion.description}</span>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            )}
                                        </PlacesAutocomplete>
                                    </div>
                                    <div className="col-md-4 mt-4">
                                        <label className="w-100">City</label>
                                        <input
                                            type="text"
                                            name="city"
                                            className="form-input mt-2"
                                            placeholder="Type here..."
                                            value={manualEntryData.city}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col-md-4 mt-4">
                                        <label htmlFor="" className="w-100">Province </label>
                                        <input
                                            type="text"
                                            name="province"
                                            className="form-input mt-2"
                                            placeholder="Type here..."
                                            value={manualEntryData.province}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col-md-4 mt-4">
                                        <label className="w-100">Country</label>
                                        <input
                                            type="text"
                                            name="country"
                                            className="form-input mt-2"
                                            placeholder="Type here..."
                                            value={manualEntryData.country}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col-md-4 mt-4">
                                        <label className="w-100">Postal Code</label>
                                        <input
                                            type="text"
                                            name="postalCode"
                                            className="form-input mt-2"
                                            placeholder="Type here..."
                                            value={manualEntryData.postalCode}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12 mt-4">
                                    <label htmlFor="" className="w-100">Notes</label>
                                    <textarea
                                        className="form-input mt-2"
                                        name="notes"
                                        id=""
                                        cols="10"
                                        rows="5"
                                        placeholder="Message"
                                        value={manualEntryData.notes}
                                        onChange={handleChange}
                                    ></textarea>
                                </div>
                                <div className="col-md-12 individual-footer">
                                    <div className="btn-groups mt-4 mb-2">
                                        <button type="submit" className="add-new ms-2 fs-20 px-5 py-4">Next</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Business_Contact_Detail;