import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { http } from "../../../http/http";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import InputMask from 'react-input-mask';
import Add_Busines_Assisgn from "./Add_Busines_Assisgn.jsx";
import Add_Business_Interaction from "./Add_Business_Interaction.jsx";
import Add_Business_Priority from "./Add_Business_Priority.jsx";
import Add_Business_Status from "./Add_Business_Status.jsx"
import CustomField from "../../customField/CustomField.jsx";


const Add_Business_Task = (props) => {

    const { id } = useParams();
    const navigate = useNavigate();
    const storedBusinessId = JSON.parse(localStorage.getItem("BusinessId"));
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
        watch,
        getValues
    } = useForm({
        mode: "all", defaultValues: {
            name: "",
            interaction_type_id: "0",
            priority_id: "0",
            status_task_id: "0",
            staff_id: [],
            start_date: "",
            start_time: "",
            start_time_format: "AM",
            end_date: "",
            end_time: "",
            end_time_format: "AM",
            estimation_time: "",
            time_spent: "",

        }
    });
    const watchedStaffIds = watch("staff_id", []);
    const { addTaskModal, setAddTaskModal, priorityList, handlePriorityData, interactionList, handleInteractionData, StatusList, handleStatusData, handleAssisgnData, assignData, handleTaskList } = props;
    const [showInderaction, setShowInderaction] = useState(false);
    const [showPriority, setShowPriority] = useState(false);
    const [showStatus, setShowStatus] = useState(false);
    const [showAssignOpen, setShowAssignOpen] = useState(false);
    const [showAssisgnModal, setShowAssignModal] = useState(false);
    const [assigned, setAssigned] = useState(false);
    const [images, setImages] = useState([]); // Array to store image files
    const [imagePreviews, setImagePreviews] = useState([]); // Array to store image previews
    const [customFields, setCustomFields] = useState([]);
    const [selectedStaff, setSelectedStaff] = useState([]);
    const fileInputRef = useRef(null);


    const [dropdownState, setDropdownState] = useState({
        isOpen: false,
        search: '',
        selectedOption: null,
        isPriorityOpen: false,
        prioritySearch: '',
        prioritySelectedOption: null,
        isStatusOpen: false,
        statusSearch: '',
        statusSelectedOption: null
    });


    const handleCheckboxChange = () => {
        const values = getValues('staff_id');
        setAssigned(values.length > 0);

        // Get the selected staff members based on their IDs
        const selectedStaffMembers = assignData.filter(staff => values.includes(staff.id.toString()));
        setSelectedStaff(selectedStaffMembers);
    };
    const displayStaffNames = () => {
        if (selectedStaff.length > 0) {
            // Map each selected staff's first character to an uppercase element
            return selectedStaff.map(staff => (
                <span key={staff.id} className="mt-2 bg-blue-light assignee_name" data-bs-toggle="tooltip" data-bs-placement="top" title={staff.name}>
                    {staff.name.charAt(0).toUpperCase()}

                </span>
            ));
        }
        return (
            <span className="select-box fw-medium " onClick={toggleAssignOpen}>
                Unassigned
            </span>
        );
    };
    const handleCustomFieldsChange = (fields) => {
        setCustomFields(fields);
    };





    const handleDropdownOpen = (type) => {
        setDropdownState(prevState => ({
            ...prevState,
            isOpen: type === 'interaction' ? !prevState.isOpen : false,
            isPriorityOpen: type === 'priority' ? !prevState.isPriorityOpen : false,
            isStatusOpen: type === 'status' ? !prevState.isStatusOpen : false
        }));
    };

    const handleInteractionChange = (option) => {
        if (option.id === "add-more") {
            setShowInderaction(true);
            // Reset the select to the default value
            setDropdownState(prevState => ({ ...prevState, selectedOption: null }));
            setValue("interaction_type_id", "0");
        } else {
            setValue("interaction_type_id", option.id);
        }
    };

    const handleSelect = (option) => {
        setDropdownState(prevState => ({
            ...prevState,
            selectedOption: option,
            isOpen: false,
            search: ''
        }));
        handleInteractionChange(option);
    };

    const filteredOptions = interactionList.filter(option =>
        option.name.toLowerCase().includes(dropdownState.search.toLowerCase())
    );

    const handlePriorityChange = (option) => {
        if (option.id === "add-more") {
            setShowPriority(true);
            // Reset the select to the default value
            setDropdownState(prevState => ({ ...prevState, prioritySelectedOption: null }));
            setValue("priority_id", "0");
        } else {
            setValue("priority_id", option.id);
        }
    };

    const handleSelectPriority = (option) => {
        setDropdownState(prevState => ({
            ...prevState,
            prioritySelectedOption: option,
            isPriorityOpen: false,
            prioritySearch: ''
        }));
        handlePriorityChange(option);
    };

    const filteredOptionsPriority = priorityList.filter(option =>
        option.name.toLowerCase().includes(dropdownState.prioritySearch.toLowerCase())
    );

    const handleStatusChange = (option) => {
        if (option.id === "add-more") {
            setShowStatus(true);
            // Reset the select to the default value
            setDropdownState(prevState => ({ ...prevState, statusSelectedOption: null }));
            setValue("status_task_id", "0");
        } else {
            setValue("status_task_id", option.id);
        }
    };

    const handleSelectStatus = (option) => {
        setDropdownState(prevState => ({
            ...prevState,
            statusSelectedOption: option,
            isStatusOpen: false,
            statusSearch: ''
        }));
        handleStatusChange(option);
    };

    const filteredOptionsStatus = StatusList.filter(option =>
        option.name.toLowerCase().includes(dropdownState.statusSearch.toLowerCase())
    );

    const toggleAssignOpen = () => {
        setShowAssignOpen(!showAssignOpen);
    };
    // const validateEstimationTime = (value) => {
    //     const pattern = /^(\d+w\s)?(\d+d\s)?(\d+h\s)?(\d+m\s)?$/;
    //     return pattern.test(value) || "Invalid format, expected format: 1w 1d 1h 1m";
    // };

    // const validateTime = (value) => {
    //     const timePattern = /^([01]\d|2[0-3]):([0-5]\d)$/;
    //     if (!timePattern.test(value)) {
    //         return "Invalid time format. Please use HH:MM format.";
    //     }
    //     return true;
    // };
    const handleFileChange = (e) => {
        const newImages = Array.from(e.target.files);
        const newImageURLs = newImages.map(image => URL.createObjectURL(image));
        setImages(prevImages => [...prevImages, ...newImages]);
        setImagePreviews(prevPreviews => [...prevPreviews, ...newImageURLs]);
    };

    const handleDeleteImage = (index) => {
        setImages(images.filter((_, idx) => idx !== index));
        setImagePreviews(imagePreviews.filter((_, idx) => idx !== index));
    };


    const AddTaskSubmit = async (data) => {
        const formData = new FormData();
        // Append text inputs and other simple fields
        Object.keys(data).forEach(key => {
            if (key !== "staff_id" && key !== "file") { // Assuming 'images' is the field name for file input
                formData.append(key, data[key]);
            }
        });

        // Append staff IDs, make sure the name attribute in the checkbox is 'staff_id'
        if (data.staff_id) {
            data.staff_id.forEach(id => {
                formData.append('staff_id[]', id);
            });
        }

        // Append multiple images
        if (images.length > 0) {
            images.forEach((image, index) => {
                formData.append(`file[]`, image, image.name); // The 'images[]' will help PHP-like backends recognize the array
            });
        }




        http(
            {
                method: "POST",
                url: `corporate/task/${storedBusinessId}/add`,
                isSecure: true,
                contentType: "multipart/form-data",
                body: formData
            },
            (res) => {
                setAddTaskModal(false);
                toast.success(res.single_message);
                navigate("/admin/client/corporate_task");
                reset();
                handleTaskList();
                setImages([]);
                setImagePreviews([]);
                setDropdownState({
                    isOpen: false,
                    search: '',
                    selectedOption: null,
                    isPriorityOpen: false,
                    prioritySearch: '',
                    prioritySelectedOption: null,
                    isStatusOpen: false,
                    statusSearch: '',
                    statusSelectedOption: null
                });
                setAssigned(false);
            },
            (err) => {
                // Handle errors according to the response
                if (err.status === 401) {
                    toast.error(err.single_message);
                } else if (err.status === 400) {
                    toast.error(err.single_message);
                } else {
                    toast.error(err.single_message);
                }
            }
        );
    }

    const onSubmit = async (data) => {
        const customFields = getValues("custom_field") || {}; // Ensure customFields is an object

        // Check if customFields is an object and has keys
        if (typeof customFields === 'object' && customFields !== null) {
            Object.keys(customFields).forEach(key => {
                data[`custom_field[${key}]`] = customFields[key];
            });
        }

        await AddTaskSubmit(data);
    };

    return (
        <Modal
            show={addTaskModal}
            onHide={() => setAddTaskModal(false)}
            centered
            size="xl"
            contentClassName="mx-auto"
            className="modal modal-xl email-modal"
            backdrop="static"
            keyboard={false}
        >

            <div className="modal-body p-4 position-relative">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-md-8 left-task-modal p-0">
                            <div className="d-flex align-items-end justify-content-between">
                                <div>
                                    <h4 className="fs-20 fw-semibold">Add Task</h4>
                                    <input
                                        type="text"
                                        className="w-bold text-black p-0 fs-3 h-0 det-input w-100"
                                        placeholder="Add Details"
                                        name="name"
                                        {...register("name", {
                                        })}
                                    />
                                </div>
                                <Modal.Header closeButton className="close-icon border-0 p-0" />
                            </div>
                            <hr />
                            <div className="mt-3">
                                <div className="input-group">
                                    <textarea
                                        name="description"
                                        id="description"
                                        cols="30"
                                        rows="15"
                                        placeholder="Type here..."
                                        className="bg-white border-1 border-gray"
                                        {...register("description", {

                                        })}
                                    >
                                    </textarea>

                                </div>
                            </div>
                            <div className="mt-3">
                                <div className="main-wrapper">
                                    <div className="upload-main-wrapper d-flex align-items-center justify-content-between w-100 border-1 border-dashed gap-2">
                                        <h4 className="fs-14">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="me-2" width="13" height="20" viewBox="0 0 13 20">
                                                <path id="XMLID_197_" d="M68.356,0h0a4.663,4.663,0,0,0-3.283,1.331,4.469,4.469,0,0,0-1.36,3.214v8.182a2.786,2.786,0,0,0,5.571,0V8.006a.929.929,0,0,0-1.857,0v4.721a.929.929,0,0,1-1.857,0V4.545a2.681,2.681,0,0,1,.816-1.928,2.8,2.8,0,0,1,1.97-.8,2.76,2.76,0,0,1,2.786,2.727v9.091A4.6,4.6,0,0,1,66.5,18.182a4.663,4.663,0,0,1-3.283-1.331,4.468,4.468,0,0,1-1.36-3.214V4.545a.929.929,0,0,0-1.857,0v9.091a6.256,6.256,0,0,0,1.9,4.5A6.528,6.528,0,0,0,66.5,20,6.44,6.44,0,0,0,73,13.636V4.545A4.6,4.6,0,0,0,68.356,0Z" transform="translate(-59.999)" fill="#202529" />
                                            </svg>
                                            Drag &amp; drop here
                                        </h4>
                                        <div className="upload-wrapper cursor-pointer" onClick={() => fileInputRef.current.click()}>
                                            <input type="file" id="file" name="file" onChange={handleFileChange} multiple ref={fileInputRef}
                                                style={{ display: "none" }} />
                                            <i className="fa fa-upload me-2"></i>
                                            <span className="file-upload-text cursor-pointer">Browse</span>
                                        </div>
                                    </div>

                                    <div className="image-preview-area">
                                        {imagePreviews.map((preview, index) => (
                                            <div key={index} className="image-preview position-relative">
                                                <img src={preview} alt="Preview" style={{ width: 100, height: 100 }} />
                                                <button onClick={() => handleDeleteImage(index)} className="border-0 bg-transparent">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 36 36">
                                                        <g id="Group_1561" data-name="Group 1561" transform="translate(-1173 -1120)">
                                                            <rect id="Rectangle_245" data-name="Rectangle 245" width="36" height="36" rx="10" transform="translate(1173 1120)" fill="#aaa" />
                                                            <path id="Union_9" data-name="Union 9" d="M1.156,10A1.162,1.162,0,0,1,.343,8.014L3.352,5,.335,1.987A1.163,1.163,0,0,1,1.981.351L4.993,3.363,8.016.34A1.163,1.163,0,0,1,9.659,1.985L6.637,5.007,9.654,8.024A1.16,1.16,0,0,1,8.007,9.66L5,6.648,1.987,9.657A1.158,1.158,0,0,1,1.164,10Z" transform="translate(1186 1133)" fill="#000" />
                                                        </g>
                                                    </svg>
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-btns gap-4 mt-4 d-flex align-items-center justify-content-between gap-2">
                                <div className="view-full">
                                    <button className="view-full">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="me-2" width="15" height="15" viewBox="0 0 15 15">
                                            <path id="expand" d="M5.309.938a.938.938,0,0,1-.937.938h-2.5v2.5A.938.938,0,1,1,0,4.371V.938A.938.938,0,0,1,.938,0H4.371A.938.938,0,0,1,5.309.938ZM4.371,13.125h-2.5v-2.5a.938.938,0,0,0-1.875,0v3.434A.938.938,0,0,0,.938,15H4.371a.938.938,0,0,0,0-1.875Zm9.691-3.434a.938.938,0,0,0-.937.938v2.5h-2.5a.938.938,0,0,0,0,1.875h3.434A.938.938,0,0,0,15,14.063V10.629A.937.937,0,0,0,14.063,9.691Zm0-9.691H10.629a.938.938,0,0,0,0,1.875h2.5v2.5a.938.938,0,0,0,1.875,0V.938A.937.937,0,0,0,14.063,0Z" fill="#77a0df" />
                                        </svg>
                                        View in full page
                                    </button>
                                </div>
                                <span className="d-flex gap-2">
                                    <a >
                                        <button className="btn-cancel" onClick={() => navigate(`/admin/client/corporate_task`)}>Cancel</button>
                                    </a>
                                    <a >
                                        <button className="btn-blue" type="submit" >Create</button>
                                    </a>
                                </span>
                            </div>
                        </div>
                        <div className="col-md-4 px-0">
                            <div className="right-task-modal">
                                {/* <div className=" col-md-12 mt-4 px-4">
                                    <label className="fs-18 px-1">Interaction Type</label>
                                    <select
                                        name="interaction_type_id"
                                        id="interaction_type_id"
                                        className="form-input bg-transparent fs-20 fw-bold px-1"

                                        {...register("interaction_type_id", {
                                            onChange: handleInteractionChange,
                                        })}
                                    >
                                        <option value="0" disabled selected>Select</option>
                                        {interactionList && interactionList.map((data) => (
                                            <option key={data.id} value={data.id}>
                                                {data.name}
                                            </option>
                                        ))}

                                        <option className="text-blue mt-1 text-start" value="add-more" onClick={() => setShowInderaction(true)}>+ Add More</option>
                                    </select>

                                </div> */}
                                <div className="col-md-12 mt-4 px-4">
                                    <label className="fs-18 px-1 cursor-pointer">Interaction Type</label>
                                    <div>
                                        <div className={`select-box ${dropdownState.isOpen ? 'active' : ''}`} onClick={() => handleDropdownOpen('interaction')}>
                                            {dropdownState.selectedOption ? dropdownState.selectedOption.name : "Select"}
                                        </div>
                                        {dropdownState.isOpen && (
                                            <div className="options-container">
                                                <input
                                                    type="text"
                                                    placeholder="Search..."
                                                    value={dropdownState.search}
                                                    onChange={e => setDropdownState(prevState => ({ ...prevState, search: e.target.value }))}
                                                    className="search-field"
                                                />
                                                {filteredOptions.map(option => (
                                                    <div
                                                        key={option.id}
                                                        className="option"
                                                        onClick={() => handleSelect(option)}
                                                    >
                                                        {option.name}
                                                    </div>
                                                ))}
                                                <div
                                                    className="option text-blue mt-1 text-start fw-medium"
                                                    onClick={() => {
                                                        handleDropdownOpen(null);
                                                        setShowInderaction(true);
                                                    }}
                                                >
                                                    <span>+</span> Add More
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    {/* <input
                    type="hidden"
                    name="interaction_type_id"
                    {...register("interaction_type_id", {
                        onChange: handleInteractionChange,
                    })}
                    value={dropdownState.selectedOption ? dropdownState.selectedOption.id : ''}
                /> */}
                                </div>
                                <div className="col-md-12 mt-4 px-4">
                                    <label className="fs-18 px-1 cursor-pointer">Priority</label>
                                    <div>
                                        <div className={`select-box fw-medium ${dropdownState.isPriorityOpen ? 'active' : ''}`} onClick={() => handleDropdownOpen('priority')}>
                                            {dropdownState.prioritySelectedOption ? dropdownState.prioritySelectedOption.name : "Select"}
                                        </div>
                                        {dropdownState.isPriorityOpen && (
                                            <div className="options-container">
                                                <input
                                                    type="text"
                                                    placeholder="Search..."
                                                    value={dropdownState.prioritySearch}
                                                    onChange={e => setDropdownState(prevState => ({ ...prevState, prioritySearch: e.target.value }))}
                                                    className="search-field"
                                                />
                                                {filteredOptionsPriority.map(option => (
                                                    <div
                                                        key={option.id}
                                                        className="option"
                                                        onClick={() => handleSelectPriority(option)}
                                                    >
                                                        {option.name}
                                                    </div>
                                                ))}
                                                <div
                                                    className="option text-blue mt-1 text-start fw-medium"
                                                    onClick={() => {
                                                        handleDropdownOpen(null);
                                                        setShowPriority(true);
                                                    }}
                                                >
                                                    <span>+</span> Add More
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    {/* <input
                                        type="hidden"
                                        name="priority_id"
                                        {...register("priority_id", {
                                            onChange: handlePriorityChange,
                                        })}
                                        value={dropdownState.prioritySelectedOption ? dropdownState.prioritySelectedOption.id : ''}
                                    /> */}
                                </div>


                                {/* <div className="col-md-12 mt-4 px-4 relative">
                                    <label className="fs-18 px-1" onClick={toggleAssignOpen}>Assignee</label>
                                    <h6 className="bg-transparent fs-20 fw-bold px-1" onClick={toggleAssignOpen}>
                                        Unassigned
                                    </h6>
                                    {showAssignOpen && (
                                        <div className="assign-open bg-white rounded-4 p-3 mt-3 position-absolute end-0 start-0 mx-4">
                                            <ul className="ps-0">
                                                {assignData && assignData.map((staff) => (
                                                    <li key={staff.id} className="d-flex justify-content-between align-items-center my-1">
                                                        <label className="fw-semibold text-black">
                                                            <img src={require("../../../../assets/images/user-pic.png")} className="me-2" alt="user-pic" />
                                                            {staff?.name}
                                                        </label>
                                                        <input
                                                            type="checkbox"
                                                            name="staff_id"
                                                            id="staff_id"
                                                            value={staff.id}
                                                            {...register("staff_id")}
                                                        />
                                                    </li>
                                                ))}
                                                <li className="d-flex justify-content-between align-items-center my-1">
                                                    <p className="text-blue mt-1 text-start" onClick={() => setShowAssignModal(true)} >
                                                        + Add More
                                                    </p>
                                                </li>
                                            </ul>
                                        </div>
                                    )}

                                </div> */}

                                <div className="col-md-12 mt-4 px-4 relative">
                                    <label className="fs-18 px-1 cursor-pointer" onClick={toggleAssignOpen}>Assignee</label>
                                    {/* <h6 className="bg-transparent fs-20 fw-bold px-1" onClick={toggleAssignOpen}>
                                            {assigned ? 'Assigned' : 'Unassigned'}
                                        </h6> */}
                                    {/* <h6 className="bg-transparent fs-20 fw-bold px-1" onClick={toggleAssignOpen}>
                                            {assigned ? `${selectedStaff.name.slice(0, 2)}` : 'Unassigned'}
                                        </h6> */}
                                    <div className="d-flex align-items-center gap-1 flex-wrap" >
                                        {displayStaffNames()}
                                    </div>
                                    {showAssignOpen && (
                                        <div className="assign-open bg-white rounded-4 p-3 mt-3 position-absolute end-0 start-0 mx-4">
                                            <ul className="ps-0">
                                                {assignData && assignData.map((staff) => (
                                                    <li key={staff.id} className="d-flex justify-content-between align-items-center my-1">
                                                        <label className="fw-semibold text-black">
                                                            <img src={require("../../../../assets/images/user-pic.png")} className="me-2" alt="user-pic" />
                                                            {staff?.name}
                                                        </label>
                                                        <input
                                                            type="checkbox"
                                                            name="staff_id"
                                                            id="staff_id"
                                                            value={staff.id}
                                                            {...register("staff_id", { onChange: handleCheckboxChange })}
                                                        />
                                                    </li>
                                                ))}
                                                <li className="d-flex justify-content-between align-items-center my-1">
                                                    <p className="text-blue fw-medium fs-14 mt-1 text-end w-fit ms-auto cursor-pointer" onClick={() => setShowAssignModal(true)}>
                                                        + Add More
                                                    </p>
                                                </li>
                                            </ul>
                                        </div>
                                    )}
                                </div>
                                {/* <div className=" col-md-12 mt-4 px-4">
                                    <label className="fs-18 px-1">Status</label>
                                    <select
                                        name="status_task_id"
                                        id="status_task_id"
                                        className="form-input bg-transparent fs-20 fw-bold px-1"
                                       
                                        {...register("status_task_id", {
                                            onChange: handleStatusChange,
                                        })}
                                    >
                                        <option value="0" disabled selected>Select</option>
                                        {StatusList && StatusList.map((data) => (
                                            <option key={data.id} value={data.id}>
                                                {data.name}
                                            </option>
                                        ))}
                                        <option className="text-blue mt-1 text-start" value="add-more" onClick={() => setShowStatus(true)}>+ Add More</option>
                                    </select>
                                    
                                </div> */}

                                <div className="col-md-12 mt-4 px-4">
                                    <label className="fs-18 px-1 cursor-pointer">Status</label>
                                    <div>
                                        <div className={`select-box fw-medium ${dropdownState.isStatusOpen ? 'active' : ''}`} onClick={() => handleDropdownOpen('status')}>
                                            {dropdownState.statusSelectedOption ? dropdownState.statusSelectedOption.name : "Select"}
                                        </div>
                                        {dropdownState.isStatusOpen && (
                                            <div className="options-container">
                                                <input
                                                    type="text"
                                                    placeholder="Search..."
                                                    value={dropdownState.statusSearch}
                                                    onChange={e => setDropdownState(prevState => ({ ...prevState, statusSearch: e.target.value }))}
                                                    className="search-field"
                                                />
                                                {filteredOptionsStatus.map(option => (
                                                    <div
                                                        key={option.id}
                                                        className="option"
                                                        onClick={() => handleSelectStatus(option)}
                                                    >
                                                        {option.name}
                                                    </div>
                                                ))}
                                                <div
                                                    className="option text-blue mt-1 text-start fw-semibold"
                                                    onClick={() => {
                                                        handleDropdownOpen(null);
                                                        setShowStatus(true);
                                                    }}
                                                >
                                                    <span>+</span> Add More
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <input
                                        type="hidden"
                                        name="status_task_id"
                                        {...register("status_task_id", {
                                            onChange: handleStatusChange,
                                        })}
                                        value={dropdownState.statusSelectedOption ? dropdownState.statusSelectedOption.id : ''}
                                    />
                                </div>
                                <div className=" col-md-12 mt-4 px-4 date mt-3">
                                    <h4 className="fs-18 px-1 fw-bold">Start Date</h4>
                                    <div className="row">
                                        <div className="col-md-12 mt-3">
                                            <label>Date</label>
                                            <input
                                                type="date"
                                                name="start_date"
                                                className="form-input mt-1"

                                                {...register("start_date", {

                                                })}

                                            />
                                        </div>
                                        <div className="col-md-6 mt-3">
                                            <label>Time</label>
                                            <InputMask
                                                mask="99:99"
                                                maskChar={null}
                                                name="start_time"
                                                className={`form-input mt-1 ${errors.start_time ? 'is-invalid' : ''}`}
                                                placeholder="HH:MM"
                                                {...register('start_time', {
                                                    // required: 'Start time is required',
                                                    // validate: validateTime // Custom validation function
                                                })}
                                            />
                                            {errors.start_time && <div className="invalid-feedback">{errors.start_time.message}</div>}
                                        </div>
                                        <div className="col-md-6 mt-3">
                                            <label>Format</label>
                                            <select
                                                className="form-input mt-1 px-3"
                                                name="start_time_format"
                                                {...register("start_time_format", {

                                                })}
                                            >
                                                <option value="PM">PM</option>
                                                <option value="AM">AM</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className=" col-md-12 mt-4 px-4 date mt-3">
                                    <h4 className="fs-18 px-1 fw-bold">Due Daccte</h4>
                                    <div className="row">
                                        <div className="col-md-12 mt-3">
                                            <label>Date</label>
                                            <input
                                                type="date"
                                                name="end_date"
                                                className="form-input mt-1"
                                                {...register("end_date", {

                                                })}
                                            />
                                        </div>
                                        <div className="col-md-6 mt-3">
                                            <label>Time</label>

                                            <InputMask
                                                mask="99:99"
                                                maskChar={null}
                                                name="end_time"
                                                className={`form-input mt-1 ${errors.start_time ? 'is-invalid' : ''}`}
                                                placeholder="HH:MM"
                                                {...register("end_time", {
                                                    // required: ' time is required',
                                                    // validate: validateTime // Custom validation function
                                                })}
                                            />
                                            {errors.start_time && <div className="invalid-feedback">{errors.start_time.message}</div>}
                                        </div>
                                        <div className="col-md-6 mt-3">
                                            <label>Format</label>
                                            <select
                                                className="form-input mt-1 px-3"
                                                name="end_time_format"
                                                {...register("end_time_format", {

                                                })}
                                            >
                                                <option value="PM">PM</option>
                                                <option value="AM">AM</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className=" col-md-12 mt-4 px-4 date mt-3">
                                    <h4 className="fs-18 px-1 fw-bold">Estimation Date</h4>
                                    <div className="row">
                                        <div className="col-md-12 mt-3">
                                            <input
                                                type="text"
                                                name='estimation_time'
                                                className="form-input mt-1"
                                                placeholder="1w 1d 1h 1m"
                                                {...register("estimation_time", {
                                                    // validate: validateEstimationTime
                                                })}
                                            />
                                            {/* {errors.estimation_time && <p role="alert"
                                                className="text-danger mb-0 pt-1 -fs-7 ms-1">{errors.estimation_time.message}</p>} */}
                                        </div>
                                    </div>
                                </div>
                                <div className=" col-md-12 mt-4 px-4 date mt-3 mb-5">
                                    <h4 className="fs-18 px-1 fw-bold">Time Spent</h4>
                                    <div className="row">
                                        <div className="col-md-12 mt-3">
                                            <input
                                                type="text"
                                                name="time_spent"
                                                className="form-input mt-1"
                                                placeholder="1w 1d 1h 1m"
                                                {...register("time_spent", {
                                                    // validate: validateEstimationTime
                                                })}
                                            />
                                            {/* {errors.time_spent && <p role="alert"
                                                className="text-danger mb-0 pt-1 -fs-7 ms-1">{errors.time_spent.message}</p>} */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 px-4 mt-3 mb-5">
                                    <CustomField url="Tasks" register={register} onCustomFieldsChange={handleCustomFieldsChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <Add_Business_Interaction
                showInderaction={showInderaction}
                setShowInderaction={setShowInderaction}
                handleInteractionData={handleInteractionData}
            />
            <Add_Business_Priority
                showPriority={showPriority}
                setShowPriority={setShowPriority}
                handlePriorityData={handlePriorityData}

            />
            <Add_Business_Status
                showStatus={showStatus}
                setShowStatus={setShowStatus}
                handleStatusData={handleStatusData}

            />
            <Add_Busines_Assisgn
                showAssisgnModal={showAssisgnModal}
                setShowAssignModal={setShowAssignModal}
                handleAssisgnData={handleAssisgnData}

            />
        </Modal>

    );
};

export default Add_Business_Task;