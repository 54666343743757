
import React, { useEffect, useState, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { http } from "../../http/http";
import { toast } from "react-toastify";
import { Authform_id, Authform_id_1013, Business_status } from "../../../helpers/staticList";
import Add_BusinessType from "./Add_BusinessType";
import Add_BusinessNature from "./Add_BusinessNature";
import Add_BusinessCate from "./Add_BusinessCate";
import Add_EnteryStyle from "./Add_EnteryStyle";
import Add_PlaningStatus from "./Add_PlaningStatus";
import { GlobalContext } from "../../../App";

const Advance_Details = (props) => {
    const { id } = useParams();
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
        watch,
        getValues
    } = useForm({ mode: "all" });
    const formValues = watch();
    const [usersAdvance, setUsersAdvance] = useState([]);
    const { showLoader, setShowLoader } = useContext(GlobalContext);
    const { fetchAdvData, fetchAdvanceData, advanceModal, setAdvanceModal, businessList, showHsBusinessTypeList, businessNatureList,
        showHsBusinessNatureList, businesCateList, showBusinessCateList, businessEnterStyleList, showBusinessEnteryList, businessplaningList, showBusinessPlaningList } = props;
    const [isBusinessTypeOpen, setIsBusinessTypeOpen] = useState(false);
    const [isBusinessNatureOpen, setBusinessNatureOpen] = useState(false);
    const [isBusinessCateOpen, setIsBusinessCateOpen] = useState(false);
    const [isEnteryStyleOpen, setIsEnetryStyleOpen] = useState(false);
    const [isPlaningStatusOpen, setIsPlaningStatusOpen] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const onHideHandler = () => {
        setAdvanceModal(false);

    };
    console.log(Authform_id_1013, "Authform_id_1013")
    const [searchBusinessNatureValue, setSearchBusinessNatureValue] = useState("");
    const [searchBusinessCateValue, setSearchBusinessCateValue] = useState("");
    const [searchEntryStyleValue, setSearchEntryStyleValue] = useState("");
    const [searchPlaningListValue, setSearchPlaningListValue] = useState("");

    // Filtered business nature list based on search value
    const filteredBusinessNatureList = businessNatureList.filter((data) =>
        data.name.toLowerCase().includes(searchBusinessNatureValue.toLowerCase())
    );

    const filteredBusinessList = businessList.filter((data) =>
        data.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    const filteredBusinessCateList = businesCateList.filter((data) =>
        data.name.toLowerCase().includes(searchBusinessCateValue.toLowerCase())
    );
    const filteredEntryStyleList = businessEnterStyleList.filter((data) =>
        data.name.toLowerCase().includes(searchEntryStyleValue.toLowerCase())
    );

    const filteredPlaningList = businessplaningList.filter((data) =>
        data.name.toLowerCase().includes(searchPlaningListValue.toLowerCase())
    );
    const onSubmit = () => {
        setShowLoader(true);
        const newUser = {
            ...formValues,
        };
        setUsersAdvance([...usersAdvance, newUser]);
        http(
            {
                method: "POST",
                url: `corporate/advance/${id}/add`,
                body: {
                    businesstype_id: formValues.businesstype_id,
                    businessnature_id: formValues.businessnature_id,
                    businesscompany_id: "1",
                    businesscategory_id: formValues.businesscategory_id,
                    business_no: formValues.business_no,
                    authform_id_59: formValues.authform_id_59,
                    authform_id_1013: formValues.authform_id_1013,
                    incorporation_date: formValues.incorporation_date,
                    data_entry_style_id: formValues.data_entry_style_id,
                    business_planing_id: formValues.business_planing_id,
                    date_planning: formValues.date_planning,
                    year_ending: formValues.year_ending,
                    hstyear_ending: formValues.hstyear_ending,
                    business_notes: formValues.business_notes

                },
                isSecure: true,
            },
            (res) => {
                toast.success(res.single_message);
                setAdvanceModal(false);
                fetchAdvData();
                setShowLoader(false);

                // Reset all fields in the form
                reset({
                    businesstype_id: "",
                    businessnature_id: "",
                    businesscategory_id: "",
                    business_no: "",
                    authform_id_59: "",
                    authform_id_1013: "",
                    incorporation_date: "",
                    data_entry_style_id: "",
                    business_planing_id: "",
                    date_planning: "",
                    year_ending: "",
                    hstyear_ending: "",
                    business_notes: "",
                });
            },
            (err) => {
                // Handle errors according to the response
                if (err.status === 401) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else if (err.status === 400) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else {
                    toast.error(err.single_message);
                    setShowLoader(false);
                }
            }
        );
    }
    return (
        <Modal
            show={advanceModal}
            onHide={onHideHandler}
            centered
            size="xl"
            contentClassName="mx-auto"
            className="modal operating-modal font-poppins m-2"
            backdrop="static"
            keyboard={false}
        >
            <div className="modal-header border-0 justify-content-between align-items-start">
                <h4 className="fs-20 fw-semibold">Add Advance Details</h4>
                <Modal.Header closeButton className="close-icon border-0 py-0"></Modal.Header>
            </div>
            <div className="modal-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="advance-form row">
                        {/* <div className="col-lg-4 col-md-12 mt-4">
                            <label htmlFor="businesstype_id" className="fs-18">Business Type <span className="text-danger">*</span></label>
                            <select
                                name="businesstype_id"
                                id="businesstype_id"
                                className="form-input mt-2"
                                {...register("businesstype_id", {
                                    required: "Required",
                                })}
                            >
                                <option value="" selected disabled>Select</option>
                                {businessList && businessList.map((data) => (
                                    <option key={data.id} value={data.id}>
                                        {data.name}
                                    </option>
                                ))}
                            </select>
                            {errors.businesstype_id && <span className="error">{errors.businesstype_id.message}</span>}
                            <p className="text-blue fw-semibold fs-14 mt-1 text-end w-fit ms-auto cursor-pointer" onClick={() => setIsBusinessTypeOpen(true)}>+ Add More</p>
                        </div> */}

                        <div className="col-lg-4 col-md-12 mt-4">
                            <label htmlFor="businesstype_id" className="fs-18">
                                Business Type <span className="text-danger">*</span>
                            </label>
                            <div className="custom-select-container w-100">
                                <div className="dropdown">
                                    <button
                                        className="form-input dropdown-toggle w-100 text-start mt-2"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        {getValues("businesstype_id") ? businessList.find((b) => b.id === parseInt(getValues("businesstype_id")))?.name || "Select" : "Select"}
                                    </button>
                                    <div className="dropdown-menu w-100 p-0">
                                        <ul className="p-0">
                                            <li>
                                                <input
                                                    type="search"
                                                    className="form-input"
                                                    placeholder="Search business type..."
                                                    value={searchValue}
                                                    onChange={(e) => setSearchValue(e.target.value)}
                                                />
                                            </li>
                                        </ul>
                                        <ul className="p-0 listing_show">
                                            {filteredBusinessList.map((data) => (
                                                <li key={data.id}>
                                                    <button
                                                        className="dropdown-item"
                                                        type="button"
                                                        onClick={() => {
                                                            setValue("businesstype_id", data.id);
                                                            setSearchValue(""); // Clear search field
                                                        }}
                                                    >
                                                        {data.name}
                                                    </button>
                                                </li>
                                            ))}

                                        </ul>
                                        <ul className="p-0">
                                            <li><p className="t_blue fw-medium fs-14 w-100 dropdown-item cursor-pointer pb-2"
                                                onClick={() => setIsBusinessTypeOpen(true)}>+ Add More</p>
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                                {errors.businesstype_id && <span className="error">{errors.businesstype_id.message}</span>}
                            </div>
                        </div>

                        {/* <div className="col-lg-4 col-md-12 mt-4">
                            <label htmlFor="businessnature_id" className="fs-18">Nature of Business <span className="text-danger">*</span></label>
                            <select
                                name="businessnature_id"
                                id="businessnature_id"
                                className="form-input mt-2"
                                {...register("businessnature_id", { required: "Required" })}
                            >
                                <option value="" disabled selected>Select</option>
                                {businessNatureList && businessNatureList.map((data) => (
                                    <option key={data.id} value={data.id}>
                                        {data.name}
                                    </option>
                                ))}
                            </select>
                            {errors.businessnature_id && <span className="error">{errors.businessnature_id.message}</span>}
                            <p className="text-blue fw-semibold fs-14 mt-1 text-end w-fit ms-auto cursor-pointer" onClick={() => setBusinessNatureOpen(true)}>+ Add More</p>
                        </div> */}
                        <div className="col-lg-4 col-md-12 mt-4">
                            <label htmlFor="businessnature_id" className="fs-18">
                                Nature of Business <span className="text-danger">*</span>
                            </label>
                            <div className="custom-select-container w-100">
                                <div className="dropdown">
                                    <button
                                        className="form-input dropdown-toggle w-100 text-start mt-2"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        {getValues("businessnature_id")
                                            ? businessNatureList.find((b) => b.id === parseInt(getValues("businessnature_id")))?.name || "Select"
                                            : "Select"}
                                    </button>
                                    <div className="dropdown-menu w-100 p-0">
                                        <ul className="p-0">
                                            <li>
                                                <input
                                                    type="search"
                                                    className="form-input"
                                                    placeholder="Search business type..."
                                                    value={searchBusinessNatureValue}
                                                    onChange={(e) => setSearchBusinessNatureValue(e.target.value)}
                                                />
                                            </li>
                                        </ul>
                                        <ul className="p-0 listing_show">
                                            {filteredBusinessNatureList.map((data) => (
                                                <li key={data.id}>
                                                    <button
                                                        className="dropdown-item"
                                                        type="button"
                                                        onClick={() => {
                                                            setValue("businessnature_id", data.id, { shouldValidate: true });
                                                            setSearchBusinessNatureValue(""); // Clear search field
                                                        }}
                                                    >
                                                        {data.name}
                                                    </button>
                                                </li>
                                            ))}

                                        </ul>
                                        <ul className="p-0">
                                            <li><p className="t_blue fw-medium fs-14 w-100 dropdown-item cursor-pointer pb-2"
                                                onClick={() => setBusinessNatureOpen(true)}>+ Add More</p>
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                                {errors.businesstype_id && <span className="error">{errors.businesstype_id.message}</span>}
                            </div>
                        </div>
                        {/* <div className="col-lg-4 col-md-12 mt-4">
                            <label htmlFor="businesscategory_id" className="fs-18">Category <span className="text-danger">*</span></label>
                            <select
                                name="businesscategory_id"
                                id="businesscategory_id"
                                className="form-input mt-2"
                                {...register("businesscategory_id", { required: "Required" })}
                            >
                                <option value="" disabled selected>Select</option>
                                {businesCateList && businesCateList.map((data) => (
                                    <option key={data.id} value={data.id}>
                                        {data.name}
                                    </option>
                                ))}
                            </select>
                            {errors.businesscategory_id && <span className="error">{errors.businesscategory_id.message}</span>}
                            <p className="text-blue fw-semibold fs-14 mt-1 text-end w-fit ms-auto cursor-pointer" onClick={() => setIsBusinessCateOpen(true)}>+ Add More</p>
                        </div> */}
                        <div className="col-lg-4 col-md-12 mt-4">
                            <label htmlFor="businesscategory_id" className="fs-18">
                                Category<span className="text-danger">*</span>
                            </label>
                            <div className="custom-select-container w-100">
                                <div className="dropdown">
                                    <button
                                        className="form-input dropdown-toggle w-100 text-start mt-2"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        {getValues("businesscategory_id")
                                            ? businesCateList.find((b) => b.id === parseInt(getValues("businesscategory_id")))?.name || "Select"
                                            : "Select"}
                                    </button>
                                    <div className="dropdown-menu w-100 p-0">
                                        <ul className="p-0">
                                            <li>
                                                <input
                                                    type="search"
                                                    className="form-input"
                                                    placeholder="Search business type..."
                                                    value={searchBusinessCateValue}
                                                    onChange={(e) => setSearchBusinessCateValue(e.target.value)}
                                                />
                                            </li>
                                        </ul>
                                        <ul className="p-0 listing_show">
                                            {filteredBusinessCateList.map((data) => (
                                                <li key={data.id}>
                                                    <button
                                                        className="dropdown-item"
                                                        type="button"
                                                        onClick={() => {
                                                            setValue("businesscategory_id", data.id, { shouldValidate: true });
                                                            setSearchBusinessCateValue(""); // Clear search field
                                                        }}
                                                    >
                                                        {data.name}
                                                    </button>
                                                </li>
                                            ))}

                                        </ul>
                                        <ul className="p-0">
                                            <li><p className="t_blue fw-medium fs-14 w-100 dropdown-item cursor-pointer pb-2"
                                                onClick={() => setIsBusinessCateOpen(true)}>+ Add More</p>
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                                {errors.businesstype_id && <span className="error">{errors.businesstype_id.message}</span>}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 mt-4">
                            <label className="fs-18">Business No.</label>
                            <input
                                name="business_no"
                                id="business_no"
                                className="form-input mt-2"
                                placeholder="Type here..."
                                {...register("business_no", {

                                })}

                            />
                        </div>
                        <div className="col-lg-4 col-md-12 mt-4">
                            <label className="fs-18">Authorization Form</label>
                            <div className="position-relative select-text">
                                <p>RC 59</p>
                                <select
                                    name="authform_id_59"
                                    id="authform_id_59"
                                    className="form-input mt-2"
                                    {...register("authform_id_59", {

                                    })}
                                >
                                    <option value="0" disabled="disabled" selected>Select</option>
                                    {Authform_id && Authform_id.getList().map((data) => (
                                        <option key={data.key} value={data.key}>
                                            {data.value}
                                        </option>
                                    ))}

                                </select>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 mt-4">
                            <label className="fs-18">Authorization Form</label>
                            <div className="position-relative select-text">
                                <p>T1013</p>
                                <select
                                    name="authform_id_1013"
                                    id="authform_id_1013"
                                    className="form-input mt-2"
                                    {...register("authform_id_1013", {

                                    })}
                                >
                                    <option value="0" disabled="disabled" selected>Select</option>

                                    {Authform_id_1013 && Authform_id_1013.getList().map((data) => (
                                        <option key={data.key} value={data.key}>
                                            {data.value}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 mt-4">
                            <label className="fs-18">Incorporation Date</label>
                            <input
                                className="form-input mt-2"
                                name="incorporation_date"
                                id="incorporation_date"
                                type="date"
                                {...register("incorporation_date", {


                                })}
                            />
                        </div>
                        {/* <div className="col-lg-4 col-md-12 mt-4">
                            <label htmlFor="data_entry_style_id" className="fs-18">Data Entry Style <span className="text-danger">*</span></label>
                            <select
                                name="data_entry_style_id"
                                id="data_entry_style_id"
                                className="form-input mt-2"
                                {...register("data_entry_style_id", { required: "Required" })}
                            >
                                <option value="" disabled selected>Select</option>
                                {businessEnterStyleList && businessEnterStyleList.map((data) => (
                                    <option key={data.id} value={data.id}>
                                        {data.name}
                                    </option>
                                ))}
                            </select>
                            {errors.data_entry_style_id && <span className="error">{errors.data_entry_style_id.message}</span>}
                            <p className="text-blue fw-semibold fs-14 mt-1 text-end w-fit ms-auto cursor-pointer" onClick={() => setIsEnetryStyleOpen(true)}>+ Add More</p>
                        </div> */}

                        <div className="col-lg-4 col-md-12 mt-4">
                            <label htmlFor="data_entry_style_id" className="fs-18">
                                Data Entry Style<span className="text-danger">*</span>
                            </label>
                            <div className="custom-select-container w-100">
                                <div className="dropdown">
                                    <button
                                        className="form-input dropdown-toggle w-100 text-start mt-2"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        {getValues("data_entry_style_id")
                                            ? businessEnterStyleList.find((b) => b.id === parseInt(getValues("data_entry_style_id")))?.name || "Select"
                                            : "Select"}
                                    </button>
                                    <div className="dropdown-menu w-100 p-0">
                                        <ul className="p-0">
                                            <li>
                                                <input
                                                    type="search"
                                                    className="form-input"
                                                    placeholder="Search business type..."
                                                    value={searchEntryStyleValue}
                                                    onChange={(e) => setSearchEntryStyleValue(e.target.value)}
                                                />
                                            </li>
                                        </ul>
                                        <ul className="p-0 listing_show">
                                            {filteredEntryStyleList.map((data) => (
                                                <li key={data.id}>
                                                    <button
                                                        className="dropdown-item"
                                                        type="button"
                                                        onClick={() => {
                                                            setValue("data_entry_style_id", data.id, { shouldValidate: true });
                                                            setSearchEntryStyleValue(""); // Clear search field
                                                        }}
                                                    >
                                                        {data.name}
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                        <ul className="p-0">
                                            <li><p className="t_blue fw-medium fs-14 w-100 dropdown-item cursor-pointer pb-2"
                                                onClick={() => setIsEnetryStyleOpen(true)}>+ Add More</p>
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                                {errors.businesstype_id && <span className="error">{errors.businesstype_id.message}</span>}
                            </div>
                        </div>
                        {/* <div className="col-lg-4 col-md-12 mt-4">
                            <label htmlFor="business_planing_id" className="fs-18">Business Planning Status <span className="text-danger">*</span></label>
                            <select
                                name="business_planing_id"
                                id="business_planing_id"
                                className="form-input mt-2"
                                {...register("business_planing_id", { required: "Required" })}
                            >
                                <option value="" disabled selected>Select</option>
                                {businessplaningList && businessplaningList.map((data) => (
                                    <option key={data.id} value={data.id}>
                                        {data.name}
                                    </option>
                                ))}
                            </select>
                            {errors.business_planing_id && <span className="error">{errors.business_planing_id.message}</span>}
                            <p className="text-blue fw-semibold fs-14 mt-1 text-end w-fit ms-auto cursor-pointer" onClick={() => setIsPlaningStatusOpen(true)}>+ Add More</p>
                        </div> */}

                        <div className="col-lg-4 col-md-12 mt-4">
                            <label htmlFor="business_planing_id" className="fs-18">
                                Business Planning Status<span className="text-danger">*</span>
                            </label>
                            <div className="custom-select-container w-100">
                                <div className="dropdown">
                                    <button
                                        className="form-input dropdown-toggle w-100 text-start mt-2"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        {getValues("business_planing_id")
                                            ? businessplaningList.find((b) => b.id === parseInt(getValues("business_planing_id")))?.name || "Select"
                                            : "Select"}
                                    </button>
                                    <div className="dropdown-menu w-100 p-0">
                                        <ul className="p-0">
                                            <li>
                                                <input
                                                    type="search"
                                                    className="form-input"
                                                    placeholder="Search business type..."
                                                    value={searchPlaningListValue}
                                                    onChange={(e) => setSearchPlaningListValue(e.target.value)}
                                                />
                                            </li>
                                        </ul>
                                        <ul className="p-0 listing_show">
                                            {filteredPlaningList.map((data) => (
                                                <li key={data.id}>
                                                    <button
                                                        className="dropdown-item"
                                                        type="button"
                                                        onClick={() => {
                                                            setValue("business_planing_id", data.id, { shouldValidate: true });
                                                            setSearchPlaningListValue(""); // Clear search field
                                                        }}
                                                    >
                                                        {data.name}
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                        <ul className="p-0">
                                            <li><p className="t_blue fw-medium fs-14 w-100 dropdown-item cursor-pointer pb-2"
                                                onClick={() => setIsPlaningStatusOpen(true)}>+ Add More</p>
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                                {errors.businesstype_id && <span className="error">{errors.businesstype_id.message}</span>}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 mt-4">
                            <label className="fs-18">Date of Business Planning</label>
                            <input
                                name="date_planning"
                                id="date_planning"
                                className="form-input mt-2"
                                type="date"
                                {...register("date_planning", {


                                })}
                            />
                        </div>
                        <div className="col-lg-4 col-md-12 mt-4">
                            <label className="fs-18">Corporation Year Ending</label>
                            <input
                                name="year_ending"
                                id="year_ending"
                                className="form-input mt-2"
                                type="date"
                                {...register("year_ending", {


                                })}
                            />
                        </div>
                        <div className="col-lg-4 col-md-12 mt-4">
                            <label className="fs-18">HST Year Ending</label>
                            <input
                                name="hstyear_ending"
                                id="hstyear_ending"
                                className="form-input mt-2"
                                type="date"
                                {...register("hstyear_ending", {


                                })}

                            />
                        </div>
                        <div className="col-md-12 mt-4">
                            <label className="fs-18">Business Planning Session Notes</label>
                            <textarea
                                className="mt-2 fs-18"
                                name="business_notes"
                                id="business_notes"
                                cols="20"
                                rows="10"
                                placeholder="Type here..."
                                {...register("business_notes", {


                                })}
                            >
                            </textarea>
                        </div>
                        <div className="col-md-12 mt-4 mb-2 text-end">
                            <button type="submit" className="btn-blue ms-auto">Add</button>
                        </div>

                    </div>
                </form>



            </div>
            <Add_BusinessType
                isBusinessTypeOpen={isBusinessTypeOpen}
                setIsBusinessTypeOpen={setIsBusinessTypeOpen}
                showHsBusinessTypeList={showHsBusinessTypeList}
            />
            <Add_BusinessNature
                isBusinessNatureOpen={isBusinessNatureOpen}
                setBusinessNatureOpen={setBusinessNatureOpen}
                showHsBusinessNatureList={showHsBusinessNatureList}
            />
            <Add_BusinessCate
                isBusinessCateOpen={isBusinessCateOpen}
                setIsBusinessCateOpen={setIsBusinessCateOpen}
                showBusinessCateList={showBusinessCateList}
            />
            <Add_EnteryStyle
                isEnteryStyleOpen={isEnteryStyleOpen}
                setIsEnetryStyleOpen={setIsEnetryStyleOpen}
                showBusinessEnteryList={showBusinessEnteryList}
            />
            <Add_PlaningStatus
                isPlaningStatusOpen={isPlaningStatusOpen}
                setIsPlaningStatusOpen={setIsPlaningStatusOpen}
                showBusinessPlaningList={showBusinessPlaningList}
            />
        </Modal>
    )
}
export default Advance_Details;