import React, { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { http } from "../../http/http";
import { toast } from "react-toastify";
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';

import { addCorporateData } from "../../../reduxTool/features/client/clientSlice";
import { useDispatch } from "react-redux";
import { GlobalContext } from "../../../App";

const AddBusiness = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        getValues,
        formState: { errors },
        watch,
    } = useForm({ mode: "all" });
    const formValues = watch();
    const { showLoader, setShowLoader } = useContext(GlobalContext);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [customFormList, setCustomFormList] = useState([]);
    const [userData, setUserData] = useState(null);
    const selectedBusinessUserData = JSON.parse(localStorage.getItem("selectedBusinessUser"));
    const selectedBusinessContactData = JSON.parse(sessionStorage.getItem("contact_business_data"));
    const [address, setAddress] = useState('');

    const [formState, setFormState] = useState({
        city: "",
        province: "",
        country: "",
        postal_code: "",
    });


    const handleNewChange = (e) => {
        const { name, value } = e.target;

        // Update local state
        setFormState((prevState) => ({
            ...prevState,
            [name]: value,
        }));

        // Update React Hook Form state
        setValue(name, value);
    };

    // Synchronize formState with watch (React Hook Form state)
    useEffect(() => {
        const subscription = watch((values) => {
            setFormState((prevState) => ({
                ...prevState,
                ...values,
            }));
        });
        return () => subscription.unsubscribe();
    }, [watch]);


    useEffect(() => {
        const populateFormFields = (data) => {
            if (!data) {
                console.warn("No data to populate form fields.");
                return;
            }
            try {
                Object.keys(data).forEach((key) => {
                    if (data[key]) {
                        if (key === "street_address") {
                            setAddress(data[key]);
                        } else if (key in formState) {
                            setFormState((prevState) => ({
                                ...prevState,
                                [key]: data[key],
                            }));
                            setValue(key, data[key]); // Ensure form values are synced
                        } else if (key === "company_name") {
                            setValue("name", data[key]);
                        } else {
                            setValue(key, data[key]);
                        }
                    }
                });
            } catch (error) {
                console.error("Error populating form fields:", error);
            }
        };

        // Retrieve data from sessionStorage and localStorage
        try {
            const storedUserData = JSON.parse(localStorage.getItem("selectedBusinessUser"));
            const contactData = JSON.parse(sessionStorage.getItem("contact_business_data"));
            // Prioritize contactData over storedUserData
            if (contactData) {
                populateFormFields(contactData);
            } else if (storedUserData) {
                populateFormFields(storedUserData);
            } else {
                console.warn("No data found in storage.");
            }
        } catch (error) {
            console.error("Error retrieving data from storage:", error);
        }
    }, [setValue]);




    const handleChange = (value) => {
        setAddress(value);
    };


    const handleSelect = async (value) => {
        setAddress(value);

        try {
            const results = await geocodeByAddress(value);
            if (results && results[0]) {
                const addressComponents = results[0].address_components;
                const formattedAddress = results[0].formatted_address;

                let selectedCity = "";
                let selectedProvince = "";
                let selectedCountry = "";
                let postalCode = "";

                // Extract the required address components
                addressComponents.forEach((component) => {
                    if (component.types.includes("locality")) {
                        selectedCity = component.long_name; // City
                    }
                    if (component.types.includes("administrative_area_level_1")) {
                        selectedProvince = component.long_name; // Province long name
                    }
                    if (component.types.includes("country")) {
                        selectedCountry = component.long_name; // Country
                    }
                    if (component.types.includes("postal_code")) {
                        postalCode = component.long_name; // Postal code
                    }
                });

                // Update form fields using both React Hook Form and local state
                setValue("office_address", formattedAddress); // Update address in the form
                setValue("city", selectedCity);
                setValue("province", selectedProvince);
                setValue("country", selectedCountry);
                setValue("postal_code", postalCode);

                setFormState((prevState) => ({
                    ...prevState,
                    city: selectedCity,
                    province: selectedProvince,
                    country: selectedCountry,
                    postal_code: postalCode,
                }));
            } else {
                console.warn("No results found for the selected address.");
            }
        } catch (error) {
            console.error(
                "Error selecting place:",
                error?.message || "No message",
                error?.stack || "No stack trace"
            );
        }
    };


    const handleCheckboxCustomForm = (event) => {
        const value = parseInt(event.target.value, 10); // Parse the value as an integer

        setSelectedOptions((prevSelectedOptions) => {
            if (prevSelectedOptions.includes(value)) {
                return prevSelectedOptions.filter((option) => option !== value);
            } else {
                return [...prevSelectedOptions, value];
            }
        });
    };

    const handleCustomFormList = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `form/listing`,
                    isSecure: true,
                },
                (res) => {
                    setCustomFormList(res?.data?.data);
                },
            )
        } catch (error) {

        }
    };
    useEffect(() => {
        handleCustomFormList();
    }, []);


    const handleBusieness = async () => {
        setShowLoader(true);
        const formValues = getValues();
        const formIds = selectedOptions.map(id => id);

        const payload = {
            email: formValues.email,
            folder_number: formValues.folder_number,
            name: formValues.name,
            status: formValues.status,
            office_address: address, // Use the updated `address`
            city: formState.city, // Include city
            postal_code: formState.postal_code, // Include postal code
            province: formState.province, // Include province
            country: formState.country, // Include country
            form_id: formIds,
            contact_detail_id: selectedBusinessUserData?.id || selectedBusinessContactData?.id,
        }

        http(
            {
                method: "POST",
                url: "corporate/add",
                isSecure: true,
                body: payload,
            },
            (res) => {
                if (res.data && res.data.length > 0) {
                    const businessId = res.data[0]?.id;
                    if (businessId) {
                        localStorage.setItem('businessId', businessId); // Store businessId in localStorage
                        localStorage.removeItem("selectedBusinessUser");
                        sessionStorage.removeItem("contact_business_data");
                        toast.success(res.single_message);
                        reset(); // Reset form
                        navigate(`/admin/client/business_profile/${businessId}`);
                        // window.location.reload();
                        setShowLoader(false);
                    } else {
                        toast.error("Business ID is missing in the response.");
                    }
                } else {
                    toast.error("Unexpected response format.");
                }
            },
            (err) => {
                // Handle errors according to the response
                if (err.status === 401) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else if (err.status === 400) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else {
                    toast.error(err.single_message);
                    setShowLoader(false);
                }
            }
        );
    };


    return (
        <div className="right_section font-poppins">
            <div className="business-form-area py-3">
                <div className="row business-head align-items-center">
                    <div className="col-md-12 left_head">
                        <h4 className="fs-20 mt-2 fw-semibold">Add Business Client</h4>
                    </div>
                    <div className="col-md-12">
                        <div className="main-form-area p-4 mt-3 bg-white shadow-lg">
                            <form onSubmit={handleSubmit(handleBusieness)}>
                                <div className="row form-group">
                                    <div className="col-md-12">
                                        <h6 className="fs-20 fw-semibold">Basic Details</h6>
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-3">
                                        <label htmlFor="first_name">Name of Incorporation <span className="text-danger">*</span></label>
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            placeholder="Type here..."
                                            className="form-input mt-2"
                                            {...register("name", {
                                                required: true,
                                                // pattern: /^(?=.*[a-zA-Z])[a-zA-Z0-9]+$/,
                                            })}
                                        />
                                        {errors.name?.type === "required" && (
                                            <p
                                                role="alert"
                                                className="text-danger mb-0 pt-1 -fs-7 ms-1"
                                            >
                                                Required
                                            </p>
                                        )}
                                        {/* {errors.name &&
                                            errors.name.type === "pattern" && (
                                                <p
                                                    role="alert"
                                                    className="text-danger mb-0 pt-1 -fs-7 ms-1"
                                                >
                                                    At least add one alphabetic
                                                </p>
                                            )} */}
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-3">
                                        <label htmlFor="name">Folder Name <span className="text-danger">*</span></label>
                                        <input
                                            type="text"
                                            name="folder_number"
                                            id="folder_number"
                                            placeholder="Type here..."
                                            className="form-input mt-2"
                                            {...register("folder_number", {
                                                required: true,
                                            })}
                                        />
                                        {errors.folder_number?.type === "required" && (
                                            <p
                                                role="alert"
                                                className="text-danger mb-0 pt-1 -fs-7 ms-1"
                                            >
                                                Required
                                            </p>
                                        )}
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-3">
                                        <label htmlFor="email">Email Address <span className="text-danger">*</span></label>
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            placeholder="Type here..."
                                            className="form-input mt-2"
                                            {...register("email", {
                                                required: true,
                                                pattern: /\S+@\S+\.\S+/,
                                            })}
                                            disabled
                                        />
                                        {errors.email?.type === "required" && (
                                            <p
                                                role="alert"
                                                className="text-danger mb-0 pt-1-fs-7 ms-1"
                                            >
                                                Required
                                            </p>
                                        )}
                                        {errors.email?.type === "pattern" && (
                                            <p
                                                role="alert"
                                                className="text-danger mb-0 pt-1 -fs-7 ms-1"
                                            >
                                                Incorrect email format
                                            </p>
                                        )}
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-3">
                                        <label htmlFor="name">Status <span className="text-danger">*</span></label>
                                        <select
                                            name="status"
                                            id="status"
                                            className="form-input mt-2"
                                            {...register("status", {
                                                required: true,
                                            })}

                                        >
                                            <option
                                                value=""
                                                selected
                                                disabled="disabled"
                                            >
                                                Status
                                            </option>
                                            <option value="1">Active</option>
                                            <option value="0">Inactive </option>
                                        </select>
                                        {errors.status?.type === "required" && (
                                            <p
                                                role="alert"
                                                className="text-danger mb-0 pt-1 -fs-7 ms-1"
                                            >
                                                Required
                                            </p>
                                        )}
                                    </div>
                                    <div className="col-lg-8  mt-3">
                                        <label htmlFor="address">Reg. Office Address</label>
                                        <PlacesAutocomplete
                                            value={address}
                                            onChange={handleChange}
                                            onSelect={handleSelect}
                                            searchOptions={{
                                                componentRestrictions: {
                                                    country: ["us", "ca"],
                                                },
                                            }}
                                        >
                                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                <div>
                                                    <input
                                                        {...getInputProps({
                                                            placeholder: "Type address here...",
                                                            className: "form-input mt-2",
                                                        })}
                                                    />
                                                    <div className="autocomplete-dropdown-container">
                                                        {loading && <div>Loading...</div>}
                                                        {suggestions.map((suggestion) => {
                                                            const className = suggestion.active
                                                                ? "suggestion-item--active"
                                                                : "suggestion-item";
                                                            return (
                                                                <div
                                                                    {...getSuggestionItemProps(suggestion, { className })}
                                                                    key={suggestion.placeId}
                                                                >
                                                                    <span>{suggestion.description}</span>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            )}
                                        </PlacesAutocomplete>
                                    </div>
                                    {/* <div className="col-md-4 mt-4">
                                        <label className="w-100">City</label>
                                        <input
                                            type="text"
                                            name="city"
                                            className="form-input mt-2"
                                            placeholder="Type here..."
                                            value={formState.city}
                                            onChange={handleNewChange}
                                            {...register("city", {})}

                                        />
                                    </div>
                                    <div className="col-md-4 mt-4">
                                        <label htmlFor="" className="w-100">Province </label>
                                        <input
                                            type="text"
                                            name="province"
                                            className="form-input mt-2"
                                            placeholder="Type here..."
                                            value={formState.province}
                                            onChange={handleNewChange}
                                            {...register("province", {})}

                                        />
                                    </div>
                                    <div className="col-md-4 mt-4">
                                        <label className="w-100">Country</label>
                                        <input
                                            type="text"
                                            name="country"
                                            className="form-input mt-2"
                                            placeholder="Type here..."
                                            value={formState.country}
                                            onChange={handleNewChange}
                                            {...register("country", {})}


                                        />
                                    </div>
                                    <div className="col-md-4 mt-4">
                                        <label className="w-100">Postal Code</label>
                                        <input
                                            type="text"
                                            name="postal_code"
                                            className="form-input mt-2"
                                            placeholder="Type here..."
                                            value={formState.postal_code}
                                            onChange={handleNewChange}
                                            {...register("postal_code", {})}

                                        />
                                    </div> */}


                                    <div className=" mt-3">
                                        <hr></hr>
                                        <label htmlFor="Required Forms" className="inv-title text-black mb-2">Required Forms</label>
                                        {customFormList && customFormList.map((option) => (
                                            <div key={option.id} className="d-flex align-items-center gap-2 mt-2">
                                                <input
                                                    type="checkbox"
                                                    value={option.id}
                                                    checked={selectedOptions.includes(option.id)}
                                                    onChange={handleCheckboxCustomForm}
                                                />
                                                <label className="text-black">{option.data[0]?.title}</label>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="col-md-12 individual-footer">
                                        <div className="btn-groups mt-4">
                                            <button
                                                type="submit"
                                                className="add-new ms-2"
                                            >
                                                Add
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AddBusiness; 