import React, { useState, useEffect, useMemo, useContext, useRef, useCallback } from "react";
// import Add_Individual_Task from "./tsidebarTaskModal/Add_individual_Task";
import moment from "moment";
import { http } from "../../http/http";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactPaginate from "react-paginate";
import { GlobalContext } from "../../../App";
import { Modal } from "react-bootstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import { handlePermissionData, handlePermissionIdsData } from "../../http/help";
import Auth from "../../../auth/Auth";

const Add_Main_Task = () => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState("tasks");
    const [addTaskModal, setAddTaskModal] = useState(false);
    const [priorityList, setPriorityList] = useState([]);
    const [interactionList, setInteractionList] = useState([]);
    const [StatusList, setStatusList] = useState([]);
    const [assignData, setAssignData] = useState([]);
    const [taskList, setTaskList] = useState([]);
    const [recurringTaskList, setRecurringTaskList] = useState([]);
    const [selectedPriority, setSelectedPriority] = useState("All");
    const [selectedStatus, setSelectedStatus] = useState("All");
    const [selectedStatusRecurringType, setSelectedStatusRecurringType] = useState(null);
    const [selectedTaskType, setSelectedTaskType] = useState(null);
    const [selectedName, setSelectedName] = useState("All");
    const [selectedAssignee, setSelectedAssignee] = useState("All");
    const [searchInput, setSearchInput] = useState("");
    const [assigneeSearchInput, setAssigneeSearchInput] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [startDateRecurring, setStartDateRecurring] = useState(null);
    const [nextMonthDateRecurring, setNextMonthDateRecurring] = useState(null);
    const [lastMonthDateRecurring, setLastMonthDateRecurring] = useState(null);
    const [filteredTasks, setFilteredTasks] = useState(taskList);
    const [filteredRecurringTasks, setFilteredRecurringTasks] = useState(recurringTaskList);
    const [filteredNames, setFilteredNames] = useState([]);
    const [filteredAssignees, setFilteredAssignees] = useState([]);
    const [isPriorityOpen, setIsPriorityOpen] = useState(false);
    const [isStatusOpen, setIsStatusOpen] = useState(false);
    const [isStatusOpenRecurring, setIsStatusOpenRecurring] = useState(false);
    const [isTaskTypeOpen, setIsTaskTypeOpen] = useState(false);
    const [isNameOpen, setIsNameOpen] = useState(false);
    const [isAssigneeOpen, setIsAssigneeOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    let taskLimit = 200;
    const [currentRecurringPage, setCurrentRecurringPage] = useState(1);
    const [totalRecurringPages, setRecurringTotalPages] = useState(1);
    let recurringTaskLimit = 200;
    const { showLoader, setShowLoader } = useContext(GlobalContext);
    const [showDropdown, setShowDropdown] = useState(false);
    const [showSubTask, setShowSubTask] = useState(false);
    const [options, setOptions] = useState([]); // Initially empty
    const [optionsRecurring, setOptionRecurring] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedRecurringOption, setSelectedRecurringOption] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenRecurring, setIsOpenRecurring] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [searchRecuriingTerm, setSearchRecurringTerm] = useState("");
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
    const [debouncedRecurringSearchTerm, setDebouncedRecurringSearchTerm] = useState(searchRecuriingTerm);
    const dropdownRef = useRef(null);
    const [openDropdown, setOpenDropdown] = useState(null);
    const [openRecurringDropdown, setOpenRecurringDropdown] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorRecurringMessage, setErrorRecurringMessage] = useState("");
    const [expandedTasks, setExpandedTasks] = useState([]);
    const [openDropdownStatusId, setOpenDropdownStatusId] = useState(null);
    const [selectedStatuses, setSelectedStatuses] = useState({});
    const [showRecurringSubTask, setShowRecurringSubTask] = useState(false);
    const [taskDetails, setTaskDetails] = useState({
        interaction_type_id: 0,
        priority_id: 0,
        status_task_id: 0,
    });

    const isDropdownOpen = (taskId) => openDropdownStatusId === taskId;
    const initialStatusSelectedOption = StatusList && StatusList.find((option) => option.id === taskDetails.status_task_id);
    const [dropdownState, setDropdownState] = useState({
        isStatusOpen: false,
        statusSelectedOption: initialStatusSelectedOption || null,
    });
    const [openOuterTemplateModal, setOpenOuterTemplateModal] = useState(false);
    const [templateOuterList, setTemplateOuterList] = useState([]);
    const [selectedTemplateId, setSelectedTemplateId] = useState("");
    const [recurringTemplateList, setRecurringTemplateList] = useState([]);
    const [recurringSelectedTemplateId, setRecurringSelectedTemplateId] = useState("");
    const [openRecurringTemplateModal, setOpenRecurringTemplateModal] = useState(false);

    // Handle template selection
    const handleTemplateChange = (e) => {
        setSelectedTemplateId(e.target.value); // Update the selected template ID
    };
    const handleRecurringTemplateChange = (e) => {
        setRecurringSelectedTemplateId(e.target.value); // Update the selected template ID
    };

    const convertHexToRGBA = (hex, opacity) => {
        if (typeof hex !== "string" || hex === null || hex.length === 0) {
            console.error("Invalid hex value:", hex);
            throw new TypeError("Hex value must be a non-empty string");
        }

        let r = 0,
            g = 0,
            b = 0;

        // 3 digits hex
        if (hex.length === 4) {
            r = parseInt(hex[1] + hex[1], 16);
            g = parseInt(hex[2] + hex[2], 16);
            b = parseInt(hex[3] + hex[3], 16);
        }
        // 6 digits hex
        else if (hex.length === 7) {
            r = parseInt(hex[1] + hex[2], 16);
            g = parseInt(hex[3] + hex[4], 16);
            b = parseInt(hex[5] + hex[6], 16);
        } else {
            console.error("Invalid hex length:", hex);
            throw new Error("Hex value must be either 4 or 7 characters long");
        }

        return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    };
    // Handle Drag End
    const handleDragEnd = (result) => {
        const { destination, source } = result;
        if (!destination) return; // Exit if dropped outside the list

        // Reorder tasks
        const reorderedTasks = Array.from(filteredTasks);
        const [removed] = reorderedTasks.splice(source.index, 1);
        reorderedTasks.splice(destination.index, 0, removed);

        setFilteredTasks(reorderedTasks);
    };

    // Handle Drag End
    const handleDragEndRecurring = (result) => {
        const { destination, source } = result;
        if (!destination) return; // Exit if dropped outside the list

        // Reorder tasks
        const reorderedTasks = Array.from(filteredRecurringTasks);
        const [removed] = reorderedTasks.splice(source.index, 1);
        reorderedTasks.splice(destination.index, 0, removed);

        setFilteredRecurringTasks(reorderedTasks);
    };

    // const handleTabClick = (tab) => {
    //     setShowLoader(true);
    //     setActiveTab(tab);
    //     setTimeout(() => {
    //         setShowLoader(false);
    //     }, 1000); // Simulate a delay of 1 second
    // };

    const handleTabClick = (tab) => {
        setShowLoader(true);
        setActiveTab(tab);

        setTimeout(() => {
            if (tab === "tasks") {
                handleTaskList(currentPage); // Trigger task list API
            } else if (tab === "recurring") {
                handleRecurringTaskList(currentRecurringPage); // Trigger recurring task API
            }
            setShowLoader(false);
        }, 500); // Simulate a delay for smooth loader behavior
    };


    // Debounce search term (wait 300ms after user stops typing to update)
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchTerm(searchTerm); // Set debounced search term after delay
        }, 300);

        return () => clearTimeout(handler); // Cleanup timeout on component unmount or input change
    }, [searchTerm]);

    // Memoize options and precompute lowercased names for faster filtering
    // const processedOptions = useMemo(() => {
    //     return options.map((option) => ({
    //         ...option,
    //         lowerCaseName: option.name.toLowerCase(), // Preprocess to include a lowercase version for easier filtering
    //     }));
    // }, [options]);
    const processedOptions = useMemo(() => {
        if (!Array.isArray(options)) {
            console.error("Expected 'options' to be an array but got:", options);
            return []; // Return an empty array to prevent errors
        }

        return options.map((option) => ({
            ...option,
            lowerCaseName: option.name.toLowerCase(), // Preprocess to include a lowercase version for easier filtering
        }));
    }, [options]);

    // Memoize the filtered options based on debounced search term
    const filteredOptions = useMemo(() => {
        if (!debouncedSearchTerm) return processedOptions; // If no search term, return full list
        const lowerCaseSearchTerm = debouncedSearchTerm.toLowerCase(); // Convert search term to lowercase
        return processedOptions.filter(
            (option) => option.lowerCaseName.includes(lowerCaseSearchTerm) // Filter based on the lowercased name
        );
    }, [debouncedSearchTerm, processedOptions]);

    // Debounce search term (wait 300ms after user stops typing to update)
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedRecurringSearchTerm(searchRecuriingTerm); // Set debounced search term after delay
        }, 300);

        return () => clearTimeout(handler); // Cleanup timeout on component unmount or input change
    }, [searchRecuriingTerm]);

    // Memoize options and precompute lowercased names for faster filtering
    // const processedRecurringOptions = useMemo(() => {
    //     return optionsRecurring.map((option) => ({
    //         ...option,
    //         lowerCaseName: option.name.toLowerCase(), // Preprocess to include a lowercase version for easier filtering
    //     }));
    // }, [optionsRecurring]);

    const processedRecurringOptions = useMemo(() => {
        if (!Array.isArray(optionsRecurring)) {
            console.error("Expected 'optionsRecurring' to be an array but got:", optionsRecurring);
            return []; // Default to an empty array if invalid
        }

        return optionsRecurring.map((option) => ({
            ...option,
            lowerCaseName: option.name.toLowerCase(), // Preprocess to include a lowercase version for easier filtering
        }));
    }, [optionsRecurring]);


    // Memoize the filtered options based on debounced search term
    const filteredRecurringOptions = useMemo(() => {
        if (!debouncedRecurringSearchTerm) return processedRecurringOptions; // If no search term, return full list
        const lowerCaseRecurringSearchTerm = debouncedRecurringSearchTerm.toLowerCase(); // Convert search term to lowercase
        return processedRecurringOptions.filter(
            (option) => option.lowerCaseName.includes(lowerCaseRecurringSearchTerm) // Filter based on the lowercased name
        );
    }, [debouncedRecurringSearchTerm, processedRecurringOptions]);

    // Handle search input changes
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value); // Update search term with user input
    };
    const handleOptionClick = useCallback((option) => {
        setSelectedOption(option); // Set selected option
        setIsOpen(false); // Close the dropdown
        setSearchTerm(""); // Clear the search input

        // Save selected option to localStorage
        localStorage.setItem("selectedOterTaskOption", JSON.stringify(option)); // Save both name and id
    }, []);

    const handleRecurringOptionClick = useCallback((option) => {
        setSelectedRecurringOption(option); // Set selected option
        setIsOpenRecurring(false); // Close the dropdown
        setSearchRecurringTerm(""); // Clear the search input

        // Save selected option to localStorage
        localStorage.setItem("selectedRecurringOuterOption", JSON.stringify(option)); // Save both name and id
    }, []);

    // Handle search input changes
    const handleRecurringSearchChange = (e) => {
        setSearchRecurringTerm(e.target.value); // Update search term with user input
    };

    const handleClickOutside = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setIsOpen(false);
        }
    };

    const toggleDropdown = () => {
        setIsOpen((prevState) => !prevState);
    };
    const toggleRecurringDropdown = () => {
        setIsOpenRecurring((prevState) => !prevState);
    };
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    // const handleButtonClick = () => {
    //     setShowLoader(true);
    //     setTimeout(() => {
    //         if (activeTab === 'tasks') {
    //             navigate('/admin/task/add_task');
    //         } else {
    //             navigate('/admin/task/add_recurring_task');
    //         }
    //         setShowLoader(false);
    //     }, 500);
    // };

    // const handleDropdownOptionClick = (option) => {
    //     setShowLoader(true);
    //     setShowDropdown(false);
    //     setTimeout(() => {

    //         if (activeTab === 'tasks') {
    //             if (option === 'add_task') {
    //                 navigate('/admin/task/add_task');
    //             } else if (option === 'add_subtask') {
    //                 setShowSubTask(true);
    //             }
    //         } else if (activeTab === 'recurring') {
    //             if (option === 'add_recurring_task') {
    //                 navigate('/admin/task/add_recurring_task');
    //             } else if (option === 'add_recurring_subtask') {

    //                 setShowRecurringSubTask(true);
    //             }
    //         }
    //         setShowLoader(false);
    //     }, 500);
    // };

    // const handleButtonClick = () => {
    //     setShowLoader(true);
    //     setTimeout(() => {
    //         if (activeTab === "tasks") {
    //             if (Auth.innerPermission("task_add")) {
    //                 navigate("/admin/task/add_task");
    //             } else {
    //                 toast.error("You are not authorized to access the resource");
    //             }
    //         } else {
    //             navigate("/admin/task/add_recurring_task");
    //         }
    //         setShowLoader(false);
    //     }, 500);
    // };


    const handleButtonClick = () => {
        setShowLoader(true);
        setTimeout(() => {
            if (activeTab === "tasks") {
                // Remove localStorage items for "tasks"
                localStorage.removeItem("selectedOterTaskOption");
                localStorage.removeItem("task_details_Outer");

                if (Auth.innerPermission("task_create")) {
                    navigate("/admin/task/add_task");
                } else {
                    toast.error("You are not authorized to access the resource");
                }
            } else if (activeTab === "recurring") {
                // Remove localStorage items for "recurring tasks"
                localStorage.removeItem("Outer_recurring_task_details");
                localStorage.removeItem("selectedRecurringOuterOption");

                navigate("/admin/task/add_recurring_task");
            }

            setShowLoader(false);
        }, 500);
    };



    const handleDropdownOptionClick = (option) => {
        setShowLoader(true);
        setShowDropdown(false);
        setTimeout(() => {
            if (activeTab === "tasks") {
                if (Auth.innerPermission("task_add")) {
                    if (option === "add_task") {
                        navigate("/admin/task/add_task");
                    } else if (option === "add_subtask") {
                        setShowSubTask(true);
                    }
                } else {
                    toast.error("You are not authorized to access the resource");
                }
            } else if (activeTab === "recurring") {
                if (option === "add_recurring_task") {
                    navigate("/admin/task/add_recurring_task");
                } else if (option === "add_recurring_subtask") {
                    setShowRecurringSubTask(true);
                }
            }
            setShowLoader(false);
        }, 500);
    };
    const closeModal = () => {
        setShowSubTask(false);
    };
    const closeRecurringSubTaskModal = () => {
        setShowRecurringSubTask(false);
    };
    const uniqueNames = useMemo(() => [...new Set(taskList?.map((task) => (task.task_type === "3" ? task.client_self?.name : `${task.client?.first_name} ${task.client?.last_name}`)))], [taskList]);
    // const uniqueAssignees = useMemo(() => [...new Set(taskList.flatMap((task) => task.assignees.map((assignee) => assignee.first_name)))], [taskList]);
    const uniqueAssignees = useMemo(() => {
        if (!Array.isArray(taskList)) {
            console.error("Expected 'taskList' to be an array but got:", taskList);
            return []; // Default to an empty array if 'taskList' is not valid
        }

        return [
            ...new Set(
                taskList.flatMap((task) =>
                    (task.assignees || []).map((assignee) => assignee.first_name)
                )
            ),
        ];
    }, [taskList]);

    useEffect(() => {
        setFilteredNames(uniqueNames.filter((name) => name?.toLowerCase().includes(searchInput.toLowerCase())));
    }, [searchInput, uniqueNames]);

    useEffect(() => {
        setFilteredAssignees(uniqueAssignees.filter((assignee) => assignee.toLowerCase().includes(assigneeSearchInput.toLowerCase())));
    }, [assigneeSearchInput, uniqueAssignees]);

    // const uniqueAssigneesRecurring = useMemo(() => [...new Set(recurringTaskList.flatMap((recurringTask) => recurringTask.tasks.flatMap((task) => task.assignees.map((assignee) => assignee.first_name))))], [recurringTaskList]);
    const uniqueAssigneesRecurring = useMemo(() => {
        if (!Array.isArray(recurringTaskList)) {
            console.error("Expected 'recurringTaskList' to be an array but got:", recurringTaskList);
            return []; // Default to an empty array
        }

        return [
            ...new Set(
                recurringTaskList.flatMap((recurringTask) =>
                    (recurringTask.tasks || []).flatMap((task) =>
                        (task.assignees || []).map((assignee) => assignee.first_name)
                    )
                )
            ),
        ];
    }, [recurringTaskList]);

    // useEffect(() => {
    //     setFilteredNames(uniqueNamesRecurring.filter(name => name?.toLowerCase().includes(searchInput.toLowerCase())));
    // }, [searchInput, uniqueNamesRecurring]);

    useEffect(() => {
        setFilteredAssignees(uniqueAssigneesRecurring.filter((assignee) => assignee.toLowerCase().includes(assigneeSearchInput.toLowerCase())));
    }, [assigneeSearchInput, uniqueAssigneesRecurring]);

    const handleDropdownSubTaskClick = (event) => {
        event.stopPropagation(); // Prevent the dropdown from closing the button action
        setShowDropdown(!showDropdown);
    };

    const handleDropdownClick = (dropdownName) => {
        if (openDropdown === dropdownName) {
            setOpenDropdown(null); // Close dropdown if it's already open
        } else {
            setOpenDropdown(dropdownName); // Open the clicked dropdown and close others
            // event.stopPropagation();
        }
    };
    const handleStatusClick = () => handleDropdownClick("status");
    const handlePriorityClick = () => handleDropdownClick("priority");
    const handleNameClick = () => handleDropdownClick("createdBy");
    // const handleAssigneeClick = () => handleDropdownClick('priority');
    const handleTaskTypeClick = () => handleDropdownClick("taskType");
    const handleAssigneeClick = () => handleDropdownClick("assignee");

    const handleDropdownRecurringClick = (dropdownName) => {
        if (openRecurringDropdown === dropdownName) {
            setOpenRecurringDropdown(null); // Close dropdown if it's already open
        } else {
            setOpenRecurringDropdown(dropdownName); // Open the clicked dropdown and close others
        }
    };

    const handleStatusRecurringClick = () => handleDropdownRecurringClick("status");
    const handlRecurringeTaskTypeClick = () => handleDropdownRecurringClick("taskType");

    const handlePriorityOptionClick = (value) => {
        setSelectedPriority(value);
        setIsPriorityOpen(false);
    };

    const handleStatusOptionClick = (value) => {
        setSelectedStatus(value);
        setIsStatusOpen(false);
    };

    const handleStatusRecurringOptionClick = (value) => {
        setSelectedStatusRecurringType(value);
        setIsStatusOpenRecurring(false);
    };
    const handleTaskTypeOptionClick = (value) => {
        setSelectedTaskType(value);
        setIsTaskTypeOpen(false);
    };
    const handleNameOptionClick = (value) => {
        setSelectedName(value);
        setIsNameOpen(false);
    };
    const handleAssigneeOptionClick = (value) => {
        setSelectedAssignee(value);
        setIsAssigneeOpen(false);
    };
    const stopPropagation = (event) => {
        event.stopPropagation();
    };

    const handlePageClick = (data) => {
        const selectedPage = data.selected + 1;
        setCurrentPage(selectedPage);
    };
    const handleRecurringPageClick = (data) => {
        const selectedPage = data.selected + 1;
        setCurrentRecurringPage(selectedPage);
    };

    const handleOuterSubTaskData = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `client/outertask/getoutertasklisting`,
                    isSecure: true,
                },
                (res) => {
                    setOptions(res?.data);
                }
            );
        } catch (error) {
            console.error("Error fetching staff data:", error);
        }
    };
    useEffect(() => {
        handleOuterSubTaskData();
    }, []);

    const handleRecurringSubTaskData = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `client/outertask/recurringoutertasklisting`,
                    isSecure: true,
                },
                (res) => {
                    setOptionRecurring(res?.data);
                }
            );
        } catch (error) {
            console.error("Error fetching staff data:", error);
        }
    };
    useEffect(() => {
        handleRecurringSubTaskData();
    }, []);

    const handlePriorityData = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `priority/listing`,
                    isSecure: true,
                },
                (res) => {
                    setPriorityList(res?.data?.data);
                }
            );
        } catch (error) { }
    };

    const handleInteractionData = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `interaction_type/listing`,
                    isSecure: true,
                },
                (res) => {
                    setInteractionList(res?.data?.data);
                }
            );
        } catch (error) { }
    };

    const handleStatusData = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `status_task/listing`,
                    isSecure: true,
                },
                (res) => {
                    setStatusList(res?.data?.data);
                }
            );
        } catch (error) { }
    };

    const handleAssisgnData = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `staff/listing`,
                    isSecure: true,
                },
                (res) => {
                    setAssignData(res?.data?.data);
                }
            );
        } catch (error) { }
    };

    const handleTaskList = async (page) => {
        setShowLoader(true);
        try {
            http(
                {
                    method: "GET",
                    url: `client/outertask/listing?page=${page}&limit=${taskLimit}`,
                    isSecure: true,
                },
                (res) => {
                    setTaskList(res?.data?.data);
                    setTotalPages(res?.data?.last_page); // Set total pages from the response
                    setShowLoader(false);
                }
            );
        } catch (error) {
            setShowLoader(false);
        }
    };

    useEffect(() => {
        handleTaskList(currentPage);
    }, [currentPage]);

    const handleRecurringTaskList = async (page) => {
        setShowLoader(true);
        try {
            http(
                {
                    method: "GET",
                    url: `client/outertask/reccuring-listing?page=${page}&limit=${recurringTaskLimit}`,
                    isSecure: true,
                },
                (res) => {
                    setRecurringTaskList(res?.data?.data);
                    setRecurringTotalPages(res?.data?.last_page);
                    setShowLoader(false);
                }
            );
        } catch (error) {
            setShowLoader(false);
        }
    };

    useEffect(() => {
        handleRecurringTaskList(currentRecurringPage);
    }, [currentRecurringPage]);

    useEffect(() => {
        handleInteractionData();
        handlePriorityData();
        handleStatusData();
        handleAssisgnData();
    }, []);

    const updateSubTaskStatusType = (StatusId, taskId) => {
        if (!taskId) {
            console.error("Task ID is required but is undefined inside updateSubTaskStatusType.");
            toast.error("Task ID is missing.");
            return;
        }

        try {
            http(
                {
                    method: "POST",
                    url: `task/update_status/${taskId}`,
                    isSecure: true,
                    body: {
                        status_task_id: StatusId,
                    },
                },
                (res) => {
                    toast.success(res.single_message);
                    handleTaskList();
                },
                (err) => {
                    // Handle errors according to the response
                    if (err.status === 401) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else if (err.status === 400) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    }
                }
            );
        } catch (error) {
            console.error("Unexpected error updating status:", error);

        }
    };

    const filteredOptionsStatus = StatusList && StatusList.filter((option) => option.name.toLowerCase().includes((dropdownState.statusSearch || "").toLowerCase()));

    useEffect(() => {
        let filtered = taskList; // Start with the full task list

        // Apply assignee filter
        if (selectedAssignee !== "All") {
            filtered = filtered.filter((task) => task.assignees.some((assignee) => assignee.first_name === selectedAssignee));
        }

        // Apply name filter
        if (selectedName !== "All") {
            filtered = filtered.filter((task) => (task.task_type === "3" ? task.client_self?.name === selectedName : `${task.client?.first_name} ${task.client?.last_name}` === selectedName));
        }

        // Apply priority filter
        if (selectedPriority !== "All") {
            filtered = filtered.filter((task) => task.priority?.id == selectedPriority);
        }

        // Apply status filter
        if (selectedStatus !== "All") {
            filtered = filtered.filter((task) => task.statustask?.id == selectedStatus);
        }

        // Apply task type filter
        if (selectedTaskType && selectedTaskType !== "All") {
            filtered = filtered.filter((task) => task.task_type == selectedTaskType);
        }

        // Apply date range filter
        if (startDate && endDate) {
            filtered = filtered.filter((task) => {
                const taskDate = moment(task.created_at);
                return taskDate.isBetween(startDate, endDate, "day", "[]");
            });
        }

        setFilteredTasks(filtered); // Update state with the filtered tasks
    }, [selectedAssignee, selectedName, selectedPriority, selectedStatus, selectedTaskType, startDate, endDate, taskList]); // Dependencies

    useEffect(() => {
        let filtered = recurringTaskList;

        if (selectedStatusRecurringType && selectedStatusRecurringType !== "All") {
            filtered = filtered.filter((task) => task.reccuring_status == selectedStatusRecurringType);
        }

        // Apply task type filter
        if (selectedTaskType && selectedTaskType !== "All") {
            filtered = filtered.filter((task) => task.reccuring_repeat == selectedTaskType);
        }

        if (startDateRecurring) {
            const selectedDate = moment(startDateRecurring).startOf("day");

            filtered = filtered.filter((recurringTask) =>
                recurringTask.tasks.some((task) => {
                    const taskDate = moment(recurringTask.reccuring_start_date).startOf("day");

                    return taskDate.isSame(selectedDate, "day");
                })
            );
        }

        // Filter by the next month date
        if (nextMonthDateRecurring) {
            const nextMonthSelectedDate = moment(nextMonthDateRecurring).startOf("day");

            filtered = filtered.filter((recurringTask) =>
                recurringTask.tasks.some((task) => {
                    const taskDate = moment(recurringTask.reccuring_start_next_date).startOf("day");

                    return taskDate.isSame(nextMonthSelectedDate, "day"); // Exact match for next month date
                })
            );
        }

        // Filter by the last month date
        if (lastMonthDateRecurring) {
            const lastMonthSelectedDate = moment(lastMonthDateRecurring).startOf("day");

            filtered = filtered.filter((recurringTask) =>
                recurringTask.tasks.some((task) => {
                    const taskDate = moment(recurringTask.reccuring_end_date).startOf("day"); // Convert task end date to a moment object at start of day

                    return taskDate.isSame(lastMonthSelectedDate, "day"); // Exact match for the last month date
                })
            );
        }

        setFilteredRecurringTasks(filtered);
    }, [selectedAssignee, selectedName, selectedPriority, selectedStatus, selectedTaskType, selectedStatusRecurringType, startDateRecurring, nextMonthDateRecurring, lastMonthDateRecurring, recurringTaskList]);

    const handleFormSubmit = (e) => {
        e.preventDefault(); // Prevent default form submission behavior

        // Validation: Check if an option has been selected
        if (!selectedOption) {
            setErrorMessage("Please select at least one option."); // Set error message if no option is selected
            return; // Stop the form submission and do not navigate
        }

        // If validation passes, proceed to navigate and close the modal
        setErrorMessage(""); // Clear any previous error message
        navigate("/admin/task/add_task?type=outer_subtask"); // Change the route after submission
        setShowSubTask(false); // Optionally close the modal after navigation
    };

    const handleRecurringFormSubmit = (e) => {
        e.preventDefault(); // Prevent default form submission behavior

        // Validation: Check if an option has been selected
        if (!selectedRecurringOption) {
            setErrorRecurringMessage("Please select at least one option."); // Set error message if no option is selected
            return; // Stop the form submission and do not navigate
        }

        // If validation passes, proceed to navigate and close the modal
        setErrorRecurringMessage(""); // Clear any previous error message
        navigate("/admin/task/add_recurring_task?type=recurring_outer_subtask"); // Change the route after submission
        setShowRecurringSubTask(false); // Optionally close the modal after navigation
    };

    // Toggle the dropdown for a specific task
    const toggleStatusDropdown = (taskId) => {
        setOpenDropdownStatusId((prevId) => (prevId === taskId ? null : taskId)); // Open if closed, close if open
    };

    // Handle status select
    const handleStatusSelect = (option, taskId) => {
        if (!taskId) {
            console.error("Error: Task ID is missing when calling handleStatusSelect.");
            toast.error("Task ID is missing. Please select a task first.");
            return;
        }

        // Close the dropdown
        setOpenDropdownStatusId(null);

        // Update the selected status state
        setSelectedStatuses((prevStatuses) => ({
            ...prevStatuses,
            [taskId]: option, // Update the selected status for the task
        }));

        // Call the function to update the subtask status
        updateSubTaskStatusType(option.id, taskId);
    };

    // Function to handle the toggling of subtasks visibility
    const handleRowAppend = (taskId) => {
        setExpandedTasks((prev) => (prev.includes(taskId) ? prev.filter((id) => id !== taskId) : [...prev, taskId]));
    };

    const TaskRow = ({ individualTask, index, level = 0, className }) => {
        const hasSubtasks = individualTask.subtasks && individualTask.subtasks.length > 0;
        const isExpanded = expandedTasks.includes(individualTask.id);

        // Helper function to get the selected status name for a task
        const getSelectedStatus = (taskId) => selectedStatuses[taskId]?.name || individualTask.statustask?.name || "Select Status";

        return (
            <>
                <Draggable key={individualTask.id} draggableId={individualTask.id.toString()} index={index}>
                    {(provided) => (
                        <tr
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            //   onClick={() => navigate(`/admin/task/taskdetail/${individualTask.id}`)} className={className}

                            onClick={() => {
                                if (Auth.innerPermission("task_view")) {
                                    navigate(`/admin/task/taskdetail/${individualTask.id}`);
                                } else {
                                    toast.error("You do not have permission to view this task.");
                                }
                            }}
                            className={className}
                        >
                            <td>
                                {hasSubtasks ? (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="9.263"
                                        viewBox="0 0 16 9.263"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleRowAppend(individualTask.id);
                                        }}
                                        // style={{
                                        //     transform: isExpanded ? "rotate(360deg)" : "rotate(270deg)",
                                        //     transition: "transform 0.3s ease",
                                        //     cursor: "pointer",
                                        // }}
                                        style={{
                                            transform: isExpanded ? "rotate(360deg)" : "rotate(270deg)",
                                            transition: "transform 0.3s ease",
                                            cursor: "pointer",
                                            pointerEvents: "auto", // Allow the click event
                                        }}
                                        {...provided.dragHandleProps}
                                    >
                                        <g id="arrow-right" transform="translate(16 -107.789) rotate(90)">
                                            <path id="Path_4085" data-name="Path 4085" d="M109.052,16a1.263,1.263,0,0,1-.893-2.156L114,8l-5.844-5.844A1.263,1.263,0,0,1,109.945.37l6.737,6.737a1.263,1.263,0,0,1,0,1.786l-6.737,6.737a1.256,1.256,0,0,1-.893.37Z" fill="#202529" />
                                        </g>
                                    </svg>
                                ) : (
                                    ""
                                )}
                            </td>
                            <td>{individualTask?.task_unique_id}</td>
                            <td>{individualTask.name || "--"}</td>
                            <td>{individualTask.task_type === "1" ? "Individual" : individualTask.task_type === "2" ? "Business" : "Self"}</td>
                            <td>
                                {Array.isArray(individualTask.assignees) && individualTask.assignees.length > 0 ? (
                                    <div className="d-flex align-items-center justify-content-start w_max">
                                        <img
                                            src={require("../../../assets/images/user-pic.png")}
                                            alt="Assignee"
                                            style={{ width: "30px", height: "30px", borderRadius: "50%" }}
                                        />
                                        <label className="w-fit ms-2">{individualTask.assignees[0]?.first_name || "--"}</label>
                                        {individualTask.assignees.length > 1 && (
                                            <div
                                                className="allname rounded-circle ms-2 d-flex justify-content-center align-items-center position-relative"
                                                style={{
                                                    width: "24px",
                                                    height: "24px",
                                                    backgroundColor: "#004fc5",
                                                    color: "#fff",
                                                    fontSize: "12px",
                                                    fontWeight: "bold",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                +{individualTask.assignees.length - 1}
                                                <div
                                                    className="tooltip-box"
                                                    style={{
                                                        position: "absolute",
                                                        top: "30px",
                                                        left: "0",
                                                        width: "fit-content",
                                                        backgroundColor: "#333",
                                                        color: "#fff",
                                                        padding: "10px",
                                                        borderRadius: "4px",
                                                        fontSize: "12px",
                                                        whiteSpace: "normal",
                                                        zIndex: 10,
                                                        maxHeight: "fit-content",
                                                        overflowY: "auto",
                                                    }}
                                                >
                                                    {individualTask.assignees
                                                        .slice(1)
                                                        .map((assignee) => `${assignee.first_name} ${assignee.last_name}`)
                                                        .join(", ")}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <span>--</span> // Show `--` when no assignees or empty array
                                )}
                            </td>

                            <td>
                                {individualTask?.task_type ? (
                                    <div className="d-flex align-items-center">
                                        <img src={require("../../../assets/images/user-pic.png")} alt="User Picture" />
                                        <label className="d-block ms-2">
                                            {individualTask?.task_type === "3" || individualTask?.task_type === "2"
                                                ? individualTask?.client_self?.name
                                                : `${individualTask?.client?.first_name} ${individualTask?.client?.last_name}`}
                                        </label>
                                    </div>
                                ) : (
                                    <span>--</span> // Display `--` when task_type is not available
                                )}
                            </td>

                            <td>
                                {individualTask?.created_at
                                    ? moment(individualTask.created_at).format("DD MMM, YYYY")
                                    : "--"}
                            </td>
                            <td>
                                {individualTask?.end_date
                                    ? moment(individualTask.end_date).format("DD MMM, YYYY")
                                    : "--"}
                            </td>
                            <td>
                                {individualTask.priority && (
                                    <span
                                        style={{
                                            backgroundColor: convertHexToRGBA(individualTask.priority.color_code, 0.2),
                                            padding: "3px 10px",
                                            borderRadius: "8px",
                                            textAlign: "center",
                                            width: "fit-content",
                                            fontSize: "14px",
                                            color: individualTask.priority.color_code,
                                        }}
                                    >
                                        {individualTask.priority.name}
                                    </span>
                                )}
                            </td>
                            {/* <td className="dropposition">
                                <div
                                    className="statusbtn-new"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        toggleStatusDropdown(individualTask.id);
                                    }}
                                >
                                    <label>{getSelectedStatus(individualTask.id)} </label>
                                    <svg className={`${isDropdownOpen(individualTask.id) ? "" : "rot"}`} xmlns="http://www.w3.org/2000/svg" width="9.447" height="7.875" viewBox="0 0 9.447 7.875">
                                        <path id="drop_down" d="M1661.834,1172.27c-.047.039-.244.213-.4.37a16.943,16.943,0,0,0-3.165,4.566,3.44,3.44,0,0,0-.268.921,1.452,1.452,0,0,0,.173.708,1.417,1.417,0,0,0,.708.63,6.241,6.241,0,0,0,.842.2,19.028,19.028,0,0,0,2.992.189,22.385,22.385,0,0,0,2.905-.165,6.826,6.826,0,0,0,1.047-.268,1.475,1.475,0,0,0,.567-.512,1.415,1.415,0,0,0,.213-.732v-.047a4.415,4.415,0,0,0-.323-1.039,16.914,16.914,0,0,0-3.125-4.456,4.013,4.013,0,0,0-.433-.386,1.443,1.443,0,0,0-.834-.268,1.475,1.475,0,0,0-.9.291Z" transform="translate(-1658 -1171.987)" fill="#212529" />
                                    </svg>
                                </div>
                                <div className={`st-dropdown mainshadow p-2 rounded-md bg-white ${isDropdownOpen(individualTask.id) ? "show" : ""}`} onClick={(e) => e.stopPropagation()}>
                                    <ul className="p-0">
                                        {filteredOptionsStatus.map((option) => (
                                            <li
                                                key={option.id}
                                                className="fs-14"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleStatusSelect(option, individualTask.id);
                                                }}
                                            >
                                                {option.name}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </td> */}
                            <td className="dropposition status-field">
                                <div
                                    className="statusbtn-new"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        toggleStatusDropdown(individualTask.id);
                                    }}
                                >
                                    <label>{getSelectedStatus(individualTask.id)} </label>
                                    <svg
                                        className={`${isDropdownOpen(individualTask.id) ? "" : "rot"}`}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="9.447"
                                        height="7.875"
                                        viewBox="0 0 9.447 7.875"
                                    >
                                        <path
                                            id="drop_down"
                                            d="M1661.834,1172.27c-.047.039-.244.213-.4.37a16.943,16.943,0,0,0-3.165,4.566,3.44,3.44,0,0,0-.268.921,1.452,1.452,0,0,0,.173.708,1.417,1.417,0,0,0,.708.63,6.241,6.241,0,0,0,.842.2,19.028,19.028,0,0,0,2.992.189,22.385,22.385,0,0,0,2.905-.165,6.826,6.826,0,0,0,1.047-.268,1.475,1.475,0,0,0,.567-.512,1.415,1.415,0,0,0,.213-.732v-.047a4.415,4.415,0,0,0-.323-1.039,16.914,16.914,0,0,0-3.125-4.456,4.013,4.013,0,0,0-.433-.386,1.443,1.443,0,0,0-.834-.268,1.475,1.475,0,0,0-.9.291Z"
                                            transform="translate(-1658 -1171.987)"
                                            fill="#212529"
                                        />
                                    </svg>
                                </div>
                                <div
                                    className={`st-dropdown mainshadow rounded-md bg-white ${isDropdownOpen(individualTask.id) ? "show" : ""
                                        }`}
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <ul className="p-0">
                                        {filteredOptionsStatus.map((option) => (
                                            <li
                                                key={option.id}
                                                className="fs-14"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleStatusSelect(option, individualTask.id);
                                                }}
                                            >
                                                {option.name}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </td>

                        </tr>
                    )}
                </Draggable>

                {/* Recursively Render Subtasks if Parent Task is Expanded */}
                {isExpanded && hasSubtasks && individualTask.subtasks.map((subtask, subIndex) => <TaskRow key={subtask.id} individualTask={subtask} index={subIndex} level={level + 1} className={getSubtaskClassName(level + 1)} />)}
            </>
        );
    };



    const RecurringTaskRow = ({ recurringTask, task, index, level = 0, className }) => {
        if (!recurringTask || !task) {
            return null; // If recurringTask or task is undefined, skip rendering.
        }

        const hasSubtasks = task.subtasks && task.subtasks.length > 0;
        const isExpanded = expandedTasks.includes(task.id);

        // Helper function to get the selected status name for a task
        const getSelectedStatus = (taskId) => selectedStatuses[taskId]?.name || task.statustask?.name || "Select Status";

        return (
            <>
                <Draggable key={task.id} draggableId={task.id.toString()} index={index}>
                    {(provided) => (
                        <tr
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            // onClick={() => navigate(`/admin/client/individual_task/individual_recurring_task_detail/${task.id}`)}
                            onClick={() => {
                                if (Auth.innerPermission("task_view")) {
                                    navigate(`/admin/task/recurring_task_detail/${task.id}`);
                                } else {
                                    toast.error("You do not have permission to view this task.");
                                }
                            }}
                            className={className}

                        >
                            <td>
                                {hasSubtasks ? (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="9.263"
                                        viewBox="0 0 16 9.263"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleRowAppend(task.id);
                                        }}
                                        style={{
                                            transform: isExpanded ? "rotate(360deg)" : "rotate(270deg)",
                                            transition: "transform 0.3s ease",
                                            cursor: "pointer",
                                            pointerEvents: "auto", // Allow the click event
                                        }}
                                        {...provided.dragHandleProps}
                                    >
                                        <g id="arrow-right" transform="translate(16 -107.789) rotate(90)">
                                            <path id="Path_4085" data-name="Path 4085" d="M109.052,16a1.263,1.263,0,0,1-.893-2.156L114,8l-5.844-5.844A1.263,1.263,0,0,1,109.945.37l6.737,6.737a1.263,1.263,0,0,1,0,1.786l-6.737,6.737a1.256,1.256,0,0,1-.893.37Z" fill="#202529" />
                                        </g>
                                    </svg>
                                ) : (
                                    ""
                                )}
                            </td>
                            <td>{recurringTask?.reccuring_unique_id}</td>
                            <td>{task.name}</td>
                            <td>{recurringTask.reccuring_repeat === "1" ? "Weekly" : recurringTask.reccuring_repeat === "2" ? "Monthly" : recurringTask.reccuring_repeat === "3" ? "Half Yearly" : recurringTask.reccuring_repeat === "4" ? "Yearly" : recurringTask.reccuring_repeat === "5" ? "Daily" : recurringTask.reccuring_repeat === "6" ? "Quarterly" : ""}</td>
                            <td>{moment(recurringTask?.reccuring_start_date).format("DD MMM, YYYY") || "--"}</td>
                            <td>
                                {recurringTask?.reccuring_start_next_date && moment(recurringTask.reccuring_start_next_date).isValid()
                                    ? moment(recurringTask.reccuring_start_next_date).format("DD MMM, YYYY")
                                    : "--"}
                            </td>
                            <td>{recurringTask.reccuring_expire === "1" || recurringTask.reccuring_end_date === null ? "Never Expire" : recurringTask.reccuring_expire === "0" ? <span>{moment(recurringTask?.reccuring_end_date).format("DD MMM, YYYY")}</span> : ""}</td>
                            {/* <td>{recurringTask.reccuring_expire === "0" ? "Expire" : recurringTask.reccuring_expire === "1" ? "Never Expire" : "--"}</td> */}
                            <td>
                                <span
                                    className={{
                                        1: "active",
                                        2: "inactive",
                                        3: "stopped",
                                    }[recurringTask?.reccuring_status] || ""} // Default to an empty string if no match
                                >{recurringTask?.reccuring_status === "1" ? "active" : recurringTask?.reccuring_status === "2" ? "inactive" : recurringTask?.reccuring_status === "3" ? "Stopped" : ""}</span>

                            </td>
                        </tr>
                    )}
                </Draggable>

                {/* Recursively Render Subtasks if Parent Task is Expanded */}
                {isExpanded && hasSubtasks && task.subtasks.map((subtask, subIndex) => <RecurringTaskRow key={subtask.id} task={subtask} recurringTask={recurringTask} index={subIndex} level={level + 1} className={getSubtaskClassName(level + 1)} />)}
            </>
        );
    };

    // Helper function to determine  name based on level
    const getSubtaskClassName = (level) => {
        switch (level) {
            case 1:
                return "subtask-row"; // First level subtasks
            case 2:
                return "subtask-sub-row"; // Second level subtasks
            case 3:
                return "subtask-sub-sub-row"; // Third level subtasks
            default:
                return "subtask-sub-sub-row"; // Apply the deepest level styling or adjust as needed
        }
    };



    const templateOuterListData = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `templates-listing`,
                    isSecure: true,
                },
                (res) => {
                    setTemplateOuterList(res?.data);
                }
            );
        } catch (error) {
            console.error("Error fetching permission IDs", error);
        }
    };

    const handleOuterTemplateData = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior

        if (selectedTemplateId === "" || selectedTemplateId === "0") {
            toast.error("Please select a valid template.");
            return;
        }

        try {
            http(
                {
                    method: "GET",
                    url: `particulartemplate/${selectedTemplateId}`,
                    isSecure: true,
                },
                (res) => {
                    toast.success(res.message);
                    navigate("/admin/task/add_task", { state: { templateData: res?.data } });
                },
                (error) => {
                    console.error("Error updating status:", error);
                    toast.error("Failed to update status.");
                }
            );
        } catch (error) {
            console.error("Error fetching permission IDs", error);
        }
    };

    const templateRecurringListData = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `templates-outertask`,
                    isSecure: true,
                },
                (res) => {
                    setRecurringTemplateList(res?.data);
                }
            );
        } catch (error) {
            console.error("Error fetching permission IDs", error);
        }
    };

    const handleRecurringTemplateData = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior

        if (recurringSelectedTemplateId === "" || recurringSelectedTemplateId === "0") {
            toast.error("Please select a valid template.");
            return;
        }

        try {
            http(
                {
                    method: "GET",
                    url: `particulartemplate/${recurringSelectedTemplateId}`,
                    isSecure: true,
                },
                (res) => {
                    toast.success(res.message);
                    navigate("/admin/task/add_recurring_task", { state: { templateData: res?.data } });
                },
                (error) => {
                    console.error("Error updating status:", error);
                    toast.error("Failed to update status.");
                }
            );
        } catch (error) {
            console.error("Error fetching permission IDs", error);
        }
    };

    const handleOuterTemplateModal = () => {
        setOpenOuterTemplateModal(false);
    };
    const handleOpenOuterTemplateModal = () => {
        setOpenOuterTemplateModal(true);
        templateOuterListData();
    };

    const handleRecurringOpenModal = () => {
        setOpenRecurringTemplateModal(true);
        templateRecurringListData();
    };

    const handleRecurringTemplateModal = () => {
        setOpenRecurringTemplateModal(false);
    };

    return (
        <div className="right_section font-poppins">
            <div className="clients-detail-area py-3">
                <div className="tasks-area mt-2 align-items-center">
                    <div className="row tabs-content">
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="individual">
                                <div className="row">
                                    <div className="col-md-12 right_head d-flex align-items-center flex-wrap justify-content-start gap-2 p-0">
                                        <div className="col-md-12 client-tabs">
                                            <ul className="nav nav-tabs border-0">
                                                <li className="nav-item me-2 mt-2">
                                                    <button data-bs-toggle="tab" className={`nav-link ${activeTab === "tasks" ? "active" : ""}`} onClick={() => handleTabClick("tasks")}>
                                                        Tasks
                                                    </button>
                                                </li>
                                                <li className="nav-item me-2 mt-2">
                                                    <button data-bs-toggle="tab" className={`nav-link ${activeTab === "recurring" ? "active" : ""}`} onClick={() => handleTabClick("recurring")}>
                                                        Recurring Tasks
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="row align-items-center mt-2">
                                    <div className="col-md-6 p-0">
                                        <h4 className="fs-20 fw-semibold">{activeTab === "tasks" ? "Tasks" : "Recurring Tasks"}</h4>
                                    </div>
                                    <div className="col-md-6 right_head d-flex align-items-center flex-wrap justify-content-end gap-2 p-0 mt-4">
                                        {activeTab === "tasks" && (
                                            <div className="btn-trans">
                                                <button className="transbtn" onClick={handleOpenOuterTemplateModal}>
                                                    Create From Template
                                                </button>
                                            </div>
                                        )}
                                        {activeTab === "recurring" && (
                                            <div className="btn-trans">
                                                <button className="transbtn" onClick={handleRecurringOpenModal}>
                                                    Create From Template
                                                </button>
                                            </div>
                                        )}

                                        <div className="add-btn-icon">
                                            <button
                                                className="add-new-button"
                                                onClick={handleButtonClick}
                                                disabled={showLoader} // Disable button while loading
                                            >
                                                <i className="fa fa-plus me-2"></i>
                                                {activeTab === "tasks" ? (
                                                    <span>Add New Task</span>) : (
                                                    <span>Add New Recurring Task</span>
                                                )
                                                }
                                                {activeTab === "tasks" && (
                                                    <>
                                                        <span className="divider-line mx-2"></span>
                                                        <i className="fa fa-caret-down" onClick={handleDropdownSubTaskClick}></i>
                                                    </>
                                                )}
                                                {activeTab === "recurring" && (
                                                    <>
                                                        <span className="divider-line mx-2"></span>
                                                        <i className="fa fa-caret-down" onClick={handleDropdownSubTaskClick}></i>
                                                    </>
                                                )}
                                            </button>
                                            {showLoader && <div className="loader">Loading...</div>}
                                            {showDropdown && activeTab === "tasks" && (
                                                <div className="dropdown">
                                                    <div onClick={() => handleDropdownOptionClick("add_task")}>Add New Task</div>
                                                    <div onClick={() => handleDropdownOptionClick("add_subtask")}>Add Subtask</div>
                                                </div>
                                            )}
                                            {showDropdown && activeTab === "recurring" && (
                                                <div className="dropdown">
                                                    <div onClick={() => handleDropdownOptionClick("add_recurring_task")}>Add Task</div>
                                                    <div onClick={() => handleDropdownOptionClick("add_recurring_subtask")}>Add Subtask</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-md-12 d-flex items-start justify-content-end gap-3 mt-4 flex-wrap p-0">
                                        {activeTab === "recurring" && (
                                            <div className="drop_box">
                                                <div className="dropdown" onClick={handlRecurringeTaskTypeClick}>
                                                    <div className="dropdown__selected">
                                                        <label className="fs-14">
                                                            {/* Display "Type" if no type is selected; otherwise, show the selected type */}
                                                            {selectedTaskType === "All" ? "Type" : selectedTaskType === "All" ? "All" : selectedTaskType === "1" ? "Weekly" : selectedTaskType === "2" ? "Monthly" : selectedTaskType === "3" ? "Half Monthly" : selectedTaskType === "4" ? "Yearly" : selectedTaskType === "5" ? "Day" : "Type"}
                                                        </label>
                                                        <span className="dropdown__icon">
                                                            {openRecurringDropdown === "taskType" ? (
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="9.447" height="7.875" viewBox="0 0 9.447 7.875">
                                                                    <path id="drop_down" d="M1661.834,1172.27c-.047.039-.244.213-.4.37a16.943,16.943,0,0,0-3.165,4.566,3.44,3.44,0,0,0-.268.921,1.452,1.452,0,0,0,.173.708,1.417,1.417,0,0,0,.708.63,6.241,6.241,0,0,0,.842.2,19.028,19.028,0,0,0,2.992.189,22.385,22.385,0,0,0,2.905-.165,6.826,6.826,0,0,0,1.047-.268,1.475,1.475,0,0,0,.567-.512,1.415,1.415,0,0,0,.213-.732v-.047a4.415,4.415,0,0,0-.323-1.039,16.914,16.914,0,0,0-3.125-4.456,4.013,4.013,0,0,0-.433-.386,1.443,1.443,0,0,0-.834-.268,1.475,1.475,0,0,0-.9.291Z" transform="translate(-1658 -1171.987)" fill="#212529" />
                                                                </svg>
                                                            ) : (
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="9.447" height="7.875" viewBox="0 0 9.447 7.875">
                                                                    <path id="drop_down" d="M1661.834,1179.579c-.047-.039-.244-.213-.4-.37a16.943,16.943,0,0,1-3.165-4.566,3.44,3.44,0,0,1-.268-.921,1.452,1.452,0,0,1,.173-.708,1.417,1.417,0,0,1,.708-.63,6.241,6.241,0,0,1,.842-.2,19.028,19.028,0,0,1,2.992-.189,22.385,22.385,0,0,1,2.905.165,6.826,6.826,0,0,1,1.047.268,1.475,1.475,0,0,1,.567.512,1.415,1.415,0,0,1,.213.732v.047a4.415,4.415,0,0,1-.323,1.039,16.914,16.914,0,0,1-3.125,4.456,4.013,4.013,0,0,1-.433.386,1.443,1.443,0,0,1-.834.268,1.475,1.475,0,0,1-.9-.291Z" transform="translate(-1658 -1171.987)" fill="#212529" />
                                                                </svg>
                                                            )}
                                                        </span>
                                                    </div>
                                                    <div className={`dropdown__options ${openRecurringDropdown === "taskType" ? "dropdown__options--open" : ""}`}>
                                                        <div className="dropdown__option" onClick={() => handleTaskTypeOptionClick("All")}>
                                                            All
                                                        </div>
                                                        <div className="dropdown__option" onClick={() => handleTaskTypeOptionClick("1")}>
                                                            Weekly
                                                        </div>
                                                        <div className="dropdown__option" onClick={() => handleTaskTypeOptionClick("2")}>
                                                            Monthly
                                                        </div>
                                                        <div className="dropdown__option" onClick={() => handleTaskTypeOptionClick("3")}>
                                                            Half Monthly
                                                        </div>
                                                        <div className="dropdown__option" onClick={() => handleTaskTypeOptionClick("4")}>
                                                            Yearly
                                                        </div>
                                                        <div className="dropdown__option" onClick={() => handleTaskTypeOptionClick("5")}>
                                                            Day
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {activeTab === "tasks" && (

                                            <div className="drop_box">
                                                <div className="dropdown" onClick={handleTaskTypeClick}>
                                                    <div className="dropdown__selected">
                                                        <label className="fs-14">
                                                            {/* Display "Type" if no type is selected; otherwise, show the selected type */}
                                                            {selectedTaskType === "All" ? "Type" : selectedTaskType === "All" ? "All" : selectedTaskType === "1" ? "Individual" : selectedTaskType === "2" ? "Business" : selectedTaskType === "3" ? "Self" : "Type"}
                                                        </label>
                                                        <span className="dropdown__icon">
                                                            {openDropdown === "taskType" ? (
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="9.447" height="7.875" viewBox="0 0 9.447 7.875">
                                                                    <path id="drop_down" d="M1661.834,1172.27c-.047.039-.244.213-.4.37a16.943,16.943,0,0,0-3.165,4.566,3.44,3.44,0,0,0-.268.921,1.452,1.452,0,0,0,.173.708,1.417,1.417,0,0,0,.708.63,6.241,6.241,0,0,0,.842.2,19.028,19.028,0,0,0,2.992.189,22.385,22.385,0,0,0,2.905-.165,6.826,6.826,0,0,0,1.047-.268,1.475,1.475,0,0,0,.567-.512,1.415,1.415,0,0,0,.213-.732v-.047a4.415,4.415,0,0,0-.323-1.039,16.914,16.914,0,0,0-3.125-4.456,4.013,4.013,0,0,0-.433-.386,1.443,1.443,0,0,0-.834-.268,1.475,1.475,0,0,0-.9.291Z" transform="translate(-1658 -1171.987)" fill="#212529" />
                                                                </svg>
                                                            ) : (
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="9.447" height="7.875" viewBox="0 0 9.447 7.875">
                                                                    <path id="drop_down" d="M1661.834,1179.579c-.047-.039-.244-.213-.4-.37a16.943,16.943,0,0,1-3.165-4.566,3.44,3.44,0,0,1-.268-.921,1.452,1.452,0,0,1,.173-.708,1.417,1.417,0,0,1,.708-.63,6.241,6.241,0,0,1,.842-.2,19.028,19.028,0,0,1,2.992-.189,22.385,22.385,0,0,1,2.905.165,6.826,6.826,0,0,1,1.047.268,1.475,1.475,0,0,1,.567.512,1.415,1.415,0,0,1,.213.732v.047a4.415,4.415,0,0,1-.323,1.039,16.914,16.914,0,0,1-3.125,4.456,4.013,4.013,0,0,1-.433.386,1.443,1.443,0,0,1-.834.268,1.475,1.475,0,0,1-.9-.291Z" transform="translate(-1658 -1171.987)" fill="#212529" />
                                                                </svg>
                                                            )}
                                                        </span>
                                                    </div>
                                                    <div className={`dropdown__options ${openDropdown === "taskType" ? "dropdown__options--open" : ""}`}>
                                                        <div className="dropdown__option" onClick={() => handleTaskTypeOptionClick("All")}>
                                                            All
                                                        </div>
                                                        <div className="dropdown__option" onClick={() => handleTaskTypeOptionClick("1")}>
                                                            Individual
                                                        </div>
                                                        <div className="dropdown__option" onClick={() => handleTaskTypeOptionClick("2")}>
                                                            Business
                                                        </div>
                                                        <div className="dropdown__option" onClick={() => handleTaskTypeOptionClick("3")}>
                                                            Self
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <div>
                                            {activeTab === "tasks" && (
                                                <div className="drop_box">
                                                    <div className="dropdown" onClick={handleAssigneeClick}>
                                                        <div className="dropdown__selected">
                                                            <label className="fs-14">Assignee</label>
                                                            <span className="dropdown__icon">
                                                                {openDropdown === "assignee" ? (
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="9.447" height="7.875" viewBox="0 0 9.447 7.875">
                                                                        <path id="drop_down" d="M1661.834,1172.27c-.047.039-.244.213-.4.37a16.943,16.943,0,0,0-3.165,4.566,3.44,3.44,0,0,0-.268.921,1.452,1.452,0,0,0,.173.708,1.417,1.417,0,0,0,.708.63,6.241,6.241,0,0,0,.842.2,19.028,19.028,0,0,0,2.992.189,22.385,22.385,0,0,0,2.905-.165,6.826,6.826,0,0,0,1.047-.268,1.475,1.475,0,0,0,.567-.512,1.415,1.415,0,0,0,.213-.732v-.047a4.415,4.415,0,0,0-.323-1.039,16.914,16.914,0,0,0-3.125-4.456,4.013,4.013,0,0,0-.433-.386,1.443,1.443,0,0,0-.834-.268,1.475,1.475,0,0,0-.9.291Z" transform="translate(-1658 -1171.987)" fill="#212529" />
                                                                    </svg>
                                                                ) : (
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="9.447" height="7.875" viewBox="0 0 9.447 7.875">
                                                                        <path id="drop_down" d="M1661.834,1179.579c-.047-.039-.244-.213-.4-.37a16.943,16.943,0,0,1-3.165-4.566,3.44,3.44,0,0,1-.268-.921,1.452,1.452,0,0,1,.173-.708,1.417,1.417,0,0,1,.708-.63,6.241,6.241,0,0,1,.842-.2,19.028,19.028,0,0,1,2.992-.189,22.385,22.385,0,0,1,2.905.165,6.826,6.826,0,0,1,1.047.268,1.475,1.475,0,0,1,.567.512,1.415,1.415,0,0,1,.213.732v.047a4.415,4.415,0,0,1-.323,1.039,16.914,16.914,0,0,1-3.125,4.456,4.013,4.013,0,0,1-.433.386,1.443,1.443,0,0,1-.834.268,1.475,1.475,0,0,1-.9-.291Z" transform="translate(-1658 -1171.987)" fill="#212529" />
                                                                    </svg>
                                                                )}
                                                            </span>
                                                        </div>
                                                        <div className={`dropdown__options ${openDropdown === "assignee" ? "dropdown__options--open" : ""}`} onClick={stopPropagation}>
                                                            <input type="text" value={assigneeSearchInput} onChange={(e) => setAssigneeSearchInput(e.target.value)} onMouseDown={stopPropagation} placeholder="Search" />
                                                            <div key="all-assignee" className="dropdown__option" onClick={() => handleAssigneeOptionClick("All")}>
                                                                All
                                                            </div>
                                                            {filteredAssignees.map((assignee) => (
                                                                <div key={assignee} className="dropdown__option" onClick={() => handleAssigneeOptionClick(assignee)}>
                                                                    {assignee}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            {activeTab === "recurring" && (
                                                <div className="drop_box datepick">
                                                    <div className="date-picker-wrapper">
                                                        <DatePicker className="dropdown fs-14 date-picker-input" selected={startDateRecurring} onChange={(date) => setStartDateRecurring(date)} isClearable={true} placeholderText="Start Date" />
                                                        {/* Conditionally render the calendar icon based on whether a date is selected */}
                                                        {!startDateRecurring && (
                                                            <span className="calendar-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calendar" viewBox="0 0 16 16">
                                                                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1zm1-1h12a1 1 0 0 1 1 1v1H1V4a1 1 0 0 1 1-1zm3.5 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zM3 8a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 3 8z" />
                                                                </svg>
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div>
                                            {activeTab === "tasks" && (
                                                <div className="drop_box">
                                                    <div className="dropdown" onClick={handleNameClick}>
                                                        <div className="dropdown__selected">
                                                            <label>Created by</label>
                                                            <span className="dropdown__icon">
                                                                {openDropdown === "createdBy" ? (
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="9.447" height="7.875" viewBox="0 0 9.447 7.875">
                                                                        <path id="drop_down" d="M1661.834,1172.27c-.047.039-.244.213-.4.37a16.943,16.943,0,0,0-3.165,4.566,3.44,3.44,0,0,0-.268.921,1.452,1.452,0,0,0,.173.708,1.417,1.417,0,0,0,.708.63,6.241,6.241,0,0,0,.842.2,19.028,19.028,0,0,0,2.992.189,22.385,22.385,0,0,0,2.905-.165,6.826,6.826,0,0,0,1.047-.268,1.475,1.475,0,0,0,.567-.512,1.415,1.415,0,0,0,.213-.732v-.047a4.415,4.415,0,0,0-.323-1.039,16.914,16.914,0,0,0-3.125-4.456,4.013,4.013,0,0,0-.433-.386,1.443,1.443,0,0,0-.834-.268,1.475,1.475,0,0,0-.9.291Z" transform="translate(-1658 -1171.987)" fill="#212529" />
                                                                    </svg>
                                                                ) : (
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="9.447" height="7.875" viewBox="0 0 9.447 7.875">
                                                                        <path id="drop_down" d="M1661.834,1179.579c-.047-.039-.244-.213-.4-.37a16.943,16.943,0,0,1-3.165-4.566,3.44,3.44,0,0,1-.268-.921,1.452,1.452,0,0,1,.173-.708,1.417,1.417,0,0,1,.708-.63,6.241,6.241,0,0,1,.842-.2,19.028,19.028,0,0,1,2.992-.189,22.385,22.385,0,0,1,2.905.165,6.826,6.826,0,0,1,1.047.268,1.475,1.475,0,0,1,.567.512,1.415,1.415,0,0,1,.213.732v.047a4.415,4.415,0,0,1-.323,1.039,16.914,16.914,0,0,1-3.125,4.456,4.013,4.013,0,0,1-.433.386,1.443,1.443,0,0,1-.834.268,1.475,1.475,0,0,1-.9-.291Z" transform="translate(-1658 -1171.987)" fill="#212529" />
                                                                    </svg>
                                                                )}
                                                            </span>
                                                        </div>
                                                        <div className={`dropdown__options ${openDropdown === "createdBy" ? "dropdown__options--open" : ""}`} onClick={stopPropagation}>
                                                            <input type="text" value={searchInput} onChange={(e) => setSearchInput(e.target.value)} onMouseDown={stopPropagation} placeholder="Search" />
                                                            <div key="all-name" className="dropdown__option" onClick={() => handleNameOptionClick("All")}>
                                                                All
                                                            </div>
                                                            {filteredNames.map((name) => (
                                                                <div key={name} className="dropdown__option" onClick={() => handleNameOptionClick(name)}>
                                                                    {name}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {activeTab === "recurring" && (
                                                <div className="drop_box datepick">
                                                    <div className="date-picker-wrapper">
                                                        <DatePicker className="dropdown fs-14 date-picker-input" selected={nextMonthDateRecurring} onChange={(date) => setNextMonthDateRecurring(date)} isClearable={true} placeholderText="Next Month Date" />
                                                        {/* Conditionally render the calendar icon based on whether a date is selected */}
                                                        {!nextMonthDateRecurring && (
                                                            <span className="calendar-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calendar" viewBox="0 0 16 16">
                                                                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1zm1-1h12a1 1 0 0 1 1 1v1H1V4a1 1 0 0 1 1-1zm3.5 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zM3 8a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 3 8z" />
                                                                </svg>
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div>
                                            {activeTab === "tasks" && (
                                                <div className="drop_box datepick">
                                                    <DatePicker
                                                        className="dropdown fs-14"
                                                        selectsRange
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        onChange={(update) => {
                                                            setStartDate(update[0]);
                                                            setEndDate(update[1]);
                                                        }}
                                                        isClearable={true}
                                                        placeholderText="Select a date range"
                                                    />
                                                </div>
                                            )}
                                            {activeTab === "recurring" && (
                                                <div className="drop_box datepick">
                                                    <div className="date-picker-wrapper">
                                                        <DatePicker className="dropdown fs-14 date-picker-input" selected={lastMonthDateRecurring} onChange={(date) => setLastMonthDateRecurring(date)} isClearable={true} placeholderText="End Month Date" />
                                                        {/* Conditionally render the calendar icon based on whether a date is selected */}
                                                        {!lastMonthDateRecurring && (
                                                            <span className="calendar-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calendar" viewBox="0 0 16 16">
                                                                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1zm1-1h12a1 1 0 0 1 1 1v1H1V4a1 1 0 0 1 1-1zm3.5 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zM3 8a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 3 8z" />
                                                                </svg>
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        {
                                            activeTab === "tasks" ? (
                                                <div className="drop_box">
                                                    <div className="dropdown" onClick={handlePriorityClick}>
                                                        <div className="dropdown__selected">
                                                            <label className="fs-14">{selectedPriority === "All" ? "Priority" : priorityList.find((option) => option.id == selectedPriority)?.name || "Priority"}</label>
                                                            <span className="dropdown__icon">
                                                                {openDropdown === "priority" ? (
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="9.447" height="7.875" viewBox="0 0 9.447 7.875">
                                                                        <path id="drop_down" d="M1661.834,1172.27c-.047.039-.244.213-.4.37a16.943,16.943,0,0,0-3.165,4.566,3.44,3.44,0,0,0-.268.921,1.452,1.452,0,0,0,.173.708,1.417,1.417,0,0,0,.708.63,6.241,6.241,0,0,0,.842.2,19.028,19.028,0,0,0,2.992.189,22.385,22.385,0,0,0,2.905-.165,6.826,6.826,0,0,0,1.047-.268,1.475,1.475,0,0,0,.567-.512,1.415,1.415,0,0,0,.213-.732v-.047a4.415,4.415,0,0,0-.323-1.039,16.914,16.914,0,0,0-3.125-4.456,4.013,4.013,0,0,0-.433-.386,1.443,1.443,0,0,0-.834-.268,1.475,1.475,0,0,0-.9.291Z" transform="translate(-1658 -1171.987)" fill="#212529" />
                                                                    </svg>
                                                                ) : (
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="9.447" height="7.875" viewBox="0 0 9.447 7.875">
                                                                        <path id="drop_down" d="M1661.834,1179.579c-.047-.039-.244-.213-.4-.37a16.943,16.943,0,0,1-3.165-4.566,3.44,3.44,0,0,1-.268-.921,1.452,1.452,0,0,1,.173-.708,1.417,1.417,0,0,1,.708-.63,6.241,6.241,0,0,1,.842-.2,19.028,19.028,0,0,1,2.992-.189,22.385,22.385,0,0,1,2.905.165,6.826,6.826,0,0,1,1.047.268,1.475,1.475,0,0,1,.567.512,1.415,1.415,0,0,1,.213.732v.047a4.415,4.415,0,0,1-.323,1.039,16.914,16.914,0,0,1-3.125,4.456,4.013,4.013,0,0,1-.433.386,1.443,1.443,0,0,1-.834.268,1.475,1.475,0,0,1-.9-.291Z" transform="translate(-1658 -1171.987)" fill="#212529" />
                                                                    </svg>
                                                                )}
                                                            </span>
                                                        </div>
                                                        <div className={`dropdown__options ${openDropdown === "priority" ? "dropdown__options--open" : ""}`}>
                                                            <div key="all-priority" className="dropdown__option" onClick={() => handlePriorityOptionClick("All")}>
                                                                All
                                                            </div>
                                                            {priorityList && priorityList.map((option) => (
                                                                <div key={option.id} className="dropdown__option" onClick={() => handlePriorityOptionClick(option.id)}>
                                                                    {option.name}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null // Show nothing when activeTab is "recurring" or anything else
                                        }

                                        {activeTab === "tasks" && (
                                            <div className="drop_box">
                                                <div className="dropdown" onClick={handleStatusClick}>
                                                    <div className="dropdown__selected">
                                                        <label className="fs-14">{selectedStatus === "All" ? "Status" : StatusList.find((option) => option.id == selectedStatus)?.name || "Status"}</label>
                                                        <span className="dropdown__icon">
                                                            {openDropdown === "status" ? (
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="9.447" height="7.875" viewBox="0 0 9.447 7.875">
                                                                    <path id="drop_down" d="M1661.834,1172.27c-.047.039-.244.213-.4.37a16.943,16.943,0,0,0-3.165,4.566,3.44,3.44,0,0,0-.268.921,1.452,1.452,0,0,0,.173.708,1.417,1.417,0,0,0,.708.63,6.241,6.241,0,0,0,.842.2,19.028,19.028,0,0,0,2.992.189,22.385,22.385,0,0,0,2.905-.165,6.826,6.826,0,0,0,1.047-.268,1.475,1.475,0,0,0,.567-.512,1.415,1.415,0,0,0,.213-.732v-.047a4.415,4.415,0,0,0-.323-1.039,16.914,16.914,0,0,0-3.125-4.456,4.013,4.013,0,0,0-.433-.386,1.443,1.443,0,0,0-.834-.268,1.475,1.475,0,0,0-.9.291Z" transform="translate(-1658 -1171.987)" fill="#212529" />
                                                                </svg>
                                                            ) : (
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="9.447" height="7.875" viewBox="0 0 9.447 7.875">
                                                                    <path id="drop_down" d="M1661.834,1179.579c-.047-.039-.244-.213-.4-.37a16.943,16.943,0,0,1-3.165-4.566,3.44,3.44,0,0,1-.268-.921,1.452,1.452,0,0,1,.173-.708,1.417,1.417,0,0,1,.708-.63,6.241,6.241,0,0,1,.842-.2,19.028,19.028,0,0,1,2.992-.189,22.385,22.385,0,0,1,2.905.165,6.826,6.826,0,0,1,1.047.268,1.475,1.475,0,0,1,.567.512,1.415,1.415,0,0,1,.213.732v.047a4.415,4.415,0,0,1-.323,1.039,16.914,16.914,0,0,1-3.125,4.456,4.013,4.013,0,0,1-.433.386,1.443,1.443,0,0,1-.834.268,1.475,1.475,0,0,1-.9-.291Z" transform="translate(-1658 -1171.987)" fill="#212529" />
                                                                </svg>
                                                            )}
                                                        </span>
                                                    </div>
                                                    <div className={`dropdown__options ${openDropdown === "status" ? "dropdown__options--open" : ""}`}>
                                                        <div key="all-status" className="dropdown__option" onClick={() => handleStatusOptionClick("All")}>
                                                            All
                                                        </div>
                                                        {StatusList && StatusList.map((option) => (
                                                            <div key={option.id} className="dropdown__option" onClick={() => handleStatusOptionClick(option.id)}>
                                                                {option.name}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {activeTab === "recurring" && (
                                            <div className="drop_box">
                                                <div className="dropdown" onClick={handleStatusRecurringClick}>
                                                    <div className="dropdown__selected">
                                                        <label className="fs-14">
                                                            {/* Display "Type" if no type is selected; otherwise, show the selected type */}
                                                            {selectedStatusRecurringType === "All" ? "Status" : selectedStatusRecurringType === "All" ? "All" : selectedStatusRecurringType === "1" ? "Active" : selectedStatusRecurringType === "2" ? "InActive" : selectedStatusRecurringType === "3" ? "Stopped" : "Status"}
                                                        </label>
                                                        <span className="dropdown__icon">
                                                            {openRecurringDropdown === "status" ? (
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="9.447" height="7.875" viewBox="0 0 9.447 7.875">
                                                                    <path id="drop_down" d="M1661.834,1172.27c-.047.039-.244.213-.4.37a16.943,16.943,0,0,0-3.165,4.566,3.44,3.44,0,0,0-.268.921,1.452,1.452,0,0,0,.173.708,1.417,1.417,0,0,0,.708.63,6.241,6.241,0,0,0,.842.2,19.028,19.028,0,0,0,2.992.189,22.385,22.385,0,0,0,2.905-.165,6.826,6.826,0,0,0,1.047-.268,1.475,1.475,0,0,0,.567-.512,1.415,1.415,0,0,0,.213-.732v-.047a4.415,4.415,0,0,0-.323-1.039,16.914,16.914,0,0,0-3.125-4.456,4.013,4.013,0,0,0-.433-.386,1.443,1.443,0,0,0-.834-.268,1.475,1.475,0,0,0-.9.291Z" transform="translate(-1658 -1171.987)" fill="#212529" />
                                                                </svg>
                                                            ) : (
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="9.447" height="7.875" viewBox="0 0 9.447 7.875">
                                                                    <path id="drop_down" d="M1661.834,1179.579c-.047-.039-.244-.213-.4-.37a16.943,16.943,0,0,1-3.165-4.566,3.44,3.44,0,0,1-.268-.921,1.452,1.452,0,0,1,.173-.708,1.417,1.417,0,0,1,.708-.63,6.241,6.241,0,0,1,.842-.2,19.028,19.028,0,0,1,2.992-.189,22.385,22.385,0,0,1,2.905.165,6.826,6.826,0,0,1,1.047.268,1.475,1.475,0,0,1,.567.512,1.415,1.415,0,0,1,.213.732v.047a4.415,4.415,0,0,1-.323,1.039,16.914,16.914,0,0,1-3.125,4.456,4.013,4.013,0,0,1-.433.386,1.443,1.443,0,0,1-.834.268,1.475,1.475,0,0,1-.9-.291Z" transform="translate(-1658 -1171.987)" fill="#212529" />
                                                                </svg>
                                                            )}
                                                        </span>
                                                    </div>
                                                    <div c className={`dropdown__options ${openRecurringDropdown === "status" ? "dropdown__options--open" : ""}`}>
                                                        <div className="dropdown__option" onClick={() => handleStatusRecurringOptionClick("All")}>
                                                            All
                                                        </div>
                                                        <div className="dropdown__option" onClick={() => handleStatusRecurringOptionClick("1")}>
                                                            Active
                                                        </div>
                                                        <div className="dropdown__option" onClick={() => handleStatusRecurringOptionClick("2")}>
                                                            InActive
                                                        </div>
                                                        <div className="dropdown__option" onClick={() => handleStatusRecurringOptionClick("3")}>
                                                            Stopped
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    {activeTab === "tasks" && (
                                        Auth.innerPermission("task_list") ? (
                                            <>
                                                <DragDropContext onDragEnd={handleDragEnd}>
                                                    <Droppable droppableId="recurringTaskList">
                                                        {(provided) => (
                                                            <div
                                                                className="col-md-12 table_part mt-2 p-0"
                                                                ref={provided.innerRef}
                                                                {...provided.droppableProps}
                                                            >
                                                                <div className="table-responsive subtask-table">
                                                                    <table className="w-100">
                                                                        <thead className="pb-2">
                                                                            <tr>
                                                                                {/* <th className="fs-14 t_blue ps-0">Select</th> */}
                                                                                <th></th>
                                                                                <th>#Task No</th>
                                                                                <th>Task</th>
                                                                                <th>Type</th>
                                                                                <th>Assign To</th>
                                                                                <th>Created By</th>
                                                                                <th>Created On</th>
                                                                                <th>End Date</th>
                                                                                <th>Priority</th>
                                                                                <th>Status</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {filteredTasks &&
                                                                                filteredTasks.map((individualTaskOuter, index) => (
                                                                                    <React.Fragment key={individualTaskOuter.id}>
                                                                                        <TaskRow
                                                                                            individualTask={individualTaskOuter}
                                                                                            index={index}
                                                                                        />
                                                                                    </React.Fragment>
                                                                                ))}
                                                                            {provided.placeholder}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext>

                                                <ReactPaginate
                                                    previousLabel="< Previous"
                                                    nextLabel="Next >"
                                                    breakLabel="..."
                                                    pageCount={totalPages}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={3}
                                                    onPageChange={handlePageClick}
                                                    containerClassName="pagination justify-content-end align-items-center mt-2 pe-0"
                                                    pageClassName="page-item nowrap"
                                                    pageLinkClassName="page-link"
                                                    previousClassName="page-item"
                                                    previousLinkClassName="page-link"
                                                    nextClassName="page-item"
                                                    nextLinkClassName="page-link"
                                                    breakClassName="page-item"
                                                    breakLinkClassName="page-link"
                                                    activeClassName="active"
                                                />
                                            </>
                                        ) : (
                                            <div>
                                                <h6 className="fs-14 text-danger text-center fw-semibold text-capitalize mt-3">
                                                    You do not have permission to access this module.
                                                </h6>
                                            </div>
                                        )
                                    )}

                                    {activeTab === "recurring" && (
                                        Auth.innerPermission("task_list") ? (

                                            <>
                                                <DragDropContext onDragEnd={handleDragEndRecurring}>
                                                    <Droppable droppableId="recurringTaskList">
                                                        {(provided) => (
                                                            <div className="col-md-12 table_part mt-2 p-0" ref={provided.innerRef} {...provided.droppableProps}>
                                                                <div className="table-responsive">
                                                                    <table className="w-100">
                                                                        <thead className="pb-2">
                                                                            <tr>
                                                                                {/* <th className="fs-14 t_blue ps-0">Select</th> */}
                                                                                <th></th>
                                                                                <th>#RTask No</th>
                                                                                <th>Task</th>
                                                                                <th>Type</th>
                                                                                <th>Started On</th>
                                                                                <th>Next Recurring Date</th>
                                                                                <th>Expires On</th>
                                                                                {/* <th>Expires On</th> */}
                                                                                <th>Status</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {Array.isArray(filteredRecurringTasks) &&
                                                                                filteredRecurringTasks.map((recurringTask, index) => {
                                                                                    const task = recurringTask.tasks && recurringTask.tasks[0];
                                                                                    if (!task) return null; // Skip if no tasks exist

                                                                                    return (
                                                                                        <React.Fragment key={recurringTask.id}>
                                                                                            <RecurringTaskRow recurringTask={recurringTask} task={task} index={index} />
                                                                                        </React.Fragment>
                                                                                    );
                                                                                })}
                                                                            {provided.placeholder}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext>
                                                <ReactPaginate previousLabel="< Previous" nextLabel="Next >" breakLabel="..." pageCount={totalRecurringPages} marginPagesDisplayed={2} pageRangeDisplayed={3} onPageChange={handleRecurringPageClick} containerClassName="pagination justify-content-end align-items-center mt-2 pe-0" pageClassName="page-item nowrap" pageLinkClassName="page-link" previousClassName="page-item" previousLinkClassName="page-link" nextClassName="page-item" nextLinkClassName="page-link" breakClassName="page-item" breakLinkClassName="page-link" activeClassName="active" />
                                            </>
                                        ) : (
                                            <div>
                                                <h6 className="fs-14 text-danger text-center fw-semibold text-capitalize mt-3">
                                                    You do not have permission to access this module.
                                                </h6>
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                show={showSubTask}
                centered
                className="modal operating-modal"
                backdrop="static"
                keyboard={false}
                onHide={closeModal} // Handle closing the modal
            >
                <div className="modal-header border-0 justify-content-between">
                    <h4 className="fs-20">Add New Subtask</h4>
                    <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
                </div>
                <div className="modal-body mt-2">
                    <form onSubmit={(e) => e.preventDefault()}>
                        <div className="row">
                            <div className="col-lg-12 custom-select-container w-100">
                                <label htmlFor="">Select Task</label>

                                <div className="custom-select-display mt-2 form-input-select" onClick={toggleDropdown}>
                                    {selectedOption ? selectedOption.name : "Select an option"} {/* Show selected option or default text */}
                                </div>
                                {isOpen && (
                                    <div className="custom-select-dropdown">
                                        <div className="srchinp position-relative">
                                            <input
                                                type="text"
                                                value={searchTerm}
                                                onChange={handleSearchChange} // Handle search input change
                                                className="custom-select-search form-input bg-transparent w-100 ps-5"
                                            />
                                            <i className="fa fa-search"></i>
                                        </div>
                                        <div className="custom-select-options">
                                            {filteredOptions.length > 0 ? (
                                                filteredOptions.map((option) => (
                                                    <div
                                                        key={option.id} // Use option.id as the unique key
                                                        className="custom-select-option"
                                                        onClick={() => handleOptionClick(option)} // Handle option selection
                                                    >
                                                        {option.name} {/* Display option name */}
                                                    </div>
                                                ))
                                            ) : (
                                                <div className="custom-select-no-options">No options found</div> // Message when no options match
                                            )}
                                        </div>
                                    </div>
                                )}
                                {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
                            </div>
                        </div>
                    </form>
                </div>
                <div className="modal-footer border-0 mt-4">
                    <button type="submit" className="btn-blue m-0" onClick={handleFormSubmit}>
                        Add
                    </button>
                </div>
            </Modal>

            <Modal
                show={showRecurringSubTask}
                centered
                className="modal operating-modal"
                backdrop="static"
                keyboard={false}
                onHide={closeRecurringSubTaskModal} // Handle closing the modal
            >
                <div className="modal-dialog modal-dialog-centered m-0">
                    <div className="modal-content font-poppins p-3 h_500">
                        <div className="modal-header border-0 justify-content-between p-0">
                            <h4 className="fs-20">Add New Recurring Subtask</h4>
                            <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
                        </div>
                        <div className="modal-body p-0 mt-2">
                            <form onSubmit={(e) => e.preventDefault()}>
                                <div className="custom-select-container w-100">
                                    <label htmlFor="">Select Task</label>

                                    <div className="custom-select-display mt-2 form-input-select" onClick={toggleRecurringDropdown}>
                                        {selectedRecurringOption ? selectedRecurringOption.name : "Select an option"}
                                    </div>
                                    {isOpenRecurring && (
                                        <div className="custom-select-dropdown">
                                            <div className="srchinp position-relative">
                                                <input type="text" value={searchRecuriingTerm} onChange={handleRecurringSearchChange} className="ps-5 custom-select-search form-input bg-transparent w-100" />
                                                <i className="fa fa-search"></i>
                                            </div>
                                            <div className="custom-select-options">
                                                {filteredRecurringOptions.length > 0 ? (
                                                    filteredRecurringOptions.map((option) => (
                                                        <div key={option.id} className="custom-select-option" onClick={() => handleRecurringOptionClick(option)}>
                                                            {option.name}
                                                        </div>
                                                    ))
                                                ) : (
                                                    <div className="custom-select-no-options">No options found</div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    {errorRecurringMessage && <p style={{ color: "red" }}>{errorRecurringMessage}</p>}
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer border-0 p-0 mt-4">
                            <button type="submit" className="btn-blue m-0" onClick={handleRecurringFormSubmit}>
                                Add
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                show={openOuterTemplateModal}
                centered
                className="modal operating-modal"
                backdrop="static"
                keyboard={false}
                onHide={handleOuterTemplateModal} // Handle closing the modal
            >
                <div className="modal-header border-0 justify-content-between">
                    <h4 className="fs-20 fw-semibold">Add Template</h4>
                    <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
                </div>
                <div className="modal-body mt-4">
                    <form onSubmit={handleOuterTemplateData}>
                        <div className="custom-select-container w-100 row">
                            <div className="col-md-12">
                                <label>Select Template</label>
                                <select
                                    className="form-input mt-1 px-3 bg-white"
                                    value={selectedTemplateId} // Bind selected value to state
                                    onChange={handleTemplateChange} // Handle change
                                >
                                    <option value="0">Select</option>
                                    {/* Dynamically map through templateList and display options */}
                                    {templateOuterList.map((template) => (
                                        <option key={template.id} value={template.id}>
                                            {template.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 mt-4 mb-2">
                                <button type="submit" className="btn-blue ms-auto px-4">
                                    Add
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
            <Modal
                show={openRecurringTemplateModal}
                centered
                className="modal operating-modal"
                backdrop="static"
                keyboard={false}
                onHide={handleRecurringTemplateModal} // Handle closing the modal
            >
                <div className="modal-header border-0 justify-content-between">
                    <h4 className="fs-20 fw-semibold">Add Template</h4>
                    <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
                </div>
                <div className="modal-body p-0 mt-2">
                    <form onSubmit={handleRecurringTemplateData}>
                        <div className="row">
                            <div className="col-lg-12 custom-select-container w-100">
                                <label>Select Template</label>
                                <select
                                    className="form-input mt-1 px-3 bg-white"
                                    value={recurringSelectedTemplateId} // Bind selected value to state
                                    onChange={handleRecurringTemplateChange} // Handle change
                                >
                                    <option value="0">Select</option>
                                    {/* Dynamically map through templateList and display options */}
                                    {recurringTemplateList.map((template) => (
                                        <option key={template.id} value={template.id}>
                                            {template.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-lg-12 mt-5 mb-2">
                                <button type="submit" className="btn-blue ms-auto px-4">Add</button>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>

            {/* <Add_Individual_Task addTaskModal={addTaskModal} setAddTaskModal={setAddTaskModal} setPriorityList={setPriorityList} priorityList={priorityList} handlePriorityData={handlePriorityData} interactionList={interactionList} setInteractionList={setInteractionList} handleInteractionData={handleInteractionData} StatusList={StatusList} setStatusList={setStatusList} handleStatusData={handleStatusData} assignData={assignData} setAssignData={setAssignData} handleAssisgnData={handleAssisgnData} handleTaskList={handleTaskList} /> */}
        </div>
    );
};
export default Add_Main_Task;
