import React, { useState, useEffect, useCallback, useContext } from "react";
import Creatable from 'react-select/creatable';
import { http } from "../../http/http";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { GlobalContext } from "../../../App";

const Search_Individual_Client = () => {
    const navigate = useNavigate()
    const { setShowLoader } = useContext(GlobalContext);
    const [searchResults, setSearchResults] = useState([]); // List of search results
    const [selectedContact, setSelectedContact] = useState(null); // Selected contact data
    const [searchTerm, setSearchTerm] = useState(""); // Input value for search
    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [hasSearched, setHasSearched] = useState(false);
    const [errors, setErrors] = useState({ firstName: "", email: "", lastName: "" });

    // Fetch data from API based on search input
    const fetchSearchResults = (query) => {


        const queryParams = new URLSearchParams({ type: 1, add_client: 1 });

        // Check if the input is an email (basic validation)
        if (query.includes("@")) {
            queryParams.append("email", query); // Query by email
        } else {
            queryParams.append("first_name", query); // Query by first name

        }

        const url = `v2/contact_detail?${queryParams.toString()}`;

        http(
            {
                method: "GET",
                url,
                isSecure: true,
            },
            (res) => {
                if (res?.data?.data) {
                    setSearchResults(res.data.data);
                } else {
                    setSearchResults([]);
                }

            },
            (err) => {
                toast.error(err.single_message || "Failed to fetch results.");
                setShowLoader(false);
            }
        );
    };


    // Debounced search handler
    const debouncedSearch = useCallback(
        debounce((query) => {
            if (query.trim()) {
                fetchSearchResults(query);
            } else {
                setSearchResults([]);
            }
        }, 300),
        []
    );


    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearchTerm(query);

        // Reset 'hasSearched' when the user starts typing
        if (!query.trim()) {
            setHasSearched(false);
            setSearchResults([]);
        } else {
            setHasSearched(true);
            debouncedSearch(query);
        }
    };

    const handleSelectContact = (contact) => {
        setSelectedContact(contact); // Save selected contact
        setSearchTerm(contact.first_name || contact.email); // Set search input
        setFirstName(contact.first_name || "");
        setMiddleName(contact.middle_name || "");
        setLastName(contact.last_name || "");
        setEmail(contact.email || "");
        setSearchResults([]); // Clear search results
        // Clear validation errors for the fields populated by the selection
        setErrors((prevErrors) => ({
            ...prevErrors,
            firstName: "",
            lastName: "",
            email: "",
        }));
    };
    const handleCancelSearch = () => {
        setSearchTerm("");          // Clear the search input
        setSearchResults([]);       // Clear the search results
        setSelectedContact(null);   // Reset selected contact
        // Reset all form fields to empty
        setFirstName("");
        setMiddleName("");
        setLastName("");
        setEmail("");
        setHasSearched(false);
    };
    const handleFormSubmit = (e) => {
        e.preventDefault(); // Prevent default form submission
        if (!validateForm()) return;
        setShowLoader(true);
        const formData = {
            firstName,
            middleName,
            lastName,
            email,
        };

        setTimeout(() => { // Simulate an API call or async operation
            // Save selected contact to localStorage if it exists
            if (selectedContact?.id) {
                localStorage.setItem("selectedUser", JSON.stringify(selectedContact));
                toast.success("Contact saved successfully!"); // Toast message
                setShowLoader(false);
                navigate("/admin/client/add_individual");
            } else {
                // Save manually entered data to sessionStorage
                sessionStorage.setItem("manualEntry", JSON.stringify(formData));
                toast.success("Added saved successfully!"); // Toast message
                setShowLoader(false);
                navigate("/admin/client/individual_contact_detail");
            }

            setShowLoader(false); // Hide loader
        }, 200); // Simulate delay
    };


    const validateForm = () => {
        const newErrors = { firstName: "", email: "", lastName: "" };

        if (!firstName.trim()) {
            newErrors.firstName = "Required.";
        }
        if (!lastName.trim()) {
            newErrors.lastName = "Required.";
        }

        if (!email.trim()) {
            newErrors.email = "Required.";
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            newErrors.email = "Invalid email format.";
        }

        setErrors(newErrors);
        return Object.values(newErrors).every((err) => !err); // Return true if no errors
    };

    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value);
        if (errors.firstName) {
            setErrors((prevErrors) => ({ ...prevErrors, firstName: "" }));
        }
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        if (errors.email) {
            setErrors((prevErrors) => ({ ...prevErrors, email: "" }));
        }
    };
    const handleLastNameChange = (e) => {
        setLastName(e.target.value);
        if (errors.lastName) {
            setErrors((prevErrors) => ({ ...prevErrors, lastName: "" }));
        }
    };

    return (
        <div className="right_section font-poppins">
            <div className="business-form-area py-3">
                <div className="row business-head align-items-center">
                    <div className="col-md-10 offset-md-1 mx-auto left_head">
                        <h4 className="fs-20 mt-2 fw-semibold">Add Individual Client</h4>
                    </div>
                    <div className="col-md-10 offset-md-1 mx-auto">
                        <div className="main-form-area p-4 mt-3 bg-white shadow-lg">
                            <form onSubmit={handleFormSubmit}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <h6 className="fs-20 fw-semibold">Basic Details</h6>
                                    </div>
                                    <div className="col-md-5 dctor-search">
                                        <div className="position-relative mt-3">
                                            <input
                                                type="text"
                                                className="form-input dtr-srch"
                                                placeholder="Search for a name"
                                                value={searchTerm}
                                                onChange={handleSearchChange}
                                            />
                                            {/* <i className="fa fa-search"></i> */}
                                            <i className="fa fa-times" onClick={handleCancelSearch}></i>
                                            <ul className={`p-0 ${!searchTerm ? "bdr-none" : ""}`}>
                                                {hasSearched && searchResults.length === 0 ? (
                                                    <li className="text-center text-muted fs-16">
                                                        Data not found.
                                                    </li>
                                                ) : (
                                                    searchResults.map((contact) => (
                                                        <li
                                                            key={contact.id}
                                                            className="list-group-item list-group-item-action d-flex align-items-center w-auto search_dropbox"
                                                            onClick={() => handleSelectContact(contact)}
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <p className="fs-16 text-black">
                                                                {contact.first_name} {contact.last_name}
                                                            </p>
                                                            <p className="fs-16 text-black">{contact.email}</p>
                                                        </li>
                                                    ))
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="row form-group">
                                    <div className="col-md-4 mt-4">
                                        <label htmlFor="" className="w-100">First Name <span className="text-danger ms-1">*</span></label>
                                        <input
                                            type="text"
                                            className="form-input mt-2"
                                            placeholder="Enter first name"
                                            value={firstName}
                                            onChange={handleFirstNameChange}
                                        />
                                        {errors.firstName && (
                                            <p className="text-danger mt-1">{errors.firstName}</p>
                                        )}
                                    </div>
                                    <div className="col-md-4 mt-4">
                                        <label htmlFor="" className="w-100">Middle Name </label>
                                        <input
                                            type="text"
                                            className="form-input mt-2"
                                            placeholder="Enter middle name"
                                            value={middleName}
                                            onChange={(e) => setMiddleName(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-4 mt-4">
                                        <label htmlFor="" className="w-100">Last Name <span className="text-danger ms-1">*</span></label>
                                        <input
                                            type="text"
                                            className="form-input mt-2"
                                            placeholder="Enter last name"
                                            value={lastName}
                                            onChange={handleLastNameChange}
                                        />
                                        {errors.lastName && (
                                            <p className="text-danger mt-1">{errors.lastName}</p>
                                        )}
                                    </div>
                                    <div className="col-md-4 mt-4">
                                        <label htmlFor="" className="w-100">Email Address <span className="text-danger ms-1">*</span></label>
                                        <input
                                            type="email"
                                            className="form-input mt-2"
                                            placeholder="Enter email address"
                                            value={email}
                                            onChange={handleEmailChange}
                                        />
                                        {errors.email && (
                                            <p className="text-danger mt-1">{errors.email}</p>
                                        )}
                                    </div>


                                    <div className="col-md-12 individual-footer">
                                        <div className="btn-groups mt-4 mb-2">
                                            <button type="submit" className="add-new ms-2 fs-20 px-5 py-4">Next</button>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Search_Individual_Client;