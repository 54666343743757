import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { http } from "../../../http/http";
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import { SurveyCreator, SurveyCreatorComponent } from 'survey-creator-react';
import 'survey-core/defaultV2.min.css';
import 'survey-creator-core/survey-creator-core.min.css';
import { toast } from 'react-toastify';

const Edit_Servay_Form = () => {
    const { id } = useParams(); // Get the form ID from the URL
    const [surveyModel, setSurveyModel] = useState(null);
    const [creator, setCreator] = useState(null);

    useEffect(() => {
        const fetchFormData = async () => {
            try {
                http(
                    {
                        method: "GET",
                        url: `form/${id}/edit`,
                        isSecure: true,
                    },
                    (res) => {
                        if (res) {

                            const formData = res?.data?.data?.[0]; // Extract the form data


                            if (formData) {
                                try {
                                    const survey = new Model(formData); // Create the Survey model
                                    setSurveyModel(survey); // Set the survey model to state

                                    // Initialize Survey Creator 
                                    const surveyCreator = new SurveyCreator();
                                    surveyCreator.JSON = formData; // Load the form data into the creator
                                    setCreator(surveyCreator);
                                } catch (error) {
                                    console.error('Error creating Survey model:', error);
                                }
                            }

                        } else {
                            console.error('Error in API response:', res);
                        }
                    }
                );
            } catch (error) {
                console.error('Error fetching form data:', error);
            }
        };

        fetchFormData();
    }, [id]);

    const handleSaveForm = async () => {
        const updatedSurveyData = creator.JSON;
        try {
            await new Promise((resolve, reject) => {
                http(
                    {
                        method: 'POST', // or PUT depending on your API
                        url: `form/update/${id}`, // Adjust endpoint as needed
                        isSecure: true,
                        body: { data: [updatedSurveyData] },
                    },
                    (res) => {
                        if (res) {
                            resolve(res);
                            toast.success(res.single_message)
                        } else {
                            reject('Error saving form data');
                        }
                    }
                );
            });
        } catch (error) {
            console.error('Error saving form data:', error);
        }
    };
    return (
        <div className="right_section font-poppins pr-0">
            <h2 className='fs-20 fw-semibold mt-4'>Edit Survey Form</h2>
            {surveyModel && creator ? (
                <>
                    <SurveyCreatorComponent creator={creator} />
                    <div className="survey_btn">
                        <button className='add-new ms-auto mt-4' onClick={handleSaveForm}>Save Form</button>
                    </div>
                </>
            ) : (
                <p>Loading form data...</p>
            )}
        </div>
    );
};

export default Edit_Servay_Form;
