import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { http } from "../../http/http";
import { toast } from "react-toastify";
import { GlobalContext } from "../../../App";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
const Individual_Contact_Detail = () => {
    const navigate = useNavigate();
    const [manualEntryData, setManualEntryData] = useState({
        type: 1,
        firstName: "",
        middleName: "",
        lastName: "",
        companyName: "",
        email: "",
        phoneNumber: "",
        dob: "",
        gender: null,
        maritalStatus: null,
        streetAddress: "",
        city: "",
        province: "",
        country: "",
        postalCode: "",
        status: 1,
        notes: "",
    });
    const { setShowLoader } = useContext(GlobalContext);
    const [address, setAddress] = useState("");
    const [errors, setErrors] = useState({ firstName: "", email: "", lastName: "" });
    useEffect(() => {
        // Retrieve data from sessionStorage
        const storedData = JSON.parse(sessionStorage.getItem("manualEntry"));
        if (storedData) {
            setManualEntryData((prevState) => ({ ...prevState, ...storedData }));
        }
    }, []);
    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setManualEntryData((prevData) => ({
    //         ...prevData,
    //         [name]: value,
    //     }));
    // };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setManualEntryData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        // Inline validation
        if (name === "email" && value) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? "" : "Invalid email format.",
            }));
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: value.trim() ? "" : "Required.",
            }));
        }
    };
    const handleSelect = async (value) => {
        setAddress(value);
        try {
            const results = await geocodeByAddress(value);
            if (results && results[0]) {
                const addressComponents = results[0].address_components;
                let city = "";
                let province = "";
                let postalCode = "";
                let country = "";
                addressComponents.forEach((component) => {
                    if (component.types.includes("locality")) {
                        city = component.long_name;
                    }
                    if (component.types.includes("administrative_area_level_1")) {
                        province = component.long_name;
                    }
                    if (component.types.includes("postal_code")) {
                        postalCode = component.long_name;
                    }
                    if (component.types.includes("country")) {
                        country = component.long_name;
                    }
                });
                setManualEntryData((prevData) => ({
                    ...prevData,
                    streetAddress: value,
                    city,
                    province,
                    postalCode,
                    country,
                }));
            } else {
                console.warn("No results found for the selected address.");
            }
        } catch (error) {
            console.error("Error selecting place:", error?.message || "No message", error?.stack || "No stack trace");
        }
    };
    const handleAddressChange = (value) => {
        setAddress(value);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;
        setShowLoader(true);
        const payload = {
            type: 1,
            first_name: manualEntryData.firstName,
            middle_name: manualEntryData.middleName,
            last_name: manualEntryData.lastName,
            company_name: manualEntryData.companyName,
            email: manualEntryData.email,
            phone_number: manualEntryData.phoneNumber,
            dob: manualEntryData.dob,
            gender: manualEntryData.gender,
            marital_status: manualEntryData.maritalStatus,
            street_address: manualEntryData.streetAddress,
            city: manualEntryData.city,
            province: manualEntryData.province,
            country: manualEntryData.country,
            postal_code: manualEntryData.postalCode,
            status: manualEntryData.status,
            notes: manualEntryData.notes,
        };
        http(
            {
                method: "POST",
                url: `v2/contact_detail/add`,
                body: payload,
                isSecure: true,
            },
            (res) => {
                toast.success(res.single_message);
                navigate("/admin/client/add_individual");
                sessionStorage.removeItem("manualEntry")
                sessionStorage.setItem("contact_data", JSON.stringify(res.data));
                setShowLoader(false);
                setManualEntryData({
                    type: 1,
                    firstName: "",
                    middleName: "",
                    lastName: "",
                    companyName: "",
                    email: "",
                    phoneNumber: "",
                    dob: "",
                    gender: null,
                    maritalStatus: null,
                    streetAddress: "",
                    city: "",
                    province: "",
                    country: "",
                    postalCode: "",
                    status: 1,
                    notes: "",
                });
            },
            (err) => {
                // Handle errors according to the response
                if (err.status === 401) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else if (err.status === 400) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else {
                    toast.error(err.single_message);
                    setShowLoader(false);
                }
            }
        );
    };
    const validateForm = () => {
        const newErrors = { firstName: "", lastName: "", email: "" };
        // Validate firstName
        if (!manualEntryData.firstName.trim()) {
            newErrors.firstName = "Required.";
        }
        // Validate lastName
        if (!manualEntryData.lastName.trim()) {
            newErrors.lastName = "Required.";
        }
        // Validate email
        if (!manualEntryData.email.trim()) {
            newErrors.email = "Required.";
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(manualEntryData.email)) {
            newErrors.email = "Invalid email format.";
        }
        setErrors(newErrors);
        return Object.values(newErrors).every((error) => !error); // Return true if no errors
    };
    return (
        <div className="right_section font-poppins">
            <div className="business-form-area py-3">
                <div className="row business-head align-items-center">
                    <div className="col-md-10 offset-md-1 mx-auto left_head">
                        <h4 className="fs-20 mt-2 fw-semibold">Add Individual Client</h4>
                    </div>
                    <div className="col-md-10 offset-md-1 mx-auto">
                        <div className="main-form-area p-4 mt-3 bg-white shadow-lg">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <h6 className="fs-20 fw-semibold">Contact Details</h6>
                                    </div>
                                </div>
                                <div className="row form-group">
                                    <div className="col-md-4 mt-4">
                                        <label htmlFor="" className="w-100">First Name <span className="text-danger ms-1">*</span></label>
                                        <input
                                            type="text"
                                            name="firstName"
                                            className="form-input mt-2"
                                            placeholder="Type here..."
                                            value={manualEntryData.firstName}
                                            onChange={handleChange}
                                        />
                                        {errors.firstName && (
                                            <p className="text-danger mt-1">{errors.firstName}</p>
                                        )}
                                    </div>
                                    <div className="col-md-4 mt-4">
                                        <label htmlFor="" className="w-100">Middle Name</label>
                                        <input
                                            type="text"
                                            name="middleName"
                                            className="form-input mt-2"
                                            placeholder="Type here..."
                                            value={manualEntryData.middleName}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col-md-4 mt-4">
                                        <label htmlFor="" className="w-100">Last Name <span className="text-danger ms-1">*</span></label>
                                        <input
                                            type="text"
                                            name="lastName"
                                            className="form-input mt-2"
                                            placeholder="Type here..."
                                            value={manualEntryData.lastName}
                                            onChange={handleChange}
                                        />
                                        {errors.lastName && (
                                            <p className="text-danger mt-1">{errors.lastName}</p>
                                        )}
                                    </div>
                                    <div className="col-md-4 mt-4">
                                        <label htmlFor="" className="w-100">Email Address <span className="text-danger ms-1">*</span></label>
                                        <input
                                            type="email"
                                            name="email"
                                            className="form-input mt-2"
                                            placeholder="Type here..."
                                            value={manualEntryData.email}
                                            onChange={handleChange}
                                        />
                                        {errors.email && (
                                            <p className="text-danger mt-1">{errors.email}</p>
                                        )}
                                    </div>
                                    <div className="col-md-4 mt-4">
                                        <label htmlFor="" className="w-100">Phone Number </label>
                                        <input
                                            type="number"
                                            name="phoneNumber"
                                            className="form-input mt-2"
                                            placeholder="Phone Number"
                                            value={manualEntryData.phoneNumber}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col-md-4 mt-4">
                                        <label htmlFor="" className="w-100">Date of Birth </label>
                                        <input
                                            type="date"
                                            name="dob"
                                            className="form-input mt-2"
                                            placeholder="Phone Number"
                                            max={new Date().toISOString().split("T")[0]}
                                            value={manualEntryData.dob}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col-md-4 mt-4">
                                        <label className="w-100">Street Address</label>
                                        <PlacesAutocomplete
                                            value={address}
                                            onChange={handleAddressChange}
                                            onSelect={handleSelect}
                                            searchOptions={{
                                                componentRestrictions: {
                                                    country: ["us", "ca"],
                                                },
                                            }}
                                        >
                                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                <div>
                                                    <input
                                                        {...getInputProps({
                                                            placeholder: "Type address here...",
                                                            className: "form-input mt-2",
                                                        })}
                                                    />
                                                    <div className="autocomplete-dropdown-container">
                                                        {loading && <div>Loading...</div>}
                                                        {suggestions.map((suggestion) => {
                                                            const className = suggestion.active
                                                                ? "suggestion-item--active"
                                                                : "suggestion-item";
                                                            return (
                                                                <div
                                                                    {...getSuggestionItemProps(suggestion, { className })}
                                                                    key={suggestion.placeId}
                                                                >
                                                                    <span>{suggestion.description}</span>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            )}
                                        </PlacesAutocomplete>
                                    </div>
                                    <div className="col-md-4 mt-4">
                                        <label className="w-100">City</label>
                                        <input
                                            type="text"
                                            name="city"
                                            className="form-input mt-2"
                                            placeholder="Type here..."
                                            value={manualEntryData.city}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col-md-4 mt-4">
                                        <label htmlFor="" className="w-100">Province </label>
                                        <input
                                            type="text"
                                            name="province"
                                            className="form-input mt-2"
                                            placeholder="Type here..."
                                            value={manualEntryData.province}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col-md-4 mt-4">
                                        <label className="w-100">Country</label>
                                        <input
                                            type="text"
                                            name="country"
                                            className="form-input mt-2"
                                            placeholder="Type here..."
                                            value={manualEntryData.country}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col-md-4 mt-4">
                                        <label className="w-100">Postal Code</label>
                                        <input
                                            type="text"
                                            name="postalCode"
                                            className="form-input mt-2"
                                            placeholder="Type here..."
                                            value={manualEntryData.postalCode}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col-md-12 mt-4">
                                        <label htmlFor="" className="w-100">Notes </label>
                                        <textarea
                                            className="form-input mt-2"
                                            name="notes"
                                            id=""
                                            cols="10"
                                            rows="5"
                                            placeholder="Message"
                                            value={manualEntryData.notes}
                                            onChange={handleChange}
                                        ></textarea>
                                    </div>
                                    <div className="col-md-12 individual-footer">
                                        <div className="btn-groups mt-4 mb-2">
                                            <button type="submit" className="add-new ms-2 fs-20 px-5 py-4">Next</button>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </div >
    );
};
export default Individual_Contact_Detail;
