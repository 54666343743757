import React, { useState } from 'react'

const Business_Linked_Contact_Detail = ({ businessContactData, onSave }) => {
    // Initialize state with provided corporate contact data
    const [formData, setFormData] = useState({
        type: businessContactData?.type || "",
        designation_id: businessContactData?.designation_id || "",
        first_name: businessContactData?.first_name || "",
        middle_name: businessContactData?.middle_name || "",
        last_name: businessContactData?.last_name || "",
        company_name: businessContactData?.company_name || "",
        sin: businessContactData?.sin || "",
        date_of_birth: businessContactData?.date_of_birth || "",
        email: businessContactData?.email || "",
        phone_number: businessContactData?.phone_number || "",
        address: businessContactData?.address || "",
        city: businessContactData?.city || "",
        province: businessContactData?.province || "",
        postal_code: businessContactData?.postal_code || "",
        country: businessContactData?.country || "",
        shareholder_part: businessContactData?.shareholder_part || "0",
    });

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        if (onSave) {
            onSave(formData); // Pass the updated data to the parent component or API
        }
    };
    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="row light_input">
                    {formData?.type === 2 ? (
                        // Render company_name input when type is 2
                        <div className="col-md-6 mt-3">
                            <label htmlFor="company_name">Company Name</label>
                            <input
                                type="text"
                                id="company_name"
                                name="company_name"
                                className="form-input mt-2"
                                value={formData.company_name}
                                onChange={handleChange}
                                readOnly
                            />
                        </div>
                    ) : (
                        // Render first_name, middle_name, and last_name when type is not 2
                        <>
                            <div className="col-md-6 mt-3">
                                <label htmlFor="first_name">First Name</label>
                                <input
                                    type="text"
                                    id="first_name"
                                    name="first_name"
                                    className="form-input mt-2"
                                    value={formData.first_name}
                                    onChange={handleChange}
                                    readOnly
                                />
                            </div>
                            <div className="col-md-6 mt-3">
                                <label htmlFor="middle_name">Middle Name</label>
                                <input
                                    type="text"
                                    id="middle_name"
                                    name="middle_name"
                                    className="form-input mt-2"
                                    value={formData.middle_name}
                                    onChange={handleChange}
                                    readOnly
                                />
                            </div>
                            <div className="col-md-6 mt-3">
                                <label htmlFor="last_name">Last Name</label>
                                <input
                                    type="text"
                                    id="last_name"
                                    name="last_name"
                                    className="form-input mt-2"
                                    value={formData.last_name}
                                    onChange={handleChange}
                                    readOnly
                                />
                            </div>
                        </>
                    )}
                    <div className="col-md-6 mt-3">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            className="form-input mt-2"
                            value={formData.email}
                            onChange={handleChange}
                            readOnly
                        />
                    </div>
                    <div className="col-md-6 mt-3">
                        <label htmlFor="designation_id">Designation </label>
                        <input
                            type="text"
                            id="designation_id"
                            name="designation_id"
                            className="form-input mt-2"
                            value={formData.designation_id}
                            onChange={handleChange}
                            readOnly
                        />
                    </div>
                    <div className="col-md-6 mt-3">
                        <label htmlFor="phone_number">Phone Number</label>
                        <input
                            type="text"
                            id="phone_number"
                            name="phone_number"
                            className="form-input mt-2"
                            value={formData.phone_number}
                            onChange={handleChange}
                            readOnly
                        />
                    </div>
                    <div className="col-md-12 mt-3">
                        <label htmlFor="address">Address</label>
                        <textarea
                            id="address"
                            name="address"
                            className="form-input mt-2"
                            value={formData.address}
                            onChange={handleChange}
                            readOnly
                        ></textarea>
                    </div>
                    <div className="col-md-6 mt-3">
                        <label htmlFor="city">City</label>
                        <input
                            type="text"
                            id="city"
                            name="city"
                            className="form-input mt-2"
                            value={formData.city}
                            onChange={handleChange}
                            readOnly
                        />
                    </div>
                    <div className="col-md-6 mt-3">
                        <label htmlFor="province">Province</label>
                        <input
                            type="text"
                            id="province"
                            name="province"
                            className="form-input mt-2"
                            value={formData.province}
                            onChange={handleChange}
                            readOnly
                        />
                    </div>
                    <div className="col-md-12 mt-3">
                        <label htmlFor="postal_code">Postal Code</label>
                        <input
                            type="text"
                            id="postal_code"
                            name="postal_code"
                            className="form-input mt-2"
                            value={formData.postal_code}
                            onChange={handleChange}
                            readOnly
                        />
                    </div>
                    {/* <div className="col-md-12 mt-4">
                        <button type="submit" className="btn-blue px-4">
                            Save Changes
                        </button>
                    </div> */}
                </div>
            </form>
        </>
    )
}

export default Business_Linked_Contact_Detail
