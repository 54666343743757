import React, { useState, useEffect, useContext, useRef } from "react";
import { useForm } from "react-hook-form";
import { http } from "../../http/http";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { GlobalContext } from "../../../App";
const Add_Invoice = () => {
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
        watch,
    } = useForm({ mode: "all" });
    const formValues = watch();
    const navigate = useNavigate();
    const { showLoader, setShowLoader } = useContext(GlobalContext);
    const [clientType, setClientType] = useState('');
    const [selectedClient, setSelectedClient] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showCopModal, setShowCopModal] = useState(false);
    const [invoiceNumber, setInvoiceNumber] = useState('');
    const [invoiceTitle, setInvoiceTitle] = useState('');
    const previousTitle = useRef("");
    const [orderNumber, setOrderNumber] = useState('');
    const [clientList, setClientList] = useState();
    const [copClientList, setCopClientList] = useState([]);
    const [totalGst, setTotalGst] = useState([]);
    const [amountArray, setAmountArray] = useState([{ 'quantity': 0, 'price': 0, 'type': "1", 'discount': 0, 'rate': 0, 'name': '0', "tax_id": "", "item": "", "descriptions": "", "amount": '' }]);
    const [finalAmountArray, setFinalAmountArray] = useState([]);
    const [finalAmountSubTotal, setFinalAmountSubTotal] = useState(0);
    const [finalGstAmountSubTotal, setFinalGstAmountSubTotal] = useState(0);
    const [selectedClientInfo, setSelectedClientInfo] = useState(null);
    const [selectedCopClientInfo, setSelectedCopClientInfo] = useState(null);
    const [subtotal, setSubtotal] = useState(0.00);
    const [selectedDiscountType, setSelectedDiscountType] = useState("1");
    const [getTaxList, setGetTaxList] = useState([]);
    const [recordCount, setRecordCount] = useState(0);
    const [invoiceNo, setInvoiceNo] = useState('');
    const [deleteInvoice, setDeleteInvoice] = useState(false);
    const [updateInvoiceItem, setUpdateInvoiceItem] = useState(false);
    const [totalDiscountPrice, setTotalDiscountprise] = useState(0);
    const [inputFields, setInputFields] = useState([
        { id: 0, item: '', description: '', quantity: '', price: '', item_discount: '0', amount: '', item_discount_price_type: '1', tax_id: '' }
    ]);
    const today = new Date().toISOString().split('T')[0];
    const [formData, setFormData] = useState({
        invoice_date: today,
        due_date: '',
        currency: 'Canadian',
        notes: '',
        client_id: '',

    });
    const [error, setErrors] = useState({});
    const [searchValue, setSearchValue] = useState(""); // Added searchValue state
    const [searchCopValue, setSearchCopValue] = useState("");

    const amountArrayConvertion = (amountArray) => {
        const finalAmounts = amountArray.map(item => {
            const amount = item.type === 1
                ? (item.price * item.quantity) - item.discount
                : (item.price * item.quantity) - ((item.price * item.quantity) * item.discount / 100);

            const gstAmount = (amount * item.rate / 100);
            const itemGstAmount = amount + gstAmount;

            return {
                ...item,
                discount_amount: item.type === 1 ? parseFloat(item.discount) || 0 : ((item.price * item.quantity) * item.discount / 100) || 0,
                itemAmount: amount,
                gstAmount: gstAmount,
                itemGstAmount: itemGstAmount
            };
        });

        let totalgst = {};
        let totalDiscount = 0;

        finalAmounts.forEach(item => {
            if (item.tax_id) {
                const key = `${item.name} ${item.rate}%`;
                totalgst[key] = (totalgst[key] || 0) + item.gstAmount;
            }
            totalDiscount += parseFloat(item.discount_amount) || 0;
        });

        const gstTotalNote = Object.keys(totalgst).map(key => ({ type: key, value: totalgst[key] }));

        setTotalGst(gstTotalNote);
        setFinalAmountArray(finalAmounts);

        const totalAmount = finalAmounts.reduce((total, item) => total + item.itemAmount, 0);
        setFinalGstAmountSubTotal(totalAmount);

        const totalFinalAmount = finalAmounts.reduce((total, item) => total + item.itemGstAmount, 0);
        setFinalAmountSubTotal(totalFinalAmount);
        setTotalDiscountprise(totalDiscount.toFixed(2));
    };

    useEffect(() => {
        amountArrayConvertion(amountArray);
    }, [updateInvoiceItem]);


    //discountItem, gstItemValue, selectedDiscountType, deleteInvoice


    const handleClientTypeChange = (type) => {
        setClientType(type);
        setSelectedClient('');
        setSelectedCopClientInfo(null);
        setSelectedClientInfo(null);
        setErrors((prevErrors) => ({ ...prevErrors, clientType: '', selectedClient: '' }));
    };

    // const handleSelectChange = (event) => {
    //     const clientId = event.target.value;
    //     setSelectedClient(clientId);

    //     if (clientId === '+ Add New') {
    //         setShowModal(true);
    //     } else {
    //         const clientInfo = clientList && clientList.find(client => client.id == clientId);
    //         setSelectedClientInfo(clientInfo);
    //     }

    // };


    // const handleCopSelectChange = (event) => {
    //     const clientId = event.target.value;
    //     setSelectedClient(clientId);

    //     if (clientId === '+ Add New') {
    //         setShowCopModal(true);
    //     } else {
    //         const clientInfo = copClientList.find(client => client.id == clientId);
    //         setSelectedCopClientInfo(clientInfo);
    //     }

    // };

    const handleAddFields = () => {
        var arr = amountArray;
        arr.push({ 'quantity': 0, 'price': 0, 'type': 0, 'discount': 0, 'rate': 0, 'name': '0', "tax_id": '', "item": "", "descriptions": "", "amount": '' });
        setAmountArray(arr)
        const newInputFields = [...inputFields, { id: inputFields[inputFields.length - 1].id + 1, item: '', description: '', quantity: '', price: '', item_discount: '0', amount: '', item_discount_price_type: '1', tax_id: '' },];
        setInputFields(newInputFields);

    };

    const handleRemoveFields = (id, index) => {
        var arr = amountArray;
        arr.splice(index, 1)
        setAmountArray(arr)
        // setDeleteInvoice(!deleteInvoice)
        setUpdateInvoiceItem(!updateInvoiceItem);
        if (inputFields.length === 1) return;
        const newInputFields = inputFields.filter((field) => field.id !== id);
        setInputFields(newInputFields);
    };

    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        const newAmountArray = [...amountArray];
        newAmountArray[index][name] = value;
        setAmountArray(newAmountArray);
        if (value.trim() !== '') {
            setErrors((prev) => {
                const newErrors = { ...prev };
                if (name === 'item') {
                    delete newErrors[`item${index}`];
                } else if (name === 'descriptions') {
                    delete newErrors[`descriptions${index}`];
                }
                return newErrors;
            });
        }
    };


    const handleTitleChange = (e) => {
        const { value, name } = e.target;
        setInvoiceTitle(value);
        if (value.trim() !== '') {
            setErrors((prev) => ({ ...prev, title: '' })); // Clear error when the user starts typing
        }
    };

    const handleInputDateChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
        if (value.trim() !== '') {
            setErrors((prev) => ({ ...prev, [name]: '' })); // Clear error when the user selects a date
        }
    };

    const handleQuantityChange = (id, value, index_val) => {
        setUpdateInvoiceItem(!updateInvoiceItem);
        setFinalGstAmountSubTotal(value);

        // Update the amountArray state with the new quantity
        const updatedAmountArray = amountArray.map((element, index) => {
            if (index === index_val) {
                return { ...element, quantity: value };
            }
            return element;
        });
        setAmountArray(updatedAmountArray);

        // Update the inputFields state with the new quantity and amount
        const newInputFields = inputFields.map(field => {
            if (field.id === id) {
                const discountValue = parseFloat(field.item_discount) || 0; // Default to 0 if discount is empty
                const amount = calculateAmount(parseFloat(value), parseFloat(field.price), discountValue, parseInt(field.item_discount_price_type));
                return { ...field, quantity: value, amount };
            }
            return field;
        });
        setInputFields(newInputFields);

        // Clear error when valid quantity is entered
        if (value.trim() !== '' && !isNaN(value) && parseInt(value) > 0) {
            setErrors((prev) => ({ ...prev, [`quantity${index_val}`]: '' }));
        }
    };

    const handleItemDiscountTypeChange = (id, type, index_val, value) => {
        setSelectedDiscountType(type);
        setUpdateInvoiceItem(!updateInvoiceItem);
        setFinalGstAmountSubTotal(value);

        const discountType = type === '1' ? 1 : 0;

        const newAmountArray = amountArray.map((element, index) => {
            if (index === index_val) {
                return { ...element, type: discountType };
            }
            return element;
        });

        setAmountArray(newAmountArray);

        const newInputFields = inputFields.map(field => {
            if (field.id === id) {
                const discountValue = parseFloat(field.item_discount) || 0; // Default to 0 if discount is empty
                const newAmount = calculateAmount(parseFloat(field.quantity), parseFloat(field.price), discountValue, discountType);
                return {
                    ...field,
                    item_discount_price_type: discountType,
                    amount: newAmount
                };
            }
            return field;
        });

        setInputFields(newInputFields);
    };

    const handleDiscountChange = (id, value, index_val) => {
        setUpdateInvoiceItem(!updateInvoiceItem);

        const newAmountArray = amountArray.map((element, index) => {
            if (index === index_val) {
                return { ...element, discount: value };
            }
            return element;
        });

        setAmountArray(newAmountArray);

        const newInputFields = inputFields.map(field => {
            if (field.id === id) {
                const discountValue = parseFloat(value) || 0; // Default to 0 if discount is empty
                const amount = calculateAmount(parseFloat(field.quantity), parseFloat(field.price), discountValue, parseInt(field.item_discount_price_type));
                return { ...field, item_discount: value, amount };
            }
            return field;
        });

        setInputFields(newInputFields);
    };

    const handlePriceChange = (id, value, index_val) => {
        setUpdateInvoiceItem(!updateInvoiceItem);

        const newAmountArray = amountArray.map((element, index) => {
            if (index === index_val) {
                return { ...element, price: value };
            }
            return element;
        });

        setAmountArray(newAmountArray);

        const newInputFields = inputFields.map(field => {
            if (field.id === id) {
                const discountValue = parseFloat(field.item_discount) || 0; // Default to 0 if discount is empty
                const amount = calculateAmount(parseFloat(field.quantity), parseFloat(value), discountValue, parseInt(field.item_discount_price_type));
                return { ...field, price: value, amount };
            }
            return field;
        });

        setInputFields(newInputFields);

        if (value.trim() !== '' && !isNaN(value) && parseFloat(value) > 0) {
            setErrors(prev => ({ ...prev, [`price${index_val}`]: '' })); // Clear error when valid price is entered
        }
    };
    const handleTaxChange = (event, index_val) => {


        const { value } = event.target;
        // setGstItemValue(value)
        setUpdateInvoiceItem(!updateInvoiceItem);
        const str = "id_34_45_56";
        const splitArray = value.split("_");
        var setArrayVal = amountArray;
        setArrayVal.map((element, index) => {
            if (index == index_val) {
                setArrayVal[index].name = splitArray[1];
                setArrayVal[index].rate = splitArray[2];
                setArrayVal[index].tax_id = splitArray[0];

            }
        });
        setAmountArray(setArrayVal);
        // setDeleteInvoice(!deleteInvoice)
        setUpdateInvoiceItem(!updateInvoiceItem);

    };

    const calculateAmount = (quantity, price, discount, discountType) => {
        let amount = quantity * price;
        if (discountType === 1) { // $ discount
            amount -= discount;
        } else if (discountType === 0) { // % discount
            amount -= (amount * discount / 100);
        }
        return isNaN(amount) ? "0.00" : amount.toFixed(2); // Returns a string representing the amount rounded to 2 decimal places
    };
    useEffect(() => {
        const newSubtotal = inputFields.reduce((acc, field) => acc + parseFloat(field.amount || 0), 0);
        setSubtotal(newSubtotal.toFixed(2));
    }, [inputFields]);

    // useEffect(() => {
    //     if (invoiceTitle.trim() !== '') {
    //         // getInvoiceNumber();
    //         // getOrderNumber();
    //         getCounterRecord();
    //     } else {
    //         // setInvoiceNumber('');
    //         // setOrderNumber(' ');
    //         getCounterRecord();
    //     }
    // }, [invoiceTitle]);

    useEffect(() => {
        if (previousTitle.current.trim() === "" && invoiceTitle.trim() !== "") {
            // Call the API only when moving from empty to non-empty
            getCounterRecord();
        }
        // Update the previous value
        previousTitle.current = invoiceTitle;
    }, [invoiceTitle]);


    const addClient = async () => {

        const formData = new FormData();
        Object.entries(formValues).forEach(([key, value]) => {
            formData.append(key, value);
        });

        http(
            {
                method: "POST",
                url: "add-individual",
                isSecure: true,
                contentType: "multipart/form-data",
                body: formData
            },
            (res) => {
                getClientList();
            },
            (err) => {

            }
        );
    }

    const getInvoiceNumber = () => {
        http(
            {
                method: "GET",
                url: "invoice/generateInvoiceNumber",
                isSecure: true,

            },
            (res) => {
                setInvoiceNumber(res?.invoice_number)
            },
            (err) => {

            }
        );
    }
    useEffect(() => {
        getInvoiceNumber();
    }, []);





    const getOrderNumber = () => {
        http(
            {
                method: "GET",
                url: "invoice/generateOrderNumber",
                isSecure: true,

            },
            (res) => {
                setOrderNumber(res.invoice_number)
            },
            (err) => {

            }
        )

    }
    useEffect(() => {
        getOrderNumber();
    }, []);
    const getClientList = () => {

        http(
            {
                method: "GET",
                url: "lists/clients",
                isSecure: true,

            },
            (res) => {
                setClientList(res.data?.data);
            },
            (err) => {

            }
        )
    }
    useEffect(() => {
        getClientList();
    }, []);

    const getCopClientList = () => {

        http(
            {
                method: "GET",
                url: "lists/corporate/clients",
                isSecure: true,

            },
            (res) => {
                setCopClientList(res.data?.data);
            },
            (err) => {

            }
        )
    }
    useEffect(() => {
        getCopClientList();
    }, []);

    const getTaxRate = () => {
        http(
            {
                method: "GET",
                url: "invoice/listingtax",
                isSecure: true,

            },
            (res) => {
                setGetTaxList(res?.data?.data)
            },
            (err) => {

            }
        )

    }
    useEffect(() => {
        getTaxRate();
    }, []);

    const getCounterRecord = () => {
        http(
            {
                method: "GET",
                url: "invoice/countrecords",
                isSecure: true,
            },
            (res) => {
                const totalInvoices = res?.data?.total_invoices || 0;
                const newInvoiceNo = `INV-${String(totalInvoices + 1).padStart(5, '0')}`;
                setRecordCount(totalInvoices);
                setInvoiceNo(newInvoiceNo);
            },
            (err) => {
                console.error("Error fetching record count:", err);
            }
        );
    };

    useEffect(() => {
        getCounterRecord();
    }, []);

    const handleKeyPress = () => {
        setInvoiceNo(`INV-${String(recordCount + 1).padStart(5, '0')}`);
    };

    const AddInvoice = async (e) => {
        e.preventDefault();
        // setShowLoader(true);
        if (!validateForm()) {
            return;
        }
        setShowLoader(true);

        // const items = amountArray.map(inputField => ({

        //     name: inputField.item,
        //     description: inputField.descriptions,
        //     quantity: parseInt(inputField.quantity),
        //     price: parseFloat(inputField.price),
        //     item_discount: parseFloat(inputField.discount),
        //     item_discount_price_type: inputField.type.toString(),
        //     tax_id: inputField.tax_id,
        //     tax_price: inputField.rate,
        //     tax_rate: parseFloat(inputField.rate),

        // }));

        const items = amountArray.map((inputField) => {
            const price = parseFloat(inputField.price); // Item price
            const quantity = parseInt(inputField.quantity, 10); // Item quantity
            const taxRate = parseFloat(inputField.rate); // Tax rate in percentage
            const discount = parseFloat(inputField.discount) || 0; // Item discount

            // Calculate the effective price after discount
            const discountPrice =
                inputField.item_discount_price_type === "1" // Percentage-based discount
                    ? price - (price * discount) / 100
                    : price - discount; // Fixed discount

            // Calculate tax_price: (effective price * quantity * taxRate / 100)
            const taxPrice = ((discountPrice * quantity * taxRate) / 100).toFixed(2);

            return {
                name: inputField.item,
                description: inputField.descriptions,
                quantity,
                price,
                item_discount: discount,
                item_discount_price_type: inputField.item_discount_price_type,
                tax_id: inputField.tax_id,
                tax_price: taxPrice, // Correctly calculated tax_price
                tax_rate: taxRate, // Tax rate
            };
        });
        const requestBody = {
            invoice_title: invoiceTitle,
            invoice_date: formData.invoice_date,
            due_date: formData.due_date,
            notes: formData.notes,
            currency: formData.currency,
            client_id: selectedClient,
            company_profile_id: 1,
            order_number: formData.order_number,
            client_type: clientType === "individual" ? "1" : "2",
            items: items,
            status: e.target.name === "saveDraft" ? "2" : ""
        };

        http(
            {
                method: "POST",
                url: "invoice/add",
                isSecure: true,
                body: requestBody
            },
            (res) => {
                toast.success(res?.single_message);
                setShowLoader(false);
                navigate("/admin/invoice/invoice_detail");
            },
            (err) => {
                // Handle errors according to the response
                if (err.status === 401) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else if (err.status === 400) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else {
                    toast.error(err.single_message);
                    setShowLoader(false);
                }
            }
        );
    }

    const validateForm = () => {
        let formIsValid = true;
        let newErrors = {};

        if (invoiceTitle.trim() === '') {
            newErrors.title = 'Title field is required';
            formIsValid = false;
        }

        if (formData.invoice_date.trim() === '') {
            newErrors.invoice_date = 'Invoice Date field is required';
            formIsValid = false;
        }
        if (formData.currency.trim() === '') {
            newErrors.currency = 'Currency field is required';
            formIsValid = false;
        }
        if (clientType === '') {
            newErrors.clientType = 'Client Type is required';
            formIsValid = false;
        } else if (selectedClient === '') {
            // Check if client is selected only when clientType is not empty
            newErrors.selectedClient = 'Client is required';
            formIsValid = false;
        }
        amountArray.forEach((field, index) => {
            if (!field.item || field.item.trim() === '') {
                newErrors[`item${index}`] = 'required';
                formIsValid = false;
            }
            if (!field.descriptions || field.descriptions.trim() === '') {
                newErrors[`descriptions${index}`] = 'required';
                formIsValid = false;
            }
            if (!field.quantity || isNaN(field.quantity) || parseInt(field.quantity) <= 0) {
                newErrors[`quantity${index}`] = 'required';
                formIsValid = false;
            }
            if (!field.price || isNaN(field.price) || parseFloat(field.price) <= 0) {
                newErrors[`price${index}`] = 'required ';
                formIsValid = false;
            }
        });
        setErrors(newErrors);
        return formIsValid;
    };


    return (
        <div className="right_section font-poppins invoicing">
            <div className="row">
                <div className="col-xl-8 offset-md-2 add-invoice-area mt-4 bg-white rounded-4 shadow-main px-3 py-4">
                    <form onSubmit={AddInvoice}>
                        <div className="row p-0">
                            <div className="col-md-12">
                                <h2 className="inv-title">Add New Invoice</h2>
                            </div>
                            <div className="col-md-4">
                                <div className="row">
                                    <div className="col-md-12 mt-4 p-0">
                                        <label className="w-100">Title <span className="text-danger">*</span></label>
                                        <input
                                            type="text"
                                            name="invoice_title"
                                            className="form-input mt-3"
                                            placeholder="Invoice"
                                            onChange={handleTitleChange}
                                        />
                                        {error.title && <div className="error">{error.title}</div>}
                                    </div>
                                    <div className="col-md-12 mt-4 p-0">
                                        <label className="w-100">Invoice No.</label>
                                        <input
                                            type="text"
                                            name=""
                                            className="form-input mt-3"
                                            placeholder="INV-00-02152"
                                            value={invoiceNo}
                                            readOnly
                                            onKeyPress={handleKeyPress}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="row">
                                    <div className="col-md-12 mt-4">
                                        <label className="w-100">Billing To <span className="text-danger">*</span></label>
                                        <div className="d-flex align-items-center gap-3">
                                            <div className="mt-3 radio-inv d-flex align-items-center gap-1">
                                                <input
                                                    id="indi"
                                                    type="radio"
                                                    name="clientType"
                                                    checked={clientType === 'individual'}
                                                    onChange={() => handleClientTypeChange('individual')}
                                                />
                                                <label htmlFor="indi">Individual Client</label>
                                            </div>
                                            <div className="mt-3 radio-inv d-flex align-items-center gap-1">
                                                <input
                                                    id="corp"
                                                    type="radio"
                                                    name="clientType"
                                                    checked={clientType === 'corporate'}
                                                    onChange={() => handleClientTypeChange('corporate')}
                                                />
                                                <label htmlFor="corp">Corporate Client</label>
                                            </div>
                                        </div>
                                        {error.clientType && <div className="error">{error.clientType}</div>}
                                    </div>
                                    {clientType === 'individual' && (
                                        <div className="col-md-8 mt-4 dropdown-custom">
                                            <div className="dropdown">
                                                <button
                                                    className="form-input dropdown-toggle w-100 text-start mt-2"
                                                    type="button"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    {selectedClient
                                                        ? clientList && clientList.find((client) => client.id === parseInt(selectedClient))?.first_name || "Select Individual Client"
                                                        : "Select Individual Client"}
                                                </button>
                                                <div className="dropdown-menu w-100 p-0">
                                                    <ul className="p-0">
                                                        <li>
                                                            <input
                                                                type="search"
                                                                className="form-input"
                                                                placeholder="Search client..."
                                                                onChange={(e) => setSearchValue(e.target.value)}
                                                            />
                                                        </li>
                                                    </ul>
                                                    <ul className="p-0 listing_show">
                                                        {clientList && clientList
                                                            .filter((client) =>
                                                                client.first_name.toLowerCase().includes(searchValue.toLowerCase())
                                                            )
                                                            .map((client) => (
                                                                <li key={client.id}>
                                                                    <button
                                                                        className="dropdown-item"
                                                                        type="button"
                                                                        onClick={() => {
                                                                            setSelectedClient(client.id);
                                                                            setSearchValue(""); // Clear search field
                                                                            const clientInfo = clientList.find((c) => c.id === client.id);
                                                                            setSelectedClientInfo(clientInfo);

                                                                            // Clear the error state here
                                                                            setErrors((prev) => ({ ...prev, selectedClient: null }));
                                                                        }}
                                                                    >
                                                                        {client.first_name}
                                                                    </button>
                                                                </li>
                                                            ))}
                                                        {/* <li>
                                                            <button
                                                                className="dropdown-item text-blue"
                                                                type="button"
                                                                style={{ backgroundColor: "lightblue" }}
                                                                onClick={() => {
                                                                    setSelectedClient("+ Add New");
                                                                    setShowModal(true);

                                                                    // Clear the error state  'selectedClient'
                                                                    setErrors((prev) => ({ ...prev, selectedClient: null }));
                                                                }}
                                                            >
                                                                + Add New
                                                            </button>
                                                        </li> */}
                                                    </ul>
                                                </div>
                                                {error.selectedClient && <div className="error">{error.selectedClient}</div>}
                                            </div>
                                        </div>
                                    )}
                                    {clientType === 'corporate' && (
                                        <div className="col-md-8 mt-4 dropdown-custom">
                                            <div className="dropdown">
                                                <button
                                                    className="form-input dropdown-toggle w-100 text-start mt-2"
                                                    type="button"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    {selectedClient
                                                        ? copClientList && copClientList.find((client) => client.id === parseInt(selectedClient))?.name || "Select Corporate Client"
                                                        : "Select Corporate Client"}
                                                </button>
                                                <div className="dropdown-menu w-100 p-0">
                                                    <ul className="p-0">
                                                        <li>
                                                            <input
                                                                type="search"
                                                                className="form-input"
                                                                placeholder="Search corporate client..."
                                                                onChange={(e) => setSearchCopValue(e.target.value)}
                                                            />
                                                        </li>
                                                    </ul>
                                                    <ul className="p-0 listing_show">
                                                        {copClientList && copClientList
                                                            .filter((client) =>
                                                                client.name.toLowerCase().includes(searchCopValue.toLowerCase())
                                                            )
                                                            .map((client) => (
                                                                <li key={client.id}>
                                                                    <button
                                                                        className="dropdown-item"
                                                                        type="button"
                                                                        onClick={() => {
                                                                            setSelectedClient(client.id);
                                                                            setSearchCopValue(""); // Clear search field
                                                                            const clientInfo = copClientList.find((c) => c.id === client.id);
                                                                            setSelectedCopClientInfo(clientInfo);

                                                                            // Clear the error state here
                                                                            setErrors((prev) => ({ ...prev, selectedClient: null }));
                                                                        }}
                                                                    >
                                                                        {client.name}
                                                                    </button>
                                                                </li>
                                                            ))}
                                                        {/* <li>
                                                            <button
                                                                className="dropdown-item text-blue"
                                                                type="button"
                                                                style={{ backgroundColor: "lightblue" }}
                                                                onClick={() => {
                                                                    setSelectedClient("+ Add New");
                                                                    setShowCopModal(true);

                                                                    // Clear the error state  'selectedClient'
                                                                    setErrors((prev) => ({ ...prev, selectedClient: null }));
                                                                }}
                                                            >
                                                                + Add New
                                                            </button>
                                                        </li> */}
                                                    </ul>
                                                </div>
                                                {error.selectedClient && <div className="error">{error.selectedClient}</div>}
                                            </div>
                                        </div>
                                    )}

                                    {/* {clientType === 'corporate' && (
                                        <>
                                            <div className="col-md-8 mt-4 dropdown-custom">
                                                <div className="drop-new position-relative">
                                                    <select
                                                        className="form-input border-0"
                                                        value={selectedClient}
                                                        onChange={handleCopSelectChange}

                                                    >
                                                        <option value="" disabled="disabled" selected>Select Corporate Client</option>
                                                        {copClientList && copClientList.map((data) => (
                                                            <option key={data.id} value={data.id}>
                                                                {data.name}
                                                            </option>
                                                        ))}
                                                        <option className="text-blue" style={{ backgroundColor: 'lightblue' }} value="+ Add New">+ Add New</option>
                                                    </select>
                                                    {error.selectedClient && <div className="error">{error.selectedClient}</div>}
                                                </div>
                                            </div>
                                        </>
                                    )} */}
                                    {clientType === 'individual' && selectedClientInfo && (
                                        <div className="col-md-12 mt-4 client-info py-2 px-3 ms-2">
                                            <div className="info-head d-flex align-items-center justify-content-between">
                                                <h3 className="fs-18">{selectedClientInfo.first_name}</h3>

                                            </div>
                                            {/* <div className="info-content mt-2">
                                                <p className="fs-14 text--muted">{selectedClientInfo.email}</p>
                                            </div> */}
                                            <div className="info-contact text-end mt-2">
                                                <span className="d-flex align-items-center flex-wrap justify-content-end">
                                                    {/* <p className="fs-14 text--muted">{selectedClientInfo.phone} |</p> */}
                                                    <p className="fs-14 text--muted ms-1">{selectedClientInfo?.email}</p>
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    {clientType === 'corporate' && selectedCopClientInfo && (
                                        <div className="col-md-12 mt-4 client-info py-2 px-3 ms-2">
                                            <div className="info-head d-flex align-items-center justify-content-between">
                                                <h3 className="fs-18">{selectedCopClientInfo.name}</h3>

                                            </div>
                                            <div className="info-content mt-2">
                                                <p className="fs-14 text--muted">{selectedCopClientInfo.address}</p>
                                            </div>
                                            <div className="info-contact text-end mt-2">
                                                <span className="d-flex align-items-center flex-wrap justify-content-end">
                                                    <p className="fs-14 text--muted">{selectedCopClientInfo.phone} |</p>
                                                    <p className="fs-14 text--muted ms-1">{selectedCopClientInfo.email}</p>
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 mt-4">
                                <label className="w-100">Order No.</label>
                                <input
                                    type="text"
                                    className="form-input mt-3"
                                    name="order_number"
                                    placeholder="Enter order no."
                                    value={formData.order_number}
                                    onChange={handleInputDateChange}
                                // value={invoiceTitle.trim() !== '' ? orderNumber : ''}
                                // readOnly
                                />
                            </div>
                            <div className="col-md-4 mt-4">
                                <label className="w-100">Invoice Date <span className="text-danger">*</span></label>
                                <input
                                    type="date"
                                    name="invoice_date"
                                    className="form-input mt-3"
                                    placeholder="INV-02152"
                                    value={formData.invoice_date}
                                    onChange={handleInputDateChange}
                                    min={today}
                                />
                                {error.invoice_date && <div className="error">{error.invoice_date}</div>}
                            </div>
                            <div className="col-md-4 mt-4">
                                <label className="w-100">Due Date </label>
                                <input
                                    type="date"
                                    name="due_date"
                                    className="form-input mt-3"
                                    placeholder="INV-02152"
                                    value={formData.due_date}
                                    onChange={handleInputDateChange}
                                    min={today}
                                />
                            </div>
                            <div className="col-md-4 mt-4">
                                <label className="w-100">Currency <span className="text-danger">*</span></label>
                                <select
                                    name="currency"
                                    id=""
                                    className="form-input mt-3"
                                    value={formData.currency}
                                    onChange={handleInputDateChange}
                                >
                                    <option value="" disabled >Select</option>
                                    <option value="Canadian">Canadian Dollar</option>
                                    <option value="American">American Dollar</option>

                                </select>
                                {error.currency && <div className="error">{error.currency}</div>}
                            </div>
                        </div>

                        <hr className="mt-4" />
                        <div className="row">
                            <h4 className="fs-18 font-semibold">Add Items</h4>
                            {inputFields.map((field, index) => (
                                <>
                                    <div className="add-items-input overflow-x-scroll d-flex gap-2 mt-4 pb-1" key={field.id || index}>
                                        <div className="item-inp">
                                            <label htmlFor={`item-${index}`} className="w-100">Item <span className="text-danger">*</span></label>
                                            <input
                                                type="text"
                                                id={`item-${index}`}
                                                name="item"
                                                className="form-input mt-3"
                                                placeholder="Item Name"
                                                value={field.name}
                                                onChange={(e) => handleInputChange(index, e)}
                                            />
                                            {error[`item${index}`] && <div className="error">{error[`item${index}`]}</div>}
                                        </div>
                                        <div className="desc-inp">
                                            <label htmlFor={`descriptions-${index}`} className="w-100">Description <span className="text-danger">*</span></label>
                                            <input
                                                type="text"
                                                id={`descriptions-${index}`}
                                                name="descriptions"
                                                className="form-input mt-3"
                                                placeholder="Enter Description"
                                                value={field.descriptions}
                                                onChange={(e) => handleInputChange(index, e)}
                                            />
                                            {error[`descriptions${index}`] && <div className="error">{error[`descriptions${index}`]}</div>}
                                        </div>
                                        <div className="qty-inp">
                                            <label htmlFor={`quantity-${index}`} className="w-100">Qty <span className="text-danger">*</span></label>
                                            <input
                                                type="number"
                                                id={`quantity-${index}`}
                                                name="quantity"
                                                className="form-input mt-3"
                                                placeholder="0"
                                                value={field.quantity}
                                                onChange={e => handleQuantityChange(field.id, e.target.value, index)}
                                            />
                                            {error[`quantity${index}`] && <div className="error">{error[`quantity${index}`]}</div>}
                                        </div>
                                        <div className="price-inp">
                                            <label htmlFor={`price-${index}`} className="w-100">Price <span className="text-danger">*</span></label>
                                            <input
                                                type="number"
                                                id={`price-${index}`}
                                                name="price"
                                                className="form-input mt-3"
                                                placeholder="00.00"
                                                value={field.price}
                                                onChange={e => handlePriceChange(field.id, e.target.value, index)}
                                            />
                                            {error[`price${index}`] && <div className="error">{error[`price${index}`]}</div>}
                                        </div>
                                        <div className="disc-inp position-relative discount">
                                            <label htmlFor={`discount-${index}`} className="w-100">Discount</label>
                                            <div className="per-inp d-flex align-items-center">
                                                <input
                                                    type="number"
                                                    id={`item_discount-${index}`}
                                                    name="item_discount"
                                                    className="form-input mt-3"
                                                    placeholder="0"
                                                    value={field.item_discount}
                                                    onChange={e => handleDiscountChange(field.id, e.target.value, index)}
                                                />
                                                <div className="per d-flex align-items-center px-2 gap-2 mt-3">
                                                    <p
                                                        className={`px-1 ${field.item_discount_price_type == 1 ? 'active' : ''}`}
                                                        onClick={() => handleItemDiscountTypeChange(field.id, '1', index)}
                                                    >
                                                        $
                                                    </p>
                                                    <p
                                                        className={`px-1 ${field.item_discount_price_type == 0 ? 'active' : ''}`}
                                                        onClick={() => handleItemDiscountTypeChange(field.id, '0', index)}
                                                    >
                                                        %
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="tax">
                                            <label>Tax</label>
                                            <select
                                                className="form-input mt-3"
                                                name="tax_id"
                                                id={`tax_id_${field.id}`}
                                                // value={field[index]?.tax_id}

                                                onChange={(e) => handleTaxChange(e, index)}
                                            >
                                                <option value="" disabled="disabled" selected>Select</option>
                                                {getTaxList && getTaxList.map((data) => (
                                                    <option key={data.id} value={data.id + '_' + data.name + '_' + parseFloat(data.rate).toFixed(2)}>
                                                        {`${data.name} (${parseFloat(data.rate).toFixed(2)}%)`}
                                                    </option>
                                                ))}


                                            </select>

                                        </div>
                                        <div className="amount-inp">
                                            <label htmlFor={`amount-${index}`} className="w-100">Amount</label>
                                            <input
                                                type="text"
                                                id={`amount-${index}`}
                                                name="amount"
                                                className="form-input mt-3"
                                                placeholder="0"
                                                value={field.amount}
                                                readOnly
                                            />
                                        </div>
                                    </div >
                                    <div className="text-end">
                                        {index !== 0 && (
                                            <a
                                                type="button"
                                                onClick={() => handleRemoveFields(field.id, index)}
                                                className="text-danger mt-4 align-content-center mt-4 h-100"
                                            >
                                                <i className="fa fa-trash"></i>
                                            </a>
                                        )}
                                    </div>

                                </>
                            ))}
                            <a className="text-blue d-block w-fit mt-2 w-auto fw-medium" onClick={handleAddFields}>+ Add Items</a>
                            <div className="row">
                                <div className="light-blue-bg p-4 my-4">
                                    <div className="row total">
                                        <div className="col-md-12 text-end d-flex align-items-center justify-content-end">
                                            <h6 className="text-muted">Sub Total:</h6>
                                            <h6 className="font-weight-bold text-muted ms-5"><b>${finalGstAmountSubTotal}</b></h6>
                                        </div>
                                        {
                                            totalGst && totalGst.map((item, index) => (
                                                <div key={index} className="col-md-12 text-end d-flex align-items-center justify-content-end mt-4">
                                                    <h6 className="text-muted">{item.type}</h6>
                                                    <h6 className="font-weight-bold text-muted ms-5"><b>${item.value.toFixed(2)}</b></h6>
                                                </div>
                                            ))
                                        }
                                        <div className="col-md-12 text-end d-flex align-items-center justify-content-end mt-4">
                                            <h6 className="text-muted">Total Discount:</h6>
                                            <h6 className="font-weight-bold text-muted ms-5"><b>${totalDiscountPrice}</b></h6></div>

                                    </div>

                                    <hr className="mt-4" />
                                    <div className="row total">
                                        <div className="col-md-12 text-end d-flex align-items-center justify-content-end">
                                            <h6 className="text-blue">Total:</h6>
                                            <h6 className="font-weight-bold text-blue ms-5"><b>${finalAmountSubTotal.toFixed(2)}</b></h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="notes-inp p-0">
                                    <label className="w-100">Notes</label>
                                    <textarea name="notes" className="form-input mt-3" id="" cols="30" rows="3" value={formData.notes}
                                        onChange={handleInputDateChange}>Type here....</textarea>
                                </div>

                                <div className="form-btns text-end d-flex items-center flex-wrap gap-2 mt-4 justify-content-end p-0">
                                    <button className="btn-transparent" onClick={() => navigate(`/admin/invoice/invoice_detail`)}>Cancel</button>
                                    <button className="add-new" name="saveDraft" onClick={AddInvoice}>Save as Draft</button>
                                    <button className="add-new" type="submit">Save</button>
                                </div>
                            </div>



                        </div>

                    </form>
                </div>
            </div>
            {/* <Modal
                show={showModal}
                onHide={setShowModal}
                centered

                contentClassName="mx-auto"
                className="modal  font-poppins"
                backdrop="static"
                keyboard={false}
            >
                <div className="modal-header px-4 pt-2 pb-0 border-0 justify-content-between">
                    <h4 className="fs-20">Add Individual Client</h4>
                    <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
                </div>


                <div className="modal-body px-4 pt-0 pb-0">
                    <form onSubmit={handleSubmit(addClient)}>
                        <div className="row mt-4" >
                            <div className="col-lg-12 col-md-12 mt-4">
                                <label className="fs-18">First Name</label>
                                <input
                                    name="first_name"
                                    id="first_name"
                                    className="form-input mt-2"
                                    type="text"
                                    {...register("first_name", {
                                        required: true,
                                    })}

                                />
                            </div>
                            <div className="col-lg-12 col-md-12 mt-4">
                                <label className="fs-18">Middle Name</label>
                                <input
                                    name="middle_name"
                                    id="middle_name"
                                    className="form-input mt-2"
                                    type="text"
                                    {...register("middle_name", {
                                        required: true,
                                    })}

                                />
                            </div>
                            <div className="col-lg-12 col-md-12 mt-4">
                                <label className="fs-18">Last Name</label>
                                <input
                                    name="last_name"
                                    id="last_name"
                                    className="form-input mt-2"
                                    type="text"
                                    {...register("last_name", {
                                        required: true,
                                    })}

                                />
                            </div>
                            <div className="col-lg-12 col-md-12 mt-4">
                                <label className="fs-18">Email</label>
                                <input
                                    name="email"
                                    id="email"
                                    className="form-input mt-2"
                                    type="text"
                                    {...register("email", {
                                        required: true,
                                    })}

                                />
                            </div>

                            <div className="modal-footer border-0 px-0 pb-4 mt-4">
                                <a ><button className="btn-blue" type="submit">Add</button></a>
                            </div>
                        </div>

                    </form>
                </div>
            </Modal>
            <Modal
                show={showCopModal}
                onHide={() => setShowCopModal(false)}
                centered

                contentClassName="mx-auto"
                className="modal  font-poppins"
                backdrop="static"
                keyboard={false}
            >
                <div className="modal-header px-4 pt-2 pb-0 border-0 justify-content-between">
                    <h4 className="fs-20">Add Individual Client</h4>
                    <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
                </div>


                <div className="modal-body px-4 pt-0 pb-0">
                    <form onSubmit={handleSubmit(addBusiness)}>
                        <div className="row mt-4" >
                            <div className="col-lg-12 col-md-12 mt-4">
                                <label className="fs-18">First Name</label>
                                <input
                                    name="name"
                                    id="name"
                                    className="form-input mt-2"
                                    type="text"
                                    {...register("name", {
                                        required: true,
                                    })}

                                />
                            </div>


                            <div className="col-lg-12 col-md-12 mt-4">
                                <label className="fs-18">Email</label>
                                <input
                                    name="email"
                                    id="email"
                                    className="form-input mt-2"
                                    type="text"
                                    {...register("email", {
                                        required: true,
                                    })}

                                />
                            </div>

                            <div className="modal-footer border-0 px-0 pb-4 mt-4">
                                <a ><button className="btn-blue" type="submit">Add</button></a>
                            </div>
                        </div>

                    </form>
                </div>
            </Modal> */}

        </div >


    )
}
export default Add_Invoice;