import React, { useState } from "react";

const SpouseDetail = ({ spouseData, onSave, }) => {
    const [showSIN, setShowSIN] = useState(false);
    const [formData, setFormData] = useState({
        first_name: spouseData?.first_name || "",
        middle_name: spouseData?.middle_name || "",
        last_name: spouseData?.last_name || "",
        sin: spouseData?.sin || "",
        contact_number: spouseData?.contact_number || "",
        email: spouseData?.email || "",
        date_of_birth: spouseData?.date_of_birth || "",
        address: spouseData?.address || "",
        city: spouseData?.city || "",
        province: spouseData?.province || "",
        postal_code: spouseData?.postal_code || "",
        date_of_landing: spouseData?.date_of_landing || "",
        mis_notes: spouseData?.mis_notes || "",
    });
    const toggleSINVisibility = () => {
        setShowSIN(!showSIN); // Toggle between showing and hiding SIN
    };
    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    // Save logic
    const handleSubmit = (e) => {
        e.preventDefault();
        if (onSave) {
            onSave(formData); // Pass updated data to the parent or save via API
        }
    };

    return (

        <>

            <form onSubmit={handleSubmit}>
                {/* <div>
                    <p className="text-blue fs-14">Client of John Smith</p>
                </div> */}
                <div className="row light_input">
                    <div className="col-md-6 mt-3">
                        <label htmlFor="first_name">First Name</label>
                        <input
                            type="text"
                            id="first_name"
                            name="first_name"
                            className="form-input mt-2"
                            value={formData.first_name}
                            onChange={handleChange}
                            placeholder="Enter first name"
                            readOnly
                        />
                    </div>
                    <div className="col-md-6 mt-3">
                        <label htmlFor="middle_name">Middle Name</label>
                        <input
                            type="text"
                            id="middle_name"
                            name="middle_name"
                            className="form-input mt-2"
                            value={formData.middle_name}
                            onChange={handleChange}
                            placeholder="Enter middle name"
                            readOnly
                        />
                    </div>
                    <div className="col-md-6 mt-3">
                        <label htmlFor="last_name">Last Name</label>
                        <input
                            type="text"
                            id="last_name"
                            name="last_name"
                            className="form-input mt-2"
                            value={formData.last_name}
                            onChange={handleChange}
                            placeholder="Enter last name"
                            readOnly
                        />
                    </div>
                    <div className="col-md-6 mt-3">
                        <label htmlFor="sin">SIN</label>
                        <div className="sin_area">
                            <input
                                type={showSIN ? "text" : "password"}
                                id="sin"
                                name="sin"
                                className="form-input mt-2"
                                value={formData.sin}
                                onChange={handleChange}
                                placeholder="Enter SIN"
                                readOnly
                            />
                            <div className="sin_icons">
                                {showSIN ? (
                                    <i
                                        className="fa fa-eye-slash"
                                        onClick={toggleSINVisibility} // Hide SIN when clicked
                                    ></i>
                                ) : (
                                    <i
                                        className="fa fa-eye"
                                        onClick={toggleSINVisibility} // Show SIN when clicked
                                    ></i>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <label htmlFor="contact_number">Contact Number</label>
                        <input
                            type="text"
                            id="contact_number"
                            name="contact_number"
                            className="form-input mt-2"
                            value={formData.contact_number}
                            onChange={handleChange}
                            placeholder="Enter contact number"
                            readOnly
                        />
                    </div>
                    <div className="col-md-6 mt-3">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            className="form-input mt-2"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="Enter email"
                            readOnly
                        />
                    </div>
                    <div className="col-md-6 mt-3">
                        <label htmlFor="date_of_birth">Date of Birth</label>
                        <input
                            type="date"
                            id="date_of_birth"
                            name="date_of_birth"
                            className="form-input mt-2"
                            value={formData.date_of_birth}
                            onChange={handleChange}
                            readOnly
                        />
                    </div>
                    <div className="col-md-6 mt-3">
                        <label htmlFor="date_of_landing">Date of Landing</label>
                        <input
                            type="date"
                            id="date_of_landing"
                            name="date_of_landing"
                            className="form-input mt-2"
                            value={formData.date_of_landing}
                            onChange={handleChange}
                            readOnly
                        />
                    </div>
                    {/* <div className="col-md-6 mt-3">
                        <label htmlFor="date_of_landing">Country of Residence</label>
                        <input
                            type="text"
                            id="country_id"
                            name="country_id"
                            className="form-input mt-2"
                            value={formData.country_id}
                            onChange={handleChange}
                            readOnly
                        />
                    </div> */}
                    <div className="col-md-6 mt-3 ">
                        <label htmlFor="address">Address</label>
                        <textarea
                            id="address"
                            name="address"
                            className="form-input mt-2 "
                            value={formData.address}
                            onChange={handleChange}
                            placeholder="Enter address"
                            readOnly
                        ></textarea>
                    </div>
                    <div className="col-md-6 mt-3">
                        <label htmlFor="city">City</label>
                        <input
                            type="text"
                            id="city"
                            name="city"
                            className="form-input mt-2"
                            value={formData.city}
                            onChange={handleChange}
                            placeholder="Enter city"
                            readOnly
                        />
                    </div>
                    <div className="col-md-6 mt-3">
                        <label htmlFor="province">Province</label>
                        <input
                            type="text"
                            id="province"
                            name="province"
                            className="form-input mt-2"
                            value={formData.province}
                            onChange={handleChange}
                            placeholder="Enter province"
                            readOnly
                        />
                    </div>
                    <div className="col-md-6 mt-3">
                        <label htmlFor="postal_code">Postal Code</label>
                        <input
                            type="text"
                            id="postal_code"
                            name="postal_code"
                            className="form-input mt-2"
                            value={formData.postal_code}
                            onChange={handleChange}
                            placeholder="Enter postal code"
                            readOnly
                        />
                    </div>
                    <div className="col-md-12 mt-3">
                        <label htmlFor="mis_notes">Notes</label>
                        <textarea
                            id="mis_notes"
                            name="mis_notes"

                            className="form-input mt-2"
                            value={formData.mis_notes}
                            onChange={handleChange}
                            placeholder="Enter notes"
                            readOnly
                        ></textarea>
                    </div>
                    {/* <div className="col-md-12 mt-4">
                        <button type="submit" className="btn-blue px-4">
                            Save Changes
                        </button>
                    </div> */}
                </div>
            </form>
        </>
    );
};

export default SpouseDetail;
