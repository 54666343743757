import React from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const Notification = () => {
    return (
        <div className='bg-white shadow---main rounded-4 h_800 p-4'>
            <span>
                <h4 class="fs-20 fw-bold">Custom Forms</h4>
                <p class="fs-16 t_blue">Customization</p>
            </span>
            <div className="notification-tabs mt-4">
                <Tabs
                    defaultActiveKey="client"
                    transition={true}
                    id="noanim-tab-example"
                    className="mb-3"
                >
                    {/* CLIENT TAB AREA */}
                    <Tab eventKey="client" title="Client">
                        <div className="client_table">
                            <div className="table_part mt-3">
                                <div className="table-responsive">
                                    <table className='notif_table w-100 mt-2'>
                                        <thead>
                                            <tr>
                                                <th className='border_none ps-0'>Module</th>
                                                <th className='border_none'>Type</th>
                                                <th className='border_none'>Push</th>
                                                <th className='border_none'>Email</th>
                                                <th className='border_none'>SMS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th rowspan="3" className='border_right'>
                                                    <h4 className='fs-18 fw-medium'>Client</h4>
                                                    <p className='fs-14 text-muted lh-base'>Enable notifications to stay updated on important account information. Disable notifications if you prefer not to receive these alerts.</p>
                                                </th>
                                                <th className='border_right border_left border_bottom'>Create</th>
                                                <td className='border_bottom border_left border_right'>
                                                    <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_bottom border_right border_left'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_bottom border_left brb-0'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className='border_none'>Update</th>
                                                <td className='border_none'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_none'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_bottom border_left border_top br-0'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className='border_top border_left border_right br-0'>Delete</th>
                                                <td className='border_top border_left border_right'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_top border_left border_right'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_top border_left brt-0'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table className='notif_table w-100 mt-2'>
                                        <thead>
                                            <tr>
                                                <th className='border_none ps-0'>Module</th>
                                                <th className='border_none'>Type</th>
                                                <th className='border_none'>Push</th>
                                                <th className='border_none'>Email</th>
                                                <th className='border_none'>SMS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th rowspan="3" className='border_right'>
                                                    <h4 className='fs-18 fw-medium'>Spouse</h4>
                                                    <p className='fs-14 text-muted lh-base'>Enable notifications to stay updated on important account information. Disable notifications if you prefer not to receive these alerts.</p>
                                                </th>
                                                <th className='border_right border_left border_bottom'>Create</th>
                                                <td className='border_bottom border_left border_right'>
                                                    <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_bottom border_right border_left'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_bottom border_left brb-0'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className='border_none'>Update</th>
                                                <td className='border_none'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_none'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_bottom border_left border_top br-0'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className='border_top border_left border_right br-0'>Delete</th>
                                                <td className='border_top border_left border_right'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_top border_left border_right'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_top border_left brt-0'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table className='notif_table w-100 mt-2'>
                                        <thead>
                                            <tr>
                                                <th className='border_none ps-0'>Module</th>
                                                <th className='border_none'>Type</th>
                                                <th className='border_none'>Push</th>
                                                <th className='border_none'>Email</th>
                                                <th className='border_none'>SMS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th rowspan="3" className='border_right'>
                                                    <h4 className='fs-18 fw-medium'>Dependent</h4>
                                                    <p className='fs-14 text-muted lh-base'>Enable notifications to stay updated on important account information. Disable notifications if you prefer not to receive these alerts.</p>
                                                </th>
                                                <th className='border_right border_left border_bottom'>Create</th>
                                                <td className='border_bottom border_left border_right'>
                                                    <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_bottom border_right border_left'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_bottom border_left brb-0'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className='border_none'>Update</th>
                                                <td className='border_none'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_none'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_bottom border_left border_top br-0'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className='border_top border_left border_right br-0'>Delete</th>
                                                <td className='border_top border_left border_right'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_top border_left border_right'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_top border_left brt-0'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table className='notif_table w-100 mt-2'>
                                        <thead>
                                            <tr>
                                                <th className='border_none ps-0'>Module</th>
                                                <th className='border_none'>Type</th>
                                                <th className='border_none'>Push</th>
                                                <th className='border_none'>Email</th>
                                                <th className='border_none'>SMS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th rowspan="3" className='border_right'>
                                                    <h4 className='fs-18 fw-medium'>Contact</h4>
                                                    <p className='fs-14 text-muted lh-base'>Enable notifications to stay updated on important account information. Disable notifications if you prefer not to receive these alerts.</p>
                                                </th>
                                                <th className='border_right border_left border_bottom'>Create</th>
                                                <td className='border_bottom border_left border_right'>
                                                    <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_bottom border_right border_left'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_bottom border_left brb-0'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className='border_none'>Update</th>
                                                <td className='border_none'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_none'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_bottom border_left border_top br-0'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className='border_top border_left border_right br-0'>Delete</th>
                                                <td className='border_top border_left border_right'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_top border_left border_right'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_top border_left brt-0'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </Tab>
                    {/* CLIENT TAB AREA */}

                    {/* BUSINESS TAB AREA */}
                    <Tab eventKey="business" title="Business">
                        <div className="client_table">
                            <div className="table_part mt-3">
                                <div className="table-responsive">
                                    <table className='notif_table w-100 mt-2'>
                                        <thead>
                                            <tr>
                                                <th className='border_none ps-0'>Module</th>
                                                <th className='border_none'>Type</th>
                                                <th className='border_none'>Push</th>
                                                <th className='border_none'>Email</th>
                                                <th className='border_none'>SMS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th rowspan="3" className='border_right'>
                                                    <h4 className='fs-18 fw-medium'>Client</h4>
                                                    <p className='fs-14 text-muted lh-base'>Enable notifications to stay updated on important account information. Disable notifications if you prefer not to receive these alerts.</p>
                                                </th>
                                                <th className='border_right border_left border_bottom'>Create</th>
                                                <td className='border_bottom border_left border_right'>
                                                    <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_bottom border_right border_left'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_bottom border_left brb-0'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className='border_none'>Update</th>
                                                <td className='border_none'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_none'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_bottom border_left border_top br-0'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className='border_top border_left border_right br-0'>Delete</th>
                                                <td className='border_top border_left border_right'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_top border_left border_right'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_top border_left brt-0'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table className='notif_table w-100 mt-2'>
                                        <thead>
                                            <tr>
                                                <th className='border_none ps-0'>Module</th>
                                                <th className='border_none'>Type</th>
                                                <th className='border_none'>Push</th>
                                                <th className='border_none'>Email</th>
                                                <th className='border_none'>SMS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th rowspan="3" className='border_right'>
                                                    <h4 className='fs-18 fw-medium'>Spouse</h4>
                                                    <p className='fs-14 text-muted lh-base'>Enable notifications to stay updated on important account information. Disable notifications if you prefer not to receive these alerts.</p>
                                                </th>
                                                <th className='border_right border_left border_bottom'>Create</th>
                                                <td className='border_bottom border_left border_right'>
                                                    <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_bottom border_right border_left'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_bottom border_left brb-0'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className='border_none'>Update</th>
                                                <td className='border_none'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_none'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_bottom border_left border_top br-0'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className='border_top border_left border_right br-0'>Delete</th>
                                                <td className='border_top border_left border_right'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_top border_left border_right'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_top border_left brt-0'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table className='notif_table w-100 mt-2'>
                                        <thead>
                                            <tr>
                                                <th className='border_none ps-0'>Module</th>
                                                <th className='border_none'>Type</th>
                                                <th className='border_none'>Push</th>
                                                <th className='border_none'>Email</th>
                                                <th className='border_none'>SMS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th rowspan="3" className='border_right'>
                                                    <h4 className='fs-18 fw-medium'>Dependent</h4>
                                                    <p className='fs-14 text-muted lh-base'>Enable notifications to stay updated on important account information. Disable notifications if you prefer not to receive these alerts.</p>
                                                </th>
                                                <th className='border_right border_left border_bottom'>Create</th>
                                                <td className='border_bottom border_left border_right'>
                                                    <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_bottom border_right border_left'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_bottom border_left brb-0'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className='border_none'>Update</th>
                                                <td className='border_none'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_none'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_bottom border_left border_top br-0'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className='border_top border_left border_right br-0'>Delete</th>
                                                <td className='border_top border_left border_right'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_top border_left border_right'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_top border_left brt-0'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table className='notif_table w-100 mt-2'>
                                        <thead>
                                            <tr>
                                                <th className='border_none ps-0'>Module</th>
                                                <th className='border_none'>Type</th>
                                                <th className='border_none'>Push</th>
                                                <th className='border_none'>Email</th>
                                                <th className='border_none'>SMS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th rowspan="3" className='border_right'>
                                                    <h4 className='fs-18 fw-medium'>Contact</h4>
                                                    <p className='fs-14 text-muted lh-base'>Enable notifications to stay updated on important account information. Disable notifications if you prefer not to receive these alerts.</p>
                                                </th>
                                                <th className='border_right border_left border_bottom'>Create</th>
                                                <td className='border_bottom border_left border_right'>
                                                    <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_bottom border_right border_left'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_bottom border_left brb-0'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className='border_none'>Update</th>
                                                <td className='border_none'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_none'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_bottom border_left border_top br-0'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className='border_top border_left border_right br-0'>Delete</th>
                                                <td className='border_top border_left border_right'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_top border_left border_right'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_top border_left brt-0'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </Tab>
                    {/* BUSINESS TAB AREA */}

                    {/* TASK TAB AREA */}
                    <Tab eventKey="task" title="Task">
                        <div className="client_table">
                            <div className="table_part mt-3">
                                <div className="table-responsive">
                                    <table className='notif_table w-100 mt-2'>
                                        <thead>
                                            <tr>
                                                <th className='border_none ps-0'>Module</th>
                                                <th className='border_none'>Type</th>
                                                <th className='border_none'>Push</th>
                                                <th className='border_none'>Email</th>
                                                <th className='border_none'>SMS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th rowspan="3" className='border_right'>
                                                    <h4 className='fs-18 fw-medium'>Client</h4>
                                                    <p className='fs-14 text-muted lh-base'>Enable notifications to stay updated on important account information. Disable notifications if you prefer not to receive these alerts.</p>
                                                </th>
                                                <th className='border_right border_left border_bottom'>Create</th>
                                                <td className='border_bottom border_left border_right'>
                                                    <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_bottom border_right border_left'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_bottom border_left brb-0'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className='border_none'>Update</th>
                                                <td className='border_none'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_none'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_bottom border_left border_top br-0'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className='border_top border_left border_right br-0'>Delete</th>
                                                <td className='border_top border_left border_right'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_top border_left border_right'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_top border_left brt-0'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table className='notif_table w-100 mt-2'>
                                        <thead>
                                            <tr>
                                                <th className='border_none ps-0'>Module</th>
                                                <th className='border_none'>Type</th>
                                                <th className='border_none'>Push</th>
                                                <th className='border_none'>Email</th>
                                                <th className='border_none'>SMS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th rowspan="3" className='border_right'>
                                                    <h4 className='fs-18 fw-medium'>Spouse</h4>
                                                    <p className='fs-14 text-muted lh-base'>Enable notifications to stay updated on important account information. Disable notifications if you prefer not to receive these alerts.</p>
                                                </th>
                                                <th className='border_right border_left border_bottom'>Create</th>
                                                <td className='border_bottom border_left border_right'>
                                                    <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_bottom border_right border_left'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_bottom border_left brb-0'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className='border_none'>Update</th>
                                                <td className='border_none'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_none'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_bottom border_left border_top br-0'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className='border_top border_left border_right br-0'>Delete</th>
                                                <td className='border_top border_left border_right'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_top border_left border_right'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_top border_left brt-0'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table className='notif_table w-100 mt-2'>
                                        <thead>
                                            <tr>
                                                <th className='border_none ps-0'>Module</th>
                                                <th className='border_none'>Type</th>
                                                <th className='border_none'>Push</th>
                                                <th className='border_none'>Email</th>
                                                <th className='border_none'>SMS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th rowspan="3" className='border_right'>
                                                    <h4 className='fs-18 fw-medium'>Dependent</h4>
                                                    <p className='fs-14 text-muted lh-base'>Enable notifications to stay updated on important account information. Disable notifications if you prefer not to receive these alerts.</p>
                                                </th>
                                                <th className='border_right border_left border_bottom'>Create</th>
                                                <td className='border_bottom border_left border_right'>
                                                    <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_bottom border_right border_left'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_bottom border_left brb-0'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className='border_none'>Update</th>
                                                <td className='border_none'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_none'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_bottom border_left border_top br-0'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className='border_top border_left border_right br-0'>Delete</th>
                                                <td className='border_top border_left border_right'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_top border_left border_right'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_top border_left brt-0'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table className='notif_table w-100 mt-2'>
                                        <thead>
                                            <tr>
                                                <th className='border_none ps-0'>Module</th>
                                                <th className='border_none'>Type</th>
                                                <th className='border_none'>Push</th>
                                                <th className='border_none'>Email</th>
                                                <th className='border_none'>SMS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th rowspan="3" className='border_right'>
                                                    <h4 className='fs-18 fw-medium'>Contact</h4>
                                                    <p className='fs-14 text-muted lh-base'>Enable notifications to stay updated on important account information. Disable notifications if you prefer not to receive these alerts.</p>
                                                </th>
                                                <th className='border_right border_left border_bottom'>Create</th>
                                                <td className='border_bottom border_left border_right'>
                                                    <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_bottom border_right border_left'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_bottom border_left brb-0'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className='border_none'>Update</th>
                                                <td className='border_none'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_none'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_bottom border_left border_top br-0'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className='border_top border_left border_right br-0'>Delete</th>
                                                <td className='border_top border_left border_right'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_top border_left border_right'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_top border_left brt-0'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </Tab>
                    {/* TASK TAB AREA */}

                    {/* INVOICE TAB AREA */}
                    <Tab eventKey="invoice" title="Invoice">
                        <div className="client_table">
                            <div className="table_part mt-3">
                                <div className="table-responsive">
                                    <table className='notif_table w-100 mt-2'>
                                        <thead>
                                            <tr>
                                                <th className='border_none ps-0'>Module</th>
                                                <th className='border_none'>Type</th>
                                                <th className='border_none'>Push</th>
                                                <th className='border_none'>Email</th>
                                                <th className='border_none'>SMS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th rowspan="3" className='border_right'>
                                                    <h4 className='fs-18 fw-medium'>Client</h4>
                                                    <p className='fs-14 text-muted lh-base'>Enable notifications to stay updated on important account information. Disable notifications if you prefer not to receive these alerts.</p>
                                                </th>
                                                <th className='border_right border_left border_bottom'>Create</th>
                                                <td className='border_bottom border_left border_right'>
                                                    <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_bottom border_right border_left'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_bottom border_left brb-0'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className='border_none'>Update</th>
                                                <td className='border_none'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_none'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_bottom border_left border_top br-0'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className='border_top border_left border_right br-0'>Delete</th>
                                                <td className='border_top border_left border_right'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_top border_left border_right'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_top border_left brt-0'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table className='notif_table w-100 mt-2'>
                                        <thead>
                                            <tr>
                                                <th className='border_none ps-0'>Module</th>
                                                <th className='border_none'>Type</th>
                                                <th className='border_none'>Push</th>
                                                <th className='border_none'>Email</th>
                                                <th className='border_none'>SMS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th rowspan="3" className='border_right'>
                                                    <h4 className='fs-18 fw-medium'>Spouse</h4>
                                                    <p className='fs-14 text-muted lh-base'>Enable notifications to stay updated on important account information. Disable notifications if you prefer not to receive these alerts.</p>
                                                </th>
                                                <th className='border_right border_left border_bottom'>Create</th>
                                                <td className='border_bottom border_left border_right'>
                                                    <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_bottom border_right border_left'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_bottom border_left brb-0'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className='border_none'>Update</th>
                                                <td className='border_none'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_none'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_bottom border_left border_top br-0'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className='border_top border_left border_right br-0'>Delete</th>
                                                <td className='border_top border_left border_right'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_top border_left border_right'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_top border_left brt-0'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table className='notif_table w-100 mt-2'>
                                        <thead>
                                            <tr>
                                                <th className='border_none ps-0'>Module</th>
                                                <th className='border_none'>Type</th>
                                                <th className='border_none'>Push</th>
                                                <th className='border_none'>Email</th>
                                                <th className='border_none'>SMS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th rowspan="3" className='border_right'>
                                                    <h4 className='fs-18 fw-medium'>Dependent</h4>
                                                    <p className='fs-14 text-muted lh-base'>Enable notifications to stay updated on important account information. Disable notifications if you prefer not to receive these alerts.</p>
                                                </th>
                                                <th className='border_right border_left border_bottom'>Create</th>
                                                <td className='border_bottom border_left border_right'>
                                                    <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_bottom border_right border_left'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_bottom border_left brb-0'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className='border_none'>Update</th>
                                                <td className='border_none'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_none'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_bottom border_left border_top br-0'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className='border_top border_left border_right br-0'>Delete</th>
                                                <td className='border_top border_left border_right'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_top border_left border_right'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_top border_left brt-0'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table className='notif_table w-100 mt-2'>
                                        <thead>
                                            <tr>
                                                <th className='border_none ps-0'>Module</th>
                                                <th className='border_none'>Type</th>
                                                <th className='border_none'>Push</th>
                                                <th className='border_none'>Email</th>
                                                <th className='border_none'>SMS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th rowspan="3" className='border_right'>
                                                    <h4 className='fs-18 fw-medium'>Contact</h4>
                                                    <p className='fs-14 text-muted lh-base'>Enable notifications to stay updated on important account information. Disable notifications if you prefer not to receive these alerts.</p>
                                                </th>
                                                <th className='border_right border_left border_bottom'>Create</th>
                                                <td className='border_bottom border_left border_right'>
                                                    <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_bottom border_right border_left'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_bottom border_left brb-0'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className='border_none'>Update</th>
                                                <td className='border_none'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_none'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_bottom border_left border_top br-0'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className='border_top border_left border_right br-0'>Delete</th>
                                                <td className='border_top border_left border_right'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_top border_left border_right'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                                <td className='border_top border_left brt-0'>
                                                <div class="switch-field">
                                                        <input id="cmn-toggle-1" class="switch" type="checkbox" />
                                                        <label for="cmn-toggle-1"></label>                                                
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </Tab>
                    {/* INVOICE TAB AREA */}
                </Tabs>
            </div>
        </div>
    )
}

export default Notification
