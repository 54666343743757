import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { http } from "../../../http/http";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import { SketchPicker } from 'react-color'
const Add_Task_Priority = (props) => {
    const { id } = useParams();
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
        setError, clearErrors,
        watch,
    } = useForm({ mode: "all" });
    const formValues = watch();
    const { showPriority, setShowPriority, handlePriorityData } = props;
    const [color, setColor] = useState('#cccccc');

    const [showColorPicker, setShowColorPicker] = useState(false);
    const handleColorChange = (event) => {
        const newColor = event.target.value;
        setColor(newColor);
    }
    const addPriority = () => {
        http(
            {
                method: "POST",
                url: `priority/add`,
                isSecure: true,
                body: {
                    name: formValues.name,
                    color_code: color,
                },
            },
            (res) => {
                toast.success(res.single_message)
                setShowPriority(false);
                handlePriorityData();
                reset();
            },
            (err) => {
                // Handle errors according to the response
                if (err.status === 401) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else if (err.status === 400) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else {
                    toast.error(err.single_message);
                }
            }
        );
    }
    return (
        <Modal
            show={showPriority}
            onHide={setShowPriority}
            centered

            contentClassName="mx-auto shadow-main"
            className="modal font-poppins"
            backdrop="static"
            keyboard={false}
        >
            <div className="modal-header border-0 justify-content-between">
                <h4 className="fs-20 fw-semibold">Add Priority</h4>
                <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
            </div>

            <div className="modal-body">
                <form onSubmit={handleSubmit(addPriority)}>
                    <div className="row">
                        <div className="col-lg-12 text-start">
                            <input
                                name="name"
                                id="name"
                                class="form-input mt-2 mx-0"
                                type="text"
                                {...register("name", {
                                    required: "required"
                                })}
                            />
                            {errors?.name && <span className="error">{errors?.name.message}</span>}
                        </div>

                        <div className="col-lg-12 mt-3">
                            <div style={{ display: 'block', alignItems: 'center', }}>
                                <label>Select a Color:</label>
                                <input type='color' value={color} onChange={handleColorChange} style={{ width: '80px', height: '45px', borderRadius: '15px', border: '0px', }} />
                                <p>{color}</p>
                            </div>
                        </div>
                        <div className="col-lg-12 mt-4 mb-2">
                            <button type="submit" class="btn-blue block ms-auto">Add</button>
                        </div></div>
                </form>
            </div>
        </Modal>
    )
}
export default Add_Task_Priority;