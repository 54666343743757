import React from 'react'

const Grids = () => {
    return (
        <div className="col-lg-12 d-flex justify-content-between align-items-center mb-2 comm-head">
            <div className="left_head">
                <h4 className="fs-20 mt-3 fw-semibold">Files</h4>
            </div>
            <div className="right_head mt-3">
                <div className="files-btn-group d-flex align-items-center">
                    <button className="grid_list active">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                            <g id="Icon" transform="translate(-6 -6)">
                                <path id="Path_3657" data-name="Path 3657" d="M13.2,15.6a.8.8,0,0,0-.8-.8H6.8a.8.8,0,0,0-.8.8v5.6a.8.8,0,0,0,.8.8h5.6a.8.8,0,0,0,.8-.8Zm8.8,0a.8.8,0,0,0-.8-.8H15.6a.8.8,0,0,0-.8.8v5.6a.8.8,0,0,0,.8.8h5.6a.8.8,0,0,0,.8-.8Zm0-8.8a.8.8,0,0,0-.8-.8H15.6a.8.8,0,0,0-.8.8v5.6a.8.8,0,0,0,.8.8h5.6a.8.8,0,0,0,.8-.8Zm-8.8,0a.8.8,0,0,0-.8-.8H6.8a.8.8,0,0,0-.8.8v5.6a.8.8,0,0,0,.8.8h5.6a.8.8,0,0,0,.8-.8Z" fill-rule="evenodd" />
                            </g>
                        </svg>
                        Grid
                    </button>
                    <button className="grid_list">
                        <svg className="list" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 15.957 15.5">
                            <g id="view" transform="translate(-3.25 -3.436)">
                                <rect id="Rectangle_371" data-name="Rectangle 371" width="6" height="6" rx="2" transform="translate(4 4.186)" fill="none" stroke-width="1.5" />
                                <rect id="Rectangle_372" data-name="Rectangle 372" width="6" height="5" rx="2" transform="translate(4 13.186)" fill="none" stroke-width="1.5" />
                                <path id="Path_3658" data-name="Path 3658" d="M13,5h7.156M13,13.945h7.156M13,7.684h3.578M13,16.629h3.578" transform="translate(-0.949 -0.067)" fill="none" stroke-width="1.5" />
                            </g>
                        </svg>
                        List
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Grids;
