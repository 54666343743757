import React, { useEffect, useState, useCallback, useContext } from "react";
import { Modal } from "react-bootstrap";
import { http } from "../../http/http";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Link, useParams, useNavigate } from "react-router-dom";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { debounce } from "lodash";
import Business_Contact_Detail from "../../common/Business_Contact_Detail";
import { GlobalContext } from "../../../App";

const Add_Contact = (props) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
        watch,
    } = useForm({ mode: "all" });
    const formValues = watch();
    const { showLoader, setShowLoader } = useContext(GlobalContext);
    // const businessId = JSON.parse(localStorage.getItem("BusinessId"));
    // const userID = JSON.parse(localStorage.getItem('__assist_erp'));
    const { contactModal, setContactModal, fetchContactData } = props;
    const [designationList, setDesignationList] = useState([]);
    const [primaryContact, setPrimaryContact] = useState("0");
    const [address, setAddress] = useState('');
    const [province, setProvince] = useState('');
    const [city, setCity] = useState('');
    const [postalCode, setPostalCode] = useState("");
    const [searchResults, setSearchResults] = useState([]); // List of search results
    const [selectedContact, setSelectedContact] = useState(null); // Selected contact data
    const [searchTerm, setSearchTerm] = useState(""); // Input value for search
    const [hasSearched, setHasSearched] = useState(false);
    const [contactBusinessOpenModel, setOpenContactBusinessModel] = useState(false);
    const [openContactModalKey, setOpenContactModalKey] = useState(null);
    const [searchError, setSearchError] = useState(""); // To store validation message
    const [sourceModal, setSourceModal] = useState(null);
    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        middle_name: "",
        company_name: "",
        phone_number: "",
        designation_id: "",
        primary_contact: '',
        address: "",
        city: "",
        province: "",
        postal_code: "",
        notes: "",
        email: "",
    });
    const [formErrors, setFormErrors] = useState({});
    const [isContactSelected, setIsContactSelected] = useState(false);
    const [isAddingNew, setIsAddingNew] = useState(false);

    const resetForm = () => {
        setFormData({
            first_name: "",
            last_name: "",
            middle_name: "",
            company_name: "",
            phone_number: "",
            designation_id: "",
            primary_contact: '',
            address: "",
            city: "",
            province: "",
            postal_code: "",
            notes: "",
            email: "",
        });
        setAddress('');
        setCity('');
        setProvince('');
        setPostalCode("");
        setSearchTerm('');
        setSelectedContact(null);
        setSearchResults([]);
        setIsContactSelected(false);
        setIsAddingNew(false);
    };
    const [searchDesignationValue, setSearchDesignationValue] = useState("");

    const filteredDesignationList =
        designationList &&
        designationList.filter((designation) =>
            designation.name.toLowerCase().includes(searchDesignationValue.toLowerCase())
        );
    const onCancel = () => {
        resetForm();
        setContactModal(false); // Close the modal
    };

    const handleaddressChange = (name, value) => {
        setFormData((prev) => ({
            ...prev,
            [name]: value, // Dynamically update the field in formData
        }));
    };

    // Fetch data from API based on search input
    const fetchSearchResults = (query) => {
        let url;

        if (query === "ContactKey") {
            url = `v2/contact_detail`;
            setSearchTerm(""); // Clear the search term if the query is 'fromContact'
        } else {
            const queryParams = new URLSearchParams({ search: query });
            url = `v2/contact_detail?${queryParams.toString()}`;
        }

        // Make the HTTP request
        http(
            {
                method: "GET",
                url,
                isSecure: true,
            },
            (res) => {
                if (res?.data?.data) {
                    if (query === "ContactKey") {
                        const filteredResults = res.data.data[0]; // Assuming 'data' is an array
                        setSelectedContact(filteredResults); // Set the first contact as selected
                    } else {
                        const filteredResults = res.data.data.filter(
                            (contact) =>
                                contact.first_name?.toLowerCase().includes(query.toLowerCase()) ||
                                contact.company_name?.toLowerCase().includes(query.toLowerCase())
                        );
                        setSearchResults(filteredResults); // Update the search results state
                    }
                } else {
                    setSearchResults([]); // Clear the search results
                }
            },
            (err) => {
                toast.error(err.single_message);
                setShowLoader(false);
            }
        );
    };


    // Debounced search handler
    const debouncedSearch = useCallback(
        debounce((query) => {
            if (query.trim()) {
                fetchSearchResults(query);
            } else {
                setSearchResults([]); // Clear results if no query
            }
        }, 300),
        []
    );



    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearchTerm(query);
        setIsContactSelected(false);
        setIsAddingNew(false);
        setFormData({
            ...formData,
            address: "",
            city: "",
            province: "",
            postal_code: "",
            first_name: "",
            middle_name: "",
            company_name: "",
            last_name: "",
            designation_id: "",
            email: "",
            phone_number: "",
            primary_contact: "",
            notes: "",

        });
        setAddress("");
        setProvince("");
        setCity("");

        // Reset 'hasSearched' and search results when the input is cleared
        if (!query.trim()) {
            setHasSearched(false);
            setSearchResults([]); // Clear search results
        } else {
            setHasSearched(true);
            debouncedSearch(query); // Call debounced search
        }
    };


    const handleSelectContact = (contact) => {
        setSelectedContact(contact); // Save selected contact in state
        setSearchTerm(''); // Clear the search term to hide the dropdown
        setSearchResults([]);
        setHasSearched(false);
        setIsContactSelected(true);
        // Prefill form fields
        setAddress(contact.street_address || "");
        setProvince(contact.province || "");
        setCity(contact.city || "");
        setPostalCode(contact.postal_code || "");
        setFormData({
            ...formData,
            address: contact.street_address || "",
            city: contact.city || "",
            province: contact.province || "",
            postal_code: contact.postal_code || "",
            first_name: contact.first_name || "",
            middle_name: contact.middle_name || "",
            company_name: contact.company_name || "",
            last_name: contact.last_name || "",
            designation_id: contact.designation_id || "",
            email: contact.email || "",
            phone_number: contact.phone_number || "",
            primary_contact: contact.primary_contact || "",
            notes: contact.notes || "",
        });

        // Clear validation errors for the pre-filled fields
        setFormErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };

            // Remove errors for fields being pre-filled
            if (contact.first_name) delete updatedErrors.first_name;
            if (contact.last_name) delete updatedErrors.last_name;
            if (contact.company_name) delete updatedErrors.company_name;
            if (contact.phone_number) delete updatedErrors.phone_number;
            if (contact.email) delete updatedErrors.email;
            // if (contact.designation_id) delete updatedErrors.designation_id;
            delete updatedErrors.searchTerm;
            return updatedErrors;
        });

    };

    const handleDesignationList = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `designation/listing`,
                    isSecure: true,
                },
                (res) => {
                    setDesignationList(res?.data?.data);
                },
            )
        }
        catch (error) {
            console.error('Error fetching staff data:', error);
        }
    };

    useEffect(() => {
        handleDesignationList();
    }, []);


    const onSubmitContact = async (e) => {
        e.preventDefault();
        // Call the validation function
        const errors = validateForm();
        // Update the formErrors state with validation errors
        setFormErrors(errors);
        // Check if there are any errors
        if (Object.keys(errors).length > 0) {
            console.error("Validation Errors:", errors);
            return; // Stop the submission process
        }
        setShowLoader(true);
        const payload = {
            type: selectedContact?.type,
            first_name: formData.first_name || "",
            middle_name: formData.middle_name || "",
            last_name: formData.last_name || "",
            company_name: formData.company_name || "",
            phone_number: formData.phone_number,
            designation_id: formData.designation_id,
            primary_contact: primaryContact,
            address: formData.address,
            city: formData.city,
            province: formData.province,
            postal_code: formData.postal_code,
            notes: formData.notes,
            email: formData.email,
            corporate_id: id,
            member_type: 3,
            contact_detail_id: selectedContact?.id

        };
        try {
            http(
                {
                    method: "POST",
                    url: `v2/corporate_member/add`,
                    isSecure: true,
                    contentType: "application/json",
                    body: payload,
                },
                (res) => {
                    if (res) {
                        // Handle successful response
                        toast.success(res.single_message);
                        setShowLoader(false);
                        setContactModal(false);
                        fetchContactData();
                        resetForm();
                    }

                },
                (err) => {
                    // Handle errors according to the response
                    if (err.status === 401) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else if (err.status === 400) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    }
                }

            );
        } catch (error) {
            // Log unexpected errors and show a generic error message
            console.error("Error adding contact:", error);
        }
    };

    const handleSelect = async (value) => {
        setAddress(value); // Temporarily set the entered value

        try {
            const results = await geocodeByAddress(value); // Get full address details

            if (results && results[0]) {
                const addressComponents = results[0].address_components;
                const formattedAddress = results[0].formatted_address; // Full address
                let selectedProvince = formData.province; // Preserve manual value
                let postalCode = formData.postal_code; // Preserve manual value
                let selectedCity = formData.city; // Preserve manual value

                // Extract specific details from the address components
                addressComponents.forEach((component) => {
                    if (component.types.includes('administrative_area_level_1')) {
                        selectedProvince = component.short_name; // Province
                    }
                    if (component.types.includes('postal_code')) {
                        postalCode = component.short_name; // Postal Code
                    }
                    if (component.types.includes('locality')) {
                        selectedCity = component.long_name; // City
                    }
                });

                // Set the values in the formData
                setFormData((prev) => ({
                    ...prev,
                    address: formattedAddress, // Set full address
                    province: selectedProvince,
                    postal_code: postalCode,
                    city: selectedCity,
                }));
            } else {
                console.warn('No results found for the selected address.');
            }
        } catch (error) {
            console.error('Error selecting place:', error?.message || 'No message', error?.stack || 'No stack trace');
        }
    };


    const handleChange = (value) => {
        setAddress(value);
    };


    const onHideHandler = () => {
        setContactModal(false);

    };


    useEffect(() => {
        if (openContactModalKey) {

            fetchSearchResults(openContactModalKey);
            setOpenContactModalKey(null);
        }
    }, [openContactModalKey]);

    useEffect(() => {
        if (selectedContact) {
            // Update the search field with the selected contact's name or company name
            const selectedName = selectedContact.first_name || selectedContact.company_name || "";
            setSearchTerm(selectedName); // Show the selected name in the search field
            setAddress(selectedContact.street_address || "");
            setProvince(selectedContact.province || "");
            setCity(selectedContact.city || "");
            setPostalCode(selectedContact.postal_code || "");
            setFormData({
                ...formData,
                address: selectedContact.street_address || "",
                city: selectedContact.city || "",
                province: selectedContact.province || "",
                postal_code: selectedContact.postal_code || "",
                first_name: selectedContact.first_name || "",
                middle_name: selectedContact.middle_name || "",
                last_name: selectedContact.last_name || "",
                company_name: selectedContact.company_name || "",
                phone_number: selectedContact.phone_number || "",
                company_name: selectedContact.company_name || "",
                email: selectedContact.email || "",
                sin: selectedContact.sin || "",
                notes: selectedContact.notes || "",
                primary_contact: selectedContact.primary_contact || "",
                designation_id: selectedContact.designation_id || "",
            });
        }
        else if (isAddingNew) {
            // Reset form data when adding a new contact
            setFormData({
                address: "",
                city: "",
                province: "",
                postal_code: "",
                first_name: "",
                middle_name: "",
                company_name: "",
                last_name: "",
                designation_id: "",
                email: "",
                phone_number: "",
                primary_contact: "",
                notes: "",
            });
        } else {
            // Reset to show only the search field
            resetForm();
        }
    }, [selectedContact, isAddingNew]);

    const validateForm = () => {
        let errors = {};
        if (!searchTerm.trim()) {
            errors.searchTerm = "Required.";
        }
        if (!formData.phone_number) {
            errors.phone_number = "Required";
        }
        //  else if (formData.phone_number.length < 7) {
        //     errors.contact_number = "Phone number must be at least 7 digits.";
        // }
        if (selectedContact?.type === 1) {
            // Validate individual fields for type 1
            if (!formData.first_name) {
                errors.first_name = "First Name is required.";
            }
            if (!formData.last_name) {
                errors.last_name = "Last Name is required.";
            }
        }
        if (selectedContact?.type === 2) {
            // Validate company name for type 2
            if (!formData.company_name) {
                errors.company_name = "Company Name is required.";
            }
        }
        if (!formData.email) {
            errors.email = "Required";
        }
        // if (!formData.designation_id) {
        //     errors.designation_id = "Required";
        // }

        return errors;
    };

    const handleInputChange = (field, value) => {
        // Update the form data
        setFormData((prev) => ({
            ...prev,
            [field]: value,
        }));

        // Clear the error for the specific field if it exists
        setFormErrors((prevErrors) => {
            const { [field]: removedError, ...rest } = prevErrors;
            return rest;
        });
    };


    return (
        <>
            <Modal
                show={contactModal}
                onHide={onHideHandler}
                centered
                size="xl"
                contentClassName="mx-auto"
                className="modal operating-modal font-poppins"
                backdrop="static"
                keyboard={false}
            >
                <div className="modal-header border-0 justify-content-between px-4 pt-4 pb-0">
                    <h4 className="fs-20 fw-semibold">Add Contact</h4>
                    <Modal.Header closeButton onClick={onCancel} className="close-icon border-0"></Modal.Header>
                </div>

                <div className="modal-body">
                    <form onSubmit={onSubmitContact}>
                        <div className="advance-form row">
                            <div className="col-lg-4 col-md-12 mt-3 dctor-search">
                                <label className="fs-18">Search Contact <span className="text-danger">*</span></label>
                                <div className="position-relative mt-2">
                                    <input
                                        type="text"
                                        className="form-input dtr-srch"
                                        placeholder="Search for a name or company"
                                        value={searchTerm}
                                        onChange={handleSearchChange} // Handle search input changes
                                    />
                                    {searchTerm.trim() && hasSearched && (
                                        <ul className={`p-0 ${!searchTerm ? "bdr-none" : ""}`}>
                                            {hasSearched && searchResults.length === 0 ? (
                                                <li>
                                                    <p
                                                        className="t_blue fw-semibold fs-14 w-100 dropdown-item cursor-pointer pb-2"
                                                        onClick={() => {
                                                            // setContactModal(false); // Close the current modal
                                                            // setOpenContactBusinessModel(true); // Open the new modal
                                                            // setSourceModal("contact");
                                                            setContactModal(false);
                                                            setOpenContactBusinessModel(true);
                                                            setIsAddingNew(true); // Enable "Add New" mode
                                                            setSearchResults([]); // Clear search results
                                                            setSelectedContact(null); // Clear selected contact
                                                            setSearchTerm(""); // Clear search term
                                                            setSourceModal("business_contact");
                                                        }}
                                                    >
                                                        + Add New
                                                    </p>
                                                </li>
                                            ) : (
                                                searchResults.map(
                                                    (contact) =>
                                                        (contact.first_name || contact.company_name) && (
                                                            <li
                                                                key={contact.id}
                                                                className="list-group-item list-group-item-action d-flex justify-content-between align-items-start  w-auto search_dropbox"
                                                                onClick={() => handleSelectContact(contact)}
                                                                style={{ cursor: "pointer" }}
                                                            >
                                                                <p className="fs-16 text-black lh-1">
                                                                    {contact.first_name
                                                                        ? `${contact.first_name} ${contact.last_name || ""}`.trim()
                                                                        : contact.company_name || "Unknown Name"}
                                                                </p>
                                                                <div className="text-end">
                                                                    <p className="fs-16 text-black lh-1">
                                                                        {contact.email}
                                                                    </p>
                                                                    <p className="fs-10 text-primary lh-1">
                                                                        {contact.type === 1 ? "Client" : "Business"}
                                                                    </p>
                                                                </div>
                                                            </li>
                                                        )
                                                )
                                            )}
                                        </ul>
                                    )}
                                </div>
                                {formErrors.searchTerm && <div className="text-danger mt-2">{formErrors.searchTerm}</div>}
                            </div>
                            {isContactSelected || isAddingNew ? (
                                <>
                                    {selectedContact?.type === 1 && (
                                        <>
                                            <div className="col-lg-4 col-md-6 col-sm-12 mt-4">
                                                <label className="fs-18">First Name <span className="text-danger">*</span></label>
                                                <input
                                                    type="text"
                                                    className="form-input mt-2"
                                                    placeholder="Type here..."
                                                    name="first_name"
                                                    value={formData.first_name || ''}
                                                    onChange={(e) => handleInputChange('first_name', e.target.value)}
                                                    disabled
                                                />
                                                {formErrors.first_name && <span className="text-danger">{formErrors.first_name}</span>}
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-12 mt-4">
                                                <label className="fs-18">Middle Name </label>
                                                <input
                                                    type="text"
                                                    className="form-input mt-2"
                                                    placeholder="Type here..."
                                                    name="middle_name"
                                                    value={formData.middle_name || ''}
                                                    onChange={(e) => handleInputChange('middle_name', e.target.value)}
                                                    disabled
                                                />

                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-12 mt-4">
                                                <label className="fs-18">Last Name <span className="text-danger">*</span></label>
                                                <input
                                                    type="text"
                                                    className="form-input mt-2"
                                                    placeholder="Type here..."
                                                    name="last_name"
                                                    value={formData.last_name || ''}
                                                    onChange={(e) => handleInputChange('last_name', e.target.value)}
                                                    disabled
                                                />
                                                {formErrors.last_name && <span className="text-danger">{formErrors.last_name}</span>}
                                            </div>
                                        </>
                                    )}
                                    {selectedContact?.type === 2 && (
                                        <div className="col-lg-4 col-md-6 col-sm-12 mt-4">
                                            <>
                                                <label className="fs-18">Company Name <span className="text-danger">*</span></label>
                                                <input
                                                    type="text"
                                                    className="form-input mt-2"
                                                    placeholder="Type here..."
                                                    name="company_name"
                                                    value={formData.company_name || ''}
                                                    onChange={(e) => handleInputChange('company_name', e.target.value)}
                                                    disabled
                                                />
                                                {formErrors.company_name && <span className="text-danger">{formErrors.company_name}</span>}
                                            </>
                                        </div>
                                    )}
                                    <div className="col-lg-4 col-md-6 col-sm-12 mt-4">
                                        <label className="fs-18">Designation</label>
                                        <div className="custom-select-container w-100">
                                            <div className="dropdown">
                                                <button
                                                    className="form-input dropdown-toggle w-100 text-start mt-1"
                                                    type="button"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    {formData.designation_id
                                                        ? designationList.find(
                                                            (designation) => designation.id === formData.designation_id
                                                        )?.name || "Select"
                                                        : "Select"}
                                                </button>
                                                <div className="dropdown-menu w-100 p-0">
                                                    <ul className="p-0">
                                                        <li>
                                                            <input
                                                                type="search"
                                                                className="form-input"
                                                                placeholder="Search Designation..."
                                                                value={searchDesignationValue}
                                                                onChange={(e) => setSearchDesignationValue(e.target.value)}
                                                            />
                                                        </li>
                                                    </ul>
                                                    <ul className="p-0 listing_show">
                                                        {filteredDesignationList && filteredDesignationList.length > 0 ? (
                                                            filteredDesignationList.map((designation, index) => (
                                                                <li key={index}>
                                                                    <button
                                                                        className="dropdown-item"
                                                                        type="button"
                                                                        onClick={() => {
                                                                            handleInputChange("designation_id", designation.id); // Update selected value
                                                                            setSearchDesignationValue(""); // Clear search input
                                                                        }}
                                                                    >
                                                                        {designation.name}
                                                                    </button>
                                                                </li>
                                                            ))
                                                        ) : (
                                                            <li>
                                                                <button className="dropdown-item" type="button" disabled>
                                                                    No Designations Available
                                                                </button>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12 mt-4">
                                        <label className="fs-18">Phone No. <span className="text-danger">*</span></label>
                                        <input
                                            type="tel"
                                            name="phone_number"
                                            id="phone_number"
                                            maxLength={15}
                                            className="form-input mt-2"
                                            placeholder="Phone No."
                                            // {...register("phone_number", {
                                            //     required: "Required",
                                            //     validate: (value) => value.trim() !== "" || "Cannot be empty or just spaces",

                                            //     minLength: {
                                            //         value: 7,
                                            //         message: "Phone number must be at least 7 digits long"
                                            //     },
                                            // })}
                                            // onKeyDown={(e) => {
                                            //     if (
                                            //         e.key === "e" || // Prevent 'e'
                                            //         e.key === "E" || // Prevent 'E'
                                            //         e.key === "+" || // Prevent '+'
                                            //         e.key === "-" || // Prevent '-'
                                            //         e.key === "." || // Prevent '.'
                                            //         isNaN(Number(e.key)) && e.key !== "Backspace" && e.key !== "ArrowLeft" && e.key !== "ArrowRight"
                                            //     ) {
                                            //         e.preventDefault();
                                            //     }
                                            // }}
                                            value={formData.phone_number || ''}
                                            onChange={(e) => handleInputChange('phone_number', e.target.value)}
                                        />
                                        {formErrors.phone_number && <span className="text-danger">{formErrors.phone_number}</span>}
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12 mt-4">
                                        <label className="fs-18">Email Address <span className="text-danger">*</span></label>
                                        <input
                                            type="email"
                                            className="form-input mt-2"
                                            placeholder="Type here..."
                                            name="email"
                                            value={formData.email || ''}
                                            onChange={(e) => handleInputChange('email', e.target.value)}
                                        />
                                        {formErrors.email && <span className="text-danger">{formErrors.email}</span>}
                                    </div>
                                    <div className="col-lg-8 col-md-12 col-md-12 mt-4">
                                        <label className="fs-18">Street Address</label>

                                        <PlacesAutocomplete
                                            value={address}
                                            onChange={handleChange}  // Handle input change here
                                            onSelect={handleSelect}  // Handle address selection here
                                            searchOptions={{
                                                componentRestrictions: { country: ['us', 'ca'] },
                                            }}
                                        >
                                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                <div>
                                                    <input
                                                        {...getInputProps({
                                                            placeholder: 'Type address here...',
                                                            className: 'form-input mt-2 google-address',
                                                        })}
                                                    />
                                                    <div className="autocomplete-dropdown-container">
                                                        {loading && <div>Loading...</div>}
                                                        {suggestions.map((suggestion) => {
                                                            const className = suggestion.active
                                                                ? 'suggestion-item--active'
                                                                : 'suggestion-item';
                                                            return (
                                                                <div
                                                                    {...getSuggestionItemProps(suggestion, {
                                                                        className,
                                                                    })}
                                                                    key={suggestion.placeId}
                                                                >
                                                                    <span>{suggestion.description}</span>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            )}
                                        </PlacesAutocomplete>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                        <label className="fs-18">Province</label>
                                        <input
                                            className="form-input mt-2"
                                            name="province"
                                            id="province"
                                            value={formData.province || ''} // Bind to formData state
                                            onChange={(e) => handleaddressChange('province', e.target.value)} // Update dynamically
                                        />
                                    </div>

                                    <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                        <label className="fs-18">Postal Code</label>
                                        <input
                                            type="text"
                                            name="postal_code"
                                            id="postal_code"
                                            className="form-input mt-2"
                                            placeholder="Type here..."
                                            value={formData.postal_code || ''} // Bind to formData state
                                            onChange={(e) => handleaddressChange('postal_code', e.target.value)} // Update dynamically
                                        />
                                    </div>

                                    <div className="col-lg-4 col-md-6 col-sm-12 mt-4">
                                        <label className="fs-18">City</label>
                                        <input
                                            type="text"
                                            className="form-input mt-2"
                                            placeholder="Type here..."
                                            value={formData.city || ''} // Bind to formData state
                                            onChange={(e) => handleaddressChange('city', e.target.value)} // Update dynamically
                                        />
                                    </div>
                                    <div className="col-md-12 mt-4">
                                        <label className="fs-18">Note</label>
                                        <textarea
                                            id=""
                                            className="mt-2"
                                            rows="5"
                                            cols="10"
                                            name="notes"
                                            value={formData.notes || ''}
                                            onChange={(e) => handleInputChange('notes', e.target.value)}
                                        >
                                        </textarea>
                                    </div>
                                    <div className="col-md-12 mt-4 mb-2">
                                        <button type="submit" className="btn-blue px-4 ms-auto">Add</button>
                                    </div>
                                </>
                            ) : null}
                        </div>
                    </form>

                </div>
            </Modal>

            <Business_Contact_Detail
                contactBusinessOpenModel={contactBusinessOpenModel}
                setOpenContactBusinessModel={setOpenContactBusinessModel}
                setContactModal={setContactModal}
                setOpenContactModalKey={setOpenContactModalKey}
                openContactModalKey={openContactModalKey}
                sourceModal={sourceModal}
                fetchSearchResults={fetchSearchResults}
                setIsAddingNew={setIsAddingNew}

            />

        </>



    )
}
export default Add_Contact;