import React, { useState } from 'react'

const DependedLinkedDetail = ({ dependedData, onSave }) => {
    const [showSIN, setShowSIN] = useState(false);
    const [formData, setFormData] = useState({
        first_name: dependedData?.first_name || "",
        middle_name: dependedData?.middle_name || "",
        last_name: dependedData?.last_name || "",
        sin: dependedData?.sin || "",
        date_of_birth: dependedData?.date_of_birth || "",
        relation: dependedData?.relation || "",
    });
    const toggleSINVisibility = () => {
        setShowSIN(!showSIN); // Toggle between showing and hiding SIN
    };
    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    // Save logic
    const handleSubmit = (e) => {
        e.preventDefault();
        if (onSave) {
            onSave(formData); // Pass updated data to the parent or save via API
        }
    };
    return (
        <>

            <form onSubmit={handleSubmit}>
                <div className="row light_input">
                    <div className="col-md-6 mt-3">
                        <label htmlFor="first_name">First Name</label>
                        <input
                            type="text"
                            id="first_name"
                            name="first_name"
                            className="form-input mt-2"
                            value={formData.first_name}
                            onChange={handleChange}
                            placeholder="Enter first name"
                            readOnly
                        />
                    </div>
                    <div className="col-md-6 mt-3">
                        <label htmlFor="middle_name">Middle Name</label>
                        <input
                            type="text"
                            id="middle_name"
                            name="middle_name"
                            className="form-input mt-2"
                            value={formData.middle_name}
                            onChange={handleChange}
                            placeholder="Enter middle name"
                            readOnly
                        />
                    </div>
                    <div className="col-md-6 mt-3">
                        <label htmlFor="last_name">Last Name</label>
                        <input
                            type="text"
                            id="last_name"
                            name="last_name"
                            className="form-input mt-2"
                            value={formData.last_name}
                            onChange={handleChange}
                            placeholder="Enter last name"
                            readOnly
                        />
                    </div>
                    <div className="col-md-6 mt-3">
                        <label htmlFor="sin">SIN</label>

                        <div className="sin_area">
                            <input
                                type={showSIN ? "text" : "password"}
                                id="sin"
                                name="sin"
                                className="form-input mt-2"
                                value={formData.sin}
                                onChange={handleChange}
                                placeholder="Enter SIN"
                                readOnly
                            />
                            <div className="sin_icons">
                                {showSIN ? (
                                    <i
                                        className="fa fa-eye-slash"
                                        onClick={toggleSINVisibility} // Hide SIN when clicked
                                    ></i>
                                ) : (
                                    <i
                                        className="fa fa-eye"
                                        onClick={toggleSINVisibility} // Show SIN when clicked
                                    ></i>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <label htmlFor="contact_number">Relation</label>
                        <input
                            type="text"
                            id="relation"
                            name="relation"
                            className="form-input mt-2"
                            value={formData.relation}
                            onChange={handleChange}
                            placeholder="Enter contact number"
                            readOnly
                        />
                    </div>
                    <div className="col-md-6 mt-3">
                        <label htmlFor="date_of_birth">Date of Birth</label>
                        <input
                            type="date"
                            id="date_of_birth"
                            name="date_of_birth"
                            className="form-input mt-2"
                            value={formData.date_of_birth}
                            onChange={handleChange}
                            readOnly
                        />
                    </div>
                    {/* <div className="col-md-12 mt-4">
                <button type="submit" className="btn-blue px-4">
                    Save Changes
                </button>
            </div> */}
                </div>
            </form>
        </>
    )
}

export default DependedLinkedDetail;
