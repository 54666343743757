import React, { useState, useContext, useEffect } from "react";
import { http } from "../../http/http";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import { GlobalContext } from "../../../App";
import SpouseDetail from "../../common/SpouseDetail";
import DependedLinkedDetail from "../../common/DependedLinkedDetail";
import ContactLinkedDetail from "../../common/ContactLinkedDetail";
import ClientLinkedDetail from "../../common/ClientLinkedDetail";
import Business_Linked_Contact_Detail from "../../common/Business_Linked_Contact_Detail";
import Business_Linked_Director_Detail from "../../common/Business_Linked_Director_Detail";
import Business_Linked_ShareHolder_Detail from "../../common/Business_Linked_ShareHolder_Detail";
import Business_Linked_Detail from "../../common/Business_Linked_Detail";
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import Delete from "../../feature/Delete";

const ContactDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { setShowLoader } = useContext(GlobalContext);
    const [contactViewDetail, setContactViewDetail] = useState({
        first_name: "",
        middle_name: "",
        last_name: "",
        sin: "",
        company_name: "",
        email: "",
        phone_number: "",
        dob: "",
        gender: null,
        marital_status: null,
        street_address: "",
        city: "",
        state: "",
        country: "",
        province: "",
        postal_code: "",
        status: 1,
        notes: "",
        type: "",
    });
    const [linkedProfiles, setLinkedProfiles] = useState([]);
    const [contactRelations, setContactRelations] = useState([]);
    const [openAccordion, setOpenAccordion] = useState(null);
    const [selectedInputs, setSelectedInputs] = useState([]);
    const [showSIN, setShowSIN] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [selectedContactId, setSelectedContactId] = useState(null);


    const toggleSINVisibility = () => {
        setShowSIN(!showSIN); // Toggle between showing and hiding SIN
    };

    const handleCheckboxChange = (relation) => {
        setLinkedProfiles((prev) => {
            const exists = prev.find((profile) => profile.id === relation.id);
            if (exists) {
                // Remove the profile if it already exists in the array
                return prev.filter((profile) => profile.id !== relation.id);
            } else {
                // Add the profile if it does not exist in the array
                return [
                    ...prev,
                    { id: relation.id, model_id: relation.model_id, model_type: relation.model_type },
                ];
            }
        });
    };


    const toggleAccordion = (index) => {

        setOpenAccordion((prev) => (prev === index ? null : index));
    };

    // Fetch contact details
    const fetchContactDetail = async () => {
        setShowLoader(true);
        try {
            await http(
                {
                    method: "GET",
                    url: `v2/contact_detail/${id}/view`,
                    isSecure: true,
                },
                (res) => {
                    setContactViewDetail({
                        first_name: res.data.first_name,
                        last_name: res.data.last_name,
                        sin: res.data.sin,
                        middle_name: res.data.middle_name,
                        company_name: res.data.company_name,
                        email: res.data.email,
                        phone_number: res.data.phone_number,
                        street_address: res.data.street_address,
                        city: res.data.city,
                        province: res.data.province,
                        postal_code: res.data.postal_code,
                        country: res.data.country,
                        type: res.data.type,
                    });


                    setShowLoader(false);
                    setContactRelations(res?.data?.contact_relations || []);
                    // Ensure selectedInputs is updated based on the server's response
                    if (res?.data?.selected_keys) {
                        setSelectedInputs(res?.data.selected_keys);
                    } else {
                        setSelectedInputs([]);
                    }

                },
                (err) => {
                    toast.error(err.single_message);
                    setShowLoader(false);
                }
            );
        } catch (error) {
            console.error("Error fetching contact detail:", error);
            setShowLoader(false);
        }
    };


    // Update contact details

    const validatePayload = () => {
        if (linkedProfiles.length > 0 && selectedInputs.length === 0) {
            console.error("Validation Error: At least one key must be selected if there are linked profiles.");
            return false;
        }
        return true;
    };
    const updateContactDetail = async () => {
        if (!validatePayload()) {
            toast.error("Please select at least one key if there are linked profiles.");
            return;
        }
        setShowLoader(true);
        const payload = {
            ...contactViewDetail,
            type: contactViewDetail?.type,
            linked_profiles: linkedProfiles,
            selected_keys: selectedInputs,
        };
        try {
            await http(
                {
                    method: "PUT",
                    url: `v2/contact_detail/update/${id}`,
                    body: payload,
                    isSecure: true,
                },
                async (res) => {
                    toast.success(res.single_message);
                    await fetchContactDetail(); // Fetch updated details dynamically
                    setOpenAccordion(null);
                    setLinkedProfiles([]);
                    setSelectedInputs([]);
                    setShowLoader(false);
                },
                (err) => {
                    toast.error(err.single_message);
                    setShowLoader(false);
                }
            );
        } catch (error) {
            console.error("Error updating contact detail:", error);
            setShowLoader(false);
        }
    };
    const handleAddressSelect = async (address) => {
        setContactViewDetail((prevState) => ({
            ...prevState,
            street_address: address, // Update the street_address field first
        }));

        try {
            const results = await geocodeByAddress(address); // Get address details
            const addressComponents = results[0].address_components; // Extract components

            // Extract specific components
            const city = addressComponents.find((comp) =>
                comp.types.includes("locality")
            )?.long_name;

            const province = addressComponents.find((comp) =>
                comp.types.includes("administrative_area_level_1")
            )?.long_name;

            const postalCode = addressComponents.find((comp) =>
                comp.types.includes("postal_code")
            )?.long_name;

            const country = addressComponents.find((comp) =>
                comp.types.includes("country")
            )?.long_name;

            // Update the state with extracted components
            setContactViewDetail((prevState) => ({
                ...prevState,
                city: city || prevState.city,
                province: province || prevState.province,
                postal_code: postalCode || prevState.postal_code,
                country: country || prevState.country,
            }));
        } catch (error) {
            console.error("Error fetching address details:", error);
        }
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setContactViewDetail((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleCheckboxChangeInput = (e) => {
        const { name, checked } = e.target;
        setSelectedInputs((prev) => {
            const updatedInputs = checked
                ? [...prev, name] // Add the checkbox name if checked
                : prev.filter((inputName) => inputName !== name); // Remove if unchecked
            return updatedInputs;
        });
    };

    const deleteContact = () => {
        setShowLoader(true);
        try {
            http(
                {
                    method: "DELETE",
                    url: `v2/contact_detail/${id}/delete`,
                    isSecure: true,
                },
                (res) => {
                    toast.success(res.single_message);
                    setShowLoader(false);
                    navigate("/admin/client?tab=contact&page=1");
                },
                (err) => {
                    // Handle errors according to the response
                    if (err.status === 401) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else if (err.status === 400) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    }
                }
            );
        } catch (error) {
            console.error('Error deleting director:', error);
        }
    };

    const handleDeleteIconClick = (contactId) => {
        setSelectedContactId(contactId); // Set the task ID to be deleted
        setOpenDeleteModal(true); // Open the delete modal
    };

    const handleDeleteConfirm = () => {
        setOpenDeleteModal(false); // Close the modal
        deleteContact(selectedContactId); // Call the delete function
    };

    useEffect(() => {
        fetchContactDetail();
    }, []);

    return (
        <div className="right_section font-poppins">
            <div className="clients-detail-area py-3">
                <div className="business-form-area py-3">
                    <div className="row business-head">
                        <div className="col-md-12 left_head">
                            <h4 className="fs-20 mt-2 fw-semibold">Master Contact Management</h4>
                        </div>

                        {/* Main Contact Section */}
                        <div className="col-md-12">
                            <div className="main-form-area p-4 mt-3 bg-white shadow-lg row">

                                <div className="col-md-12 p-0">
                                    <form>
                                        <div className="row">
                                            <div className="col-md-8 p-0">
                                                <div className="row">
                                                    <div className="col-lg-12 d-flex align-items-center justify-content-between gap-2 mb-3">
                                                        <h5 className="fs-20 fw-semibold">Basic Details</h5>
                                                        {Array.isArray(contactRelations) &&
                                                            contactRelations.length === 0 && (
                                                                <a
                                                                    className="file-delete"
                                                                    onClick={() => handleDeleteIconClick(contactViewDetail.id)}
                                                                >
                                                                    <img
                                                                        alt=""
                                                                        src={require("../../../assets/images/delete-icon.png")}
                                                                    />
                                                                </a>
                                                            )}

                                                    </div>

                                                </div>
                                                <div className="row scroll_box">
                                                    {contactViewDetail.type === 1 && (
                                                        <>
                                                            <div className="col-md-4 mt-3">
                                                                <label htmlFor="first_name">First Name</label>
                                                                <input
                                                                    type="text"
                                                                    name="first_name"
                                                                    id="first_name"
                                                                    className="form-input mt-2"
                                                                    value={contactViewDetail.first_name}
                                                                    onChange={handleInputChange}
                                                                    placeholder="Enter first name"
                                                                    disabled
                                                                />
                                                                {/* <input
                                                                    type="checkbox"
                                                                    name="first_name"
                                                                    checked={selectedInputs.includes("first_name")}
                                                                    onChange={handleCheckboxChangeInput}
                                                                /> */}
                                                            </div>
                                                            <div className="col-md-4 mt-3">
                                                                <label htmlFor="last_name">Middle Name</label>
                                                                <input
                                                                    type="text"
                                                                    name="middle_name"
                                                                    id="middle_name"
                                                                    className="form-input mt-2"
                                                                    value={contactViewDetail.middle_name}
                                                                    onChange={handleInputChange}
                                                                    placeholder="Enter last name"
                                                                    disabled
                                                                />
                                                                {/* <input
                                                                    type="checkbox"
                                                                    name="middle_name"
                                                                    checked={selectedInputs.includes("middle_name")}
                                                                    onChange={handleCheckboxChangeInput}
                                                                /> */}
                                                            </div>
                                                            <div className="col-md-4 mt-3">
                                                                <label htmlFor="last_name">Last Name</label>
                                                                <input
                                                                    type="text"
                                                                    name="last_name"
                                                                    id="last_name"
                                                                    className="form-input mt-2"
                                                                    value={contactViewDetail.last_name}
                                                                    onChange={handleInputChange}
                                                                    placeholder="Enter last name"
                                                                    disabled
                                                                />
                                                                {/* <input
                                                                    type="checkbox"
                                                                    name="last_name"
                                                                    checked={selectedInputs.includes("last_name")}
                                                                    onChange={handleCheckboxChangeInput}
                                                                /> */}
                                                            </div>
                                                            <div className="col-md-4 mt-3">
                                                                <div className="form_checks d-flex align-items-center gap-2">
                                                                    <label htmlFor="sin">SIN</label>
                                                                    <input
                                                                        type="checkbox"
                                                                        name="sin"
                                                                        checked={selectedInputs.includes("sin")}
                                                                        onChange={handleCheckboxChangeInput}
                                                                    />
                                                                </div>
                                                                <div className="sin_area" >
                                                                    <input
                                                                        type={showSIN ? "text" : "password"} // Conditionally show or hide SIN
                                                                        className="form-input mt-2"
                                                                        placeholder="Type here..."
                                                                        name="sin"
                                                                        maxLength={9}
                                                                        value={contactViewDetail.sin}
                                                                        onChange={handleInputChange}
                                                                    />

                                                                    <div className="sin_icons">
                                                                        {showSIN ? (
                                                                            <i
                                                                                className="fa fa-eye-slash"
                                                                                onClick={toggleSINVisibility} // Hide SIN when clicked
                                                                            ></i>
                                                                        ) : (
                                                                            <i
                                                                                className="fa fa-eye"
                                                                                onClick={toggleSINVisibility} // Show SIN when clicked
                                                                            ></i>
                                                                        )}
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </>
                                                    )}

                                                    {contactViewDetail.type === 2 && (
                                                        <div className="col-md-8 mt-3">
                                                            <label htmlFor="company_name">Company Name</label>
                                                            <input
                                                                type="text"
                                                                name="company_name"
                                                                id="company_name"
                                                                className="form-input mt-2"
                                                                value={contactViewDetail.company_name}
                                                                onChange={handleInputChange}
                                                                placeholder="Enter company name"
                                                            />
                                                            {/* <input
                                                                type="checkbox"
                                                                name="company_name"
                                                                checked={selectedInputs.includes("company_name")}
                                                                onChange={handleCheckboxChangeInput}
                                                            /> */}
                                                        </div>
                                                    )}

                                                    <div className="col-md-4 mt-3">
                                                        <div className="form_checks d-flex align-items-center gap-2">
                                                            <label htmlFor="email">Email</label>
                                                            <input
                                                                type="checkbox"
                                                                name="email"
                                                                checked={selectedInputs.includes("email")}
                                                                onChange={handleCheckboxChangeInput}
                                                            />
                                                        </div>

                                                        <input
                                                            type="email"
                                                            name="email"
                                                            id="email"
                                                            className="form-input mt-2"
                                                            value={contactViewDetail.email}
                                                            onChange={handleInputChange}
                                                            placeholder="Enter email"
                                                        />

                                                    </div>
                                                    <div className="col-md-4 mt-3">
                                                        <div className="form_checks d-flex align-items-center gap-2">
                                                            <label htmlFor="phone_number">Phone</label>
                                                            <input
                                                                type="checkbox"
                                                                name="phone_number"
                                                                checked={selectedInputs.includes("phone_number")}
                                                                onChange={handleCheckboxChangeInput}
                                                            />
                                                        </div>
                                                        <input
                                                            type="text"
                                                            name="phone_number"
                                                            id="phone_number"
                                                            className="form-input mt-2"
                                                            value={contactViewDetail.phone_number}
                                                            onChange={handleInputChange}
                                                            placeholder="Enter phone number"
                                                        />

                                                    </div>
                                                    <div className="col-md-8 mt-3">
                                                        <div className="form_checks d-flex align-items-center gap-2">
                                                            <label htmlFor="street_address">Address</label>
                                                            <input
                                                                type="checkbox"
                                                                name="street_address"
                                                                checked={selectedInputs.includes("street_address")}
                                                                onChange={handleCheckboxChangeInput}
                                                            />
                                                        </div>

                                                        <PlacesAutocomplete
                                                            value={contactViewDetail.street_address}
                                                            onChange={(address) =>
                                                                setContactViewDetail((prevState) => ({
                                                                    ...prevState,
                                                                    street_address: address,
                                                                }))
                                                            }
                                                            onSelect={handleAddressSelect}
                                                        >
                                                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                                <div>
                                                                    <input
                                                                        {...getInputProps({
                                                                            placeholder: "Search address...",
                                                                            className: "form-input mt-2",
                                                                        })}
                                                                    />
                                                                    <div className="autocomplete-dropdown">
                                                                        {loading && <div>Loading...</div>}
                                                                        {suggestions.map((suggestion) => {
                                                                            const className = suggestion.active
                                                                                ? "suggestion-item--active"
                                                                                : "suggestion-item";
                                                                            return (
                                                                                <div
                                                                                    {...getSuggestionItemProps(suggestion, {
                                                                                        className,
                                                                                    })}
                                                                                >
                                                                                    <span>{suggestion.description}</span>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </PlacesAutocomplete>

                                                    </div>
                                                    <div className="col-md-4 mt-3">
                                                        <div className="form_checks d-flex align-items-center gap-2">
                                                            <label htmlFor="city">City</label>
                                                            <input
                                                                type="checkbox"
                                                                name="city"
                                                                checked={selectedInputs.includes("city")}
                                                                onChange={handleCheckboxChangeInput}
                                                            />
                                                        </div>
                                                        <input
                                                            type="text"
                                                            name="city"
                                                            id="city"
                                                            className="form-input mt-2"
                                                            value={contactViewDetail.city}
                                                            onChange={handleInputChange}
                                                            placeholder="Enter city"
                                                        />
                                                    </div>
                                                    <div className="col-md-4 mt-3">
                                                        <div className="form_checks d-flex align-items-center gap-2">
                                                            <label htmlFor="province">Province</label>
                                                            <input
                                                                type="checkbox"
                                                                name="province"
                                                                checked={selectedInputs.includes("province")}
                                                                onChange={handleCheckboxChangeInput}
                                                            />
                                                        </div>
                                                        <input
                                                            type="text"
                                                            name="province"
                                                            id="province"
                                                            className="form-input mt-2"
                                                            value={contactViewDetail.province}
                                                            onChange={handleInputChange}
                                                            placeholder="Enter province"
                                                        />
                                                    </div>
                                                    <div className="col-md-4 mt-3">
                                                        <div className="form_checks d-flex align-items-center gap-2">
                                                            <label htmlFor="country">Country</label>
                                                            <input
                                                                type="checkbox"
                                                                name="country"
                                                                checked={selectedInputs.includes("country")}
                                                                onChange={handleCheckboxChangeInput}
                                                            />
                                                        </div>
                                                        <input
                                                            type="text"
                                                            name="country"
                                                            id="country"
                                                            className="form-input mt-2"
                                                            value={contactViewDetail.country}
                                                            onChange={handleInputChange}
                                                            placeholder="Enter country"
                                                        />
                                                    </div>

                                                    <div className="col-md-4 mt-3">
                                                        <div className="form_checks d-flex align-items-center gap-2">
                                                            <label htmlFor="postal_code">Postal Code</label>
                                                            <input
                                                                type="checkbox"
                                                                name="postal_code"
                                                                checked={selectedInputs.includes("postal_code")}
                                                                onChange={handleCheckboxChangeInput}
                                                            />
                                                        </div>
                                                        <input
                                                            type="text"
                                                            name="postal_code"
                                                            id="postal_code"
                                                            className="form-input mt-2"
                                                            value={contactViewDetail.postal_code}
                                                            onChange={handleInputChange}
                                                            placeholder="Enter postal code"
                                                        />
                                                    </div>
                                                    <div className="col-md-12">
                                                        <hr className="my-3" />
                                                    </div>

                                                    <div className="col-md-12">
                                                        <h4 className="fs-20 fw-semibold">Linked Profiles</h4>
                                                        <div className="accordion-container mt-3 contact_accordion" style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                                                            {contactRelations.length > 0 &&
                                                                contactRelations.map((relation, index) => {
                                                                    // Determine the component to render dynamically
                                                                    let component;
                                                                    let link_name = "";
                                                                    let heading = relation?.realtion_heading;
                                                                    let linkedTo = relation?.model?.linked_to;
                                                                    let link_profile;
                                                                    let link_url;


                                                                    switch (relation?.realtion_type) {
                                                                        case "Spouse":
                                                                            link_name = "Spouse of"
                                                                            // link_profile =  linkedTo?first_name 
                                                                            component = <SpouseDetail spouseData={relation.model} />;
                                                                            break;
                                                                        case "ClientContact":
                                                                            link_name = "Contact of"
                                                                            component = <ContactLinkedDetail contactData={relation.model} />;
                                                                            break;
                                                                        case "Client":
                                                                            link_name = "Client as"
                                                                            component = <ClientLinkedDetail clientData={relation.model} />;
                                                                            break;
                                                                        case "Dependent":
                                                                            link_name = "Dependent of"
                                                                            component = <DependedLinkedDetail dependedData={relation.model} />;
                                                                            break;
                                                                        case "Corporate":
                                                                            link_name = "Business as"
                                                                            component = <Business_Linked_Detail businessData={relation.model} />;
                                                                            break;
                                                                        case "BusinessContact":
                                                                            link_name = "Contact of"
                                                                            component = <Business_Linked_Contact_Detail businessContactData={relation.model} />;
                                                                            break;
                                                                        case "Executive":
                                                                            link_name = "Executive of"
                                                                            component = <Business_Linked_Director_Detail businessDirectorData={relation.model} />;
                                                                            break;
                                                                        case "ShareHolder":
                                                                            link_name = "ShareHolder of"
                                                                            component = <Business_Linked_ShareHolder_Detail businessShareholderData={relation.model} />;
                                                                            break;
                                                                        default:
                                                                            component = null;
                                                                    }
                                                                    if (linkedTo) {
                                                                        link_profile = `${linkedTo.first_name || ""} ${linkedTo.last_name || ""}`.trim();
                                                                        if (!link_profile) {
                                                                            link_profile = linkedTo.name || "Unknown";
                                                                        }
                                                                        // Construct the dynamic URL
                                                                        if (linkedTo.first_name) {
                                                                            link_url = `/admin/client/individual_profile/${linkedTo.id}`;
                                                                        } else if (linkedTo.name) {
                                                                            link_url = `/admin/client/business_profile/${linkedTo.id}`;
                                                                        }
                                                                    }
                                                                    return (
                                                                        <div key={index} className="accordion-item">

                                                                            <div
                                                                                className={`accordion-header ${openAccordion === index ? "show-accordion" : ""}`}
                                                                                style={{
                                                                                    backgroundColor: openAccordion === index ? "#e6f7ff" : "#f8f9fa", // Change background for active item
                                                                                    padding: "12px",
                                                                                    border: "1px solid #ddd",
                                                                                    cursor: "pointer",
                                                                                }}
                                                                                onClick={() => toggleAccordion(index)}
                                                                            >
                                                                                <div className="text-start">
                                                                                    <h3 style={{ fontSize: "18px", color: "#333" }}>{heading}</h3>
                                                                                    {linkedTo && (
                                                                                        <p
                                                                                            className="text-blue fs-12"
                                                                                            style={{ cursor: "pointer" }}
                                                                                            onClick={(e) => {
                                                                                                e.stopPropagation(); // Prevent accordion toggle on click
                                                                                                if (link_url) {
                                                                                                    navigate(link_url); // Use useNavigate to navigate
                                                                                                }
                                                                                            }}
                                                                                        >
                                                                                            {link_name} {link_profile}
                                                                                        </p>
                                                                                    )}
                                                                                </div>

                                                                                <img
                                                                                    src={require("../../../assets/images/arrow-right.png")}
                                                                                    className={`accordion-arrow ${openAccordion === index ? "rotate-arrow" : ""}`}
                                                                                    alt="arrow"
                                                                                />
                                                                            </div>

                                                                            {openAccordion === index && (
                                                                                <div
                                                                                    className="accordion-body"
                                                                                    style={{
                                                                                        backgroundColor: "#ffffff",
                                                                                        padding: "16px",
                                                                                        border: "1px solid #ddd",
                                                                                        borderTop: "none",
                                                                                    }}
                                                                                >
                                                                                    {component}
                                                                                </div>
                                                                            )}
                                                                        </div>

                                                                    );
                                                                })}
                                                        </div>



                                                    </div>
                                                </div>
                                            </div>
                                            {/* Linked Profiles Section */}
                                            <div className="col-md-4">
                                                <h4 className="fs-20 fw-semibold">Also Update Linked Profiles</h4>
                                                <div className="table_part mt-2 p-0 linked_scroll">
                                                    <div className="table-responsive">
                                                        {contactRelations.length > 0 ? (
                                                            <table className="w-100">
                                                                <thead>
                                                                    <tr>
                                                                        <th></th>
                                                                        <th>Module</th>
                                                                        <th>Link To</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {contactRelations.map((relation, index) => {

                                                                        let linkedTo = relation?.model?.linked_to;
                                                                        let link_profile;
                                                                        let link_url;
                                                                        if (linkedTo) {
                                                                            if (!linkedTo?.folder_number) {
                                                                                link_profile = linkedTo?.first_name;
                                                                                link_url = `/admin/client/individual_profile/${relation?.model?.linked_to?.id}`;
                                                                            } else {
                                                                                link_profile = linkedTo?.name;
                                                                                link_url = `/admin/client/business_profile/${relation?.model?.linked_to?.id}`; // Corrected reference
                                                                            }
                                                                        }

                                                                        return (
                                                                            <tr key={index}>
                                                                                <td>
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        className="custom-control-input mt-1"
                                                                                        checked={linkedProfiles.some((profile) => profile.id === relation.id)}
                                                                                        onChange={() => handleCheckboxChange(relation)}
                                                                                    />
                                                                                </td>
                                                                                <td>{relation?.realtion_type || "Unknown"}</td>
                                                                                <td>
                                                                                    {link_profile && (
                                                                                        <span
                                                                                            className="text-blue fs-12"
                                                                                            style={{ cursor: "pointer" }}
                                                                                            onClick={(e) => {
                                                                                                e.stopPropagation(); // Prevent parent click events
                                                                                                if (link_url) {
                                                                                                    navigate(link_url); // Navigate to the constructed URL
                                                                                                }
                                                                                            }}
                                                                                        >
                                                                                            {link_profile}
                                                                                        </span>
                                                                                    )}
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    })}
                                                                </tbody>

                                                            </table>
                                                        ) : (
                                                            <p className="text-muted">No linked profiles found.</p>
                                                        )}
                                                    </div>
                                                </div>

                                                {/* Update Button */}
                                                <div className="col-md-12 mt-3">
                                                    <button
                                                        type="button"
                                                        className="btn-blue px-4 ms-auto my-3"
                                                        onClick={updateContactDetail}
                                                    >
                                                        Update Contact
                                                    </button>
                                                </div>
                                            </div>
                                        </div>


                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Delete
                open={openDeleteModal}
                onClose={() => setOpenDeleteModal(false)}
                onDelete={handleDeleteConfirm}
            />
        </div>
    );
};

export default ContactDetail;
