import React, { useState, useCallback } from "react";
import { debounce } from "lodash";
import { http } from "../http/http";

export const Business_Search_Field = ({ source, selectedData, onDataSelect }) => {
    const [searchResults, setSearchResults] = useState([]);
    // const [searchTerm, setSearchTerm] = useState(selectedData?.first_name || ""); 
    const [searchTerm, setSearchTerm] = useState(
        selectedData?.type === 2 ? selectedData?.company_name : selectedData?.first_name || ""
    );

    const [hasSearched, setHasSearched] = useState(false);

    const fetchSearchResults = (query) => {
        const queryParams = new URLSearchParams({ search: query });
        const url = `v2/contact_detail?${queryParams.toString()}`;

        http(
            {
                method: "GET",
                url,
                isSecure: true,
            },
            (res) => {
                if (res?.data?.data) {
                    // const filteredResults = res.data.data.filter((contact) =>
                    //     contact.first_name?.toLowerCase().includes(query.toLowerCase())
                    // );

                    const filteredResults = res.data.data.filter((contact) =>
                    (contact.first_name?.toLowerCase().includes(query.toLowerCase()) ||
                        contact.company_name?.toLowerCase().includes(query.toLowerCase()))
                    );
                    setSearchResults(filteredResults);
                } else {
                    setSearchResults([]);
                }
            },
            (err) => {
                console.error("Error Response:", err);
            }
        );
    };

    const debouncedSearch = useCallback(
        debounce((query) => {
            if (query.trim()) {
                fetchSearchResults(query);
            } else {
                setSearchResults([]);
            }
        }, 300),
        []
    );

    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearchTerm(query);

        if (!query.trim()) {
            setHasSearched(false);
            setSearchResults([]);
        } else {
            setHasSearched(true);
            debouncedSearch(query);
        }
    };

    const handleSelectData = (data) => {
        setSearchTerm(data.first_name || data.company_name); // Show selected data in search field
        setSearchResults([]); // Clear search results
        setHasSearched(false);

        // Pass selected data to the parent based on the source
        onDataSelect(data, source);
    };

    return (
        <div className="col-lg-4 col-md-12 mt-3 dctor-search">
            <label className="fs-18">
                {source === "contact"
                    ? "Search Contact"
                    : source === "shareholder"
                        ? "Search shareholder"
                        : "Search Executive "}
                <span className="text-danger">*</span>
            </label>
            <div className="position-relative mt-2">
                <input
                    type="text"
                    className="form-input dtr-srch"
                    placeholder={`Search for a ${source}`}
                    value={searchTerm}
                    onChange={handleSearchChange} // Handle search input changes
                />
                <ul className={`p-0 ${!searchTerm ? "bdr-none" : ""}`}>
                    {hasSearched && searchResults.length === 0 ? (
                        <li>
                            <p>No data found</p>
                        </li>
                    ) : (
                        searchResults.map(
                            (data) =>
                                (data.first_name || data.company_name) && ( // Check if either first_name or company_name exists
                                    <li
                                        key={data.id}
                                        className="list-group-item list-group-item-action d-flex justify-content-start align-items-center w-auto search_dropbox"
                                        onClick={() => handleSelectData(data)}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <p className="fs-16 text-black">
                                            {data.first_name
                                                ? `${data.first_name} ${data.last_name || ""}`.trim()
                                                : data.company_name || "Unknown Name"}
                                        </p>
                                    </li>
                                )
                        )
                    )}
                </ul>
            </div>
        </div>
    );
};
