
import React, { useEffect, useState, useContext } from "react";
import { useLocation, Link, useParams, } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import { GlobalContext } from "../../../App";
import { http } from "../../http/http";

const Breadcrumbs = () => {
    const location = useLocation();
    const { id } = useParams(); // Get the "id" parameter from the URL
    const { setShowLoader } = useContext(GlobalContext);
    const [profileData, setProfileData] = useState(null);


    const routeType = location.pathname.includes("individual_profile")
        ? "individual_profile"
        : location.pathname.includes("business_profile")
            ? "business_profile"
            : null;
    const pathnames = location.pathname.split("/").filter((x) => x);
    let clientUser = profileData?.clientData?.first_name ? profileData?.clientData?.first_name : profileData?.clientData?.name ? profileData?.clientData?.name : null;




    const breadcrumbNameMap = {
        "superAdmin": "Super Admin",
        "login": "Login",
        "dashboard": "Dashboard",
        "tenant": "Tenant Management",
        "edit_profile": "Edit Profile",
        "admin": "Admin",
        "signin": "Sign In",
        "review": "Review",
        "unverified": "Email Unverified",
        "forgetpassword": "Forget Password",
        "otp": "OTP",
        "changepassword": "Change Password",
        "client": "Clients",
        "add_individual": "Add Individual Client",
        "add_business": "Add Business Client",
        "business_profile": "Business Profile",
        "individual_profile": "Individual Profile",
        "note": "Notes",
        "notedetail": "Note Details",
        "corporate_note": "Corporate Notes",
        "note_detail": "Note Detail",
        "corporate_task": "Corporate Task",
        "individual_task": "Individual Task",
        "individual_task_detail": "Task Detail",
        "add_task": "Add Task",
        "add_tasks": "Add Task",
        "add_recurring_task": "Add Recurring Task",
        "recurring_add_task": "Add Recurring Task",
        "business_recurring_add_task": "Add Recurring Task",
        "recurring_task_detail": "Recurring Task Details",
        "individual_recurring_task_detail": "Recurring Task Detail",
        "business_task_detail": "Task Detail",
        "business_recurring_task_detail": "Recurring Task Detail",        // "task": "Tasks",
        "edit_task": "Edit Task Details",
        "invoice_detail": "Invoice Details",
        "add_invoice": "Add Invoice",
        "add_reccuring_invoice": "Add Recurring Invoice",
        "invoice_preview": "Invoice Preview",
        "reccuring_preview": "Recurring Invoice",
        "edit_invoice": "Edit Invoice",
        "edit_reccuring_invoice": "Edit Recurring Invoice",
        "taskdetail": "Task Details",
        "corporate_file": "Corporate Files",
        "search_individual_client": "Search Individual Client",
        "search_business_client": "Search Business Client",
        "individual_contact_detail": "Individual Client Detail",
        "business_contact_detail": "Business Client Detail",
        "contact_detail": "Contact Detail",
        "add_custom_form": "Add Custom Form",
        "edit_servay_form": "Edit Custom Form"
    };

    const clientImageUrl = "../../../assets/images/user-pic.png"; // Replace with your actual image URL
    // const clientName = ; // Replace with your actual client name

    const isNumeric = (str) => /^\d+$/.test(str);

    if (pathnames.length > 0 && isNumeric(pathnames[pathnames.length - 1])) {
        pathnames.pop();
    }

    const capitalize = (str) => {
        return str.replace(/\b\w/g, (char) => char.toUpperCase());
    };

    const generateBreadcrumbItems = () => {
        let items = [];
        let showClientInfo = false;

        for (let index = 0; index < pathnames.length; index++) {
            const value = pathnames[index];
            const isLast = index === pathnames.length - 1;

            let name = breadcrumbNameMap[value] || value;
            name = capitalize(name);

            if (value === "business_profile" || value === "individual_profile" || value === "individual_task") {
                name = breadcrumbNameMap[value];
            }

            if (index === 0 && value === "admin") continue;
            const toPath = value === "invoice" ? `/admin/invoice/invoice_detail` : `/${pathnames.slice(0, index + 1).join("/")}`;

            // Set showClientInfo to true for specific values
            if (value === "client" || value === "task" || value === "invoice" || value === "setting" || value === "individual_task") {
                showClientInfo = true;
            }

            if (value === "client") {
                items.push(
                    <li key={value} className={`breadcrumb-item`} aria-current={isLast ? 'page' : undefined} >
                        <Link className="fs-24 text-black" to={toPath}>
                            {name}
                        </Link>
                    </li >
                );
                showClientInfo = true;
            } else if (value === "note" && showClientInfo) {
                items.push(
                    <li key={value} className={`breadcrumb-item fs-18 text-black d-flex align-items-center gap-1`} aria-current={isLast ? 'page' : undefined}>
                        <img src={require("../../../assets/images/user-pic.png")} alt="Client" style={{ width: 25, height: 25, marginRight: 5 }} />
                        <Link className="fs-18 text-black ms-1 text-capitalize" to={toPath}>{clientUser} {name}</Link>
                    </li>
                );
                showClientInfo = false; // Reset the flag after displaying client info
            }
            else if (value === "notedetail" && showClientInfo) {
                items.push(
                    <li key={value} className={`breadcrumb-item fs-18 text-black d-flex align-items-center gap-1`} aria-current={isLast ? 'page' : undefined}>
                        <img src={require("../../../assets/images/user-pic.png")} alt="Client" style={{ width: 25, height: 25, marginRight: 5 }} />
                        <Link className="fs-18 text-black ms-1 text-capitalize" to={toPath}>{clientUser} {name}</Link>
                    </li>
                );
                showClientInfo = false; // Reset the flag after displaying client info
            }
            else if (value === "search_business_client" && showClientInfo) {
                items.push(
                    <li key={value} className={`breadcrumb-item fs-18 text-black d-flex align-items-center gap-1`} aria-current={isLast ? 'page' : undefined}>

                        <Link className="fs-18 text-black ms-1 text-capitalize" to={toPath}> {name}</Link>
                    </li>
                );
                showClientInfo = false; // Reset the flag after displaying client info
            }
            else if (value === "contact_detail" && showClientInfo) {
                items.push(
                    <li key={value} className={`breadcrumb-item fs-18 text-black d-flex align-items-center gap-1`} aria-current={isLast ? 'page' : undefined}>

                        <Link className="fs-18 text-black ms-1 text-capitalize" to={toPath}> {name}</Link>
                    </li>
                );
                showClientInfo = false; // Reset the flag after displaying client info
            }
            else if (value === "search_individual_client" && showClientInfo) {
                items.push(
                    <li key={value} className={`breadcrumb-item fs-18 text-black d-flex align-items-center gap-1`} aria-current={isLast ? 'page' : undefined}>

                        <Link className="fs-18 text-black ms-1 text-capitalize" to={toPath}>{name}</Link>
                    </li>
                );
                showClientInfo = false; // Reset the flag after displaying client info
            }
            else if (value === "individual_contact_detail" && showClientInfo) {
                items.push(
                    <li key={value} className={`breadcrumb-item fs-18 text-black d-flex align-items-center gap-1`} aria-current={isLast ? 'page' : undefined}>

                        <Link className="fs-18 text-black ms-1 text-capitalize" to={toPath}>{name}</Link>
                    </li>
                );
                showClientInfo = false; // Reset the flag after displaying client info
            }
            else if (value === "business_contact_detail" && showClientInfo) {
                items.push(
                    <li key={value} className={`breadcrumb-item fs-18 text-black d-flex align-items-center gap-1`} aria-current={isLast ? 'page' : undefined}>

                        <Link className="fs-18 text-black ms-1 text-capitalize" to={toPath}>{name}</Link>
                    </li>
                );
                showClientInfo = false; // Reset the flag after displaying client info
            }
            else if (value === "add_individual" && showClientInfo) {
                items.push(
                    <li key={value} className={`breadcrumb-item fs-18 text-black d-flex align-items-center gap-1`} aria-current={isLast ? 'page' : undefined}>

                        <Link className="fs-18 text-black ms-1 text-capitalize" to={toPath}>{name}</Link>
                    </li>
                );
                showClientInfo = false; // Reset the flag after displaying client info
            }
            else if (value === "add_business" && showClientInfo) {
                items.push(
                    <li key={value} className={`breadcrumb-item fs-18 text-black d-flex align-items-center gap-1`} aria-current={isLast ? 'page' : undefined}>

                        <Link className="fs-18 text-black ms-1 text-capitalize" to={toPath}>{name}</Link>
                    </li>
                );
                showClientInfo = false; // Reset the flag after displaying client info
            }

            else if (value === "individual_task" && showClientInfo) {
                items.push(
                    <li key={value} className={`breadcrumb-item fs-18 text-black d-flex align-items-center gap-1`} aria-current={isLast ? 'page' : undefined}>
                        <img src={require("../../../assets/images/user-pic.png")} alt="Client" style={{ width: 25, height: 25, marginRight: 5 }} />
                        <Link className="fs-18 text-black ms-1 text-capitalize" to={toPath}>{clientUser} {name}</Link>
                    </li>
                );
                showClientInfo = false; // Reset the flag after displaying client info
            }
            else if (value === "corporate_task" && showClientInfo) {
                items.push(
                    <li key={value} className={`breadcrumb-item fs-18 text-black d-flex align-items-center gap-1`} aria-current={isLast ? 'page' : undefined}>
                        <img src={require("../../../assets/images/user-pic.png")} alt="Client" style={{ width: 25, height: 25, marginRight: 5 }} />
                        <Link className="fs-18 text-black ms-1 text-capitalize" to={toPath}>{clientUser} {name}</Link>
                    </li>
                );
                showClientInfo = false; // Reset the flag after displaying client info
            }

            else if (value === "individual_profile" && showClientInfo) {
                items.push(
                    <li key={value} className={`breadcrumb-item fs-18 text-black d-flex align-items-center gap-1`} aria-current={isLast ? 'page' : undefined}>
                        <img src={require("../../../assets/images/user-pic.png")} alt="Client" style={{ width: 25, height: 25, marginRight: 5 }} />
                        <p className="fs-18 text-black ms-1 text-capitalize">{clientUser} {name}</p>
                    </li>
                );
                showClientInfo = false; // Reset the flag after displaying client info
            }
            else if (value === "business_profile" && showClientInfo) {
                items.push(
                    <li key={value} className={`breadcrumb-item fs-18 text-black d-flex align-items-center gap-1`} aria-current={isLast ? 'page' : undefined}>
                        <img src={require("../../../assets/images/user-pic.png")} alt="Client" style={{ width: 25, height: 25, marginRight: 5 }} />
                        <p className="fs-18 text-black ms-1 text-capitalize">{clientUser} {name}</p>
                    </li>
                );
                showClientInfo = false; // Reset the flag after displaying client info
            }
            else if (value === "corporate_note" && showClientInfo) {
                items.push(
                    <li key={value} className={`breadcrumb-item fs-18 text-black d-flex align-items-center gap-1`} aria-current={isLast ? 'page' : undefined}>
                        <img src={require("../../../assets/images/user-pic.png")} alt="Client" style={{ width: 25, height: 25, marginRight: 5 }} />
                        <Link className="fs-18 text-black ms-1 text-capitalize" to={toPath}>{clientUser} {name}</Link>
                    </li>
                );
                showClientInfo = false; // Reset the flag after displaying client info
            }

            else if (value === "add_task" && showClientInfo) {
                items.push(
                    <li key={value} className={`breadcrumb-item fs-18 text-black d-flex align-items-center gap-1`} aria-current={isLast ? 'page' : undefined}>
                        <Link className="fs-18 text-black ms-1 text-capitalize" to={toPath}>{name}</Link>
                    </li>
                );
                showClientInfo = false; // Reset the flag after displaying client info
            }
            else if (value === "add_recurring_task" && showClientInfo) {
                items.push(
                    <li key={value} className={`breadcrumb-item fs-18 text-black d-flex align-items-center gap-1`} aria-current={isLast ? 'page' : undefined}>
                        <Link className="fs-18 text-black ms-1 text-capitalize" to={toPath}>{name}</Link>
                    </li>
                );
                showClientInfo = false; // Reset the flag after displaying client info
            }
            else if (value === "taskdetail" && showClientInfo) {
                items.push(
                    <li key={value} className={`breadcrumb-item fs-18 text-black d-flex align-items-center gap-1`} aria-current={isLast ? 'page' : undefined}>
                        <Link className="fs-18 text-black ms-1 text-capitalize" to={toPath}>{name}</Link>
                    </li>
                );
                showClientInfo = false; // Reset the flag after displaying client info
            }
            else if (value === "recurring_task_detail" && showClientInfo) {
                items.push(
                    <li key={value} className={`breadcrumb-item fs-18 text-black d-flex align-items-center gap-1`} aria-current={isLast ? 'page' : undefined}>
                        <Link className="fs-18 text-black ms-1 text-capitalize" to={toPath}>{name}</Link>
                    </li>
                );
                showClientInfo = false; // Reset the flag after displaying client info
            }
            else if (value === "invoice_detail" && showClientInfo) {
                items.push(
                    <li key={value} className={`breadcrumb-item fs-18 text-black d-flex align-items-center gap-1`} aria-current={isLast ? 'page' : undefined}>
                        <Link className="fs-18 text-black ms-1 text-capitalize" to={toPath}>{name}</Link>
                    </li>
                );
                showClientInfo = false; // Reset the flag after displaying client info
            }
            else if (value === "add_invoice" && showClientInfo) {
                items.push(
                    <li key={value} className={`breadcrumb-item fs-18 text-black d-flex align-items-center gap-1`} aria-current={isLast ? 'page' : undefined}>
                        <Link className="fs-18 text-black ms-1 text-capitalize" to={toPath}>{name}</Link>
                    </li>
                );
                showClientInfo = false; // Reset the flag after displaying client info
            }
            else if (value === "add_reccuring_invoice" && showClientInfo) {
                items.push(
                    <li key={value} className={`breadcrumb-item fs-18 text-black d-flex align-items-center gap-1`} aria-current={isLast ? 'page' : undefined}>
                        <Link className="fs-18 text-black ms-1 text-capitalize" to={toPath}>{name}</Link>
                    </li>
                );
                showClientInfo = false; // Reset the flag after displaying client info
            }
            else if (value === "edit_invoice" && showClientInfo) {
                items.push(
                    <li key={value} className={`breadcrumb-item fs-18 text-black d-flex align-items-center gap-1`} aria-current={isLast ? 'page' : undefined}>
                        <Link className="fs-18 text-black ms-1 text-capitalize" to={toPath}>{name}</Link>
                    </li>
                );
                showClientInfo = false; // Reset the flag after displaying client info
            }
            else if (value === "edit_reccuring_invoice" && showClientInfo) {
                items.push(
                    <li key={value} className={`breadcrumb-item fs-18 text-black d-flex align-items-center gap-1`} aria-current={isLast ? 'page' : undefined}>
                        <Link className="fs-18 text-black ms-1 text-capitalize" to={toPath}>{name}</Link>
                    </li>
                );
                showClientInfo = false; // Reset the flag after displaying client info
            }
            else if (value === "add_custom_form" && showClientInfo) {
                items.push(
                    <li key={value} className={`breadcrumb-item fs-18 text-black d-flex align-items-center gap-1`} aria-current={isLast ? 'page' : undefined}>
                        <Link className="fs-18 text-black ms-1 text-capitalize" to={toPath}>{name}</Link>
                    </li>
                );
                showClientInfo = false; // Reset the flag after displaying client info
            }
            else if (value === "edit_servay_form" && showClientInfo) {
                items.push(
                    <li key={value} className={`breadcrumb-item fs-18 text-black d-flex align-items-center gap-1`} aria-current={isLast ? 'page' : undefined}>
                        <Link className="fs-18 text-black ms-1 text-capitalize" to={toPath}>{name}</Link>
                    </li>
                );
                showClientInfo = false; // Reset the flag after displaying client info
            }

            else {
                items.push(
                    <li key={value} className={`breadcrumb-item fs-24 text-black d-flex align-items-center gap-1 ${isLast ? ' active' : ''}`} aria-current={isLast ? 'page' : undefined}>
                        {isLast ? (
                            name
                        ) : (
                            <Link className="fs-24 text-black" to={toPath}>{name}</Link>
                        )}
                    </li>
                );
            }
        }

        return items;
    };

    const shouldUpdateTitle = false;

    useEffect(() => {
        if (shouldUpdateTitle) {
            const title = pathnames
                .map((path) => breadcrumbNameMap[path])
                .filter((name) => name)
                .join(" > ") || "Admin";
            document.title = title;
        }
    }, [pathnames, shouldUpdateTitle]);

    useEffect(() => {
        if (routeType && id) {
            fetchProfileData(routeType, id);
        }
    }, [routeType, id]);

    const fetchProfileData = (type, profileId) => {
        setShowLoader(true);
        http(
            {
                method: "GET",
                url: `client-data/${type}/${profileId}`, // Dynamically use the route type and ID
                isSecure: true,
            },
            (res) => {
                setProfileData(res.data)
                // Handle response data here
                setShowLoader(false);
            },
            (err) => {
                console.error("Error fetching profile:", err);
                //   toast.error(err.single_message || "Failed to fetch profile.");
                setShowLoader(false);
            }
        );
    };
    useEffect(() => {

        fetchProfileData();
    }, []);

    return (
        <Breadcrumb>
            {generateBreadcrumbItems()}
        </Breadcrumb>
    );
};

export default Breadcrumbs;










