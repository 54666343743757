import React from "react";
import SuperAdminHeader from "../header/SuperAdminHeader";
import SuperAdminSideBar from "../sidebar/SuperAdminSideBar";
import Footer from "../../common/Footer";
const SuperAdminDashboard = () => {
    return (
        <div className="right_section font-poppins">
            <div className="dashboard-indicators row">
                <div className="col-md-6 col-lg-3 indic mt-4">
                    <div className="inside grad-one d-flex align-items-center justify-content-between rounded-4">
                        <div className="left-inside">
                            <h4 className="fw-bold text-black">2,458</h4>
                            <p className="text-blue mt-3">Total Clients</p>
                        </div>
                        <div className="right-inside p-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
                                <g id="Group_391" data-name="Group 391" transform="translate(-211.971 -130)">
                                    <rect id="Rectangle_158" data-name="Rectangle 158" width="80" height="80" rx="20" transform="translate(211.971 130)" fill="#065ac4" opacity="0.2" />
                                    <path id="Clients" d="M1162.141,1286.575a7.556,7.556,0,0,1-6.1,7.358,7.627,7.627,0,0,1-5.013-.77,7.556,7.556,0,0,1-3.616-8.844,7.6,7.6,0,0,1,3.044-4.064,7.5,7.5,0,0,1,9.489.967,7.309,7.309,0,0,1,1.647,2.453A7.728,7.728,0,0,1,1162.141,1286.575Zm-7.537,21.072c-6.141,0-11.386-.967-11.386-4.87s5.21-4.905,11.386-4.905c6.141,0,11.386.967,11.386,4.87S1160.78,1307.647,1154.6,1307.647Zm10.76-20.928a10.342,10.342,0,0,1-1.772,5.8.332.332,0,0,0-.036.125.348.348,0,0,0,.018.143.183.183,0,0,0,.09.108.162.162,0,0,0,.125.054,4.681,4.681,0,0,0,.86.089,6.273,6.273,0,0,0,6.319-4.691,6.41,6.41,0,0,0-1.074-5.55,6.325,6.325,0,0,0-5.066-2.524,5.948,5.948,0,0,0-1.253.125.189.189,0,0,0-.161.089.194.194,0,0,0-.018.09l.054.107A10.375,10.375,0,0,1,1165.364,1286.718Zm4.87,9.309a4.978,4.978,0,0,1,2.184.7,5.059,5.059,0,0,1,1.629,1.629,3.291,3.291,0,0,1,.34,1.486,3.367,3.367,0,0,1-.34,1.5c-.824,1.79-3.473,2.363-4.512,2.506a.349.349,0,0,1-.143-.018.322.322,0,0,1-.125-.09.286.286,0,0,1-.072-.125.348.348,0,0,1-.018-.143,7.93,7.93,0,0,0-4.762-7.842l-.036-.036v-.054a.018.018,0,0,1,.018-.018c0-.018.018-.018.036-.018a26.319,26.319,0,0,1,5.8.537Zm-25.494-2.99a7.032,7.032,0,0,0,.86-.089.268.268,0,0,0,.125-.054.475.475,0,0,0,.09-.108.2.2,0,0,0,.018-.143.258.258,0,0,0-.036-.125,10.445,10.445,0,0,1,.143-11.852.387.387,0,0,0,.054-.089.227.227,0,0,0-.018-.108.4.4,0,0,0-.143-.089,7.848,7.848,0,0,0-1.271-.125,6.411,6.411,0,0,0-6.248,5.192,6.619,6.619,0,0,0,.108,2.9,6.273,6.273,0,0,0,6.32,4.691Zm.286,2.524a.066.066,0,0,1-.018.054c0,.018-.018.018-.036.036a8,8,0,0,0-4.762,7.842.384.384,0,0,1,0,.143.472.472,0,0,1-.215.215.385.385,0,0,1-.143,0c-1.02-.143-3.688-.716-4.511-2.506a3.539,3.539,0,0,1-.34-1.486,3.618,3.618,0,0,1,.34-1.5,5.185,5.185,0,0,1,1.629-1.611,5,5,0,0,1,2.184-.716,28.816,28.816,0,0,1,5.818-.5.056.056,0,0,1,.036.018l.018.018Z" transform="translate(-903.007 -1123.672)" fill="#065ac4" fillRule="evenodd" />
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3 indic mt-4">
                    <div className="inside grad-two d-flex align-items-center justify-content-between rounded-4">
                        <div className="left-inside">
                            <h4 className="fw-bold text-black">2,458</h4>
                            <p className="text-blue mt-3">Total Clients</p>
                        </div>
                        <div className="right-inside p-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
                                <g id="Group_392" data-name="Group 392" transform="translate(-647 -130)">
                                    <rect id="Rectangle_159" data-name="Rectangle 159" width="80" height="80" rx="20" transform="translate(647 130)" fill="#f5bb48" opacity="0.2" />
                                    <path id="Path_3513" data-name="Path 3513" d="M864.471,1193.944h9.9a1.361,1.361,0,0,0,.965-.4,1.379,1.379,0,0,0,.4-.974,1.347,1.347,0,0,0-.4-.956,1.361,1.361,0,0,0-.963-.4h-9.9a1.359,1.359,0,0,0-.963.4,1.342,1.342,0,0,0-.4.956,1.39,1.39,0,0,0,.4.974A1.362,1.362,0,0,0,864.471,1193.944Zm6.149-11.926h-6.149a1.362,1.362,0,0,0-.965.4,1.39,1.39,0,0,0-.4.974,1.342,1.342,0,0,0,.4.956,1.358,1.358,0,0,0,.963.4h6.149a1.357,1.357,0,0,0,.963-.4,1.34,1.34,0,0,0,.4-.956,1.382,1.382,0,0,0-.4-.974A1.359,1.359,0,0,0,870.62,1182.017Zm12.985-1.6c.428,0,.893-.018,1.316-.018a.924.924,0,0,1,.316.055,1.262,1.262,0,0,1,.266.184.731.731,0,0,1,.177.276.756.756,0,0,1,.061.312V1196a8.148,8.148,0,0,1-.61,3.142,8.382,8.382,0,0,1-1.766,2.683,8.478,8.478,0,0,1-2.655,1.782,8.018,8.018,0,0,1-3.139.643H863.087a8.639,8.639,0,0,1-8.587-8.655v-19.81a8.257,8.257,0,0,1,8.205-8.288h9.715a.658.658,0,0,1,.322.074.622.622,0,0,1,.274.184.848.848,0,0,1,.18.257.917.917,0,0,1,.063.331v5.917a6.145,6.145,0,0,0,6.077,6.137c.777,0,1.47,0,2.064.018H883.6Zm.5-2.683c-1.5,0-3.258,0-4.526-.018a3.718,3.718,0,0,1-3.675-3.712v-4.833a.874.874,0,0,1,.143-.5.937.937,0,0,1,.4-.331.966.966,0,0,1,.511-.037.846.846,0,0,1,.447.239c.983,1.048,2.338,2.463,3.675,3.878l3.64,3.822a.9.9,0,0,1,.228.441.856.856,0,0,1-.05.5.807.807,0,0,1-.314.386A1.072,1.072,0,0,1,884.106,1177.736Z" transform="translate(-183.5 -1016.167)" fill="#f5bb48" fillRule="evenodd" />
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3 indic mt-4">
                    <div className="inside grad-three d-flex align-items-center justify-content-between">
                        <div className="left-inside">
                            <h4 className="fw-bold text-black">2,458</h4>
                            <p className="text-blue mt-3">Total Clients</p>
                        </div>
                        <div className="right-inside p-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
                                <g id="Group_393" data-name="Group 393" transform="translate(-1081 -130)">
                                    <rect id="Rectangle_160" data-name="Rectangle 160" width="80" height="80" rx="20" transform="translate(1081 130)" fill="#f93333" opacity="0.2" />
                                    <path id="Path_506" data-name="Path 506" d="M408.521,1279h16.59c6.114,0,9.519,3.523,9.519,9.561v20.448c0,6.136-3.4,9.58-9.519,9.58h-16.59c-6.017,0-9.521-3.444-9.521-9.58v-20.448C399,1282.523,402.5,1279,408.521,1279Zm.534,9.224v-.02h5.917a1.531,1.531,0,0,1,1.1.455,1.553,1.553,0,0,1,0,2.2,1.531,1.531,0,0,1-1.1.455h-5.917a1.544,1.544,0,0,1,0-3.088Zm0,12.035h15.519a1.545,1.545,0,0,0,0-3.088H409.055a1.545,1.545,0,0,0,0,3.088Zm0,9.046h15.519a1.5,1.5,0,0,0,.99-.515,1.556,1.556,0,0,0,0-2.078,1.5,1.5,0,0,0-.99-.515H409.055a1.587,1.587,0,0,0-1.484.732,1.584,1.584,0,0,0,0,1.662,1.528,1.528,0,0,0,1.484.713Z" transform="translate(704.667 -1129)" fill="#f93333" fillRule="evenodd" />
                                </g>
                            </svg>

                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3 indic mt-4">
                    <div className="inside grad-four d-flex align-items-center justify-content-between rounded-4">
                        <div className="left-inside">
                            <h4 className="fw-bold text-black">2,458</h4>
                            <p className="text-blue mt-3">Total Clients</p>
                        </div>
                        <div className="right-inside p-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
                                <g id="Group_394" data-name="Group 394" transform="translate(-1516 -130)">
                                    <rect id="Rectangle_161" data-name="Rectangle 161" width="80" height="80" rx="20" transform="translate(1516 130)" fill="#1c8b15" opacity="0.2" />
                                    <g id="Billing" transform="translate(1541.333 150)">
                                        <path id="Path_3462" data-name="Path 3462" d="M4.25,10A8.746,8.746,0,0,1,13,1.25H24.044A8.746,8.746,0,0,1,32.791,10V39.457a1.381,1.381,0,0,1-2.471.848L28.93,38.519a2.3,2.3,0,0,0-3.634,0l-1.39,1.787a1.381,1.381,0,0,1-2.18,0l-1.389-1.787a2.3,2.3,0,0,0-3.634,0l-1.389,1.787a1.381,1.381,0,0,1-2.18,0l-1.39-1.787a2.3,2.3,0,0,0-3.634,0l-1.39,1.787a1.381,1.381,0,0,1-2.471-.848Zm7.365,1.841A1.381,1.381,0,0,1,13,10.457H24.044a1.381,1.381,0,0,1,0,2.762H13A1.381,1.381,0,0,1,11.615,11.838Zm0,7.365A1.381,1.381,0,0,1,13,17.822H24.044a1.381,1.381,0,0,1,0,2.762H13A1.381,1.381,0,0,1,11.615,19.2Z" transform="translate(-4.25 -1.25)" fill="#1c8b15" fillRule="evenodd" />
                                    </g>
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>

            <div className="graphs-area row py-4">
                <div className="col-md-12">
                    <img src={require("../../../assets/images/graphs.png")} alt="" />
                </div>
            </div>
            <div className="table-area row">
                <div className="col-md-12 table-heading">
                    <h2 className="font-semibold">Data Table</h2>
                </div>
                <div className="col-md-12 table_part">
                    <div className="table-responsive">
                        <table className="w-100">
                            <thead className="pb-2">
                                <tr>
                                    <th className="px-4 py-3">Company Name</th>
                                    <th className="px-4 py-3">Domain Name</th>
                                    <th className="px-4 py-3">Domain Suffix</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="">
                                    <td className="px-4 py-3">Morissette Group</td>
                                    <td className="px-4 py-3">tired-thyme.name</td>
                                    <td className="px-4 py-3">@org</td>
                                </tr>
                                <tr className="">
                                    <td className="px-4 py-3">Morissette Group</td>
                                    <td className="px-4 py-3">tired-thyme.name</td>
                                    <td className="px-4 py-3">@org</td>
                                </tr>
                                <tr className="">
                                    <td className="px-4 py-3">Morissette Group</td>
                                    <td className="px-4 py-3">tired-thyme.name</td>
                                    <td className="px-4 py-3">@org</td>
                                </tr>
                                <tr className="">
                                    <td className="px-4 py-3">Morissette Group</td>
                                    <td className="px-4 py-3">tired-thyme.name</td>
                                    <td className="px-4 py-3">@org</td>
                                </tr>
                                <tr className="">
                                    <td className="px-4 py-3">Morissette Group</td>
                                    <td className="px-4 py-3">tired-thyme.name</td>
                                    <td className="px-4 py-3">@org</td>
                                </tr>
                                <tr>
                                    <td className="px-4 py-3">Morissette Group</td>
                                    <td className="px-4 py-3">tired-thyme.name</td>
                                    <td className="px-4 py-3">@org</td>
                                </tr>

                                <tr className="">
                                    <td className="px-4 py-3">Morissette Group</td>
                                    <td className="px-4 py-3">tired-thyme.name</td>
                                    <td className="px-4 py-3">@org</td>
                                </tr><tr className="">
                                    <td className="px-4 py-3">Morissette Group</td>
                                    <td className="px-4 py-3">tired-thyme.name</td>
                                    <td className="px-4 py-3">@org</td>
                                </tr>

                                <tr className="">
                                    <td className="px-4 py-3">Morissette Group</td>
                                    <td className="px-4 py-3">tired-thyme.name</td>
                                    <td className="px-4 py-3">@org</td>
                                </tr>
                                <tr className="">
                                    <td className="px-4 py-3">Morissette Group</td>
                                    <td className="px-4 py-3">tired-thyme.name</td>
                                    <td className="px-4 py-3">@org</td>
                                </tr>
                                <tr className="">
                                    <td className="px-4 py-3">Morissette Group</td>
                                    <td className="px-4 py-3">tired-thyme.name</td>
                                    <td className="px-4 py-3">@org</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


        </div>
    )
}
export default SuperAdminDashboard;