import React, { useState, useEffect } from "react";
import { http } from "../../http/http";

const CustomField = ({ url, register, onCustomFieldsChange }) => {
    const [customFields, setCustomFields] = useState([]);
    const [showAssignOpen, setShowAssignOpen] = useState(false);

    const addCustomField = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `custom-field/${url}`,
                    isSecure: true,
                },
                (res) => {
                    setCustomFields(res?.data?.data);
                    onCustomFieldsChange(res?.data?.data);
                }
            );
        } catch (error) {
            console.error('Error fetching custom fields:', error);
        }
    };

    useEffect(() => {
        addCustomField();
    }, [url]);

    const handleCheckboxChange = (event) => {
        console.log(event.target.name, event.target.checked);
    };

    const toggleAssignOpen = () => {
        setShowAssignOpen(!showAssignOpen);
    };

    const renderField = (field) => {
        switch (field.type) {
            case "text":
                return (
                    <div key={field.id} className="col-md-12">
                        <label htmlFor={field.attributes_json.id}>{field.title}</label>
                        <input
                            type="text"
                            className="form-input bg-white"
                            id={field.attributes_json.id}
                            name={`custom_field[${field.attributes_json.name}]`}
                            placeholder={field.attributes_json.placeholder || ''}
                            {...register(`custom_field[${field.attributes_json.name}]`)}
                        />
                    </div>
                );
            // Handle other field types similarly...
            case "checkbox":
                return (
                    <div key={field.id} className="col-md-12 mt-4 px-4 relative">
                        <label className="fs-18 px-1" htmlFor={field.attributes_json.id} onClick={toggleAssignOpen}>{field.title}</label>
                        {showAssignOpen && (
                            <div className="assign-open bg-white rounded-4 p-3 mt-3 position-absolute end-0 start-0 mx-4">
                                <ul className="ps-0">
                                    {field.attributes_json.options.map((option, index) => (
                                        <li key={`${field.id}-${index}`} className="d-flex justify-content-between align-items-center my-1">
                                            <label className="fw-semibold text-black">
                                                <img src={require("../../../assets/images/user-pic.png")} className="me-2" alt="user-pic" />
                                                {option}
                                            </label>
                                            <input
                                                type="checkbox"
                                                name={`custom_field[${field.attributes_json.name}]`}
                                                id={`${field.attributes_json.id}-${index}`}
                                                value={option}
                                                {...register(`custom_field[${field.attributes_json.name}]`, { onChange: handleCheckboxChange })}
                                            />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <>
            {customFields && customFields.map(renderField)}
        </>
    );
};

export default CustomField;
