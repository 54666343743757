
import Auth from '../auth/Auth';
const HttpClientHeader = {
    getHeaders: () => {
        return {
            'Authorization': `Bearer ${Auth.token()}`,
            // 'Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
            // 'Language': getDefaultLanguage(),
            // 'FCM-Token': HttpClientHeader.getDeviceId(),
            // 'Device-Name': HttpClientHeader.getDeviceName(),
            // 'Device-Type': HttpClientHeader.getDeviceType(),
            // 'Device-ID': HttpClientHeader.getDeviceId(),
            // 'App-Version': HttpClientHeader.getAppVersion(),
            // 'Push-Notification': HttpClientHeader.getPushNotification(),
            'Content-Type': 'multipart/form-data',
            //'Content-Type': 'application/json'

        };
    },

    getPushNotification: () => {
        let pushNotification = localStorage.getItem('push-notification');
        if (!pushNotification) {
            pushNotification = '1';
            localStorage.setItem('push-notification', pushNotification);
        }
        return pushNotification;
    },

    getAppVersion: () => {
        let appVersion = localStorage.getItem('app-version');
        if (!appVersion) {
            appVersion = '1.1';
            localStorage.setItem('app-version', appVersion);
        }
        return appVersion;
    },

    getDeviceId: () => {
        let deviceId = localStorage.getItem('device-id');
        if (!deviceId) {
            deviceId = uuidv4();
            localStorage.setItem('device-id', deviceId);
        }
        return deviceId;
    },

    getDeviceType: () => {
        const userAgent = window.navigator.userAgent;
        if (/Android/i.test(userAgent)) {
            return 'A';
        }
        if (/iPhone|iPad|iPod/i.test(userAgent)) {
            return 'I';
        }
        return 'W';
    },

    getDeviceName: () => {
        const userAgent = window.navigator.userAgent;
        return userAgent;

    }
};

export default HttpClientHeader;