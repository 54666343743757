import React, { useState } from 'react'
import { Modal } from "react-bootstrap";
const Delete = ({ open, onClose, onDelete }) => {

    return (
        <Modal
            show={open}
            onHide={onClose}
            size="md"
            centered
            contentClassName="mx-auto p-3 h-auto"
            className="modal pin-modal font-poppins"
            backdrop="static"
            keyboard={false}
        >
            <div className="modal-header border-0 justify-content-between p-0">
                <h4 className="fs-20">Delete</h4>
                <Modal.Header closeButton className="close-icon border-0" onClick={onClose}></Modal.Header>
            </div>
            <div className="modal-body ">
                <h4 className="txt text-center mt-2">Are you sure you want to Delete?</h4>

            </div>
            <div className=" text-center my-4">
                <form>
                    <div className="modal-footer border-0 justify-content-center p-0">
                        <button type="button" className="btn-gray" onClick={onClose}>Cancel</button>
                        <button type="button" className="btn-red" onClick={onDelete}>Delete</button>
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default Delete;
