import { TASK_COLOR_STATUS, TASK_STATUS } from "./enum";

const GeneralHelper = {
    getTaskStatusName: function (status) {
        // Find the key whose value matches the given status
        for (const [key, value] of Object.entries(TASK_STATUS)) {
            if (value === status) {
                return key.replace(/_/g, ' ').toLowerCase(); // Replace underscores with spaces and convert to lowercase
            }
        }
        return "unknown status"; // Fallback if status is not found
    },
    getTaskStatusColorName: function (status) {
        // Find the key whose value matches the given status
        for (const [key, value] of Object.entries(TASK_COLOR_STATUS)) {
            if (value === status) {
                return key.replace(/_/g, ' ').toLowerCase(); // Replace underscores with spaces and convert to lowercase
            }
        }
        return "unknown status"; // Fallback if status is not found
    }
}
export default GeneralHelper;