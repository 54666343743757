import { configureStore } from '@reduxjs/toolkit';
// import userReducer from './features/userSlice';
import userReducer from '../features/userSlice'
import clientReducer from "../features/client/clientSlice"

export const store = configureStore({
  reducer: {
    user: userReducer,
    clientSlice: clientReducer
  },
});



