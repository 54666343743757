// import React from "react";
// import { Navigate, Outlet } from "react-router-dom";
// import Auth from "../../auth/Auth"

// const OnboardLayout = () => {
//   return (
//     <div className="wrapper-bg">
//       <div className="loginWrapper">
//         {Auth.isUserAuthenticated() ? (
//           <Navigate to="/admin/dashboard" />
//         ) : (
//           <Outlet />
//         )}
//       </div>
//     </div>
//   );
// };

// export default OnboardLayout;
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Auth from "../../auth/Auth";

const OnboardLayout = () => {
  // Middleware to check if the user is authenticated
  return Auth.isUserAuthenticated() ? (
    <Navigate to="/admin/dashboard" />
  ) : (
    <div className="wrapper-bg">
      <div className="loginWrapper">
        <Outlet />
      </div>
    </div>
  );
};

export default OnboardLayout;
