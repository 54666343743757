import React from 'react'

const FileListing = () => {
    return (
        <div className="row">
            <div className="col-lg-4 mt-4">
                <div className="bg-white p-3 shadow_main rounded-4">
                    <div className="d-flex align-items-start justify-content-between gap-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
                            <g id="Group_1350" data-name="Group 1350" transform="translate(-158 -395)">
                                <circle id="Ellipse_73" data-name="Ellipse 73" cx="30" cy="30" r="30" transform="translate(158 395)" fill="#ffecd3" />
                                <path id="Path_3683" data-name="Path 3683" d="M356.312,1170.6h3.837c4.612,0,6.863,2.462,6.85,7.512v6.087c0,4.825-2.975,7.8-7.812,7.8H349.8c-4.812,0-7.8-2.975-7.8-7.812V1174.8c0-5.175,2.3-7.8,6.837-7.8h1.975a3.767,3.767,0,0,1,1.65.375,3.724,3.724,0,0,1,1.313,1.063l1.1,1.462a1.935,1.935,0,0,0,.639.512A1.777,1.777,0,0,0,356.312,1170.6Zm-7.6,13.012h11.575a.938.938,0,0,0,0-1.875H348.712a.938.938,0,0,0,0,1.875Z" transform="translate(-166 -754)" fill="#fea025" fill-rule="evenodd" />
                            </g>
                        </svg>
                        <div className="l-head d-flex align-items-center gap-2">
                            <img width={30} height={30} src={require("../../../../assets/images/user-pic.png")} alt="" />
                            <a href="#!" className="link_btn">
                                <img src={require("../../../../assets/images/linkicon.png")} alt="" />
                                <p className="fs-14 text-muted ms-1">2</p>
                            </a>
                            <a href="#!" className="dot_btn">
                                <div className="dot_icon">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="mt-4">
                        <h5 className="fs-14 text-black fw-semibold">Man magenta compelling</h5>
                        <p className="fs-12 text-muted">Apr 29, 2024, 10:52 am</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FileListing
