import React from "react";
import BusinessTabs from "../../common/BusinessTabs";

const Business_Files = () => {

    return (
        <div className="right_section font-poppins">
            <div className="clients-detail-area py-3">
                <BusinessTabs />
                <div className="row files_area mt-2">
                    <div className="col-md-7 left_files p-0">
                        <div className="row">
                            <div className="col-lg-12 d-flex justify-content-between align-items-center mb-2 comm-head">
                                <div className="left_head">
                                    <h4 className="fs-20 mt-3 fw-semibold">Files</h4>
                                </div>
                                <div className="right_head mt-3 pe-3">
                                    <div className="files-btn-group d-flex align-items-center">
                                        <button className="grid_list active">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                                <g id="Icon" transform="translate(-6 -6)">
                                                    <path id="Path_3657" data-name="Path 3657" d="M13.2,15.6a.8.8,0,0,0-.8-.8H6.8a.8.8,0,0,0-.8.8v5.6a.8.8,0,0,0,.8.8h5.6a.8.8,0,0,0,.8-.8Zm8.8,0a.8.8,0,0,0-.8-.8H15.6a.8.8,0,0,0-.8.8v5.6a.8.8,0,0,0,.8.8h5.6a.8.8,0,0,0,.8-.8Zm0-8.8a.8.8,0,0,0-.8-.8H15.6a.8.8,0,0,0-.8.8v5.6a.8.8,0,0,0,.8.8h5.6a.8.8,0,0,0,.8-.8Zm-8.8,0a.8.8,0,0,0-.8-.8H6.8a.8.8,0,0,0-.8.8v5.6a.8.8,0,0,0,.8.8h5.6a.8.8,0,0,0,.8-.8Z" fill-rule="evenodd" />
                                                </g>
                                            </svg>
                                            Grid
                                        </button>
                                        <button className="grid_list">
                                            <svg className="list" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 15.957 15.5">
                                                <g id="view" transform="translate(-3.25 -3.436)">
                                                    <rect id="Rectangle_371" data-name="Rectangle 371" width="6" height="6" rx="2" transform="translate(4 4.186)" fill="none" stroke-width="1.5" />
                                                    <rect id="Rectangle_372" data-name="Rectangle 372" width="6" height="5" rx="2" transform="translate(4 13.186)" fill="none" stroke-width="1.5" />
                                                    <path id="Path_3658" data-name="Path 3658" d="M13,5h7.156M13,13.945h7.156M13,7.684h3.578M13,16.629h3.578" transform="translate(-0.949 -0.067)" fill="none" stroke-width="1.5" />
                                                </g>
                                            </svg>
                                            List
                                        </button>

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 create_folder">
                                <a href="#!" className="position-relative">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="158" height="136" viewBox="0 0 158 136">
                                        <g id="Group_1527" data-name="Group 1527" transform="translate(-138 -198)">
                                            <g id="Icon" transform="translate(136.75 195.25)">
                                                <path id="Path_3625" data-name="Path 3625" d="M139.041,138.75a20.22,20.22,0,0,0,20.209-20.216V56.047a20.22,20.22,0,0,0-20.209-20.216H99.269a1.83,1.83,0,0,1-1.6-.948c-3.535-6.366-10.641-19.15-14.176-25.517A12.857,12.857,0,0,0,72.254,2.75h-50.8A20.22,20.22,0,0,0,1.25,22.966v95.568A20.22,20.22,0,0,0,21.459,138.75Z" transform="translate(0 0)" fill="#bfd1ee" fill-rule="evenodd" />
                                                <path id="Path_3626" data-name="Path 3626" d="M13.919,4.25l8.167,14.7H58.623a27.515,27.515,0,0,1,20.216,8.829V24.466A20.224,20.224,0,0,0,58.623,4.25Z" transform="translate(80.411 9.527)" fill="#bfd1ee" fill-rule="evenodd" />
                                            </g>
                                            <g id="add" transform="translate(204 251)">
                                                <path id="Path_3627" data-name="Path 3627" d="M2.3,7.19A5.789,5.789,0,0,1,8.112,2H18.179a5.782,5.782,0,0,1,5.807,5.153,57.938,57.938,0,0,1,.3,7.138.735.735,0,0,1-.738.717.751.751,0,0,1-.748-.77A56.479,56.479,0,0,0,22.507,7.3a4.3,4.3,0,0,0-4.328-3.813H8.112A4.3,4.3,0,0,0,3.777,7.329a58.175,58.175,0,0,0,0,11.636,4.306,4.306,0,0,0,4.337,3.847h3.547a.743.743,0,1,1,0,1.487H8.117A5.793,5.793,0,0,1,2.3,19.1,59.656,59.656,0,0,1,2.3,7.19Z" fill="#004fc5" />
                                                <path id="Path_3628" data-name="Path 3628" d="M36,49.318a.743.743,0,0,0,.743.743h2.792A5.787,5.787,0,0,0,45.346,44.9q.055-.56.1-1.1a.737.737,0,0,0-.737-.8.75.75,0,0,0-.745.689q-.044.525-.1,1.065a4.3,4.3,0,0,1-4.331,3.821H36.743A.743.743,0,0,0,36,49.318Z" transform="translate(-21.363 -25.762)" fill="#004fc5" />
                                                <path id="Path_3629" data-name="Path 3629" d="M19,23.832a.743.743,0,0,1,.743-.743h3.345V19.743a.743.743,0,1,1,1.487,0v3.345H27.92a.743.743,0,1,1,0,1.487H24.575V27.92a.743.743,0,1,1-1.487,0V24.575H19.743A.743.743,0,0,1,19,23.832Z" transform="translate(-10.682 -10.682)" fill="#004fc5" />
                                            </g>
                                            <path id="Rectangle_333" data-name="Rectangle 333" d="M0,0H158a0,0,0,0,1,0,0V14a14,14,0,0,1-14,14H14A14,14,0,0,1,0,14V0A0,0,0,0,1,0,0Z" transform="translate(138 306)" fill="#99b7e7" />
                                        </g>
                                    </svg>
                                    <p className="fs-12">Create New Folder</p>
                                </a>

                            </div>
                            <div className="col-lg-12"><hr className="mt-4 mb-0" /></div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 mt-4">
                                <div className="bg-white p-3 shadow_main rounded-4">
                                    <div className="d-flex align-items-start justify-content-between gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
                                            <g id="Group_1350" data-name="Group 1350" transform="translate(-158 -395)">
                                                <circle id="Ellipse_73" data-name="Ellipse 73" cx="30" cy="30" r="30" transform="translate(158 395)" fill="#ffecd3" />
                                                <path id="Path_3683" data-name="Path 3683" d="M356.312,1170.6h3.837c4.612,0,6.863,2.462,6.85,7.512v6.087c0,4.825-2.975,7.8-7.812,7.8H349.8c-4.812,0-7.8-2.975-7.8-7.812V1174.8c0-5.175,2.3-7.8,6.837-7.8h1.975a3.767,3.767,0,0,1,1.65.375,3.724,3.724,0,0,1,1.313,1.063l1.1,1.462a1.935,1.935,0,0,0,.639.512A1.777,1.777,0,0,0,356.312,1170.6Zm-7.6,13.012h11.575a.938.938,0,0,0,0-1.875H348.712a.938.938,0,0,0,0,1.875Z" transform="translate(-166 -754)" fill="#fea025" fill-rule="evenodd" />
                                            </g>
                                        </svg>
                                        <div className="l-head d-flex align-items-center gap-2">
                                            <img width={30} height={30} src={require("../../../assets/images/user-pic.png")} alt="" />
                                            <a href="#!" className="link_btn">
                                                <img src={require("../../../assets/images/linkicon.png")} alt="" />
                                                <p className="fs-14 text-muted ms-1">2</p>
                                            </a>
                                            <a href="#!" className="dot_btn">
                                                <div className="dot_icon">
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                </div>
                                            </a>
                                        </div>

                                    </div>
                                    <div className="mt-4">
                                        <h5 className="fs-14 text-black fw-semibold">Man magenta compelling</h5>
                                        <p className="fs-12 text-muted">Apr 29, 2024, 10:52 am</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mt-4">
                                <div className="bg-white p-3 shadow_main rounded-4">
                                    <div className="d-flex align-items-start justify-content-between gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
                                            <g id="Group_1350" data-name="Group 1350" transform="translate(-158 -395)">
                                                <circle id="Ellipse_73" data-name="Ellipse 73" cx="30" cy="30" r="30" transform="translate(158 395)" fill="#ffecd3" />
                                                <path id="Path_3683" data-name="Path 3683" d="M356.312,1170.6h3.837c4.612,0,6.863,2.462,6.85,7.512v6.087c0,4.825-2.975,7.8-7.812,7.8H349.8c-4.812,0-7.8-2.975-7.8-7.812V1174.8c0-5.175,2.3-7.8,6.837-7.8h1.975a3.767,3.767,0,0,1,1.65.375,3.724,3.724,0,0,1,1.313,1.063l1.1,1.462a1.935,1.935,0,0,0,.639.512A1.777,1.777,0,0,0,356.312,1170.6Zm-7.6,13.012h11.575a.938.938,0,0,0,0-1.875H348.712a.938.938,0,0,0,0,1.875Z" transform="translate(-166 -754)" fill="#fea025" fill-rule="evenodd" />
                                            </g>
                                        </svg>
                                        <div className="l-head d-flex align-items-center gap-2">
                                            <img width={30} height={30} src={require("../../../assets/images/user-pic.png")} alt="" />
                                            <a href="#!" className="link_btn">
                                                <img src={require("../../../assets/images/linkicon.png")} alt="" />
                                                <p className="fs-14 text-muted ms-1">2</p>
                                            </a>
                                            <a href="#!" className="dot_btn">
                                                <div className="dot_icon">
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                </div>
                                            </a>
                                        </div>

                                    </div>
                                    <div className="mt-4">
                                        <h5 className="fs-14 text-black fw-semibold">Mandatory</h5>
                                        <p className="fs-12 text-muted">Apr 29, 2024, 10:52 am</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mt-4">
                                <div className="bg-white p-3 shadow_main rounded-4">
                                    <div className="d-flex align-items-start justify-content-between gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
                                            <g id="Group_1350" data-name="Group 1350" transform="translate(-158 -395)">
                                                <circle id="Ellipse_73" data-name="Ellipse 73" cx="30" cy="30" r="30" transform="translate(158 395)" fill="#ffecd3" />
                                                <path id="Path_3683" data-name="Path 3683" d="M356.312,1170.6h3.837c4.612,0,6.863,2.462,6.85,7.512v6.087c0,4.825-2.975,7.8-7.812,7.8H349.8c-4.812,0-7.8-2.975-7.8-7.812V1174.8c0-5.175,2.3-7.8,6.837-7.8h1.975a3.767,3.767,0,0,1,1.65.375,3.724,3.724,0,0,1,1.313,1.063l1.1,1.462a1.935,1.935,0,0,0,.639.512A1.777,1.777,0,0,0,356.312,1170.6Zm-7.6,13.012h11.575a.938.938,0,0,0,0-1.875H348.712a.938.938,0,0,0,0,1.875Z" transform="translate(-166 -754)" fill="#fea025" fill-rule="evenodd" />
                                            </g>
                                        </svg>
                                        <div className="l-head d-flex align-items-center gap-2">
                                            <img width={30} height={30} src={require("../../../assets/images/user-pic.png")} alt="" />
                                            <a href="#!" className="link_btn">
                                                <img src={require("../../../assets/images/linkicon.png")} alt="" />
                                                <p className="fs-14 text-muted ms-1">2</p>
                                            </a>
                                            <a href="#!" className="dot_btn">
                                                <div className="dot_icon">
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                </div>
                                            </a>
                                        </div>

                                    </div>
                                    <div className="mt-4">
                                        <h5 className="fs-14 text-black fw-semibold">Supposing Upward</h5>
                                        <p className="fs-12 text-muted">Apr 29, 2024, 10:52 am</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mt-4">
                                <div className="bg-white p-3 shadow_main rounded-4">
                                    <div className="d-flex align-items-start justify-content-between gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
                                            <g id="Group_1350" data-name="Group 1350" transform="translate(-158 -395)">
                                                <circle id="Ellipse_73" data-name="Ellipse 73" cx="30" cy="30" r="30" transform="translate(158 395)" fill="#ffecd3" />
                                                <path id="Path_3683" data-name="Path 3683" d="M356.312,1170.6h3.837c4.612,0,6.863,2.462,6.85,7.512v6.087c0,4.825-2.975,7.8-7.812,7.8H349.8c-4.812,0-7.8-2.975-7.8-7.812V1174.8c0-5.175,2.3-7.8,6.837-7.8h1.975a3.767,3.767,0,0,1,1.65.375,3.724,3.724,0,0,1,1.313,1.063l1.1,1.462a1.935,1.935,0,0,0,.639.512A1.777,1.777,0,0,0,356.312,1170.6Zm-7.6,13.012h11.575a.938.938,0,0,0,0-1.875H348.712a.938.938,0,0,0,0,1.875Z" transform="translate(-166 -754)" fill="#fea025" fill-rule="evenodd" />
                                            </g>
                                        </svg>
                                        <div className="l-head d-flex align-items-center gap-2">
                                            <img width={30} height={30} src={require("../../../assets/images/user-pic.png")} alt="" />
                                            <a href="#!" className="link_btn">
                                                <img src={require("../../../assets/images/linkicon.png")} alt="" />
                                                <p className="fs-14 text-muted ms-1">2</p>
                                            </a>
                                            <a href="#!" className="dot_btn">
                                                <div className="dot_icon">
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                </div>
                                            </a>
                                        </div>

                                    </div>
                                    <div className="mt-4">
                                        <h5 className="fs-14 text-black fw-semibold">Applications</h5>
                                        <p className="fs-12 text-muted">Apr 29, 2024, 10:52 am</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mt-4">
                                <div className="bg-white p-3 shadow_main rounded-4">
                                    <div className="d-flex align-items-start justify-content-between gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
                                            <g id="Group_1350" data-name="Group 1350" transform="translate(-158 -395)">
                                                <circle id="Ellipse_73" data-name="Ellipse 73" cx="30" cy="30" r="30" transform="translate(158 395)" fill="#ffecd3" />
                                                <path id="Path_3683" data-name="Path 3683" d="M356.312,1170.6h3.837c4.612,0,6.863,2.462,6.85,7.512v6.087c0,4.825-2.975,7.8-7.812,7.8H349.8c-4.812,0-7.8-2.975-7.8-7.812V1174.8c0-5.175,2.3-7.8,6.837-7.8h1.975a3.767,3.767,0,0,1,1.65.375,3.724,3.724,0,0,1,1.313,1.063l1.1,1.462a1.935,1.935,0,0,0,.639.512A1.777,1.777,0,0,0,356.312,1170.6Zm-7.6,13.012h11.575a.938.938,0,0,0,0-1.875H348.712a.938.938,0,0,0,0,1.875Z" transform="translate(-166 -754)" fill="#fea025" fill-rule="evenodd" />
                                            </g>
                                        </svg>
                                        <div className="l-head d-flex align-items-center gap-2">
                                            <img width={30} height={30} src={require("../../../assets/images/user-pic.png")} alt="" />
                                            <a href="#!" className="link_btn">
                                                <img src={require("../../../assets/images/linkicon.png")} alt="" />
                                                <p className="fs-14 text-muted ms-1">2</p>
                                            </a>
                                            <a href="#!" className="dot_btn">
                                                <div className="dot_icon">
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                </div>
                                            </a>
                                        </div>

                                    </div>
                                    <div className="mt-4">
                                        <h5 className="fs-14 text-black fw-semibold">Scalable</h5>
                                        <p className="fs-12 text-muted">Apr 29, 2024, 10:52 am</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mt-4">
                                <div className="bg-white p-3 shadow_main rounded-4">
                                    <div className="d-flex align-items-start justify-content-between gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
                                            <g id="Group_1350" data-name="Group 1350" transform="translate(-158 -395)">
                                                <circle id="Ellipse_73" data-name="Ellipse 73" cx="30" cy="30" r="30" transform="translate(158 395)" fill="#ffecd3" />
                                                <path id="Path_3683" data-name="Path 3683" d="M356.312,1170.6h3.837c4.612,0,6.863,2.462,6.85,7.512v6.087c0,4.825-2.975,7.8-7.812,7.8H349.8c-4.812,0-7.8-2.975-7.8-7.812V1174.8c0-5.175,2.3-7.8,6.837-7.8h1.975a3.767,3.767,0,0,1,1.65.375,3.724,3.724,0,0,1,1.313,1.063l1.1,1.462a1.935,1.935,0,0,0,.639.512A1.777,1.777,0,0,0,356.312,1170.6Zm-7.6,13.012h11.575a.938.938,0,0,0,0-1.875H348.712a.938.938,0,0,0,0,1.875Z" transform="translate(-166 -754)" fill="#fea025" fill-rule="evenodd" />
                                            </g>
                                        </svg>
                                        <div className="l-head d-flex align-items-center gap-2">
                                            <img width={30} height={30} src={require("../../../assets/images/user-pic.png")} alt="" />
                                            <a href="#!" className="link_btn">
                                                <img src={require("../../../assets/images/linkicon.png")} alt="" />
                                                <p className="fs-14 text-muted ms-1">2</p>
                                            </a>
                                            <a href="#!" className="dot_btn">
                                                <div className="dot_icon">
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                </div>
                                            </a>
                                        </div>

                                    </div>
                                    <div className="mt-4">
                                        <h5 className="fs-14 text-black fw-semibold">Man magenta compelling</h5>
                                        <p className="fs-12 text-muted">Apr 29, 2024, 10:52 am</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mt-4">
                                <div className="bg-white p-3 shadow_main rounded-4">
                                    <div className="d-flex align-items-start justify-content-between gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
                                            <g id="Group_1350" data-name="Group 1350" transform="translate(-158 -395)">
                                                <circle id="Ellipse_73" data-name="Ellipse 73" cx="30" cy="30" r="30" transform="translate(158 395)" fill="#ffecd3" />
                                                <path id="Path_3683" data-name="Path 3683" d="M356.312,1170.6h3.837c4.612,0,6.863,2.462,6.85,7.512v6.087c0,4.825-2.975,7.8-7.812,7.8H349.8c-4.812,0-7.8-2.975-7.8-7.812V1174.8c0-5.175,2.3-7.8,6.837-7.8h1.975a3.767,3.767,0,0,1,1.65.375,3.724,3.724,0,0,1,1.313,1.063l1.1,1.462a1.935,1.935,0,0,0,.639.512A1.777,1.777,0,0,0,356.312,1170.6Zm-7.6,13.012h11.575a.938.938,0,0,0,0-1.875H348.712a.938.938,0,0,0,0,1.875Z" transform="translate(-166 -754)" fill="#fea025" fill-rule="evenodd" />
                                            </g>
                                        </svg>
                                        <div className="l-head d-flex align-items-center gap-2">
                                            <img width={30} height={30} src={require("../../../assets/images/user-pic.png")} alt="" />
                                            <a href="#!" className="link_btn">
                                                <img src={require("../../../assets/images/linkicon.png")} alt="" />
                                                <p className="fs-14 text-muted ms-1">2</p>
                                            </a>
                                            <a href="#!" className="dot_btn">
                                                <div className="dot_icon">
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                </div>
                                            </a>
                                        </div>

                                    </div>
                                    <div className="mt-4">
                                        <h5 className="fs-14 text-black fw-semibold">Mandatory</h5>
                                        <p className="fs-12 text-muted">Apr 29, 2024, 10:52 am</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mt-4">
                                <div className="bg-white p-3 shadow_main rounded-4">
                                    <div className="d-flex align-items-start justify-content-between gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
                                            <g id="Group_1350" data-name="Group 1350" transform="translate(-158 -395)">
                                                <circle id="Ellipse_73" data-name="Ellipse 73" cx="30" cy="30" r="30" transform="translate(158 395)" fill="#ffecd3" />
                                                <path id="Path_3683" data-name="Path 3683" d="M356.312,1170.6h3.837c4.612,0,6.863,2.462,6.85,7.512v6.087c0,4.825-2.975,7.8-7.812,7.8H349.8c-4.812,0-7.8-2.975-7.8-7.812V1174.8c0-5.175,2.3-7.8,6.837-7.8h1.975a3.767,3.767,0,0,1,1.65.375,3.724,3.724,0,0,1,1.313,1.063l1.1,1.462a1.935,1.935,0,0,0,.639.512A1.777,1.777,0,0,0,356.312,1170.6Zm-7.6,13.012h11.575a.938.938,0,0,0,0-1.875H348.712a.938.938,0,0,0,0,1.875Z" transform="translate(-166 -754)" fill="#fea025" fill-rule="evenodd" />
                                            </g>
                                        </svg>
                                        <div className="l-head d-flex align-items-center gap-2">
                                            <img width={30} height={30} src={require("../../../assets/images/user-pic.png")} alt="" />
                                            <a href="#!" className="link_btn">
                                                <img src={require("../../../assets/images/linkicon.png")} alt="" />
                                                <p className="fs-14 text-muted ms-1">2</p>
                                            </a>
                                            <a href="#!" className="dot_btn">
                                                <div className="dot_icon">
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                </div>
                                            </a>
                                        </div>

                                    </div>
                                    <div className="mt-4">
                                        <h5 className="fs-14 text-black fw-semibold">Supposing Upward</h5>
                                        <p className="fs-12 text-muted">Apr 29, 2024, 10:52 am</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mt-4">
                                <div className="bg-white p-3 shadow_main rounded-4">
                                    <div className="d-flex align-items-start justify-content-between gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
                                            <g id="Group_1350" data-name="Group 1350" transform="translate(-158 -395)">
                                                <circle id="Ellipse_73" data-name="Ellipse 73" cx="30" cy="30" r="30" transform="translate(158 395)" fill="#ffecd3" />
                                                <path id="Path_3683" data-name="Path 3683" d="M356.312,1170.6h3.837c4.612,0,6.863,2.462,6.85,7.512v6.087c0,4.825-2.975,7.8-7.812,7.8H349.8c-4.812,0-7.8-2.975-7.8-7.812V1174.8c0-5.175,2.3-7.8,6.837-7.8h1.975a3.767,3.767,0,0,1,1.65.375,3.724,3.724,0,0,1,1.313,1.063l1.1,1.462a1.935,1.935,0,0,0,.639.512A1.777,1.777,0,0,0,356.312,1170.6Zm-7.6,13.012h11.575a.938.938,0,0,0,0-1.875H348.712a.938.938,0,0,0,0,1.875Z" transform="translate(-166 -754)" fill="#fea025" fill-rule="evenodd" />
                                            </g>
                                        </svg>
                                        <div className="l-head d-flex align-items-center gap-2">
                                            <img width={30} height={30} src={require("../../../assets/images/user-pic.png")} alt="" />
                                            <a href="#!" className="link_btn">
                                                <img src={require("../../../assets/images/linkicon.png")} alt="" />
                                                <p className="fs-14 text-muted ms-1">2</p>
                                            </a>
                                            <a href="#!" className="dot_btn">
                                                <div className="dot_icon">
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                </div>
                                            </a>
                                        </div>

                                    </div>
                                    <div className="mt-4">
                                        <h5 className="fs-14 text-black fw-semibold">Applications</h5>
                                        <p className="fs-12 text-muted">Apr 29, 2024, 10:52 am</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mt-4">
                                <div className="bg-white p-3 shadow_main rounded-4">
                                    <div className="d-flex align-items-start justify-content-between gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
                                            <g id="Group_1350" data-name="Group 1350" transform="translate(-158 -395)">
                                                <circle id="Ellipse_73" data-name="Ellipse 73" cx="30" cy="30" r="30" transform="translate(158 395)" fill="#ffecd3" />
                                                <path id="Path_3683" data-name="Path 3683" d="M356.312,1170.6h3.837c4.612,0,6.863,2.462,6.85,7.512v6.087c0,4.825-2.975,7.8-7.812,7.8H349.8c-4.812,0-7.8-2.975-7.8-7.812V1174.8c0-5.175,2.3-7.8,6.837-7.8h1.975a3.767,3.767,0,0,1,1.65.375,3.724,3.724,0,0,1,1.313,1.063l1.1,1.462a1.935,1.935,0,0,0,.639.512A1.777,1.777,0,0,0,356.312,1170.6Zm-7.6,13.012h11.575a.938.938,0,0,0,0-1.875H348.712a.938.938,0,0,0,0,1.875Z" transform="translate(-166 -754)" fill="#fea025" fill-rule="evenodd" />
                                            </g>
                                        </svg>
                                        <div className="l-head d-flex align-items-center gap-2">
                                            <img width={30} height={30} src={require("../../../assets/images/user-pic.png")} alt="" />
                                            <a href="#!" className="link_btn">
                                                <img src={require("../../../assets/images/linkicon.png")} alt="" />
                                                <p className="fs-14 text-muted ms-1">2</p>
                                            </a>
                                            <a href="#!" className="dot_btn">
                                                <div className="dot_icon">
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                </div>
                                            </a>
                                        </div>

                                    </div>
                                    <div className="mt-4">
                                        <h5 className="fs-14 text-black fw-semibold">Scalable</h5>
                                        <p className="fs-12 text-muted">Apr 29, 2024, 10:52 am</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mt-4">
                                <div className="bg-white p-3 shadow_main rounded-4">
                                    <div className="d-flex align-items-start justify-content-between gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
                                            <g id="Group_1350" data-name="Group 1350" transform="translate(-158 -395)">
                                                <circle id="Ellipse_73" data-name="Ellipse 73" cx="30" cy="30" r="30" transform="translate(158 395)" fill="#ffecd3" />
                                                <path id="Path_3683" data-name="Path 3683" d="M356.312,1170.6h3.837c4.612,0,6.863,2.462,6.85,7.512v6.087c0,4.825-2.975,7.8-7.812,7.8H349.8c-4.812,0-7.8-2.975-7.8-7.812V1174.8c0-5.175,2.3-7.8,6.837-7.8h1.975a3.767,3.767,0,0,1,1.65.375,3.724,3.724,0,0,1,1.313,1.063l1.1,1.462a1.935,1.935,0,0,0,.639.512A1.777,1.777,0,0,0,356.312,1170.6Zm-7.6,13.012h11.575a.938.938,0,0,0,0-1.875H348.712a.938.938,0,0,0,0,1.875Z" transform="translate(-166 -754)" fill="#fea025" fill-rule="evenodd" />
                                            </g>
                                        </svg>
                                        <div className="l-head d-flex align-items-center gap-2">
                                            <img width={30} height={30} src={require("../../../assets/images/user-pic.png")} alt="" />
                                            <a href="#!" className="link_btn">
                                                <img src={require("../../../assets/images/linkicon.png")} alt="" />
                                                <p className="fs-14 text-muted ms-1">2</p>
                                            </a>
                                            <a href="#!" className="dot_btn">
                                                <div className="dot_icon">
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                </div>
                                            </a>
                                        </div>

                                    </div>
                                    <div className="mt-4">
                                        <h5 className="fs-14 text-black fw-semibold">Applications</h5>
                                        <p className="fs-12 text-muted">Apr 29, 2024, 10:52 am</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mt-4">
                                <div className="bg-white p-3 shadow_main rounded-4">
                                    <div className="d-flex align-items-start justify-content-between gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
                                            <g id="Group_1350" data-name="Group 1350" transform="translate(-158 -395)">
                                                <circle id="Ellipse_73" data-name="Ellipse 73" cx="30" cy="30" r="30" transform="translate(158 395)" fill="#ffecd3" />
                                                <path id="Path_3683" data-name="Path 3683" d="M356.312,1170.6h3.837c4.612,0,6.863,2.462,6.85,7.512v6.087c0,4.825-2.975,7.8-7.812,7.8H349.8c-4.812,0-7.8-2.975-7.8-7.812V1174.8c0-5.175,2.3-7.8,6.837-7.8h1.975a3.767,3.767,0,0,1,1.65.375,3.724,3.724,0,0,1,1.313,1.063l1.1,1.462a1.935,1.935,0,0,0,.639.512A1.777,1.777,0,0,0,356.312,1170.6Zm-7.6,13.012h11.575a.938.938,0,0,0,0-1.875H348.712a.938.938,0,0,0,0,1.875Z" transform="translate(-166 -754)" fill="#fea025" fill-rule="evenodd" />
                                            </g>
                                        </svg>
                                        <div className="l-head d-flex align-items-center gap-2">
                                            <img width={30} height={30} src={require("../../../assets/images/user-pic.png")} alt="" />
                                            <a href="#!" className="link_btn">
                                                <img src={require("../../../assets/images/linkicon.png")} alt="" />
                                                <p className="fs-14 text-muted ms-1">2</p>
                                            </a>
                                            <a href="#!" className="dot_btn">
                                                <div className="dot_icon">
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                </div>
                                            </a>
                                        </div>

                                    </div>
                                    <div className="mt-4">
                                        <h5 className="fs-14 text-black fw-semibold">Scalable</h5>
                                        <p className="fs-12 text-muted">Apr 29, 2024, 10:52 am</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5 right_files end-0 show-files">
                        <div className="noteform-area ps-5 pe-2 py-3 position-relative">
                            <div className="vr-line"></div>
                            <div className="files_head">
                                <h5 className="fs-20 fw-semibold">Upload Files</h5>
                                <div class="files_upload main-wrapper">
                                    <div class="upload-main-wrapper mx-auto text-center">
                                        <div class="upload-wrapper justify-content-center add-ind cursor-pointer">
                                            <input type="file" name="id_proof" accept=".pdf,.doc,.docx,.xls,.xlsx" id="file-upload" />
                                            <label for="file-upload" class="file-upload-text cursor-pointer justify-content-center">
                                                <i class="fa fa-upload me-2 fs-16 text-white"></i>
                                                <span class="fs-16 text-white">Browse</span>
                                            </label>
                                        </div>
                                        <h4 class="fs-14 mt-2">Drag &amp; drop here</h4>
                                        <p class="upload-description">Supports: JPEG, JPG, PDF, XLS, DOC Max Size: 10 Mb</p>
                                    </div>
                                </div>
                            </div>

                            <div className="files-content mt-4">
                                <h6 className="fs-16 text-muted">2 Files Uploading...</h6>
                                <div class="up-box p-3 rounded-4 shadow_main bg-white mt-3 border-none">
                                    <div class="file-upload-item">
                                        <div class="d-flex align-items-center justify-content-start gap-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                                                <g id="Group_715" data-name="Group 715" transform="translate(-1374 -1270)">
                                                    <circle id="Ellipse_73" data-name="Ellipse 73" cx="20" cy="20" r="20" transform="translate(1374 1270)" fill="#bad5f3" />
                                                    <g id="doc" transform="translate(1386.315 1279.725)">
                                                        <g id="Vrstva_x0020_1_4_" transform="translate(0 0)">
                                                            <path id="Path_3532" data-name="Path 3532" d="M102.432,0h6.876l5.821,6.073v11.8a2.673,2.673,0,0,1-2.673,2.673H102.432a2.673,2.673,0,0,1-2.673-2.673V2.673A2.673,2.673,0,0,1,102.432,0Z" transform="translate(-99.759 0)" fill="#0263d1" />
                                                            <g id="Group_705" data-name="Group 705" transform="translate(2.482 0)">
                                                                <path id="Path_3533" data-name="Path 3533" d="M467.212,0V6.023h5.829Z" transform="translate(-460.153)" fill="#fff" fill-rule="evenodd" opacity="0.302" />
                                                                <path id="Path_3534" data-name="Path 3534" d="M195.356,435.657v-3.4h1.205a2.2,2.2,0,0,1,.673.1,1.852,1.852,0,0,1,.565.3,1.349,1.349,0,0,1,.4.532,2.064,2.064,0,0,1,0,1.529,1.349,1.349,0,0,1-.4.532,1.852,1.852,0,0,1-.565.3,2.2,2.2,0,0,1-.673.1Zm.851-.74h.252a1.462,1.462,0,0,0,.377-.047,1.078,1.078,0,0,0,.321-.155.674.674,0,0,0,.233-.3,1.108,1.108,0,0,0,.086-.46,1.131,1.131,0,0,0-.086-.463.681.681,0,0,0-.233-.3,1.015,1.015,0,0,0-.321-.155,1.462,1.462,0,0,0-.377-.047h-.252Zm4.2.779a1.74,1.74,0,0,1-1.272-.5,1.793,1.793,0,0,1,0-2.488,1.74,1.74,0,0,1,1.272-.5,1.721,1.721,0,0,1,1.258.5,1.8,1.8,0,0,1,0,2.488A1.721,1.721,0,0,1,200.409,435.7Zm-.654-1.022a.882.882,0,0,0,1.294,0,1.147,1.147,0,0,0,0-1.435.882.882,0,0,0-1.294,0,1.137,1.137,0,0,0,0,1.435Zm4.433,1.022a1.745,1.745,0,0,1-1.236-.46,1.9,1.9,0,0,1,.006-2.557,1.749,1.749,0,0,1,1.23-.463,1.729,1.729,0,0,1,1.086.327,1.222,1.222,0,0,1,.488.864l-.859.175a.769.769,0,0,0-.255-.454.7.7,0,0,0-.465-.172.8.8,0,0,0-.609.26,1.234,1.234,0,0,0,0,1.477.793.793,0,0,0,.612.263.706.706,0,0,0,.454-.15.657.657,0,0,0,.235-.4l.878.2a1.291,1.291,0,0,1-.535.8A1.757,1.757,0,0,1,204.188,435.7Z" transform="translate(-195.356 -420.993)" fill="#fff" />
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                            <div class="file-info">
                                                <div class="d-flex align-items-center justify-content-between gap-3">
                                                    <div>
                                                        <div class="file-name">Uploading: </div>
                                                        <div class="file-size mt-1">file.html.pdf | Size: 65.2 KB</div>
                                                    </div>
                                                    <div class="text-end">
                                                        <i class="fa fa-times fs-14 text-muted cursor-pointer" title="Remove File"></i>
                                                        <div class="progress-percentage fs-12 text-black mt-1">100%</div>
                                                    </div>
                                                </div>
                                                <div class="progress-container">
                                                    <svg class="rc-progress-line" viewBox="0 0 100 2" preserveAspectRatio="none"><path class="rc-progress-line-trail" d="M 1,1 L 99,1" stroke-linecap="round" stroke="#d3d3d3" stroke-width="2" fill-opacity="0"></path><path class="rc-progress-line-path" d="M 1,1 L 99,1" stroke-linecap="round" stroke="#007bff" stroke-width="2" fill-opacity="0" ></path>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="up-box p-3 rounded-4 shadow_main bg-white mt-3 border-none">
                                    <div class="file-upload-item">
                                        <div class="d-flex align-items-center justify-content-start gap-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                                                <g id="Group_713" data-name="Group 713" transform="translate(-1374 -1380)">
                                                    <circle id="Ellipse_73" data-name="Ellipse 73" cx="20" cy="20" r="20" transform="translate(1374 1380)" fill="#f9c3c5" />
                                                    <g id="pdf" transform="translate(1386.315 1390.007)">
                                                        <g id="Vrstva_x0020_1_15_" transform="translate(0 0)">
                                                            <path id="Path_3537" data-name="Path 3537" d="M102.448,0h6.871l5.823,6.07V17.875a2.673,2.673,0,0,1-2.67,2.675H102.448a2.674,2.674,0,0,1-2.675-2.675V2.675A2.674,2.674,0,0,1,102.448,0Z" transform="translate(-99.773 0)" fill="#e5252a" fill-rule="evenodd" />
                                                            <g id="Group_706" data-name="Group 706" transform="translate(2.973 0)">
                                                                <path id="Path_3538" data-name="Path 3538" d="M467.219,0V6.024h5.828Z" transform="translate(-460.651)" fill="#fff" fill-rule="evenodd" opacity="0.302" />
                                                                <path id="Path_3539" data-name="Path 3539" d="M214.278,449.713v-3.754h1.6a1.324,1.324,0,0,1,.945.329,1.253,1.253,0,0,1,0,1.746,1.324,1.324,0,0,1-.945.329h-.637v1.351Zm.96-2.167h.529a.473.473,0,0,0,.334-.1.4.4,0,0,0,0-.565.473.473,0,0,0-.334-.1h-.529Zm2.331,2.167v-3.754h1.33a2.322,2.322,0,0,1,.739.113,1.987,1.987,0,0,1,.626.334,1.474,1.474,0,0,1,.442.585,2.321,2.321,0,0,1,0,1.689,1.474,1.474,0,0,1-.442.585,1.988,1.988,0,0,1-.626.334,2.323,2.323,0,0,1-.739.113Zm.94-.816h.277a1.537,1.537,0,0,0,.416-.051,1.248,1.248,0,0,0,.354-.169.761.761,0,0,0,.257-.329,1.458,1.458,0,0,0,0-1.022.761.761,0,0,0-.257-.329,1.246,1.246,0,0,0-.354-.169,1.539,1.539,0,0,0-.416-.051h-.277Zm2.84.816v-3.754h2.67v.816h-1.71v.6h1.366v.811h-1.366v1.525Z" transform="translate(-214.278 -434.379)" fill="#fff" />
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                            <div class="file-info">
                                                <div class="d-flex align-items-center justify-content-between gap-3">
                                                    <div>
                                                        <div class="file-name">Uploading: </div>
                                                        <div class="file-size mt-1">file.html.pdf | Size: 65.2 KB</div>
                                                    </div>
                                                    <div class="text-end">
                                                        <i class="fa fa-times fs-14 text-muted cursor-pointer" title="Remove File"></i>
                                                        <div class="progress-percentage fs-12 text-black mt-1">100%</div>
                                                    </div>
                                                </div>
                                                <div class="progress-container">
                                                    <svg class="rc-progress-line" viewBox="0 0 100 2" preserveAspectRatio="none"><path class="rc-progress-line-trail" d="M 1,1 L 99,1" stroke-linecap="round" stroke="#d3d3d3" stroke-width="2" fill-opacity="0"></path><path class="rc-progress-line-path" d="M 1,1 L 99,1" stroke-linecap="round" stroke="#007bff" stroke-width="2" fill-opacity="0" ></path>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="comp-files mt-4">
                                <div className="complete-head d-flex align-items-center justify-content-between flex-wrap gap-2">
                                    <h4 className="fs-16">Completed Files</h4>
                                    <div className="d-flex align-items-center gap-2">
                                        <input type="checkbox" id="" className="" />
                                        <label htmlFor="selectall" className="fs-13 text-muted">Select all</label>
                                    </div>
                                </div>

                                <div className="complete-box mt-3 p-3 rounded-4 shadow_main bg-white border-none gap-2 d-flex align-items-center">
                                    <input type="checkbox" id="" className="" />
                                    <svg xmlns="http://www.w3.org/2000/svg" max-width="40" width="40" height="40" viewBox="0 0 40 40">
                                        <g id="Group_1038" data-name="Group 1038" transform="translate(-1374 -1490)">
                                            <circle id="Ellipse_73" data-name="Ellipse 73" cx="20" cy="20" r="20" transform="translate(1374 1490)" fill="#bad9c9" />
                                            <g id="xls" transform="translate(1388.776 1502.891)">
                                                <g id="Vrstva_x0020_1_30_" transform="translate(0 0)">
                                                    <path id="Path_3540" data-name="Path 3540" d="M101.741,0h5l4.233,4.418V13a1.944,1.944,0,0,1-1.942,1.947h-7.293A1.947,1.947,0,0,1,99.8,13V1.942A1.946,1.946,0,0,1,101.741,0Z" transform="translate(-99.799 0)" fill="#00733b" fill-rule="evenodd" />
                                                    <g id="Group_707" data-name="Group 707" transform="translate(2.198 0)">
                                                        <path id="Path_3541" data-name="Path 3541" d="M467.218,0V4.38h4.238Z" transform="translate(-462.478)" fill="#fff" fill-rule="evenodd" opacity="0.302" />
                                                        <path id="Path_3542" data-name="Path 3542" d="M218.655,433.712h-.725l-.5-.835-.5.835h-.731l.862-1.451L216.31,431h.731l.393.655.387-.655h.731l-.753,1.265Zm.256,0V431h.693v2.122h1.178v.589h-1.871Zm3.016.033a1.108,1.108,0,0,1-.72-.256.847.847,0,0,1-.333-.616l.595-.175a.538.538,0,0,0,.164.333.481.481,0,0,0,.327.131.433.433,0,0,0,.245-.065.21.21,0,0,0,.093-.175.2.2,0,0,0-.076-.153.694.694,0,0,0-.191-.1,2.521,2.521,0,0,0-.262-.076,1.827,1.827,0,0,1-.289-.1,1.017,1.017,0,0,1-.262-.147.568.568,0,0,1-.191-.24.791.791,0,0,1-.076-.355.673.673,0,0,1,.284-.556,1.062,1.062,0,0,1,.687-.224,1.275,1.275,0,0,1,.7.191.833.833,0,0,1,.371.507l-.622.262a.543.543,0,0,0-.158-.278.435.435,0,0,0-.289-.1.388.388,0,0,0-.207.055.16.16,0,0,0-.071.142.167.167,0,0,0,.1.142.911.911,0,0,0,.251.076,2.142,2.142,0,0,1,.322.082,1.986,1.986,0,0,1,.327.147.653.653,0,0,1,.245.267.882.882,0,0,1,.1.442.74.74,0,0,1-.295.611A1.219,1.219,0,0,1,221.928,433.745Z" transform="translate(-216.206 -422.83)" fill="#fff" />
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                    <div className="comp-text d-flex align-items-center justify-content-between gap-2">
                                        <div>
                                            <h5 className="fs-14 fw-medium">File Name.xls</h5>
                                            <span className="d-flex align-items-center gap-2">
                                                <p className="fs-12 text-muted">Size:1mb</p>
                                                |
                                                <p className="fs-12 text-muted">Size:1mb</p>
                                            </span>
                                        </div>
                                        <a href="">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                                <g id="Group_1055" data-name="Group 1055" transform="translate(16079 13588)">
                                                    <rect id="Rectangle_276" data-name="Rectangle 276" width="30" height="30" rx="8" transform="translate(-16079 -13588)" fill="#fad3d4" />
                                                    <path id="Path_3653" data-name="Path 3653" d="M411.1,1337.268a.46.46,0,0,1,.193.042.5.5,0,0,1,.163.112.517.517,0,0,1,.107.168.5.5,0,0,1,.036.2v.266a.512.512,0,0,1-.144.357.491.491,0,0,1-.355.154H399.5a.49.49,0,0,1-.355-.154.51.51,0,0,1-.145-.357v-.266a.514.514,0,0,1,.143-.364.5.5,0,0,1,.163-.112.461.461,0,0,1,.193-.042h2.041a.865.865,0,0,0,.559-.2.892.892,0,0,0,.309-.5l.106-.476a1.391,1.391,0,0,1,.482-.777,1.412,1.412,0,0,1,.857-.308h2.891a1.418,1.418,0,0,1,.843.3,1.391,1.391,0,0,1,.486.749l.114.511a.9.9,0,0,0,.309.511.948.948,0,0,0,.559.2Zm-1.037,9.722c.21-1.981.586-6.7.586-6.747a.527.527,0,0,0-.023-.21.58.58,0,0,0-.1-.182.528.528,0,0,0-.166-.119.49.49,0,0,0-.2-.042h-9.708a.5.5,0,0,0-.2.042.52.52,0,0,0-.166.119.566.566,0,0,0-.133.392c0,.007.015.175.037.455.1,1.232.376,4.668.555,6.3a2.033,2.033,0,0,0,2.051,1.974c.879.021,1.784.028,2.71.028.872,0,1.757-.007,2.66-.028a2.017,2.017,0,0,0,1.459-.553,2.039,2.039,0,0,0,.64-1.428Z" transform="translate(-16469.299 -14914.999)" fill="#e5252a" fill-rule="evenodd" />
                                                </g>
                                            </svg>

                                        </a>
                                    </div>
                                </div>
                                <div className="complete-box mt-3 p-3 rounded-4 shadow_main bg-white border-none gap-2 d-flex align-items-center">
                                    <input type="checkbox" id="" className="" />
                                    <svg xmlns="http://www.w3.org/2000/svg" max-width="40" width="40" height="40" viewBox="0 0 40 40">
                                        <g id="Group_1038" data-name="Group 1038" transform="translate(-1374 -1490)">
                                            <circle id="Ellipse_73" data-name="Ellipse 73" cx="20" cy="20" r="20" transform="translate(1374 1490)" fill="#bad9c9" />
                                            <g id="xls" transform="translate(1388.776 1502.891)">
                                                <g id="Vrstva_x0020_1_30_" transform="translate(0 0)">
                                                    <path id="Path_3540" data-name="Path 3540" d="M101.741,0h5l4.233,4.418V13a1.944,1.944,0,0,1-1.942,1.947h-7.293A1.947,1.947,0,0,1,99.8,13V1.942A1.946,1.946,0,0,1,101.741,0Z" transform="translate(-99.799 0)" fill="#00733b" fill-rule="evenodd" />
                                                    <g id="Group_707" data-name="Group 707" transform="translate(2.198 0)">
                                                        <path id="Path_3541" data-name="Path 3541" d="M467.218,0V4.38h4.238Z" transform="translate(-462.478)" fill="#fff" fill-rule="evenodd" opacity="0.302" />
                                                        <path id="Path_3542" data-name="Path 3542" d="M218.655,433.712h-.725l-.5-.835-.5.835h-.731l.862-1.451L216.31,431h.731l.393.655.387-.655h.731l-.753,1.265Zm.256,0V431h.693v2.122h1.178v.589h-1.871Zm3.016.033a1.108,1.108,0,0,1-.72-.256.847.847,0,0,1-.333-.616l.595-.175a.538.538,0,0,0,.164.333.481.481,0,0,0,.327.131.433.433,0,0,0,.245-.065.21.21,0,0,0,.093-.175.2.2,0,0,0-.076-.153.694.694,0,0,0-.191-.1,2.521,2.521,0,0,0-.262-.076,1.827,1.827,0,0,1-.289-.1,1.017,1.017,0,0,1-.262-.147.568.568,0,0,1-.191-.24.791.791,0,0,1-.076-.355.673.673,0,0,1,.284-.556,1.062,1.062,0,0,1,.687-.224,1.275,1.275,0,0,1,.7.191.833.833,0,0,1,.371.507l-.622.262a.543.543,0,0,0-.158-.278.435.435,0,0,0-.289-.1.388.388,0,0,0-.207.055.16.16,0,0,0-.071.142.167.167,0,0,0,.1.142.911.911,0,0,0,.251.076,2.142,2.142,0,0,1,.322.082,1.986,1.986,0,0,1,.327.147.653.653,0,0,1,.245.267.882.882,0,0,1,.1.442.74.74,0,0,1-.295.611A1.219,1.219,0,0,1,221.928,433.745Z" transform="translate(-216.206 -422.83)" fill="#fff" />
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                    <div className="comp-text d-flex align-items-center justify-content-between gap-2">
                                        <div>
                                            <h5 className="fs-14 fw-medium">File Name.xls</h5>
                                            <span className="d-flex align-items-center gap-2">
                                                <p className="fs-12 text-muted">Size:1mb</p>
                                                |
                                                <p className="fs-12 text-muted">Size:1mb</p>
                                            </span>
                                        </div>
                                        <a href="">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                                <g id="Group_1055" data-name="Group 1055" transform="translate(16079 13588)">
                                                    <rect id="Rectangle_276" data-name="Rectangle 276" width="30" height="30" rx="8" transform="translate(-16079 -13588)" fill="#fad3d4" />
                                                    <path id="Path_3653" data-name="Path 3653" d="M411.1,1337.268a.46.46,0,0,1,.193.042.5.5,0,0,1,.163.112.517.517,0,0,1,.107.168.5.5,0,0,1,.036.2v.266a.512.512,0,0,1-.144.357.491.491,0,0,1-.355.154H399.5a.49.49,0,0,1-.355-.154.51.51,0,0,1-.145-.357v-.266a.514.514,0,0,1,.143-.364.5.5,0,0,1,.163-.112.461.461,0,0,1,.193-.042h2.041a.865.865,0,0,0,.559-.2.892.892,0,0,0,.309-.5l.106-.476a1.391,1.391,0,0,1,.482-.777,1.412,1.412,0,0,1,.857-.308h2.891a1.418,1.418,0,0,1,.843.3,1.391,1.391,0,0,1,.486.749l.114.511a.9.9,0,0,0,.309.511.948.948,0,0,0,.559.2Zm-1.037,9.722c.21-1.981.586-6.7.586-6.747a.527.527,0,0,0-.023-.21.58.58,0,0,0-.1-.182.528.528,0,0,0-.166-.119.49.49,0,0,0-.2-.042h-9.708a.5.5,0,0,0-.2.042.52.52,0,0,0-.166.119.566.566,0,0,0-.133.392c0,.007.015.175.037.455.1,1.232.376,4.668.555,6.3a2.033,2.033,0,0,0,2.051,1.974c.879.021,1.784.028,2.71.028.872,0,1.757-.007,2.66-.028a2.017,2.017,0,0,0,1.459-.553,2.039,2.039,0,0,0,.64-1.428Z" transform="translate(-16469.299 -14914.999)" fill="#e5252a" fill-rule="evenodd" />
                                                </g>
                                            </svg>

                                        </a>
                                    </div>
                                </div>
                                <div className="complete-box mt-3 p-3 rounded-4 shadow_main bg-white border-none gap-2 d-flex align-items-center">
                                    <input type="checkbox" id="" className="" />
                                    <svg xmlns="http://www.w3.org/2000/svg" max-width="40" width="40" height="40" viewBox="0 0 40 40">
                                        <g id="Group_1038" data-name="Group 1038" transform="translate(-1374 -1490)">
                                            <circle id="Ellipse_73" data-name="Ellipse 73" cx="20" cy="20" r="20" transform="translate(1374 1490)" fill="#bad9c9" />
                                            <g id="xls" transform="translate(1388.776 1502.891)">
                                                <g id="Vrstva_x0020_1_30_" transform="translate(0 0)">
                                                    <path id="Path_3540" data-name="Path 3540" d="M101.741,0h5l4.233,4.418V13a1.944,1.944,0,0,1-1.942,1.947h-7.293A1.947,1.947,0,0,1,99.8,13V1.942A1.946,1.946,0,0,1,101.741,0Z" transform="translate(-99.799 0)" fill="#00733b" fill-rule="evenodd" />
                                                    <g id="Group_707" data-name="Group 707" transform="translate(2.198 0)">
                                                        <path id="Path_3541" data-name="Path 3541" d="M467.218,0V4.38h4.238Z" transform="translate(-462.478)" fill="#fff" fill-rule="evenodd" opacity="0.302" />
                                                        <path id="Path_3542" data-name="Path 3542" d="M218.655,433.712h-.725l-.5-.835-.5.835h-.731l.862-1.451L216.31,431h.731l.393.655.387-.655h.731l-.753,1.265Zm.256,0V431h.693v2.122h1.178v.589h-1.871Zm3.016.033a1.108,1.108,0,0,1-.72-.256.847.847,0,0,1-.333-.616l.595-.175a.538.538,0,0,0,.164.333.481.481,0,0,0,.327.131.433.433,0,0,0,.245-.065.21.21,0,0,0,.093-.175.2.2,0,0,0-.076-.153.694.694,0,0,0-.191-.1,2.521,2.521,0,0,0-.262-.076,1.827,1.827,0,0,1-.289-.1,1.017,1.017,0,0,1-.262-.147.568.568,0,0,1-.191-.24.791.791,0,0,1-.076-.355.673.673,0,0,1,.284-.556,1.062,1.062,0,0,1,.687-.224,1.275,1.275,0,0,1,.7.191.833.833,0,0,1,.371.507l-.622.262a.543.543,0,0,0-.158-.278.435.435,0,0,0-.289-.1.388.388,0,0,0-.207.055.16.16,0,0,0-.071.142.167.167,0,0,0,.1.142.911.911,0,0,0,.251.076,2.142,2.142,0,0,1,.322.082,1.986,1.986,0,0,1,.327.147.653.653,0,0,1,.245.267.882.882,0,0,1,.1.442.74.74,0,0,1-.295.611A1.219,1.219,0,0,1,221.928,433.745Z" transform="translate(-216.206 -422.83)" fill="#fff" />
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                    <div className="comp-text d-flex align-items-center justify-content-between gap-2">
                                        <div>
                                            <h5 className="fs-14 fw-medium">File Name.xls</h5>
                                            <span className="d-flex align-items-center gap-2">
                                                <p className="fs-12 text-muted">Size:1mb</p>
                                                |
                                                <p className="fs-12 text-muted">Size:1mb</p>
                                            </span>
                                        </div>
                                        <a href="">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                                <g id="Group_1055" data-name="Group 1055" transform="translate(16079 13588)">
                                                    <rect id="Rectangle_276" data-name="Rectangle 276" width="30" height="30" rx="8" transform="translate(-16079 -13588)" fill="#fad3d4" />
                                                    <path id="Path_3653" data-name="Path 3653" d="M411.1,1337.268a.46.46,0,0,1,.193.042.5.5,0,0,1,.163.112.517.517,0,0,1,.107.168.5.5,0,0,1,.036.2v.266a.512.512,0,0,1-.144.357.491.491,0,0,1-.355.154H399.5a.49.49,0,0,1-.355-.154.51.51,0,0,1-.145-.357v-.266a.514.514,0,0,1,.143-.364.5.5,0,0,1,.163-.112.461.461,0,0,1,.193-.042h2.041a.865.865,0,0,0,.559-.2.892.892,0,0,0,.309-.5l.106-.476a1.391,1.391,0,0,1,.482-.777,1.412,1.412,0,0,1,.857-.308h2.891a1.418,1.418,0,0,1,.843.3,1.391,1.391,0,0,1,.486.749l.114.511a.9.9,0,0,0,.309.511.948.948,0,0,0,.559.2Zm-1.037,9.722c.21-1.981.586-6.7.586-6.747a.527.527,0,0,0-.023-.21.58.58,0,0,0-.1-.182.528.528,0,0,0-.166-.119.49.49,0,0,0-.2-.042h-9.708a.5.5,0,0,0-.2.042.52.52,0,0,0-.166.119.566.566,0,0,0-.133.392c0,.007.015.175.037.455.1,1.232.376,4.668.555,6.3a2.033,2.033,0,0,0,2.051,1.974c.879.021,1.784.028,2.71.028.872,0,1.757-.007,2.66-.028a2.017,2.017,0,0,0,1.459-.553,2.039,2.039,0,0,0,.64-1.428Z" transform="translate(-16469.299 -14914.999)" fill="#e5252a" fill-rule="evenodd" />
                                                </g>
                                            </svg>

                                        </a>
                                    </div>
                                </div>
                                <div className="complete-box mt-3 p-3 rounded-4 shadow_main bg-white border-none gap-2 d-flex align-items-center">
                                    <input type="checkbox" id="" className="" />
                                    <svg xmlns="http://www.w3.org/2000/svg" max-width="40" width="40" height="40" viewBox="0 0 40 40">
                                        <g id="Group_1038" data-name="Group 1038" transform="translate(-1374 -1490)">
                                            <circle id="Ellipse_73" data-name="Ellipse 73" cx="20" cy="20" r="20" transform="translate(1374 1490)" fill="#bad9c9" />
                                            <g id="xls" transform="translate(1388.776 1502.891)">
                                                <g id="Vrstva_x0020_1_30_" transform="translate(0 0)">
                                                    <path id="Path_3540" data-name="Path 3540" d="M101.741,0h5l4.233,4.418V13a1.944,1.944,0,0,1-1.942,1.947h-7.293A1.947,1.947,0,0,1,99.8,13V1.942A1.946,1.946,0,0,1,101.741,0Z" transform="translate(-99.799 0)" fill="#00733b" fill-rule="evenodd" />
                                                    <g id="Group_707" data-name="Group 707" transform="translate(2.198 0)">
                                                        <path id="Path_3541" data-name="Path 3541" d="M467.218,0V4.38h4.238Z" transform="translate(-462.478)" fill="#fff" fill-rule="evenodd" opacity="0.302" />
                                                        <path id="Path_3542" data-name="Path 3542" d="M218.655,433.712h-.725l-.5-.835-.5.835h-.731l.862-1.451L216.31,431h.731l.393.655.387-.655h.731l-.753,1.265Zm.256,0V431h.693v2.122h1.178v.589h-1.871Zm3.016.033a1.108,1.108,0,0,1-.72-.256.847.847,0,0,1-.333-.616l.595-.175a.538.538,0,0,0,.164.333.481.481,0,0,0,.327.131.433.433,0,0,0,.245-.065.21.21,0,0,0,.093-.175.2.2,0,0,0-.076-.153.694.694,0,0,0-.191-.1,2.521,2.521,0,0,0-.262-.076,1.827,1.827,0,0,1-.289-.1,1.017,1.017,0,0,1-.262-.147.568.568,0,0,1-.191-.24.791.791,0,0,1-.076-.355.673.673,0,0,1,.284-.556,1.062,1.062,0,0,1,.687-.224,1.275,1.275,0,0,1,.7.191.833.833,0,0,1,.371.507l-.622.262a.543.543,0,0,0-.158-.278.435.435,0,0,0-.289-.1.388.388,0,0,0-.207.055.16.16,0,0,0-.071.142.167.167,0,0,0,.1.142.911.911,0,0,0,.251.076,2.142,2.142,0,0,1,.322.082,1.986,1.986,0,0,1,.327.147.653.653,0,0,1,.245.267.882.882,0,0,1,.1.442.74.74,0,0,1-.295.611A1.219,1.219,0,0,1,221.928,433.745Z" transform="translate(-216.206 -422.83)" fill="#fff" />
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                    <div className="comp-text d-flex align-items-center justify-content-between gap-2">
                                        <div>
                                            <h5 className="fs-14 fw-medium">File Name.xls</h5>
                                            <span className="d-flex align-items-center gap-2">
                                                <p className="fs-12 text-muted">Size:1mb</p>
                                                |
                                                <p className="fs-12 text-muted">Size:1mb</p>
                                            </span>
                                        </div>
                                        <a href="">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                                <g id="Group_1055" data-name="Group 1055" transform="translate(16079 13588)">
                                                    <rect id="Rectangle_276" data-name="Rectangle 276" width="30" height="30" rx="8" transform="translate(-16079 -13588)" fill="#fad3d4" />
                                                    <path id="Path_3653" data-name="Path 3653" d="M411.1,1337.268a.46.46,0,0,1,.193.042.5.5,0,0,1,.163.112.517.517,0,0,1,.107.168.5.5,0,0,1,.036.2v.266a.512.512,0,0,1-.144.357.491.491,0,0,1-.355.154H399.5a.49.49,0,0,1-.355-.154.51.51,0,0,1-.145-.357v-.266a.514.514,0,0,1,.143-.364.5.5,0,0,1,.163-.112.461.461,0,0,1,.193-.042h2.041a.865.865,0,0,0,.559-.2.892.892,0,0,0,.309-.5l.106-.476a1.391,1.391,0,0,1,.482-.777,1.412,1.412,0,0,1,.857-.308h2.891a1.418,1.418,0,0,1,.843.3,1.391,1.391,0,0,1,.486.749l.114.511a.9.9,0,0,0,.309.511.948.948,0,0,0,.559.2Zm-1.037,9.722c.21-1.981.586-6.7.586-6.747a.527.527,0,0,0-.023-.21.58.58,0,0,0-.1-.182.528.528,0,0,0-.166-.119.49.49,0,0,0-.2-.042h-9.708a.5.5,0,0,0-.2.042.52.52,0,0,0-.166.119.566.566,0,0,0-.133.392c0,.007.015.175.037.455.1,1.232.376,4.668.555,6.3a2.033,2.033,0,0,0,2.051,1.974c.879.021,1.784.028,2.71.028.872,0,1.757-.007,2.66-.028a2.017,2.017,0,0,0,1.459-.553,2.039,2.039,0,0,0,.64-1.428Z" transform="translate(-16469.299 -14914.999)" fill="#e5252a" fill-rule="evenodd" />
                                                </g>
                                            </svg>

                                        </a>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>

    )
}
export default Business_Files;