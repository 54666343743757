
export const TASK_STATUS = {
    OPEN: 1,
    IN_PROGRESS: 2,
    RE_OPEN: 3,
    FIXED: 4,
    IN_COMPLETE: 5,
    COMPLETED: 6,
    SUBMITTED: 7,
    VERIFIED: 8,
    HOLD: 9,
};

export const TASK_COLOR_STATUS = {
    OPEN: 1,
    INPROGRESS: 2,
    REOPEN: 3,
    FIXED: 4,
    INCOMPLETE: 5,
    COMPLETED: 6,
    SUBMITTED: 7,
    VERIFIED: 8,
    HOLD: 9,
};
