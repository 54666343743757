import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { http } from "../http/http";
import { toast } from "react-toastify";
import { GlobalContext } from "../../App";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import { Modal } from "react-bootstrap";

const Business_Contact_Detail = (props) => {
    const { setIsAddingNew, contactBusinessOpenModel, setOpenContactBusinessModel, setOpenShareHolderOpenModel, setContactModal, setOpenContactModalKey, sourceModal, fetchSearchResults, setDirectorModal, setShareHolderModal } = props;
    const navigate = useNavigate();
    const [manualEntryData, setManualEntryData] = useState({
        firstName: "",
        middleName: "",
        lastName: "",
        sin: "",
        companyName: "",
        email: "",
        phoneNumber: "",
        dob: "",
        gender: null,
        maritalStatus: null,
        streetAddress: "",
        city: "",
        province: "",
        country: "",
        postalCode: "",
        status: 1,
        notes: "",
    });
    const { setShowLoader } = useContext(GlobalContext);
    const [address, setAddress] = useState("");
    const [clientType, setClientType] = useState(1)
    const [errors, setErrors] = useState({ firstName: "", email: "", lastName: "" });
    const [showSIN, setShowSIN] = useState(false);

    const toggleSINVisibility = () => {
        setShowSIN(!showSIN); // Toggle between showing and hiding SIN
    };

    const handleClientTypeChange = (type) => {
        setClientType(type);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        setManualEntryData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        // Inline validation
        if (name === "email" && value) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? "" : "Invalid email format.",
            }));
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: value.trim() ? "" : "Required.",
            }));
        }
    };

    const handleSelect = async (value) => {
        setAddress(value);

        try {
            const results = await geocodeByAddress(value);
            if (results && results[0]) {
                const addressComponents = results[0].address_components;
                let city = "";
                let province = "";
                let postalCode = "";
                let country = "";

                addressComponents.forEach((component) => {
                    if (component.types.includes("locality")) {
                        city = component.long_name;
                    }
                    if (component.types.includes("administrative_area_level_1")) {
                        province = component.long_name;
                    }
                    if (component.types.includes("postal_code")) {
                        postalCode = component.long_name;
                    }
                    if (component.types.includes("country")) {
                        country = component.long_name;
                    }
                });

                setManualEntryData((prevData) => ({
                    ...prevData,
                    streetAddress: value,
                    city,
                    province,
                    postalCode,
                    country,
                }));
            } else {
                console.warn("No results found for the selected address.");
            }
        } catch (error) {
            console.error("Error selecting place:", error?.message || "No message", error?.stack || "No stack trace");
        }
    };

    const handleAddressChange = (value) => {
        setAddress(value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;
        setShowLoader(true);
        const payload = {
            // type: 1,
            type: clientType, // Set the client type (1 = Individual, 2 = Company)
            first_name: clientType === 1 ? String(manualEntryData.firstName).trim() : "", // Include first name for individual
            middle_name: clientType === 1 ? String(manualEntryData.middleName).trim() : "", // Include middle name for individual
            last_name: clientType === 1 ? String(manualEntryData.lastName).trim() : "", // Include last name for individual
            sin: clientType === 1 ? String(manualEntryData.sin).trim() : "", // Include last name for individual
            company_name: clientType === 2 ? String(manualEntryData.companyName).trim() : "", // Include company name for company
            first_name: manualEntryData.firstName,
            middle_name: manualEntryData.middleName,
            last_name: manualEntryData.lastName,
            company_name: manualEntryData.companyName,
            email: manualEntryData.email,
            phone_number: manualEntryData.phoneNumber,
            dob: manualEntryData.dob,
            gender: manualEntryData.gender,
            marital_status: manualEntryData.maritalStatus,
            street_address: manualEntryData.streetAddress,
            city: manualEntryData.city,
            province: manualEntryData.province,
            country: manualEntryData.country,
            postal_code: manualEntryData.postalCode,
            status: manualEntryData.status,
            notes: manualEntryData.notes,
        };
        http(
            {
                method: "POST",
                url: `v2/contact_detail/add`,
                body: payload,
                isSecure: true,
            },
            (res) => {
                toast.success(res.single_message);
                setShowLoader(false);
                fetchSearchResults();
                if (sourceModal === "business_contact") {
                    setOpenContactBusinessModel(false);
                    setContactModal(true);
                    setOpenContactModalKey("ContactKey");
                } else if (sourceModal === "director") {
                    setOpenContactBusinessModel(false); // Ensure this is correct for your logic
                    setDirectorModal(true)
                    setOpenContactModalKey("DirectorKey");

                } else if (sourceModal === "shareholder") {
                    setOpenContactBusinessModel(false); // Ensure this is correct for your logic
                    setShareHolderModal(true);
                    setOpenContactModalKey("ShareHolderKey");
                }
                setManualEntryData({

                    firstName: "",
                    middleName: "",
                    lastName: "",
                    sin: "",
                    companyName: "",
                    email: "",
                    phoneNumber: "",
                    dob: "",
                    gender: null,
                    maritalStatus: null,
                    streetAddress: "",
                    city: "",
                    province: "",
                    country: "",
                    postalCode: "",
                    status: 1,
                    notes: "",
                });
                setAddress('');
            },
            (err) => {
                // Handle errors according to the response
                if (err.status === 401) {
                    toast.error(err.single_message);
                    setShowLoader(false);

                } else if (err.status === 400) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else {
                    toast.error(err.single_message);
                    setShowLoader(false);
                }
            }
        );
    };

    const validateForm = () => {
        // const newErrors = { firstName: "", lastName: "", email: "" };


        let newErrors = {};
        // Validation based on client type
        if (clientType == 1) {
            // Individual - Validate First Name and Last Name
            if (!manualEntryData.firstName.trim()) {
                newErrors.firstName = "Required.";
            }
            if (!manualEntryData.lastName.trim()) {
                newErrors.lastName = "Required.";
            }


        } else if (clientType == 2) {

            if (!manualEntryData.companyName.trim()) {
                newErrors.companyName = "Required.";
            }

        }
        if (!manualEntryData.phoneNumber.trim()) {
            newErrors.phoneNumber = "Required.";
        }

        // // Validate firstName
        // if (!manualEntryData.firstName.trim()) {
        //     newErrors.firstName = "Required.";
        // }

        // // Validate lastName
        // if (!manualEntryData.lastName.trim()) {
        //     newErrors.lastName = "Required.";
        // }

        // Validate email
        if (!manualEntryData.email.trim()) {
            newErrors.email = "Required.";
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(manualEntryData.email)) {
            newErrors.email = "Invalid email format.";
        }

        setErrors(newErrors);
        return Object.values(newErrors).every((error) => !error); // Return true if no errors
    };

    const onCancel = () => {
        setManualEntryData({
            firstName: "",
            middleName: "",
            lastName: "",
            sin: "",
            companyName: "",
            email: "",
            phoneNumber: "",
            dob: "",
            gender: null,
            maritalStatus: null,
            streetAddress: "",
            city: "",
            province: "",
            country: "",
            postalCode: "",
            status: 1,
            notes: "",
        });
        setAddress("");
        setClientType(1);
        setOpenContactBusinessModel(false); // Close the modal
        setIsAddingNew(false); // Disable "Add New" mode
    };

    return (
        <Modal
            show={contactBusinessOpenModel}
            onHide={onCancel}
            centered
            size="xl"
            contentClassName="mx-auto"
            className="modal operating-modal font-poppins"
            backdrop="static"
            keyboard={false}
        >
            <div className="modal-header border-0 justify-content-between">
                <h4 className="fs-20 fw-semibold">Add Contact</h4>
                <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
            </div>
            <div className="modal-body">
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        {/* <div className="col-md-12">
                            <h6 className="fs-20 fw-semibold">Contact Details</h6>
                        </div> */}
                    </div>
                    <div className="col-lg-12 mt-3">
                        <label className="fs-18 fw-semibold mb-2 text-black">
                            Contact For <span className="text-danger">*</span>
                        </label>
                        <div className="d-flex align-items-center gap-4 mt-2">
                            <div className="d-flex align-items-center gap-2">
                                <input type="radio" id="individual" checked={clientType == 1} onChange={() => handleClientTypeChange("1")} />
                                <label htmlFor="individual">Person</label>
                            </div>
                            <div className="d-flex align-items-center gap-2">
                                <input
                                    type="radio"
                                    id="business"
                                    checked={clientType == 2}
                                    onChange={() => handleClientTypeChange("2")}
                                // disabled={sourceModal === "spouse"}
                                />
                                <label htmlFor="business">Company</label>
                            </div>
                        </div>
                    </div>
                    <div className="row form-group">
                        {clientType == 1 ? (
                            <>
                                <div className="col-lg-4 col-md-6 col-sm-12 mt-3">
                                    <label className="fs-18">
                                        First Name <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-input mt-2"
                                        placeholder="Type here..."
                                        name="firstName"
                                        value={manualEntryData.firstName}
                                        onChange={handleChange}

                                    />
                                    {errors.firstName && (
                                        <p className="text-danger mt-1">{errors.firstName}</p>
                                    )}
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 mt-3">
                                    <label className="fs-18">
                                        Middle Name <span className="text-danger">*</span>
                                    </label>
                                    <input

                                        type="text"
                                        name="middleName"
                                        className="form-input mt-2"
                                        placeholder="Type here..."
                                        value={manualEntryData.middleName}
                                        onChange={handleChange}

                                    />
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 mt-3">
                                    <label className="fs-18">
                                        Last Name <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        name="lastName"
                                        className="form-input mt-2"
                                        placeholder="Type here..."
                                        value={manualEntryData.lastName}
                                        onChange={handleChange}

                                    />
                                    {errors.lastName && (
                                        <p className="text-danger mt-1">{errors.lastName}</p>
                                    )}
                                </div>
                                <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                    <label className="fs-18">SIN</label>
                                    <div className="sin_area">
                                        <input
                                            type={showSIN ? "text" : "password"}
                                            name="sin"
                                            id="sin"
                                            className="form-input mt-2"
                                            maxLength={9}
                                            value={manualEntryData.sin}
                                            onChange={handleChange}
                                        />
                                        <div className="sin_icons">
                                            {showSIN ? (
                                                <i
                                                    className="fa fa-eye-slash"
                                                    onClick={toggleSINVisibility} // Hide SIN when clicked
                                                ></i>
                                            ) : (
                                                <i
                                                    className="fa fa-eye"
                                                    onClick={toggleSINVisibility} // Show SIN when clicked
                                                ></i>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div className="col-lg-4 col-md-6 col-sm-12 mt-3">
                                <label className="fs-18">
                                    Company Name <span className="text-danger">*</span>
                                </label>
                                <input
                                    type="text"
                                    name="companyName"
                                    className="form-input mt-2"
                                    placeholder="Type here..."
                                    value={manualEntryData.companyName}
                                    onChange={handleChange}

                                />
                                {errors.companyName && (
                                    <p className="text-danger mt-1">{errors.companyName}</p>
                                )}
                            </div>
                        )}
                        <div className="col-md-4 mt-4">
                            <label htmlFor="" className="w-100">Email Address <span className="text-danger ms-1">*</span></label>
                            <input
                                type="email"
                                name="email"
                                className="form-input mt-2"
                                placeholder="Type here..."
                                value={manualEntryData.email}
                                onChange={handleChange}
                            />
                            {errors.email && (
                                <p className="text-danger mt-1">{errors.email}</p>
                            )}
                        </div>
                        <div className="col-md-4 mt-4">
                            <label htmlFor="" className="w-100">Phone Number <span className="text-danger ms-1">*</span></label>
                            <input
                                type="number"
                                name="phoneNumber"
                                className="form-input mt-2"
                                placeholder="Phone Number"
                                value={manualEntryData.phoneNumber}
                                onChange={handleChange}
                            />
                            {errors.phoneNumber && (
                                <p className="text-danger mt-1">{errors.phoneNumber}</p>
                            )}
                        </div>
                        {clientType == 1 ? (
                            <div className="col-md-4 mt-4">
                                <label htmlFor="" className="w-100">Date of Birth </label>
                                <input
                                    type="date"
                                    name="dob"
                                    className="form-input mt-2"
                                    placeholder="Phone Number"
                                    value={manualEntryData.dob}
                                    onChange={handleChange}
                                />
                            </div>
                        ) : null}
                        <div className="col-md-4 mt-4">
                            <label className="w-100">Street Address</label>
                            <PlacesAutocomplete
                                value={address}
                                onChange={handleAddressChange}
                                onSelect={handleSelect}
                                searchOptions={{
                                    componentRestrictions: {
                                        country: ["us", "ca"],
                                    },
                                }}
                            >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div>
                                        <input
                                            {...getInputProps({
                                                placeholder: "Type address here...",
                                                className: "form-input mt-2",
                                            })}
                                        />
                                        <div className="autocomplete-dropdown-container">
                                            {loading && <div>Loading...</div>}
                                            {suggestions.map((suggestion) => {
                                                const className = suggestion.active
                                                    ? "suggestion-item--active"
                                                    : "suggestion-item";
                                                return (
                                                    <div
                                                        {...getSuggestionItemProps(suggestion, { className })}
                                                        key={suggestion.placeId}
                                                    >
                                                        <span>{suggestion.description}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </PlacesAutocomplete>
                        </div>
                        <div className="col-md-4 mt-4">
                            <label className="w-100">City</label>
                            <input
                                type="text"
                                name="city"
                                className="form-input mt-2"
                                placeholder="Type here..."
                                value={manualEntryData.city}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-md-4 mt-4">
                            <label htmlFor="" className="w-100">Province </label>
                            <input
                                type="text"
                                name="province"
                                className="form-input mt-2"
                                placeholder="Type here..."
                                value={manualEntryData.province}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-md-4 mt-4">
                            <label className="w-100">Country</label>
                            <input
                                type="text"
                                name="country"
                                className="form-input mt-2"
                                placeholder="Type here..."
                                value={manualEntryData.country}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-md-4 mt-4">
                            <label className="w-100">Postal Code</label>
                            <input
                                type="text"
                                name="postalCode"
                                className="form-input mt-2"
                                placeholder="Type here..."
                                value={manualEntryData.postalCode}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-md-12 mt-4">
                            <label htmlFor="" className="w-100">Notes </label>
                            <textarea
                                className="form-input mt-2"
                                name="notes"
                                id=""
                                cols="10"
                                rows="5"
                                placeholder="Message"
                                value={manualEntryData.notes}
                                onChange={handleChange}
                            ></textarea>
                        </div>
                        <div className="col-md-12 individual-footer">
                            <div className="btn-groups mt-4 mb-2">
                                <button type="submit" className="add-new ms-2 fs-20 px-5 py-4">Next</button>
                            </div>
                        </div>
                    </div>

                </form>

            </div>
        </Modal>
    )
}
export default Business_Contact_Detail;