import { http } from "./http";
import Auth from "../../auth/Auth";
export const handlePermissionData = async (setPermission, setModulePermissions) => {
    try {
        http(
            {
                method: "GET",
                url: `user/roles-permissions`,
                isSecure: true,
            },
            (res) => {
                Auth.setLocalPermission(res?.data);
                // const userPermissions = res?.data?.permissions || [];
                // setPermission(res?.data);
                // const dynamicPermissions = userPermissions.reduce((acc, perm) => {
                //     const [module] = perm.split('_'); // Extract module prefix (e.g., "task" from "task_view")
                //     if (!acc[module]) acc[module] = []; // Initialize array if it doesn't exist
                //     acc[module].push(perm); // Add the permission to the respective module array
                //     return acc;
                // }, {});

                // setModulePermissions(dynamicPermissions);
            }
        );
    } catch (error) {
        console.error("Error fetching permissions", error);
    }
};

export const handlePermissionIdsData = async (setPermissionIds) => {
    try {
        http(
            {
                method: "GET",
                url: `adminusers/listids`,
                isSecure: true,
            },
            (res) => {
                // setPermissionIds(res?.data);
                Auth.setLocalPermissionIds(res?.data);
            }
        );
    } catch (error) {
        console.error("Error fetching permission IDs", error);
    }
};

export const handleDesignationData = async (setDesignationListing, type = "") => {
    try {
        http(
            {
                method: "GET",
                url: `designation/listing?type=${type}`,
                isSecure: true,
            },
            (res) => {
                setDesignationListing(res?.data?.data);
            }
        );
    } catch (error) {
        console.error("Error fetching permission IDs", error);
    }
};




