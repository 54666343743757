import React, { useState } from 'react'

const ContactLinkedDetail = ({ contactData, onSave }) => {

    // Initialize state with the provided client data
    const [formData, setFormData] = useState({
        phone_number: contactData?.phone_number || "",
        unit_no: contactData?.unit_no || "",
        address: contactData?.address || "",
        city: contactData?.city || "",
        province: contactData?.province || "",
        postal_code: contactData?.postal_code || "",
        whatsapp_contact: contactData?.whatsapp_contact || "",
        client_since: contactData?.client_since || "",
        client_type: contactData?.client_type || "",
    });

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        if (onSave) {
            onSave(formData); // Pass the updated data to the parent component or API
        }
    };
    return (
        <>

            <form onSubmit={handleSubmit}>
                <div className="row light_input">
                    <div className="col-md-6 mt-3">
                        <label htmlFor="first_name">Phone number</label>
                        <input
                            type="text"
                            id="phone_number"
                            name="phone_number"
                            className="form-input mt-2"
                            value={formData.phone_number}
                            onChange={handleChange}
                            placeholder="Enter phone number"
                            readOnly
                        />
                    </div>
                    <div className="col-md-6 mt-3">
                        <label>Whatsapp Contact No. </label>
                        <input
                            type="text"
                            id="whatsapp_contact"
                            name="whatsapp_contact"
                            className="form-input mt-2"
                            value={formData.whatsapp_contact}
                            onChange={handleChange}
                            placeholder="Enter contact number"
                            readOnly
                        />
                    </div>
                    <div className="col-md-6 mt-3">
                        <label htmlFor="date_of_birth">Client Since</label>
                        <input
                            type="date"
                            id="client_since"
                            name="client_since"
                            className="form-input mt-2"
                            value={formData.client_since}
                            onChange={handleChange}
                            readOnly
                        />
                    </div>
                    <div className="col-md-6 mt-3">
                        <label htmlFor="date_of_birth">Client Type</label>
                        <input
                            type="text"
                            id="client_type"
                            name="client_type"
                            className="form-input mt-2"
                            value={formData.client_type}
                            onChange={handleChange}
                            readOnly
                        />
                    </div>
                    <div className="col-md-6 mt-3">
                        <label htmlFor="address">Address</label>
                        <input
                            id="address"
                            name="address"
                            className="form-input mt-2"
                            value={formData.address}
                            onChange={handleChange}
                            placeholder="Enter address"
                            readOnly
                        ></input>
                    </div>
                    <div className="col-md-6 mt-3">
                        <label htmlFor="city">City</label>
                        <input
                            type="text"
                            id="city"
                            name="city"
                            className="form-input mt-2"
                            value={formData.city}
                            onChange={handleChange}
                            placeholder="Enter city"
                            readOnly
                        />
                    </div>
                    <div className="col-md-6 mt-3">
                        <label htmlFor="province">Province</label>
                        <input
                            type="text"
                            id="province"
                            name="province"
                            className="form-input mt-2"
                            value={formData.province}
                            onChange={handleChange}
                            placeholder="Enter province"
                            readOnly
                        />
                    </div>
                    <div className="col-md-6 mt-3">
                        <label htmlFor="postal_code">Postal Code</label>
                        <input
                            type="text"
                            id="postal_code"
                            name="postal_code"
                            className="form-input mt-2"
                            value={formData.postal_code}
                            onChange={handleChange}
                            placeholder="Enter postal code"
                            readOnly
                        />
                    </div>
                    {/* <div className="col-md-12 mt-4">
                        <button type="submit" className="btn-blue px-4">
                            Save Changes
                        </button>
                    </div> */}
                </div>
            </form>
        </>
    )
}

export default ContactLinkedDetail
