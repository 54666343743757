import axios, { HttpStatusCode } from 'axios';
import ToastMessage from './ToastMessage';
import HttpClientHeader from './HttpClientHeader';
import Auth from '../auth/Auth';

const HttpClient = {
    async request(method, url, data = null, config = {}, settings = {}) {
        try {
            // const baseUrl = "http://demo.stsserver.com:8104/api";  // Ensure base URL is set from environment variables
            let baseUrl;

            const urldomain = window.location.href;
            const domain = new URL(urldomain).origin;

            if (
                domain.includes("localhost") ||
                domain.includes("192.168.5.18") ||
                domain.includes("devserver") ||
                domain.includes("stsserver")
            ) {
                baseUrl = process.env.REACT_APP_LOCAL_URL
                    ? process.env.REACT_APP_LOCAL_URL
                    : `${domain}/backend/api`;
            } else {
                baseUrl = `${domain}/backend/api`;
            }

            // Set default values for settings
            settings.showToast = settings.showToast ?? true;
            settings.headerContentType = settings.headerContentType ?? 'multipart';

            // Validate header content type and configure headers
            const headers = HttpClientHeader.getHeaders();
            if (settings.headerContentType === 'json') {
                headers['Content-Type'] = 'application/json';
            } else {
                // Let Axios handle Content-Type for FormData to ensure the boundary is set
                if (!(data instanceof FormData)) {
                    headers['Content-Type'] = 'multipart/form-data';
                }
            }

            // Handle PUT and DELETE requests with method override
            if (method === 'PUT' || method === 'DELETE') {
                if (data instanceof FormData) {
                    data.append('_method', method);
                } else {
                    data = { ...data, _method: method };
                }
                method = 'POST';
            }

            // Perform the HTTP request
            const response = await axios({
                method: method,
                url: baseUrl + url,
                data: data,
                headers: headers,
                onUploadProgress: (progressEvent) => {
                    // Locate progress elements
                    const progressBar = document.getElementById("progress-bar");
                    const progressText = document.getElementById("progress-text");
                    // Check if elements exist before updating
                    if (progressBar && progressText) {
                        // Calculate the upload percentage
                        const percentCompleted = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        );
                        // Update progress bar and text
                        progressBar.style.width = `${percentCompleted}%`;
                        progressText.textContent = `${percentCompleted}%`;
                        // Update bar color based on progress
                        if (percentCompleted > 0) {
                            progressBar.style.background = 'green'; // Progress color
                        } else {
                            progressBar.style.background = 'orange'; // Default state
                            progressBar.style.width = '45%'; // Default width
                        }
                    } else {
                        console.warn("Progress bar or text elements are not found in the DOM.");
                    }
                },
                signal: config.signal,
                ...config
            });

            // Process response
            if (response.data.success) {
                if (settings.showToast) {
                    ToastMessage.success(response.data.single_message);
                }
                return response.data.data;
            } else {
                throw new Error(response.data.single_message);
            }
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.single_message);
            } else {
                console.error('Error', error);
            }
            const errorMessage = error.response ? error.response.data.single_message : error.single_message;
            // Handle specific case for status code 429
            if (error.response && error.response.status == HttpStatusCode.TooManyRequests) {

            } else {
                ToastMessage.error(errorMessage);
            }

            if (error.response && error.response.status == HttpStatusCode.Unauthorized) {
                // Auth.clearAuth();
                Auth.logout();
                // window.location.reload();
            } else {
                throw new Error(errorMessage);
            }
        }
    },

    get(url, data = {}, config = {}, settings = {}) {
        const queryString = Object.keys(data).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])).join('&');
        const separator = url.includes('?') ? '&' : '?';
        url = url + (queryString ? separator + queryString : '');
        return this.request('GET', url, null, config, settings);
    },

    post(url, data = {}, config = {}, settings = {}) {
        return this.request('POST', url, data, config, settings);
    },

    put(url, data = {}, config = {}, settings = {}) {
        return this.request('PUT', url, data, config, settings);
    },

    delete(url, data = {}, config = {}, settings = {}) {
        const queryString = Object.keys(data).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])).join('&');
        const separator = url.includes('?') ? '&' : '?';
        url = url + (queryString ? separator + queryString : '');
        return this.request('DELETE', url, null, config, settings);
    }
};

export default HttpClient;