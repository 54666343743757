import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { http } from "../../http/http";
import moment from "moment";
import ReactPaginate from 'react-paginate';
import { toast } from 'react-toastify';
// import { handlePermissionData, handlePermissionIdsData } from '../../http/help';
import Auth from "../../../auth/Auth";
import { GlobalContext } from "../../../App";
import { Modal } from "react-bootstrap";


const Invoice_Details = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { showLoader, setShowLoader } = useContext(GlobalContext);
    // const [activeTab, setActiveTab] = useState(() => {
    //     const queryParams = new URLSearchParams(location.search);
    //     return queryParams.get("tab") || "invoices";
    // });
    const [activeTab, setActiveTab] = useState("all-invoice");
    const [invoiceList, setInvoiceList] = useState([]);
    const [reccuringInvoiceList, setReccuringInvoiceList] = useState([])
    const [activeFilter, setActiveFilter] = useState('All');
    const [activeFilterRecurring, setActiveFilterRecurring] = useState('All');
    const [showOptions, setShowOptions] = useState(false);
    const [showTotal, setShowTotal] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenRecurring, setIsOpenRecurring] = useState(false);
    const [currentPage, setCurrentPage] = useState(() => {
        const queryParams = new URLSearchParams(location.search);
        return Number(queryParams.get("page")) || 1;
    });
    const [totalPages, setTotalPages] = useState(1);
    const [currentPageRecurring, setCurrentPageRecurring] = useState(() => {
        const queryParams = new URLSearchParams(location.search);
        return Number(queryParams.get("page")) || 1;
    });
    const [totalPagesRecurring, setTotalPagesRecurring] = useState(1);
    const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);
    const [selectedRecurringInvoiceId, setSelectedRecurringInvoiceId] = useState(null);
    const [selectedInvoiceDetails, setSelectedInvoiceDetails] = useState(null);
    const [selectedRecurringInvoiceDetail, setSelectedRecurringInvoiceDetail] = useState(null);
    const [inVoicePaymentModal, setInVoicePaymentModal] = useState(false);


    const filterOptions = {
        All: "0",
        Paid: "1",
        Unpaid: "2",
        Draft: "3",
        "Partially Paid": "4",
        Active: "5",
    };

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        setSelectedRecurringInvoiceId(null);
        setSelectedInvoiceId(null);
        // Reset selectedInvoiceDetails and selectedRecurringInvoiceDetail when switching tabs
        setSelectedInvoiceDetails(null);
        setSelectedRecurringInvoiceDetail(null);
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const toggleDropdownRecurring = () => {
        setIsOpenRecurring(!isOpenRecurring);
    };
    const getListHandle = (page) => {
        setShowLoader(true);
        http(
            {
                method: "GET",
                url: `invoice/listing?page=${page}`,
                isSecure: true,

            },
            (res) => {
                setInvoiceList(res?.data?.invoice?.data)
                setCurrentPage(res?.data?.invoice.current_page);
                setTotalPages(res?.data?.invoice.last_page);
                setShowLoader(false);
            },
            (err) => {
                // Handle errors according to the response
                if (err.status === 401) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else if (err.status === 400) {
                    toast.error(err.single_message);
                    setShowLoader(false);
                } else {
                    toast.error(err.single_message);
                    setShowLoader(false);
                }
            }
        );
    }

    useEffect(() => {
        getListHandle(currentPage);
    }, [currentPage]);

    // const handlePageChange = (page) => {
    //     if (page >= 1 && page <= totalPages) {
    //         setCurrentPage(page);
    //     }
    // };
    const handlePageClick = (event) => {
        const selectedPage = event.selected + 1;
        setCurrentPage(selectedPage);
    };

    const getReccuringInvoiceList = () => {
        http(
            {
                method: "GET",
                url: "invoice/reccuring/listing",
                isSecure: true,

            },
            (res) => {
                setReccuringInvoiceList(res?.data?.invoice?.data)
                setCurrentPageRecurring(res?.data?.invoice.current_page);
                setTotalPagesRecurring(res?.data?.invoice.last_page);
            },
            (err) => {

            }
        );
    }
    const handlePageClickRecurring = (event) => {
        const selectedPage = event.selected + 1;
        setCurrentPageRecurring(selectedPage);
    };



    useEffect(() => {
        // getListHandle();
        getReccuringInvoiceList(currentPageRecurring);
    }, [currentPageRecurring]);

    const getTotalInvoice = () => {
        http(
            {
                method: "GET",
                url: "invoice/total",
                isSecure: true,

            },
            (res) => {

                setShowTotal(res?.data)
            },
            (err) => {

            }
        );
    }

    useEffect(() => {
        getTotalInvoice();
    }, []);

    const filterInvoices = (status) => {
        setActiveFilter(status);
        setIsOpen(false);
    };

    const filteredInvoices = () => {
        if (activeFilter === 'All') {
            return invoiceList;
        }

        if (activeFilter === 'Paid') {
            return invoiceList.filter(invoice => invoice.status == 1);
        }
        if (activeFilter === 'Unpaid') {
            return invoiceList.filter(invoice => invoice.status == 3);
        }
        if (activeFilter === 'Draft') {
            return invoiceList.filter(invoice => invoice.status == 2);
        }
        if (activeFilter === 'Partially Paid') {
            return invoiceList.filter(invoice => invoice.status == 4);
        }
        if (activeFilter === 'Active') {
            return invoiceList.filter(invoice => invoice.status == 5);
        }

        // Add more filters as needed
    };
    const filterInvoicesRecurring = (status) => {
        setActiveFilterRecurring(status);
        setIsOpenRecurring(false);
    };

    const filteredInvoicesRecurring = () => {
        if (activeFilterRecurring === 'All') {
            return reccuringInvoiceList;
        }
        if (activeFilterRecurring === 'Active') {
            return reccuringInvoiceList.filter(invoice => invoice.status == 1);
        }
        if (activeFilterRecurring === 'InActive') {
            return reccuringInvoiceList.filter(invoice => invoice.status == 2);
        }
        if (activeFilterRecurring === 'Stopped') {
            return reccuringInvoiceList.filter(invoice => invoice.status == 3);
        }


        // Add more filters as needed
    };



    // const getSerachInvoice = () => {
    //     http(
    //         {
    //             method: "POST",
    //             url: "invoice/search",
    //             isSecure: true,
    //             body: {
    //                 status: "3"
    //             }

    //         },
    //         (res) => {
    //         },
    //         (err) => {

    //         }
    //     );
    // }
    const handleRowClick = (invoiceId) => {
        if (!Auth.innerPermission("invoice_view")) {
            // Show an error message if the user is not authorized
            toast.error("You are not authorized to view  details.");
            return; // Exit the function
        }
        setSelectedInvoiceId(invoiceId); // Set the selected user ID
        try {
            http(
                {
                    method: "GET",
                    url: `invoice/${invoiceId}/view`,
                    isSecure: true,
                },
                (res) => {
                    setSelectedInvoiceDetails(res?.data?.invoice);
                },
                (err) => {
                    // Handle errors according to the response
                    if (err.status === 401) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else if (err.status === 400) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    }
                }
            )
        }
        catch (error) {

        }
    };


    const handleRecurringRowClick = (invoiceRecurringId) => {
        if (!Auth.innerPermission("invoice_view")) {
            // Show an error message if the user is not authorized
            toast.error("You are not authorized to view  details.");
            return; // Exit the function
        }
        setSelectedRecurringInvoiceId(invoiceRecurringId);
        try {
            http(
                {
                    method: "GET",
                    url: `invoice/reccuring/${invoiceRecurringId}/view`,
                    isSecure: true,
                },
                (res) => {
                    setSelectedRecurringInvoiceDetail(res?.data?.invoice);
                },
                (err) => {
                    // Handle errors according to the response
                    if (err.status === 401) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else if (err.status === 400) {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    } else {
                        toast.error(err.single_message);
                        setShowLoader(false);
                    }
                }
            )
        }
        catch (error) {

        }
    };

    const senInvoiceHandle = () => {
        http(
            {
                method: "GET",
                url: `invoice/generate-invoice/${selectedInvoiceDetails?.id}`,
                isSecure: true,
            },
            (res) => {
                toast.success(res?.message);
                setInVoicePaymentModal(true);
                // navigate("/admin/invoice_detail")
            },
            (err) => { },
        );
    }

    const handleRemoveSelection = () => {
        // Reset selected invoice details
        setSelectedInvoiceDetails(null);
        setSelectedInvoiceId(null);
    };

    const handleRecurringRemoveSelection = () => {
        // Reset selected invoice details
        setSelectedRecurringInvoiceDetail(null);
        setSelectedRecurringInvoiceId(null);
    };


    const handleAddInvoiceClick = () => {
        if (Auth.innerPermission("invoice_create")) {
            navigate('/admin/invoice/add_invoice');
        } else {
            toast.error("You are not authorized to access the resource");
        }
    };

    // onClick handler for the "Add New Invoice" button
    const handleAddRecurringInvoiceClick = () => {
        if (Auth.innerPermission("recurring_invoice_create")) {
            navigate('/admin/invoice/add_reccuring_invoice');
        } else {
            toast.error("You are not authorized to access the resource");
        }
    };

    const handleEditInvoiceClick = () => {
        if (Auth.innerPermission("invoice_update") || Auth.innerPermission("invoice_edit")) {
            // Navigate to edit invoice page if the user has either permission
            navigate(`/admin/invoice/edit_invoice/${selectedInvoiceDetails?.id}`);
        } else {
            // Show toast message if the user does not have either permission
            toast.error("You are not authorized to access the resource.");
        }
    };

    // const handleTabClick = (tabName) => {
    //     setShowLoader(true); // Show loader when switching tabs

    //     // Simulate data loading or fetching delay
    //     setTimeout(() => {
    //         setActiveTab(tabName); // Update active tab
    //      // Reset page to 1 when switching tabs
    //         setShowLoader(false); // Hide loader after "loading" finishes
    //     }, 500); // Simulated 500ms delay for smoother loader transi 
    // };


    // onClick handler for the "Edit" button
    const handleEditRecurringInvoiceClick = () => {
        if (Auth.innerPermission("recurring_invoice_update") || Auth.innerPermission("recurring_invoice_edit")) {
            // Navigate to edit invoice page if the user has permission
            navigate(`/admin/invoice/edit_reccuring_invoice/${selectedRecurringInvoiceDetail?.id}`)
        } else {
            // Show toast message if the user does not have permission
            toast.error("You are not authorized to access the resource.");
        }
    };


    return (
        <div className="right_section font-poppins">
            <div className="clients-area py-3">
                <div className="right_section font-poppins">
                    <div className="invoice_row">
                        <div className={`l_invoice ${selectedInvoiceDetails || selectedRecurringInvoiceDetail ? "showinvoice" : ""}`}>
                            <div className="invoice-head-main mt-4">
                                <h4 className='fs-20 fw-semibold mb-3'>Overview</h4>
                                <div className="d-flex align-items-center justify-content-between gap-3 flex-wrap">
                                    <div className="overdue" >
                                        <h4>${showTotal?.total_due_amount}</h4>
                                        <p className="overdue-badge">Overdue</p>
                                    </div>
                                    <span></span>
                                    <div className="open">
                                        <h4>${showTotal?.total_paid_amount}</h4>
                                        <p className="paid-badge">Paid</p>
                                    </div>
                                    <span></span>
                                    <div className="draft">
                                        <h4>${showTotal?.total_draft_amount}</h4>
                                        <p className="draft-badge">Draft</p>
                                    </div>
                                </div>
                            </div>
                            <div className="invoice-area py-4">
                                <ul className="nav nav-tabs gap-2 mb-3" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className={`nav-link ${activeTab === "all-invoice" ? "active" : ""}`}
                                            id="all-invoice-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#all-invoice"
                                            type="button"
                                            role="tab"
                                            aria-controls="all-invoice"
                                            aria-selected={activeTab === "all-invoice"}
                                            onClick={() => handleTabChange("all-invoice")}
                                        >
                                            Invoices
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className={`nav-link ${activeTab === "recurring-invoice" ? "active" : ""}`}
                                            id="recurring-invoice-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#recurring-invoice"
                                            type="button"
                                            role="tab"
                                            aria-controls="recurring-invoice"
                                            aria-selected={activeTab === "recurring-invoice"}
                                            onClick={() => handleTabChange("recurring-invoice")}
                                        >
                                            Recurring Invoices
                                        </button>
                                    </li>
                                </ul>

                                <div className="tab-content" id="myTabContent mt-2">
                                    <div className="tab-pane fade show active" id="all-invoice" role="tabpanel" aria-labelledby="all-invoice-tab">
                                        <div className="invoice-area mt-2 align-items-center">
                                            <div className="row invoice-head">
                                                <div className="col-md-12 left_head d-flex align-items-center justify-content-between flex-wrap">
                                                    <h4 className="fs-20 mt-2 fw-semibold">Invoices</h4>

                                                    <div className="right-inv-head d-flex align-items-center gap-2 flex-wrap">
                                                        <div className="dropdown-custom">
                                                            <div className="filter-new position-relative">
                                                                <div className="custom-filter h-40 d-flex align-items-center justify-content-between" onClick={toggleDropdown}>
                                                                    <h5 className="d-flex align-items-center gap-2">
                                                                        <img className={`rotate ${isOpen ? 'open' : ''}`} src={require("../../../assets/images/filter_icon.png")} alt="" />
                                                                        Filter
                                                                    </h5>
                                                                    <img className="rotate" src={require("../../../assets/images/drop_down_filter.png")} alt="" />
                                                                </div>
                                                                {isOpen && (
                                                                    <div className="custom-filter-open shadow-main">
                                                                        <ul className="pb-2 ps-0">
                                                                            <li className="fs-14 mx-2" onClick={() => filterInvoices('All')}>All</li>
                                                                            <li className="fs-14 mx-2" onClick={() => filterInvoices('Paid')}>Paid</li>
                                                                            <li className="fs-14 mx-2" onClick={() => filterInvoices('Unpaid')}>Unpaid</li>
                                                                            <li className="fs-14 mx-2" onClick={() => filterInvoices('Draft')}>Draft</li>
                                                                            <li className="fs-14 mx-2" onClick={() => filterInvoices('Partially Paid')}>Partial</li>
                                                                            {/* <li className="fs-14 mx-2" onClick={() => filterInvoices('Active')}>Active</li> */}
                                                                        </ul>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <a className="add-new text-white d-flex align-items-center py-3" onClick={handleAddInvoiceClick}>
                                                            <i className="fa fa-plus me-2"></i>
                                                            Add New Invoice
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* {canViewInvoice innerPermission() ? ( */}
                                            {Auth.innerPermission("invoice_list") ? (
                                                <div className="main-table-area mt-2 invoice-table">
                                                    <div className="table-responsive">

                                                        <table className="w-100">
                                                            <thead>
                                                                <tr>
                                                                    <th>Invoice No.</th>
                                                                    <th>Title</th>
                                                                    <th>Customer</th>
                                                                    {/* <th>Order No.</th>
                                                                    <th>Due Date</th>
                                                                    <th>Invoice Date</th> */}
                                                                    <th>Status</th>
                                                                    <th>Due Date</th>
                                                                    <th>Total Amount</th>
                                                                    <th>Paid Amount</th>
                                                                    <th>Balance Due</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    Array.isArray(filteredInvoices()) && filteredInvoices().length > 0 ? (
                                                                        filteredInvoices().map((invoice, index) => (
                                                                            <tr
                                                                                key={invoice?.id}
                                                                                onClick={() => handleRowClick(invoice.id)}
                                                                                className={selectedInvoiceId === invoice.id ? "active_tr" : ""}
                                                                            //   onClick={() => navigate(`/admin/invoice/invoice_preview/${invoice?.id}`)}
                                                                            // onClick={() => {
                                                                            //     if (Auth.innerPermission('invoice_view')) { // Check for permission
                                                                            //         navigate(`/admin/invoice/invoice_preview/${invoice?.id}`);
                                                                            //     } else {
                                                                            //         toast.error('You do not have permission to view this invoice.');
                                                                            //     }
                                                                            // }}
                                                                            >
                                                                                <td>{invoice?.invoice_number}</td>
                                                                                <td>{invoice?.invoice_title}</td>
                                                                                <td>
                                                                                    {invoice?.client_type == 1
                                                                                        ? invoice?.customer?.first_name
                                                                                        : invoice?.client_type == 2
                                                                                            ? invoice?.customer?.name
                                                                                            : '--'}
                                                                                </td>

                                                                                {/* <td>{invoice?.title}</td> */}
                                                                                {/* <td>{invoice?.order_number}</td>
                                                                                <td>{invoice?.due_date ? moment(invoice?.due_date).format('DD MMM YYYY') : '--'}</td>
                                                                                <td>{moment(invoice?.invoice_date).format('DD MMM YYYY')}</td> */}
                                                                                <td>
                                                                                    <span
                                                                                        className="publish"
                                                                                        style={{
                                                                                            backgroundColor:
                                                                                                invoice?.status === '1' ? '#D1E8D0' : // light green
                                                                                                    invoice?.status === '2' ? '#F1F1F1' : // light gray
                                                                                                        invoice?.status === '3' ? '#FED6D6' : // light red
                                                                                                            invoice?.status === '4' ? '#FFEECC' : // light yellow
                                                                                                                'transparent',
                                                                                            color:
                                                                                                invoice?.status === '1' ? '#1C8B15' : // green
                                                                                                    invoice?.status === '2' ? '#202529' : // black
                                                                                                        invoice?.status === '3' ? '#F93333' : // red
                                                                                                            invoice?.status === '4' ? '#FFAA27' : // yellow
                                                                                                                // invoice?.status === '5' ? '#024FC5' : // red
                                                                                                                'inherit'
                                                                                        }}
                                                                                    >
                                                                                        {invoice?.status === '1' ? 'Paid' :
                                                                                            invoice?.status === '2' ? 'Draft' :
                                                                                                invoice?.status === '3' ? 'Unpaid' :
                                                                                                    invoice?.status === '4' ? 'Partial' : '--'}
                                                                                    </span>
                                                                                </td>
                                                                                <td>{invoice?.due_date || "--"}</td>
                                                                                <td>${invoice?.total_price || "--"}</td>
                                                                                <td>{invoice?.payment_price || "--"}</td>
                                                                                <td>{invoice?.balance_due || "--"}</td>
                                                                            </tr>
                                                                        ))
                                                                    ) : (
                                                                        <tr>
                                                                            <td colSpan="9" className='text-center'>No invoices found.</td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            </tbody>

                                                        </table>

                                                        <ReactPaginate
                                                            previousLabel="<"
                                                            nextLabel=">"
                                                            breakLabel="..."
                                                            pageCount={totalPages}
                                                            marginPagesDisplayed={2}
                                                            pageRangeDisplayed={3}
                                                            onPageChange={handlePageClick}
                                                            containerClassName="pagination justify-content-end align-items-center mt-2 pe-0"
                                                            pageClassName='page-item nowrap'
                                                            pageLinkClassName='page-link'
                                                            previousClassName='page-item'
                                                            previousLinkClassName='page-link'
                                                            nextClassName='page-item'
                                                            nextLinkClassName='page-link'
                                                            breakClassName='page-item'
                                                            breakLinkClassName='page-link'
                                                            activeClassName='active'
                                                        />
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="no-access-message">
                                                    You are not authorized to access the resource.
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="recurring-invoice" role="tabpanel" aria-labelledby="recurring-invoice-tab">
                                        <div className="invoice-area mt-2 align-items-center">
                                            <div className="row invoice-head">
                                                <div className="col-md-12 left_head d-flex align-items-center flex-wrap justify-content-between">
                                                    <h4 className="fs-20 mt-2 fw-semibold">Recurring Invoices</h4>

                                                    <div className="right-inv-head d-flex align-items-center gap-2 flex-wrap">
                                                        <div className="dropdown-custom">
                                                            <div className="filter-new position-relative">
                                                                <div className="custom-filter h-40 d-flex align-items-center justify-content-between" onClick={toggleDropdownRecurring}>
                                                                    <h5 className="d-flex align-items-center gap-2">
                                                                        <img className={`rotate ${isOpenRecurring ? 'open' : ''}`} src={require("../../../assets/images/filter_icon.png")} alt="" />
                                                                        Filter
                                                                    </h5>
                                                                    <img className="rotate" src={require("../../../assets/images/drop_down_filter.png")} alt="" />
                                                                </div>
                                                                {isOpenRecurring && (
                                                                    <div className="custom-filter-open shadow-main">
                                                                        <ul className="pb-2 ps-0">
                                                                            <li className="fs-14 mx-2" onClick={() => filterInvoicesRecurring('All')}>All</li>
                                                                            <li className="fs-14 mx-2" onClick={() => filterInvoicesRecurring('Active')}>Active</li>
                                                                            <li className="fs-14 mx-2" onClick={() => filterInvoicesRecurring('InActive')}>Inactive</li>
                                                                            <li className="fs-14 mx-2" onClick={() => filterInvoicesRecurring('Stopped')}>Stopped</li>

                                                                        </ul>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <a className="add-new text-white d-flex align-items-center py-3" onClick={handleAddRecurringInvoiceClick}>
                                                            <i className="fa fa-plus me-2"></i>
                                                            Add New Recurring Invoice
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            {Auth.innerPermission("recurring_invoice_list") ? (
                                                <div className="main-table-area mt-2 invoice-table">
                                                    <div className="table-responsive">
                                                        <table className="w-100">
                                                            <thead>
                                                                <tr>


                                                                    <th>Recurring Invoice No.</th>
                                                                    <th>Title</th>
                                                                    <th>Customer</th>
                                                                    <th>Order No.</th>
                                                                    {/* <th>Due Date</th> */}
                                                                    <th>Last Invoice Date</th>
                                                                    <th>Next Invoice Date</th>
                                                                    <th>Total Amount</th>
                                                                    <th>Status</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    Array.isArray(filteredInvoicesRecurring()) && filteredInvoicesRecurring().length > 0 ? (
                                                                        filteredInvoicesRecurring().map((recurringList, index) => (
                                                                            <tr
                                                                                key={recurringList?.id}
                                                                                onClick={() => handleRecurringRowClick(recurringList?.invoices?.[0]?.id)}
                                                                                className={selectedRecurringInvoiceId === recurringList?.invoices?.[0]?.id ? "active_tr" : ""}
                                                                            // onClick={() => navigate(`/admin/invoice/reccuring_preview/${recurringList?.invoices?.[0]?.id}`)}
                                                                            // onClick={() => {
                                                                            //     if (Auth.innerPermission('recurring_invoice_view')) { // Check for permission
                                                                            //         navigate(`/admin/invoice/reccuring_preview/${recurringList?.invoices?.[0]?.id}`);
                                                                            //     } else {
                                                                            //         toast.error('You do not have permission to view this invoice.');
                                                                            //     }
                                                                            // }}

                                                                            >
                                                                                <td>{recurringList?.recurring_number}</td>
                                                                                <td>{recurringList?.invoices?.[0]?.invoice_title || "--"}</td>
                                                                                <td>{recurringList?.invoices?.[0]?.client_type === "1"
                                                                                    ? recurringList?.invoices?.[0]?.customer?.first_name
                                                                                    : recurringList?.invoices?.[0]?.client_type === "2"
                                                                                        ? recurringList?.invoices?.[0]?.customer?.name
                                                                                        : '--'}</td>

                                                                                <td>{recurringList?.invoices?.[0]?.order_number || "--"}</td>
                                                                                {/* <td>{recurringList?.due_date ? moment(recurringList?.due_date).format('DD MMM YYYY') : "--"}</td> */}
                                                                                <td>{recurringList?.invoice_date ? moment(recurringList?.invoice_date).format('DD MMM YYYY') : "--"}</td>
                                                                                <td>{recurringList?.recurring_start_next_date ? moment(recurringList?.recurring_start_next_date).format('DD MMM YYYY') : "--"}</td>
                                                                                <td>${recurringList?.invoices?.[0]?.total_price || "--"}</td>
                                                                                <td>
                                                                                    <span
                                                                                        className="status_badge"
                                                                                        style={{
                                                                                            backgroundColor:
                                                                                                recurringList?.recurring_status === "1" ? '#D5E2F6' : // light green
                                                                                                    recurringList?.recurring_status === "2" ? '#FED6D6' : // light gray
                                                                                                        recurringList?.recurring_status === "3" ? '#F1F2F3' : // light red
                                                                                                            'transparent',
                                                                                            color:
                                                                                                recurringList?.recurring_status === "1" ? '#024FC5' : // green
                                                                                                    recurringList?.recurring_status === "2" ? '#E5252A' : // black
                                                                                                        recurringList?.recurring_status === "3" ? '#000000' : // red
                                                                                                            'inherit'
                                                                                        }}
                                                                                    >
                                                                                        {recurringList?.recurring_status === "1" ? 'Active' :
                                                                                            recurringList?.recurring_status === "2" ? 'Inactive' :
                                                                                                recurringList?.recurring_status === "3" ? 'Stopped' : '--'}
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                    ) : (
                                                                        <tr>
                                                                            <td colSpan="8" className='text-center'>No recurring invoices found.</td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            </tbody>
                                                        </table>
                                                        <ReactPaginate
                                                            previousLabel="<"
                                                            nextLabel=">"
                                                            breakLabel="..."
                                                            pageCount={totalPagesRecurring}
                                                            marginPagesDisplayed={2}
                                                            pageRangeDisplayed={3}
                                                            onPageChange={handlePageClickRecurring}
                                                            containerClassName="pagination justify-content-end align-items-center mt-2 pe-0"
                                                            pageClassName='page-item nowrap'
                                                            pageLinkClassName='page-link'
                                                            previousClassName='page-item'
                                                            previousLinkClassName='page-link'
                                                            nextClassName='page-item'
                                                            nextLinkClassName='page-link'
                                                            breakClassName='page-item'
                                                            breakLinkClassName='page-link'
                                                            activeClassName='active'
                                                        />
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="no-access-message">
                                                    You are not authorized to access the resource.
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {activeTab === "all-invoice" && (
                            <div className={`r_invoice bg-white p-4 mt-4 position-relative ${selectedInvoiceDetails ? "showinvoice" : ""}`}>
                                <div className="close_modal">
                                    <i className='fa fa-times fs-18 cursor-pointer' onClick={handleRemoveSelection}></i>
                                </div>
                                <div className="inv_head d-flex align-items-center justify-content-between gap-2">
                                    <h4 className='fs-20 fw-semibold'>{selectedInvoiceDetails?.invoice_number}</h4>
                                    <div className="d-flex align-items-center flex-wrap gap-2">
                                        <a className='fs-14 fw-medium d-flex align-items-center gap-1 text-black' onClick={handleEditInvoiceClick}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="11.808" height="11.808" viewBox="0 0 11.808 11.808">
                                                <g id="write" transform="translate(-7.998 -8)">
                                                    <path id="Path_3528" data-name="Path 3528" d="M8.251,24.827,8,26.877a.616.616,0,0,0,.614.7.605.605,0,0,0,.078,0l2.051-.248a1.954,1.954,0,0,0,1.138-.559L17.2,21.45a.246.246,0,0,0,0-.348l-2.726-2.729a.246.246,0,0,0-.348,0L8.81,23.688a1.954,1.954,0,0,0-.559,1.138Z" transform="translate(0 -7.767)" fill="#202529" />
                                                    <path id="Path_3529" data-name="Path 3529" d="M41.174,9.645,39.9,8.374a1.275,1.275,0,0,0-1.8,0L36.9,9.568a.246.246,0,0,0,0,.348l2.728,2.728a.246.246,0,0,0,.348,0l1.194-1.194A1.275,1.275,0,0,0,41.174,9.645Z" transform="translate(-21.741 0)" fill="#202529" />
                                                    <path id="Path_3530" data-name="Path 3530" d="M35.642,52.492A.492.492,0,0,0,35.15,52H29.492a.492.492,0,0,0,0,.984H35.15A.492.492,0,0,0,35.642,52.492Z" transform="translate(-15.835 -33.176)" fill="#202529" />
                                                </g>
                                            </svg>
                                            Edit
                                        </a>
                                        {/* <a className='fs-14 fw-medium text-black'>Convert Quote</a> */}
                                        <a className='fs-14 fw-medium' onClick={senInvoiceHandle}>Send Invoice</a>
                                    </div>
                                </div>
                                <div className="inv_headsecond d-flex align-items-center justify-content-between gap-2 mt-4">
                                    {/* <div className='d-flex align-items-center gap-3'>
                                        <a className='fs-14 fw-regular d-flex align-items-center gap-1'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                                                <path id="expand" d="M4.247.75a.75.75,0,0,1-.75.75h-2v2A.75.75,0,1,1,0,3.5V.75A.75.75,0,0,1,.75,0H3.5A.75.75,0,0,1,4.247.75ZM3.5,10.5h-2v-2A.75.75,0,1,0,0,8.5V11.25A.75.75,0,0,0,.75,12H3.5a.75.75,0,1,0,0-1.5ZM11.25,7.753a.75.75,0,0,0-.75.75v2h-2a.75.75,0,1,0,0,1.5H11.25a.75.75,0,0,0,.75-.75V8.5A.75.75,0,0,0,11.25,7.753ZM11.25,0H8.5a.75.75,0,1,0,0,1.5h2v2a.75.75,0,1,0,1.5,0V.75A.75.75,0,0,0,11.25,0Z" fill="#77a0df" />
                                            </svg>
                                            View Full Size
                                        </a>
                                        <a className='fs-14 fw-regular d-flex align-items-center gap-1 text-black'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="11.974" height="12" viewBox="0 0 11.974 12">
                                                <g id="direct-download" transform="translate(0 -2)">
                                                    <path id="Path_3702" data-name="Path 3702" d="M9.6,10.4a.449.449,0,0,1-.325-.139l-3.15-3.3A.45.45,0,0,1,6.45,6.2H8.1V2.75A.751.751,0,0,1,8.85,2h1.5a.751.751,0,0,1,.75.75V6.2h1.65a.45.45,0,0,1,.325.761l-3.15,3.3A.449.449,0,0,1,9.6,10.4Z" transform="translate(-3.613)" fill="#212529" />
                                                    <path id="Path_3703" data-name="Path 3703" d="M11.1,20.4H.873A.975.975,0,0,1,0,19.35v-.3A.975.975,0,0,1,.873,18H11.1a.975.975,0,0,1,.873,1.05v.3A.975.975,0,0,1,11.1,20.4Z" transform="translate(0 -6.4)" fill="#212529" />
                                                </g>
                                            </svg>
                                            Download
                                        </a>
                                    </div> */}
                                    <span
                                        className='invoice_status px-2 py-1 rounded-3 fs-14'
                                        style={{
                                            backgroundColor:
                                                selectedInvoiceDetails?.status === '1' ? '#D1E8D0' : // light green
                                                    selectedInvoiceDetails?.status === '2' ? '#F1F1F1' : // light gray
                                                        selectedInvoiceDetails?.status === '3' ? '#FED6D6' : // light red
                                                            selectedInvoiceDetails?.status === '4' ? '#FFEECC' : // light yellow
                                                                selectedInvoiceDetails?.status === '5' ? '#D5E2F6' : // light yellow
                                                                    'transparent',
                                            color:
                                                selectedInvoiceDetails?.status === '1' ? '#1C8B15' : // green
                                                    selectedInvoiceDetails?.status === '2' ? '#202529' : // black
                                                        selectedInvoiceDetails?.status === '3' ? '#F93333' : // red
                                                            selectedInvoiceDetails?.status === '4' ? '#FFAA27' : // yellow
                                                                selectedInvoiceDetails?.status === '5' ? '#024FC5' : // red
                                                                    'inherit'
                                        }}
                                    >

                                        {selectedInvoiceDetails?.status === '1' ? 'Paid' :
                                            selectedInvoiceDetails?.status === '2' ? 'Draft' :
                                                selectedInvoiceDetails?.status === '3' ? 'Unpaid' :
                                                    selectedInvoiceDetails?.status === '4' ? 'Partial' :
                                                        selectedInvoiceDetails?.status === '5' ? 'Active' : '--'}
                                    </span>
                                </div>
                                <div className="invoice-pdfbox py-3 px-4 mt-3">
                                    <div className="pdf-preview-area bg-white p-3">
                                        <table className='pb-0'>
                                            <tbody>
                                                <tr>
                                                    <td className="py-2 px-3">
                                                        <img style={{ width: '250px', height: '100%', borderRadius: '0px' }} src={require("../../../assets/images/assist-pdf.png")} />
                                                    </td>
                                                    <td className="py-2 px-0" align="right">
                                                        <h4>INVOICE</h4>
                                                        <h6>{selectedInvoiceDetails?.invoice_number}</h6>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="py-2 px-0">
                                                        <h2>AssistERP</h2>
                                                        <h3>{selectedInvoiceDetails?.company_profile?.address}</h3>
                                                    </td>
                                                    <td className="py-2 px-0" align="right">
                                                        <p>Bill To :</p>
                                                        {selectedInvoiceDetails?.client_type === "1" ? (
                                                            <h5>{selectedInvoiceDetails?.customer?.first_name}</h5>
                                                        ) : (
                                                            ""
                                                            // <h5>{selectedInvoiceDetails?.customer?.address}</h5>
                                                        )}
                                                        <h3>{selectedInvoiceDetails?.customer?.office_location}</h3>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="p-0"><h5>Due Date: {selectedInvoiceDetails?.due_date || "--"}</h5></td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <table className="item-table pt-0">
                                            <thead>
                                                <tr >
                                                    <th>Item Name</th>
                                                    <th>Item Description</th>
                                                    <th>Price</th>
                                                    <th>Qty.</th>
                                                    <th>Discount</th>
                                                    <th>Tax</th>
                                                    <th>Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {selectedInvoiceDetails?.invoice_items?.map((itemList, index) => (
                                                    <tr key={index}>
                                                        <td>{itemList?.name || "--"}</td>
                                                        <td>{itemList?.description || "--"}</td>
                                                        <td>${itemList?.price || "--"}</td>
                                                        <td>{itemList?.quantity || "--"}</td>
                                                        <td>{itemList?.item_discount_price || "--"}</td>
                                                        <td>{itemList?.tax_price || "--"}</td>
                                                        <td>${itemList?.item_total || "--"}</td>
                                                    </tr>
                                                ))}

                                                <tr>
                                                    <td className="py-2 px-0" colSpan="7">
                                                        <hr />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="p-0" align="right" colSpan="4"><h5>Sub Total:</h5></td>
                                                    <td className="p-0" colSpan="3" align="right">${selectedInvoiceDetails?.total_price}</td>
                                                </tr>
                                                <tr>
                                                    <td className="p-0" align="right" colSpan="4"><h5>Total Tax:</h5></td>
                                                    <td className="p-0" colSpan="3" align="right">${selectedInvoiceDetails?.total_tax_price}</td>
                                                </tr>
                                                <tr>
                                                    <td className="p-0" align="right" colSpan="4"><h5>Discount:</h5></td>
                                                    <td className="p-0" colSpan="3" align="right">${selectedInvoiceDetails?.total_discount_price}</td>
                                                </tr>
                                                <tr>
                                                    <td className="py-2 px-0" colSpan="7"><hr /></td>
                                                </tr>
                                                <tr>
                                                    <td className="p-0" align="right" colSpan="4"><h2>Total:</h2></td>
                                                    <td className="p-0" colSpan="3" align="right"><h2>${selectedInvoiceDetails?.total_price}</h2></td>
                                                </tr>
                                                <tr>
                                                    <td className="py-2 px-0" colSpan="7"><hr /></td>
                                                </tr>
                                                <tr>
                                                    <td className="p-0" align="right" colSpan="4"><h5>Paid:</h5></td>
                                                    <td className="p-0" colSpan="3" align="right">${selectedInvoiceDetails?.payment_price}</td>
                                                </tr>
                                                <tr>
                                                    <td className="p-0" align="right" colSpan="4"><h5>Balance Due:</h5></td>
                                                    <td className="p-0" colSpan="3" align="right">${selectedInvoiceDetails?.balance_due}</td>
                                                </tr>
                                                <tr>
                                                    <td className="py-2 px-0" colSpan="7">
                                                        <p>If you use this site regularly and would like to help keep the site on the Internet, please consider donating a small sum to help pay for the hosting and bandwidth bill. If you use this site regularly and would like to help keep the site on the Internet, please consider donating a small sum to help pay for the hosting and bandwidth bill. </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        )}
                        {activeTab === "recurring-invoice" && (
                            <div className={`r_invoice bg-white p-4 mt-4 position-relative ${selectedRecurringInvoiceDetail ? "showinvoice" : ""}`}>
                                <div className="inv_head d-flex align-items-center justify-content-between gap-2">
                                    <h4 className='fs-20 fw-semibold'>{selectedInvoiceDetails?.invoice_number}</h4>
                                    <div className="close_modal">
                                        <i className='fa fa-times fs-18 cursor-pointer' onClick={handleRecurringRemoveSelection}></i>
                                    </div>
                                    <div className="d-flex align-items-center flex-wrap gap-2">
                                        <a className='fs-14 fw-medium d-flex align-items-center gap-1 text-black' onClick={handleEditRecurringInvoiceClick}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="11.808" height="11.808" viewBox="0 0 11.808 11.808">
                                                <g id="write" transform="translate(-7.998 -8)">
                                                    <path id="Path_3528" data-name="Path 3528" d="M8.251,24.827,8,26.877a.616.616,0,0,0,.614.7.605.605,0,0,0,.078,0l2.051-.248a1.954,1.954,0,0,0,1.138-.559L17.2,21.45a.246.246,0,0,0,0-.348l-2.726-2.729a.246.246,0,0,0-.348,0L8.81,23.688a1.954,1.954,0,0,0-.559,1.138Z" transform="translate(0 -7.767)" fill="#202529" />
                                                    <path id="Path_3529" data-name="Path 3529" d="M41.174,9.645,39.9,8.374a1.275,1.275,0,0,0-1.8,0L36.9,9.568a.246.246,0,0,0,0,.348l2.728,2.728a.246.246,0,0,0,.348,0l1.194-1.194A1.275,1.275,0,0,0,41.174,9.645Z" transform="translate(-21.741 0)" fill="#202529" />
                                                    <path id="Path_3530" data-name="Path 3530" d="M35.642,52.492A.492.492,0,0,0,35.15,52H29.492a.492.492,0,0,0,0,.984H35.15A.492.492,0,0,0,35.642,52.492Z" transform="translate(-15.835 -33.176)" fill="#202529" />
                                                </g>
                                            </svg>
                                            Edit
                                        </a>
                                        {/* <a href="#!" className='fs-14 fw-medium text-black'>Convert Quote</a>
                                    <a className='fs-14 fw-medium' onClick={senInvoiceHandle}>Send Invoice</a> */}
                                    </div>
                                </div>
                                <div className="inv_headsecond d-flex align-items-center justify-content-between gap-2 mt-4">
                                    {/* <div className='d-flex align-items-center gap-3'>
                                        <a className='fs-14 fw-regular d-flex align-items-center gap-1'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                                                <path id="expand" d="M4.247.75a.75.75,0,0,1-.75.75h-2v2A.75.75,0,1,1,0,3.5V.75A.75.75,0,0,1,.75,0H3.5A.75.75,0,0,1,4.247.75ZM3.5,10.5h-2v-2A.75.75,0,1,0,0,8.5V11.25A.75.75,0,0,0,.75,12H3.5a.75.75,0,1,0,0-1.5ZM11.25,7.753a.75.75,0,0,0-.75.75v2h-2a.75.75,0,1,0,0,1.5H11.25a.75.75,0,0,0,.75-.75V8.5A.75.75,0,0,0,11.25,7.753ZM11.25,0H8.5a.75.75,0,1,0,0,1.5h2v2a.75.75,0,1,0,1.5,0V.75A.75.75,0,0,0,11.25,0Z" fill="#77a0df" />
                                            </svg>
                                            View Full Size
                                        </a>
                                        <a className='fs-14 fw-regular d-flex align-items-center gap-1 text-black'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="11.974" height="12" viewBox="0 0 11.974 12">
                                                <g id="direct-download" transform="translate(0 -2)">
                                                    <path id="Path_3702" data-name="Path 3702" d="M9.6,10.4a.449.449,0,0,1-.325-.139l-3.15-3.3A.45.45,0,0,1,6.45,6.2H8.1V2.75A.751.751,0,0,1,8.85,2h1.5a.751.751,0,0,1,.75.75V6.2h1.65a.45.45,0,0,1,.325.761l-3.15,3.3A.449.449,0,0,1,9.6,10.4Z" transform="translate(-3.613)" fill="#212529" />
                                                    <path id="Path_3703" data-name="Path 3703" d="M11.1,20.4H.873A.975.975,0,0,1,0,19.35v-.3A.975.975,0,0,1,.873,18H11.1a.975.975,0,0,1,.873,1.05v.3A.975.975,0,0,1,11.1,20.4Z" transform="translate(0 -6.4)" fill="#212529" />
                                                </g>
                                            </svg>
                                            Download
                                        </a>
                                    </div> */}
                                    {/* <span

                                    style={{
                                        backgroundColor:
                                            selectedInvoiceDetails?.status === '1' ? '#D1E8D0' : // light green
                                                selectedInvoiceDetails?.status === '2' ? '#F1F1F1' : // light gray
                                                    selectedInvoiceDetails?.status === '3' ? '#FED6D6' : // light red
                                                        selectedInvoiceDetails?.status === '4' ? '#FFEECC' : // light yellow
                                                            selectedInvoiceDetails?.status === '5' ? '#D5E2F6' : // light yellow
                                                                'transparent',
                                        color:
                                            selectedInvoiceDetails?.status === '1' ? '#1C8B15' : // green
                                                selectedInvoiceDetails?.status === '2' ? '#202529' : // black
                                                    selectedInvoiceDetails?.status === '3' ? '#F93333' : // red
                                                        selectedInvoiceDetails?.status === '4' ? '#FFAA27' : // yellow
                                                            selectedInvoiceDetails?.status === '5' ? '#024FC5' : // red
                                                                'inherit'
                                    }}
                                >

                                    {selectedInvoiceDetails?.status === '1' ? 'Paid' :
                                        selectedInvoiceDetails?.status === '2' ? 'Draft' :
                                            selectedInvoiceDetails?.status === '3' ? 'Unpaid' :
                                                selectedInvoiceDetails?.status === '4' ? 'Partial' :
                                                    selectedInvoiceDetails?.status === '5' ? 'Active' : '--'}
                                </span> */}
                                </div>
                                <div className="invoice-pdfbox py-3 px-4 mt-3">
                                    <div className="pdf-preview-area bg-white p-3">
                                        <table className='pb-0'>
                                            <tbody>
                                                <tr>
                                                    <td className="py-2 px-3">
                                                        <img style={{ width: '250px', height: '100%', borderRadius: '0px' }} src={require("../../../assets/images/assist-pdf.png")} />
                                                    </td>
                                                    <td className="py-2 px-0" align="right">
                                                        <h4>INVOICE</h4>
                                                        <h6>{selectedRecurringInvoiceDetail?.recurring_invoice?.recurring_number}</h6>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="py-2 px-0">
                                                        <h2>AssistERP</h2>
                                                        <h3>{selectedRecurringInvoiceDetail?.company_profile?.address}</h3>
                                                    </td>
                                                    <td className="py-2 px-0" align="right">
                                                        <p>Bill To :</p>
                                                        {selectedRecurringInvoiceDetail?.client_type === "1" ? (
                                                            <h5>{selectedRecurringInvoiceDetail?.customer?.first_name}</h5>
                                                        ) : (
                                                            <h5>{selectedRecurringInvoiceDetail?.customer?.address}</h5>
                                                        )}
                                                        <h3>Shawna Common 8550 The Causeway Port Nola Mauritania, Brampton, Ontario, Canada</h3>
                                                    </td>
                                                </tr>
                                                {/* <tr>
                                                    <td className="p-0"><h5>Due Date: {selectedRecurringInvoiceDetail?.due_date || "--"}</h5></td>
                                                </tr> */}
                                            </tbody>
                                        </table>

                                        <table className="item-table pt-0">
                                            <thead>
                                                <tr >
                                                    <th>Item Name</th>
                                                    <th>Item Description</th>
                                                    <th>Price</th>
                                                    <th>Qty.</th>
                                                    <th>Discount</th>
                                                    <th>Tax</th>
                                                    <th>Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {selectedRecurringInvoiceDetail?.invoice_items?.map((itemList, index) => (
                                                    <tr key={index}>
                                                        <td>{itemList?.name || "--"}</td>
                                                        <td>{itemList?.description || "--"}</td>
                                                        <td>${itemList?.price || "--"}</td>
                                                        <td>{itemList?.quantity || "--"}</td>
                                                        <td>{itemList?.item_discount_price || "--"}</td>
                                                        <td>{itemList?.tax_price || "--"}</td>
                                                        <td>${itemList?.item_total || "--"}</td>
                                                    </tr>
                                                ))}

                                                <tr>
                                                    <td className="py-2 px-0" colSpan="7">
                                                        <hr />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="p-0" align="right" colSpan="4"><h5>Sub Total:</h5></td>
                                                    <td className="p-0" colSpan="3" align="right">${selectedRecurringInvoiceDetail?.total_price}</td>
                                                </tr>
                                                <tr>
                                                    <td className="p-0" align="right" colSpan="4"><h5>Total Tax:</h5></td>
                                                    <td className="p-0" colSpan="3" align="right">${selectedRecurringInvoiceDetail?.total_tax_price}</td>
                                                </tr>
                                                <tr>
                                                    <td className="p-0" align="right" colSpan="4"><h5>Discount:</h5></td>
                                                    <td className="p-0" colSpan="3" align="right">${selectedRecurringInvoiceDetail?.total_discount_price}</td>
                                                </tr>
                                                <tr>
                                                    <td className="py-2 px-0" colSpan="7"><hr /></td>
                                                </tr>
                                                <tr>
                                                    <td className="p-0" align="right" colSpan="4"><h2>Total:</h2></td>
                                                    <td className="p-0" colSpan="3" align="right"><h2>${selectedRecurringInvoiceDetail?.total_price}</h2></td>
                                                </tr>
                                                <tr>
                                                    <td className="py-2 px-0" colSpan="7"><hr /></td>
                                                </tr>
                                                <tr>
                                                    <td className="p-0" align="right" colSpan="4"><h5>Paid:</h5></td>
                                                    <td className="p-0" colSpan="3" align="right">${selectedRecurringInvoiceDetail?.payment_price}</td>
                                                </tr>
                                                <tr>
                                                    <td className="p-0" align="right" colSpan="4"><h5>Balance Due:</h5></td>
                                                    <td className="p-0" colSpan="3" align="right">${selectedRecurringInvoiceDetail?.balance_due}</td>
                                                </tr>
                                                <tr>
                                                    <td className="py-2 px-0" colSpan="7">
                                                        <p>If you use this site regularly and would like to help keep the site on the Internet, please consider donating a small sum to help pay for the hosting and bandwidth bill. If you use this site regularly and would like to help keep the site on the Internet, please consider donating a small sum to help pay for the hosting and bandwidth bill. </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                </div>
            </div>

            <Modal
                show={inVoicePaymentModal}
                onHide={setInVoicePaymentModal}
                centered

                contentClassName="mx-auto"
                className="modal"
                backdrop="static"
                keyboard={false}
            >
                <div className="modal-content font-poppins px-4 py-3">
                    <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
                </div>

                <div className="pop-form mt-2 text-center">

                    <img src={require("../../../assets/images/invoice-sent.png")} alt="" />
                    <h4 className="fs-20 mt-3 mb-2">Invoice Sent Successfully</h4>
                    <p className="fs-14 text-secondary">The invoice has been successfully dispatched to the customer via
                        email.</p>
                    <h6 className="fs-14 fw-semibold">Make Payment</h6>
                    <div className="pay-link d-flex align-items-center justify-content-center gap-2 mt-3">
                        <span className="fs-24 text-blue"></span>
                        <a onClick={() => navigate(`/admin/invoice/payment`, { state: { price: selectedInvoiceDetails?.total_price, id: selectedInvoiceDetails?.id } })} className="fs-12 text-blue lh-base">Customers will also get a make
                            <br />payment link in the invoice email.</a>
                        <span className="fs-24 text-blue"></span>
                    </div>

                </div>
            </Modal>
        </div>
    )
}
export default Invoice_Details;